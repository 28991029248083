<template>
  <aside id="sidebar-menu">
    <a ref="download-excel" download class="download-excel hidden" href="" />
    <a ref="download-pdf" download class="download-pdf hidden" href="" />

    <v-navigation-drawer floating permanent>
      <section id="date-picker">
        <pavilion-selector
          v-if="$router.currentRoute.name !== 'NotFoundPage'"
          is-for-desktop
          :brands="brands"
          :selected-brand="selectedBrand"
          :selected-primary-pavilion="selectedPrimaryPavilion"
          :selected-secondary-pavilion="selectedSecondaryPavilion"
          :selected-dates.sync="selectedDates"
          :selected-days="selectedDays"
          :changing-pavilion="changingPavilion"
        ></pavilion-selector>
      </section>
      <div
        v-if="selectedBrand && !selectedSecondaryPavilion"
        class="button-canvas"
        @click="toggleEventsMenu()"
      >
        <div class="button switch">
          <v-img src="@/assets/icons/switch.svg" max-height="56" max-width="40" />
        </div>
        <div class="event-title">
          <image-loader
            v-if="selectedBrand.logo && selectedBrand.logo.topBar.small"
            :src="selectedBrand.logo.topBar.small"
            classes="brand-logo 3"
            contain
            max-width="40"
            max-height="25"
          />
          <div
            v-if="selectedPrimaryPavilion.event && selectedPrimaryPavilion.event.name"
            class="name"
          >
            {{ selectedPrimaryPavilion.event.name | translate }}
          </div>
        </div>
      </div>
      <div v-else-if="selectedBrand && selectedSecondaryPavilion" class="button-canvas compare">
        <div class="button switch" @click="toggleCompareMenu()">
          <v-img src="@/assets/icons/switch.svg" max-height="56" max-width="40" />
        </div>
        <div class="event-title">
          <div class="name">
            {{ 'T_COMPARE' | translate }}
          </div>
        </div>
        <v-btn icon color="white" @click="onClickClear()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div v-if="!selectedSecondaryPavilion" class="main-menu">
        <div class="menu-link" @click="onClickCompare()">
          <v-img src="@/assets/icons/compare.svg" max-width="24" max-height="24" />
          <span>{{ 'T_COMPARE' | translate }}</span>
        </div>
        <div class="menu-link" @click="onClickDownload()">
          <v-progress-circular
            v-if="downloadingFile"
            size="24"
            indeterminate
            color="white"
          ></v-progress-circular>
          <v-img
            v-else
            src="@/assets/icons/download-pdf.svg"
            lazy-src="@/assets/icons/download-pdf.svg"
            max-width="24"
            max-height="24"
          />

          <span>{{
            (downloadingFile === 'pdf' ? 'T_GENERATING_PDF' : 'T_DOWNLOAD_PDF') | translate
          }}</span>
          <span v-if="filteredDates" class="filtered">{{ 'T_FILTERED' | translate }}</span>
        </div>
        <div class="menu-link" @click="onClickDownloadExcel()">
          <v-progress-circular
            v-if="downloadingFile"
            size="24"
            indeterminate
            color="white"
          ></v-progress-circular>
          <v-img
            v-else
            src="@/assets/icons/download-excel.svg"
            lazy-src="@/assets/icons/download-excel.svg"
            max-width="24"
            max-height="24"
          />
          <span>{{
            (downloadingFile === 'excel' ? 'T_GENERATING_EXCEL' : 'T_DOWNLOAD_EXCEL') | translate
          }}</span>
          <span v-if="filteredDates" class="filtered">{{ 'T_FILTERED' | translate }}</span>
        </div>
        <div class="menu-link bottom" @click="onClickLogout()">
          <v-progress-circular
            v-if="downloadingFile"
            indeterminate
            color="white"
          ></v-progress-circular>
          <v-img
            v-else
            src="@/assets/icons/log-out.svg"
            lazy-src="@/assets/icons/log-out.svg"
            max-width="24"
            max-height="24"
          />
          <span>{{ 'T_LOGOUT' | translate }}</span>
        </div>
      </div>
      <img
        v-if="!selectedSecondaryPavilion"
        src="@/assets/sidebar-menu-line.svg"
        class="sidebar-menu-line"
      />
      <sections-nav-bar />
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showEventsMenu"
      fixed
      bottom
      color="white--text"
      floating
      stateless
      class="blurred-blue"
      width="100%"
    >
      <div class="menu-header">
        <div class="menu-title">{{ 'T_SELECT_AN_AUTOSHOW' | translate }}</div>
        <v-btn icon color="white" @click="toggleEventsMenu()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <brands-list
        v-if="getBrands().length > 1 && menuSelectedBrand"
        is-for-desktop
        :brands="getBrands()"
        :selected-brand="menuSelectedBrand"
        :selected-brand-callback="setMenuSelectedBrand"
      />
      <events-menu
        v-if="getPavilionsPerYear()"
        :data="getPavilionsPerYear()"
        is-for-desktop
        :selected-pavilion-callback="onClickPrimaryPavilion"
        :selected-pavilion="menuClickedPrimaryPavilion"
        :loading="changingPavilion"
        single-event
      />
      <div
        v-if="selectedPrimaryPavilion"
        class="compare-btn-wrapper"
        :class="{ displayed: menuClickedPrimaryPavilion && selectedPrimaryPavilion }"
      >
        <v-btn
          rounded
          color="secondary"
          :loading="changingPavilion"
          @click="
            menuClickedPrimaryPavilion._id === selectedPrimaryPavilion._id
              ? toggleEventsMenu()
              : confirmPrimaryPavilion()
          "
          >{{
            menuClickedPrimaryPavilion._id === selectedPrimaryPavilion._id
              ? 'T_CLOSE'
              : 'T_CONFIRM' | translate
          }}</v-btn
        >
      </div>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showCompareMenu"
      fixed
      bottom
      color="white--text"
      floating
      stateless
      class="compare-menu"
      width="100%"
    >
      <div class="menu-header">
        <div class="menu-title">{{ 'T_COMPARE_EVENTS' | translate }}</div>
        <v-btn icon color="white" @click="toggleCompareMenu()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <brands-list
        v-if="getBrands().length > 1 && menuSelectedBrand"
        is-for-desktop
        :brands="getBrands()"
        :selected-brand="menuSelectedBrand"
        :selected-brand-callback="setMenuSelectedBrand"
      />
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="primary-pavilion">
            <div
              v-if="
                menuSelectedBrand && selectedBrand && menuSelectedBrand._id !== selectedBrand._id
              "
              class="event-name"
            >
              {{ 'T_SELECT_AN_AUTOSHOW' | translate }}
            </div>
            <div v-else-if="comparePavilions.primary" class="event-name">
              {{ comparePavilions.primary.event.name | translate | truncate(35) }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <events-menu
              v-if="getPavilionsPerYear()"
              is-for-desktop
              :compare="comparePavilions.secondary"
              :loading="changingPavilion"
              :data="getPavilionsPerYear()"
              :selected-pavilion-callback="changeComparePrimaryPavilion"
              :selected-pavilion="comparePavilions.primary"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="compare-with">
              {{ 'T_COMPARE_WITH' | translate }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <events-menu
              v-if="getPavilionsPerYear()"
              is-for-desktop
              :data="getPavilionsPerYear()"
              :loading="changingPavilion"
              :compare="comparePavilions.primary"
              :selected-pavilion-callback="changeCompareSecondaryPavilion"
              :selected-pavilion="comparePavilions.secondary"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div
        class="compare-btn-wrapper"
        :class="{
          displayed:
            comparePavilions.primary &&
            comparePavilions.primary._id &&
            comparePavilions.secondary &&
            comparePavilions.secondary._id
        }"
      >
        <v-btn rounded :loading="changingPavilion" color="secondary" @click="compareShows()">{{
          'T_COMPARE' | translate
        }}</v-btn>
      </div>
    </v-navigation-drawer>
  </aside>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import PavilionSelectorService from '@/services/PavilionSelectorService'
import CompareDataService from '@/services/CompareDataService'
import LoadingService from '@/services/LoadingService'
import DataService from '@/services/DataService'
import LoginService from '@/services/LoginService'
import PavilionSelector from '@/components/PavilionSelector'
import ImageLoader from '@/components/ImageLoader.vue'
import BrandsList from '@/components/BrandsList'
import EventsMenu from '@/components/EventsMenu'
import SectionsNavBar from '@/components/Desktop/SectionsNavBar'
export default {
  components: { PavilionSelector, BrandsList, EventsMenu, ImageLoader, SectionsNavBar },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      brands: [],
      selectedBrand: null,
      selectedPrimaryPavilion: null,
      selectedSecondaryPavilion: null,
      primaryData: null,
      secondaryData: null,
      selectedDates: null,
      selectedDays: null,
      query: '',
      showEventsMenu: false,
      showMainMenu: false,
      showCompareMenu: false,
      menuSelectedBrand: null,
      shouldDisplayMenuIcon: true,
      comparePavilions: {
        primary: false,
        secondary: false
      },
      changingPavilion: false,
      menuClickedPrimaryPavilion: false,
      previousSection: false,
      downloadingFile: false
    }
  },
  computed: {
    filteredDates() {
      return (
        _.get(this.selectedPrimaryPavilion, 'event.startDate', false) !==
          _.get(this.selectedDates, '[0]', false) ||
        _.get(this.selectedPrimaryPavilion, 'event.endDate', false) !==
          _.get(this.selectedDates, '[1]', false)
      )
    }
  },
  watch: {
    '$route.query': function () {
      this.updateQuery()
    },
    selectedDates() {
      console.info('Selected dates changed:', this.selectedDates)
    }
  },
  async mounted() {
    PavilionSelectorService.events.on('update-pavilion-data', this.setData)
    this.updateQuery()
    if (!_.isEmpty(await LoginService.getStatus())) {
      this.brands = await DataService.getBrands()
    }
  },
  methods: {
    changeComparePrimaryPavilion(pavilion) {
      this.comparePavilions.primary = pavilion
    },
    changeCompareSecondaryPavilion(pavilion) {
      this.comparePavilions.secondary = pavilion
    },
    setMenuSelectedBrand(brand) {
      console.warn('setMenuSelectedBrand', brand.key)
      this.menuSelectedBrand = brand
      // this.selectedBrand = brand
    },
    getBrands() {
      return _.get(PavilionSelectorService, 'brands', false)
    },
    async compareShows() {
      if (!this.comparePavilions.primary || !this.comparePavilions.secondary) {
        return
      }
      this.changingPavilion = true
      // console.warn(`start loading`)
      LoadingService.setLoading(true)
      await CompareDataService.setPrimaryPavilion(
        this.comparePavilions.primary,
        this.comparePavilions.secondary,
        true
      )
      this.updateCompareDataServiceData()
      this.changingPavilion = false
      this.closeAllMenus()
    },
    returnToHomepage() {
      this.$router.push('/event').catch(() => {})
    },
    setData(data) {
      _.each(data, (val, key) => {
        _.set(this, key, val)
      })
      this.isLoading = false
      this.$forceUpdate()
    },
    closeAllMenus() {
      this.showMainMenu = false
      this.showEventsMenu = false
      this.showCompareMenu = false
    },
    toggleCompareMenu() {
      this.showCompareMenu = !this.showCompareMenu
      this.menuSelectedBrand = _.cloneDeep(this.selectedBrand)
      this.updateCompareDataServiceData(false)
    },
    toggleMainMenu() {
      this.showMainMenu = !this.showMainMenu
      this.showCompareMenu = false
    },
    toggleEventsMenu() {
      this.selectedBrand = CompareDataService.primaryPavilion.brand
      this.menuSelectedBrand = _.cloneDeep(this.selectedBrand)
      this.menuClickedPrimaryPavilion = _.cloneDeep(this.selectedPrimaryPavilion)
      this.showEventsMenu = !this.showEventsMenu
    },
    getPavilionsPerYear() {
      const brand = _.get(this, 'menuSelectedBrand', this.selectedBrand)
      if (!_.get(brand, 'pavilions', false)) {
        return false
      }
      const tmp = _.groupBy(brand.pavilions, (p) => _.get(p, 'event.year', false))
      const orderedPavilions = []
      const years = _.reverse(_.sortBy(_.keys(tmp), (year) => year))
      _.each(years, (year) => {
        orderedPavilions.push({
          year,
          pavilions: _.reverse(_.sortBy(tmp[year], (pavilion) => _.get(pavilion, 'event.endDate')))
        })
      })
      return orderedPavilions
    },
    updateQuery() {
      this.shouldDisplayMenuIcon = this.$router.currentRoute.name !== 'NotFoundPage'
      const queryList = _.map(this.$route.query, (value, key) => `${key}=${value}`)
      this.query = `?${queryList.join('&')}`
    },
    async onClickComparePavilions() {
      this.showEventsMenu = false
    },
    async onClickClear() {
      await CompareDataService.setSecondaryPavilion(null)
      this.updateCompareDataServiceData()
    },
    async onClickPrimaryPavilion(pavilion) {
      if (this.changingPavilion) {
        return
      }
      this.menuClickedPrimaryPavilion = pavilion
    },
    async confirmPrimaryPavilion() {
      this.selectedPrimaryPavilion = this.menuClickedPrimaryPavilion
      this.changingPavilion = true
      LoadingService.setLoading(true)
      await PavilionSelectorService.getData(
        {
          primaryPavilion: _.get(this.menuClickedPrimaryPavilion, '_id', false),
          secondaryPavilion: null
        },
        true,
        true
      )
      this.brands = PavilionSelectorService.brands
      const foundPavilion = this.findUpdatedPavilion(this.brands, this.menuClickedPrimaryPavilion)
      await CompareDataService.setPrimaryPavilion(foundPavilion, false, true)
      PavilionSelectorService.selectedPrimaryPavilion = foundPavilion
      this.updateCompareDataServiceData()
      this.changingPavilion = false
      this.toggleEventsMenu()
    },
    findUpdatedPavilion(brands, pavilion) {
      let foundPavilion = false
      _.each(brands, (brand) => {
        foundPavilion = _.find(brand.pavilions, { _id: _.get(pavilion, '_id', false) })
        if (!_.isUndefined(foundPavilion)) {
          return false
        }
      })
      return _.isUndefined(foundPavilion) ? false : foundPavilion
    },
    updateCompareDataServiceData(redirect = true) {
      this.selectedBrand = CompareDataService.primaryPavilion.brand
      this.menuSelectedBrand = _.cloneDeep(this.selectedBrand)
      this.comparePavilions.primary = _.get(CompareDataService, 'primaryPavilion', false)
      this.comparePavilions.secondary = _.get(CompareDataService, 'secondaryPavilion', false)
      this.selectedPrimaryPavilion = _.get(CompareDataService, 'primaryPavilion', false)
      this.selectedSecondaryPavilion = _.get(CompareDataService, 'secondaryPavilion', false)
      // console.warn('selectedPrimaryPavilion: ', this.selectedPrimaryPavilion)
      // console.warn('selectedSecondaryPavilion: ', this.selectedSecondaryPavilion)
      // NOTE: Redirects after show selection
      if (redirect) {
        this.$router.push('/event').catch(() => {})
        setTimeout(() => {
          // console.warn(`finished loading`)
          LoadingService.setLoading(false)
        }, 150)
        console.warn(`will redirect to event section`)
      }
      this.$forceUpdate()
    },
    onClickCompare() {
      this.showCompareMenu = !this.showCompareMenu
      this.updateCompareDataServiceData(false)
    },
    async onClickDownload() {
      if (this.downloadingFile) {
        return
      }
      try {
        this.downloadingFile = 'pdf'
        const blob = await PavilionSelectorService.downloadPDF(this.selectedDates)
        if (blob instanceof Blob) {
          const blobUrl = URL.createObjectURL(blob)
          const link = this.$refs['download-pdf']
          link.href = blobUrl
          link.download = this.getExportFilename('pdf')
          link.click()
        }
      } catch (error) {
        console.error(`onClickDownload - Error:`, error)
      }
      this.downloadingFile = false
    },
    async onClickDownloadExcel() {
      if (this.downloadingFile) {
        return
      }
      try {
        this.downloadingFile = 'excel'
        const blob = await PavilionSelectorService.downloadExcel(this.selectedDates)
        if (blob instanceof Blob) {
          const blobUrl = URL.createObjectURL(blob)
          const link = this.$refs['download-excel']
          link.href = blobUrl
          link.download = this.getExportFilename('xlsx')
          link.click()
        }
      } catch (error) {
        console.error(`onClickDownloadExcel - Error:`, error)
      }
      this.downloadingFile = false
    },
    getExportFilename(type) {
      return `${_.get(this.primaryData, 'pavilion.brand.key', 'export')}-${_.get(
        this.primaryData,
        'pavilion.event.key',
        false
      )}-${moment().format('YYYY-MM-DD')}.${type}`
    },
    drawerWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? 440 : 307
    },
    async onClickLogout() {
      if (this.downloadingFile) {
        return
      }
      try {
        await LoginService.logout()
        this.$router.push({ path: '/', query: {} }).catch(() => {})
        this.$router.go()
      } catch (error) {
        console.error(error)
      }
    },
    getSectionIcon(section, active) {
      return require(`@/assets/icons/${section.icon}${active ? '-active' : ''}.svg`)
    },
    getCurrentSectionKey(defaultVal = false) {
      return _.get(_.split(this.$route.path, '/'), [1], defaultVal ? defaultVal : 'event')
    },
    goToSection(key) {
      if (this.getCurrentSectionKey() === key) {
        return
      }
      this.$router
        .push({
          path: `/${key}`,
          query: this.$route.query
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../varsDesktop.scss';
#sidebar-menu {
  min-width: 280px;
  width: 280px;
  height: calc(100vh - 40px);
  color: white;
  background: #3e4b78;
  user-select: none;
  .menu-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 36px 0;
    padding-left: 32px;
    cursor: pointer;
    .v-progress-circular {
      margin-right: 8px;
    }
    .v-image {
      margin-right: 8px;
    }
    span {
      line-height: 24px;
    }
  }
  .sidebar-menu-line {
    width: 60%;
    height: 2px;
    margin-left: 32px;
  }
  .theme--light.v-navigation-drawer {
    padding-top: 0;
  }
  .compare-menu,
  .blurred-blue {
    top: 40px !important;
    width: 400px !important;
    height: calc(100vh - 40px) !important;
  }
  .compare-menu {
    .menu-header {
      background: linear-gradient(90deg, #71dbd4 10.64%, #5ab9dc 55.32%, #8094dd 100%);
    }
  }
  .blurred-blue {
    background-color: rgba(128, 148, 221, 1);
  }
  .menu-header {
    background-color: $cyan;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    .menu-title {
      font-style: italic;
      font-weight: 400;
      font-size: 25px;
      line-height: 31px;
    }
  }
  .event-name {
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
  }
}
#date-picker {
  background-color: $cyan;
}

.compare-btn-wrapper {
  margin-top: 32px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  touch-action: none;
  button {
    pointer-events: auto;
    touch-action: auto;
  }
  &.displayed {
    opacity: 1;
  }
}
.button-canvas {
  width: 100%;
  height: 56px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  > * {
    height: 100%;
  }
  .event-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
    font-style: italic;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    .name {
      width: 80%;
    }
  }
  .button {
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
    justify-content: center;
    width: 40px;

    .wrapper {
      padding: 16px;
    }

    &.switch {
      width: 40px;
      padding: 0;
    }
  }
  &.compare {
    background: linear-gradient(90deg, #71dbd4 10.64%, #5ab9dc 55.32%, #8094dd 100%);
    .name {
      font-style: italic;
      font-weight: 400;
      font-size: 25px;
      line-height: 31px;
      padding-left: 16px;
    }
  }
}
</style>
<style lang="scss">
#sidebar-menu {
  .compare-menu,
  .blurred-blue {
    .v-expansion-panel {
      margin-top: 0;
    }
  }
  .compare-menu {
    .v-navigation-drawer__content {
      background: linear-gradient(
        90deg,
        rgba(113, 219, 212, 0.5) 0%,
        rgba(90, 185, 220, 0.5) 50%,
        rgba(128, 148, 221, 0.5) 100%
      );
    }
  }
  .blurred-blue {
    .events-menu {
      padding: 32px;
    }
  }
  .filtered {
    font-size: 12px;
    line-height: 24px;
    margin-left: 6px;
    color: #8094dd;
  }
}
</style>
