<template>
  <div
    class="pavilion-experiences"
    :class="{ compare: compare, 'is-for-pdf': isForPdf, 'is-for-desktop': isForDesktop }"
  >
    <div v-for="experience in experiences" :key="experience._id" class="experience">
      <v-card class="no-gradient">
        <template v-if="isForPdf">
          <div class="info-box">
            <h1>{{ experience.name | translate }}</h1>
          </div>
          <div class="souvenir-types">
            <span>{{ getSouvenirTypes(experience) }}</span>
          </div>
        </template>
        <template v-else-if="compare">
          <h1>{{ experience.name | translate }}</h1>
          <div class="image">
            <template
              v-if="experience.gallery && experience.gallery[0] && experience.gallery[0].url"
            >
              <img v-if="isForPdf" :src="experience.gallery[0].url" class="img" height="68" />
              <image-loader
                v-else
                :src="experience.gallery[0].url"
                classes="img"
                gradient-loading
                :lazy-src="getLazySrc(experience.gallery[0].url, '80')"
                :aspect-ratio="16 / 9"
                @click.native="displayFullscreen(experience)"
              />
            </template>

            <div
              v-if="getSouvenirTypes(experience)"
              class="souvenir-types"
              @click="displayFullscreen(experience, true)"
            >
              <v-img
                v-if="!isForPdf"
                src="@/assets/icons/souvenir.svg"
                lazy-src="@/assets/icons/souvenir.svg"
                max-width="16"
                max-height="16"
                width="16"
                height="16"
              />
              <img
                v-else
                src="@/assets/icons/souvenir.svg"
                max-width="16"
                max-height="16"
                width="16"
                height="16"
              />
              <span>{{ getSouvenirTypes(experience) }}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="image">
            <template
              v-if="experience.gallery && experience.gallery[0] && experience.gallery[0].url"
            >
              <image-loader
                :src="experience.gallery[0].url"
                classes="img"
                gradient-loading
                :lazy-src="getLazySrc(experience.gallery[0].url, '80')"
                :aspect-ratio="16 / 9"
                @click.native="displayFullscreen(experience)"
              />
            </template>
            <div
              v-if="getSouvenirTypes(experience)"
              class="souvenir-types"
              @click="displayFullscreen(experience, true)"
            >
              <v-img
                src="@/assets/icons/souvenir.svg"
                lazy-src="@/assets/icons/souvenir.svg"
                max-width="16"
                max-height="16"
                width="16"
                height="16"
              />
              <span>{{ getSouvenirTypes(experience) }}</span>
            </div>
          </div>
          <div class="info-box">
            <h1>{{ experience.name | translate }}</h1>
            <p>{{ experience.description | translate }}</p>
          </div>
        </template>
        <div v-if="isForPdf" class="other-images" :class="experience.imgsClass">
          <img
            v-if="
              experience.souvenirExamples &&
              experience.souvenirExamples[0] &&
              experience.souvenirExamples[0].url
            "
            :src="experience.souvenirExamples[0].url"
            class="img"
            width="55"
          />
          <img
            v-if="
              experience.souvenirExamples &&
              experience.souvenirExamples[1] &&
              experience.souvenirExamples[1].url
            "
            :src="experience.souvenirExamples[1].url"
            class="img"
            width="55"
          />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import TranslateService from '@/services/TranslateService'
import FullscreenViewerService from '@/services/FullscreenViewerService'
import ImageLoader from '@/components/ImageLoader.vue'

export default {
  components: { ImageLoader },
  props: {
    pavilion: {
      type: Object,
      default: () => {}
    },
    compare: {
      type: Boolean,
      default: false
    },
    isForPdf: {
      type: Boolean,
      default: false
    },
    isForDesktop: {
      type: Boolean,
      default: false
    },
    allLoaded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      experiences: false
    }
  },
  async mounted() {
    const experiences = _.cloneDeep(this.pavilion.experiences)
    _.each(experiences, (experience) => {
      experience.souvenirExamples = _.filter(
        experience._attachments,
        (attachment) => attachment._name === 'souvenirExamples'
      )
      experience.gallery = _.filter(
        experience._attachments,
        (attachment) => attachment._name === 'gallery'
      )
    })
    this.experiences = _.take(experiences, 9)
    this.$emit('update:allLoaded', true)
    for (let index = 0; index < experiences.length; index++) {
      experiences[index].imgsClass = await this.getOtherImagesLayout(experiences[index])
    }
  },
  methods: {
    getLazySrc(url, resizeOptions) {
      return `${url}?resize=${resizeOptions}`
    },
    async getOtherImagesLayout(experience) {
      const firstImg = _.get(experience, 'souvenirExamples[0].url', false)
      const secondImg = _.get(experience, 'souvenirExamples[1].url', false)
      if (firstImg && secondImg) {
        return 'square'
      } else if (firstImg && !secondImg) {
        // console.warn(`will load image = `, firstImg)
        const { width, height } = await this.loadImg(firstImg)
        if (width > height) {
          return 'landscape'
        }
      }
      return 'portrait'
    },
    async loadImg(imgUrl) {
      return new Promise((resolve) => {
        const img = new Image()
        let timeout = false
        img.onload = function () {
          clearTimeout(timeout)
          resolve({ width: this.width, height: this.height })
        }
        img.src = imgUrl
        timeout = setTimeout(() => {
          console.info(`Timed out when trying to load image: ${imgUrl}`)
          resolve({ width: 1, height: 1 })
        }, 5000)
      })
    },
    getSouvenirTypes(experience) {
      const souvenirTypes = []
      _.each(experience.souvenirTypes, (type) => {
        souvenirTypes.push(TranslateService.get('T_' + _.toUpper(type)))
      })
      return souvenirTypes.length === 0 ? false : _.join(souvenirTypes, ', ')
    },
    displayFullscreen(experience, displayFromSouvenirExamples = false) {
      FullscreenViewerService.events.emit(
        'displayViewer',
        {
          progress: displayFromSouvenirExamples ? experience.gallery.length : 0,
          items: _.concat(experience.gallery, experience.souvenirExamples)
        },
        true
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';

.pavilion-experiences {
  .info-box {
    font-style: italic;
    color: white;
    margin-left: $standard-margin;
  }
  h1,
  .souvenir-types {
    font-weight: 700;
    font-size: $scale-vw * 14;
    line-height: $scale-vw * 18;
  }
  h1,
  .v-image {
    margin-bottom: $half-margin;
  }
  p {
    font-weight: 400;
    font-size: $scale-vw * 12;
    line-height: $scale-vw * 15;
  }
  .experience {
    > .v-card {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: $standard-margin;
    }
  }
  .souvenir-types {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $purple;
    font-style: normal;
    span {
      margin-left: $half-margin;
    }
    .v-image {
      margin-bottom: 0;
    }
  }
  .image {
    width: calc(45% - #{$half-margin});

    > .v-image {
      border-radius: 4px;
    }
  }
  .v-card {
    width: 100%;
  }
  .info-box {
    width: calc(55% - #{$half-margin});
    text-align: left;
  }
  &.compare {
    .v-card {
      padding: $half-margin;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      .image {
        width: 100%;
      }
    }
  }
  &.is-for-pdf {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 8px;
    .theme--light.v-card.no-gradient {
      background-color: #e6e6e6;
      flex-direction: column;
      margin-bottom: 0;
    }
    .info-box {
      width: 100%;
      margin-left: 0;
      margin-top: 8px;
      color: black;
      h1 {
        font-style: italic;
        font-weight: 700;
        font-size: 8px;
        line-height: 10px;
        margin-bottom: 8px;
      }
      p {
        font-style: italic;
        font-weight: 400;
        font-size: 6px;
        line-height: 8px;
      }
    }
    .souvenir-types {
      font-style: normal;
      font-weight: 700;
      font-size: 6px;
      line-height: 8px;
      span {
        margin-left: 4px;
      }
    }
    .img {
      border-radius: 4px;
    }
    .image {
      width: 100%;
      .img {
        width: 100%;
      }
      > .v-image {
        margin-bottom: 8px;
      }
    }
    .experience {
      // min-height: 220px;
      width: calc((100% - 9px) / 3);
      margin: 0;
      margin-bottom: 3px;
      > .v-card {
        padding: 8px;
        box-shadow: none;
        height: 100%;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
    .other-images {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .v-image {
        margin-bottom: 0;
        border-radius: 4px;
      }
      .v-image + .v-image {
        margin-left: 4px;
      }
      img {
        max-height: 64px;
      }
      &.square {
        img {
          max-width: 55px;
        }
      }
      &.landscape {
        width: 100%;
      }
      &.portrait {
        img {
          max-width: 45px;
        }
      }
    }
  }
  &:not(.is-for-pdf) {
    &.is-for-desktop {
      .experience {
        margin: 0 8px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
@media (min-width: 500px) {
  .pavilion-experiences {
    .info-box {
      margin-left: $desktop-standard-margin;
    }
    h1,
    .souvenir-types {
      font-size: $desktop-scale-vw * 16;
      line-height: $desktop-scale-vw * 20;
    }
    h1,
    .v-image {
      margin-bottom: $desktop-half-margin;
    }
    p {
      font-size: $desktop-scale-vw * 14;
      line-height: $desktop-scale-vw * 17;
    }
    .experience {
      display: inline-block;
      width: calc((100% - (#{$desktop-half-margin} * 3)) / 4);
      > .v-card {
        padding: $desktop-standard-margin;
      }
    }
    .souvenir-types {
      span {
        margin-left: $desktop-half-margin;
      }
    }
    .image {
      width: calc(45% - #{$desktop-half-margin});
    }
    .info-box {
      width: calc(55% - #{$desktop-half-margin});
    }
  }
}
</style>
<style lang="scss" scoped>
@import '../../vars.scss';
@media (min-width: 500px) {
  .pavilion-experiences {
    &.is-for-desktop {
      &.compare {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .v-card {
          padding: $desktop-half-margin;
          width: 100%;
        }
        .experience {
          width: calc((100% - #{$desktop-half-margin}) / 2);
        }
      }
    }
  }
}
</style>
