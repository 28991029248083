<template>
  <div v-if="sections.length > 0" id="sections-nav-bar" class="desktop">
    <div class="sections-wrapper">
      <div
        v-for="section in sections"
        :key="section.key"
        class="section-link"
        :class="{ selected: getCurrentSectionKey() === section.key }"
        @click="goToSection(section.key)"
      >
        <div v-if="section.key === 'traffic' || section.key === 'sales'" class="icon-wrapper">
          <img :src="getSectionIcon(section, getCurrentSectionKey() === section.key)" />
        </div>
        <div v-else class="icon-wrapper">
          <img :src="getSectionIcon(section)" />
        </div>
        <div class="section-title">
          {{ section.title | translate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import PavilionSelectorService from '@/services/PavilionSelectorService'

export default {
  components: {},
  props: {},
  data() {
    return {
      sections: false
    }
  },
  mounted() {
    if (this.getCurrentSectionKey('none') === 'none') {
      this.goToSection('event')
    }
    this.sections = _.get(PavilionSelectorService, 'filteredSections', false)
    PavilionSelectorService.events.on('filtered-sections', (sections) => {
      this.sections = sections
      const currentSectionKey = this.getCurrentSectionKey()
      if (
        currentSectionKey !== 'pdf' &&
        !_.includes(_.map(this.sections, 'key'), currentSectionKey)
      ) {
        console.warn(
          `section ${currentSectionKey} is not accessible, will redirect to event section`
        )
        this.goToSection(`event`)
      }
      this.$forceUpdate()
    })
  },
  methods: {
    getSectionIcon(section, active) {
      return require(`@/assets/icons/${section.icon}${active ? '-active' : ''}.svg`)
    },
    getCurrentSectionKey(defaultVal = false) {
      return _.get(_.split(this.$route.path, '/'), [1], defaultVal ? defaultVal : 'event')
    },
    goToSection(key) {
      if (this.getCurrentSectionKey() === key) {
        return
      }
      this.$router
        .push({
          path: `/${key}`,
          query: this.$route.query
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../varsDesktop.scss';
#sections-nav-bar {
  width: 100%;
  height: $scale-vh * 96;
  color: white;
  .sections-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
    overflow: hidden;
    text-align: center;
    padding: 16px 32px;
  }

  .section-link {
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    .icon-wrapper {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .section-title {
      margin-left: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
    &.selected {
      .section-title {
        font-weight: 700;
      }
      .icon-wrapper {
        background: linear-gradient(180deg, $orange 0%, $purple 100%);
      }
    }
  }
}
</style>
