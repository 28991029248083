var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"desktop",class:[
    _vm.TranslateService.currentLocale,
    !_vm.user ? 'gradient-background' : '',
    _vm.currentSectionKey === 'pdf' ? 'is-for-pdf' : ''
  ]},[(!_vm.isLoaded)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):[_c('v-main',{staticClass:"main-container",class:{
        'no-scroll': _vm.showFullscreenOverlay,
        'no-margin-bottom': _vm.currentSectionKey === 'pdf'
      }},[(_vm.currentSectionKey !== 'pdf')?_c('nav-bar',{attrs:{"user":_vm.user}}):_vm._e(),(!_vm.user)?_c('login-page',{attrs:{"is-for-desktop":"","user":_vm.user,"success-login-callback":_vm.onLoginCallback},on:{"update:user":function($event){_vm.user=$event}}}):[(_vm.currentSectionKey === 'pdf')?[_c('router-view')]:[(_vm.currentSectionKey !== 'pdf')?_c('sidebar-menu',{attrs:{"user":_vm.user}}):_vm._e(),_c('section',{attrs:{"id":"content-wrapper"}},[_c('router-view'),_c('viewer-fullscreen',{attrs:{"is-for-desktop":"","current-section-key":_vm.currentSectionKey,"current-fullscreen-slide":_vm.currentFullscreenSlide,"show-fullscreen-overlay":_vm.showFullscreenOverlay,"fullscreen-image":_vm.fullscreenImage,"fullscreen-content":_vm.fullscreenContent,"fullscreen-items":_vm.fullscreenItems,"close-callback":_vm.closeFullscreenViewer}})],1)]]],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }