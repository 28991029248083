<template>
  <div class="pavilion-gallery" :class="{ compare: compare, 'is-for-desktop': isForDesktop }">
    <div class="carousel">
      <v-carousel
        ref="carousel"
        :height="carouselHeight"
        width="100%"
        :value="selectedSlide"
        delimiter-icon="mdi-minus-thick"
        hide-delimiters
        eager
        :touchless="isForDesktop"
        :show-arrows="false"
        @change="changedSlide"
      >
        <v-carousel-item
          v-for="(image, idx) in pavilion.gallery"
          :key="`img-${idx}`"
          eager
          @click="displayFullscreen()"
        >
          <image-loader
            eager
            gradient-loading
            :aspect-ratio="16 / 9"
            :src="image"
            :lazy-src="getLazySrc(image, '1000')"
          />
        </v-carousel-item>
      </v-carousel>
      <div class="progress">
        <div
          v-for="(image, idx) in pavilion.gallery"
          :key="idx"
          class="dot"
          :class="{ selected: image == selected }"
          @click="changedSlide(idx)"
        >
          <div class="dot-icon"></div>
        </div>
      </div>
      <div class="gallery-divider" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import FullscreenViewerService from '@/services/FullscreenViewerService'
import ImageLoader from '@/components/ImageLoader.vue'
import Carousel from '@/mixins/Carousel.vue'

export default {
  components: { ImageLoader },
  mixins: [Carousel],
  props: {
    pavilion: {
      type: Object,
      default: () => {}
    },
    compare: {
      type: Boolean,
      default: false
    },
    isForDesktop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: _.first(this.pavilion.gallery),
      carouselHeight: this.resizeCarousel(),
      selectedSlide: 0,
      canClick: true,
      canClickTimeout: false
    }
  },
  watch: {
    pavilion() {
      this.changedSlide(0)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeCarousel()
      this.changedSlide(0)
    })
  },
  created() {
    window.addEventListener('resize', this.resizeCarousel)
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeCarousel)
  },
  methods: {
    getLazySrc(url, resizeOptions) {
      return `${url}?resize=${resizeOptions}`
    },
    changedSlide(slideIndex) {
      let tmp = slideIndex
      if (tmp < 0) {
        tmp = _.get(this.pavilion, 'gallery.length', 1) - 1
      } else if (tmp >= _.get(this.pavilion, 'gallery.length', 0)) {
        tmp = 0
      }
      this.selectedSlide = tmp
      this.selected = _.get(
        this.pavilion,
        `gallery[${this.selectedSlide}]`,
        _.first(this.pavilion.gallery)
      )
      this.canClick = false
      clearTimeout(this.canClickTimeout)
      this.canClickTimeout = setTimeout(() => {
        this.canClick = true
      }, 300)
    },
    resizeCarousel() {
      if (this.isForDesktop) {
        this.carouselHeight = '300px'
        return
      }
      if (this.compare) {
        this.carouselHeight = '85px'
      } else {
        this.carouselHeight = window.innerWidth < window.innerHeight ? '180px' : '400px'
      }
      this.$forceUpdate()
    },
    displayFullscreen() {
      if (this.drag || !this.canClick) {
        return
      }
      clearTimeout(this.canClickTimeout)
      const items = []
      _.each(this.pavilion.gallery, (img) => {
        items.push(_.find(this.pavilion._attachments, { url: img }))
      })
      FullscreenViewerService.events.emit(
        'displayViewer',
        { progress: this.selectedSlide, items },
        true
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';

.pavilion-gallery {
  display: flex;
  .carousel {
    width: 100%;
    max-height: 400px;
    border-radius: $half-margin;
    overflow: hidden;
    .progress {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: $half-margin;
      .dot {
        padding: 8px;
        .dot-icon {
          height: 4px;
          width: 4px;
          background-color: white;
          transform: scale(1);
          transition: background-color 0.3s, transform 0.3s;
          border-radius: 50%;
        }
        &.selected {
          .dot-icon {
            border-radius: 1px;
            background-color: $orange;
            transform: scaleX(2);
          }
        }
      }
    }
  }
  .gallery-divider {
    width: 100%;
    border-bottom: 1px dashed white;
    margin-top: $half-margin;
    margin-bottom: $standard-margin;
  }
  &.compare {
    .progress {
      margin-top: 0;
    }
    .gallery-divider {
      width: 100%;
      border-bottom: 1px dashed white;
      margin: $half-margin 0;
      margin-top: 0;
    }
  }
  &.is-for-desktop {
    .gallery-divider {
      margin: 16px 0;
    }
    .progress {
      margin-top: 16px;
    }
  }
}
</style>
<style lang="scss">
@import '../../vars.scss';

.pavilion-gallery {
  .carousel {
    position: relative;
    .v-btn {
      .v-btn__content {
        .v-icon {
          color: white;
        }
      }
      &.v-item--active {
        .v-btn__content {
          .v-icon {
            color: $orange;
          }
        }
      }
      &:before {
        background-color: transparent;
      }
    }
    .v-carousel__controls__item .v-icon {
      opacity: 1;
    }
    .v-image {
      border-radius: $half-margin;
    }
  }
}
</style>
