<script>
// import _ from 'lodash'
// import moment from 'moment'

export default {
  name: 'Carousel',
  data() {
    return {
      move: [],
      selectedSlide: 0,
      drag: false,
      touch: false
    }
  },
  async mounted() {
    // For touch devices
    if (!this.isForDesktop) {
      return
    }
    this.$refs.carousel.$el.addEventListener('touchmove', (event) => {
      this.drag = false
      this.touch = true
      this.logic(event)
    })
    window.addEventListener('touchend', () => {
      this.move = []
    })
    // For non-touch devices
    this.$refs.carousel.$el.addEventListener('mousedown', (event) => {
      this.drag = true
      this.touch = false
      this.logic(event)
    })
    this.$refs.carousel.$el.addEventListener('mousemove', (event) => {
      this.drag ? this.logic(event) : null
    })
    window.addEventListener('mouseup', () => {
      this.drag = false
      this.touch = false
      this.move = []
    })
  },
  methods: {
    logic(event) {
      let currentMove = this.touch ? event.touches[0].clientX : event.clientX
      if (this.move.length == 0) {
        this.move.push(currentMove)
      }
      if (this.move[this.move.length - 1] - currentMove < -100) {
        this.changedSlide(this.selectedSlide - 1)
        this.drag = false
        this.touch = false
      }
      if (this.move[this.move.length - 1] - currentMove > 100) {
        this.changedSlide(this.selectedSlide + 1)
        this.drag = false
        this.touch = false
      }
    }
  }
}
</script>
