<template>
  <div class="line-chart">
    <canvas ref="canvas" />
  </div>
</template>
<script>
import _ from 'lodash'
import Chart from 'chart.js/auto'
// import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartsDataService from '@/services/ChartsDataService'
import numeral from 'numeral'

// Chart.register(ChartDataLabels)

Chart.register({
  id: 'custom-line',
  afterDraw: function (chart) {
    if (
      !_.get(chart, 'options.drawLineSelection', false) ||
      _.get(chart, 'data.datasets', []).length === 0
    ) {
      return
    }
    const activePoint = chart.data.datasets[0].activeElement || 0
    if (activePoint === -1) {
      // NOTE: If no day selected
      return
    }
    const ctx = chart.ctx
    const xAxis = chart.scales.x
    const yAxis = chart.scales.y
    const x = xAxis.getPixelForTick(activePoint - 1)
    const yTop = 0
    ctx.save()
    if (ctx.setLineDash !== undefined) {
      ctx.setLineDash([2, 5])
    }
    if (ctx.mozDash !== undefined) {
      ctx.mozDash = [2, 5]
    }
    ctx.strokeStyle = '#aaaaaa'
    ctx.beginPath()
    ctx.moveTo(x, yAxis.bottom)
    ctx.lineTo(x, yTop)
    ctx.stroke()
    ctx.restore()
  }
})
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {}
    },
    highlightValue: {
      type: Number,
      default: 0
    },
    isForPdf: {
      type: Boolean,
      default: false
    },
    forceColor: {
      type: String,
      default: ''
    }
  },
  watch: {
    data() {
      _.each(this.data.datasets, (dataset, i) => {
        this.chart.data.datasets[i].data = []
        _.each(dataset.data, (d) => {
          this.chart.data.datasets[i].data.push(d)
        })
      })
      this.chart.data.labels = []
      this.chart.update('none')
      _.each(this.data.labels, (label) => {
        this.chart.data.labels.push(label)
      })
      this.chart.update('none')
    },
    highlightValue() {
      this.updateHighlightedValue()
    }
  },
  mounted() {
    const chartsBackgroundColors = _.isEmpty(this.forceColor)
      ? ChartsDataService.lineColors
      : [this.forceColor]
    const ctx = this.$refs.canvas.getContext('2d', { willReadFrequently: true })
    let options = {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: 'index',
        intersect: false
      },
      tooltips: {
        mode: 'index',
        intersect: false
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
      plugins: {
        tooltip: {
          enabled: false
        },
        legend: {
          display: false,
          position: 'top',
          align: 'start',
          labels: ChartsDataService.getDefaultLabelsConf(),
          onClick: () => false
        },
        datalabels: {
          display: false
        },
        drawSelectionLine: !_.get(this.options, 'drawSelectionLine', false) ? false : {}
      },
      scales: {
        x: {
          ticks: {
            font: ChartsDataService.getDefaultFont(this.isForPdf),
            color: this.isForPdf ? 'black' : '#8094DD'
          }
        },
        y: {
          ticks: {
            font: ChartsDataService.getDefaultFont(this.isForPdf),
            color: this.isForPdf ? 'black' : '#8094DD'
          },
          grid: {
            borderDash: [3, 5],
            drawBorder: this.isForPdf,
            color: this.isForPdf ? this.gridColorForPdf : this.gridColor
            // color: this.isForPdf ? 'black' : '#8094DD'
          },
          beginAtZero: true
        }
      },
      backgroundColor: 'red' // ChartsDataService.lineColors
    }
    if (this.isForPdf) {
      options.borderColor = '#E1E000'
    }
    options = _.merge(options, this.options)
    _.set(options, 'scales.y.ticks.maxTicksLimit', 7)
    _.set(options, 'scales.x.grid.display', false)
    _.set(options, 'scales.y.ticks.callback', ChartsDataService.formatYTicksLabels)
    const customDatasetBackgroundColors = _.get(options, 'datasetsBackgroundColors', false)
    _.each(this.data.datasets, (dataset, index) => {
      dataset.pointHitRadius = 50
      dataset.pointRadius = 3
      dataset.activeElement = -1
      dataset.borderWidth = 2
      dataset.backgroundColor = _.get(
        customDatasetBackgroundColors || chartsBackgroundColors,
        `[${index}]`,
        '#4285F4'
      )
    })
    // _.set(options, 'scales.y.max', ChartsDataService.getMaxValueForYAxis(this.data.datasets))
    _.set(options, 'scales.y.type', 'linear')
    _.set(options, 'scales.y.grace', '25%')
    if (this.isForPdf) {
      _.set(options, 'animation.duration', 0)
      if (!_.get(options, 'plugins.datalabels.labels.title.font.family', false)) {
        _.set(options, 'plugins.datalabels', {
          display: true,
          align: 'center',
          opacity: 1,
          labels: {
            title: {
              font: {
                family: 'SourceSansPro-Bold',
                size: 8,
                weight: 700
              },
              anchor: 'end',
              align: 'top',
              offset: '0',
              color: 'black',
              formatter: function (value, context) {
                const val = _.get(context, `dataset.data[${context.dataIndex}]`, 0)
                return val === 0 ? '' : numeral(val).format('0,0')
              }
            }
          }
        })
      }
    }
    this.chart = new Chart(ctx, {
      type: 'line',
      data: this.data,
      options
    })
    this.updateHighlightedValue()
  },
  methods: {
    gridColorForPdf(context) {
      return `rgba(0, 0, 0, ${context.tick.value === 0 ? 0 : 0.2})`
    },
    gridColor() {
      return '#8094DD'
    },
    updateHighlightedValue() {
      this.chart.update()
      this.chart.activeElements = []
      _.each(this.chart.data.datasets, (dataset, datasetIndex) => {
        dataset.pointRadius = this.getPointRadii(dataset, datasetIndex)
        dataset.activeElement = !_.includes(dataset.pointRadius, 0) ? -1 : this.highlightValue
        if (datasetIndex === 0) {
          this.chart.options.scales.x.ticks.color = []
          this.chart.options.scales.x.ticks.color = this.getTickColor(dataset)
        }
      })
      this.chart.update()
    },
    getTickColor(dataset) {
      const colors = []
      if (this.isForPdf) {
        _.each(dataset.data, () => {
          colors.push('black')
        })
      } else if (this.highlightValue === 0) {
        _.each(dataset.data, () => {
          colors.push(this.isForPdf ? 'black' : '#8094DD')
        })
      } else {
        _.each(dataset.data, (data, i) => {
          colors.push(i !== this.highlightValue - 1 ? '#8094DD' : 'white')
        })
      }
      return colors
    },
    getPointRadii(dataset) {
      const radius = []
      if (this.highlightValue === 0) {
        _.each(dataset.data, () => {
          radius.push(3)
        })
      } else {
        _.each(dataset.data, (d, i) => {
          radius.push(i === this.highlightValue - 1 ? 3 : 0)
        })
      }
      return radius
    }
  }
}
</script>

<style scoped></style>
