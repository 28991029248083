<template>
  <v-card :class="'content-card ' + classes" @click="redirectToSection()">
    <div class="card-header">
      <div class="card-title">
        <span
          v-if="type === 'compare' && compareData && compareData.circleColor"
          class="circle-color"
          :style="`background-color:${compareData.circleColor}`"
        /><span class="truncate-title">{{ title }}</span>
        <span v-if="contentList.length > 0 && showListTotal">({{ contentList.length }})</span>
      </div>
      <v-icon
        v-if="canExpand"
        :class="{ hidden: noData }"
        color="purple"
        class="fullscreen-icon"
        @click="displayFullscreen(title, contentList.length > 0 ? contentList : content)"
        >mdi-fullscreen</v-icon
      >
      <v-img
        v-else-if="!noData && link.length > 0"
        src="@/assets/icons/jump-arrow.svg"
        width="16"
        height="16"
      />
    </div>
    <div class="card-content">
      <content>
        <template v-if="noData">
          <div v-if="isForPdf" class="no-data">
            {{ 'T_NO_DATA_PDF' | translate }}
          </div>
          <div v-else class="no-data">
            {{ 'T_NO_DATA' | translate }}
          </div>
        </template>
        <template v-else-if="contentList.length > 0">
          <ul>
            <li v-for="(item, i) in truncatedList" :key="i">
              <v-icon v-if="hasListBullet" color="white" x-small>mdi-circle</v-icon>
              {{ item }}
            </li>
            <li v-if="truncatedList.length < contentList.length">...</li>
          </ul>
        </template>
        <template v-else-if="truncateText">{{ content | truncate(truncateText) }}</template>
        <template
          v-else-if="
            type === 'compare' &&
            compareData &&
            compareData.primary &&
            compareData.secondary !== false
          "
        >
          <span class="primary-data">
            <template v-if="compareData.primaryPercentage">
              {{ compareData.primaryPercentage }}
            </template>
            <template v-else-if="formatter">{{ formatter(compareData.primary) }}</template>
            <template v-else>
              {{ formatNumber(compareData.primary) }}
            </template>
          </span>
          <template v-if="compareData.primaryPercentage">
            <div
              v-if="percentageDifference !== 0"
              class="difference"
              :class="percentageDifference > 0 ? 'up' : 'down'"
            >
              <span>{{ formattedPercentageDifference }}</span>
              <v-img
                v-if="percentageDifference > 0"
                src="@/assets/icons/indicator-up.svg"
                width="10"
                height="10"
                lazy-src="@/assets/icons/indicator-up.svg"
              />
              <v-img
                v-else
                src="@/assets/icons/indicator-down.svg"
                width="10"
                height="10"
                lazy-src="@/assets/icons/indicator-down.svg"
              />
            </div>
          </template>
          <template v-else>
            <div
              v-if="differenceValue !== 0"
              class="difference"
              :class="differenceValue > 0 ? 'up' : 'down'"
            >
              <span>{{ formattedDifference }}</span>
              <v-img
                v-if="differenceValue > 0"
                src="@/assets/icons/indicator-up.svg"
                width="10"
                height="10"
                lazy-src="@/assets/icons/indicator-up.svg"
              />
              <v-img
                v-else
                src="@/assets/icons/indicator-down.svg"
                width="10"
                height="10"
                lazy-src="@/assets/icons/indicator-down.svg"
              />
            </div>
          </template>
        </template>
        <template v-else-if="type === 'compare'">
          <template v-if="conversion">
            {{ content }}
          </template>
          <template v-else-if="formatter">
            {{ formatter(content) }}
          </template>
          <template v-else>
            {{ formatNumber(content) }}
          </template>
        </template>
        <template v-else>
          <template v-if="formatter">
            {{ formatter(content) }}
          </template>
          <template v-else-if="isNumber(content)">
            {{ formatNumber(content) }}
          </template>
          <template v-else>
            {{ content }}
          </template>
        </template>
      </content>
    </div>
  </v-card>
</template>

<script>
import _ from 'lodash'
import numeral from 'numeral'
import FullscreenViewerService from '@/services/FullscreenViewerService'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: [String, Number, Boolean],
      default: '0'
    },
    contentList: {
      type: Array,
      default: () => {
        return []
      }
    },
    formatter: {
      type: [Function, Boolean],
      default: false
    },
    showListTotal: {
      type: Boolean,
      default: false
    },
    isForPdf: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: ''
    },
    alignCenter: {
      type: Boolean,
      default: false
    },
    noData: {
      type: [Boolean, Number],
      default: false
    },
    hasListBullet: {
      type: Boolean,
      default: false
    },
    halfWidth: {
      type: Boolean,
      default: false
    },
    darkBackground: {
      type: Boolean,
      default: false
    },
    italic: {
      type: Boolean,
      default: false
    },
    canExpand: {
      type: Boolean,
      default: false
    },
    noGradient: {
      type: Boolean,
      default: false
    },
    conversion: {
      type: Boolean,
      default: false
    },
    truncateText: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    compareData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  computed: {
    percentageDifference() {
      return numeral(
        _.get(this.compareData, 'primary', '') - _.get(this.compareData, 'secondary', '')
      ).format('0.00')
    },
    formattedPercentageDifference() {
      return `${this.percentageDifference}%`
    },
    differenceValue() {
      if (_.get(this.compareData, 'primaryPercentage', false)) {
        return this.compareData.primaryPercentage
      }
      return _.get(this.compareData, 'primary', 0) - _.get(this.compareData, 'secondary', 0)
    },
    formattedDifference() {
      let diffVal = 0
      if (_.get(this.compareData, 'primaryPercentage', false)) {
        diffVal = this.compareData.primary - this.compareData.secondary
      } else {
        diffVal = this.differenceValue
      }
      if (diffVal === 0) {
        return 0
      }
      return `${diffVal > 0 ? '+' : ''}${
        this.formatter ? this.formatter(diffVal) : this.formatNumber(diffVal)
      }${_.get(this.compareData, 'primaryPercentage', false) ? '%' : ''}`
    },
    truncatedList() {
      return this.truncateText !== ''
        ? _.take(this.contentList, this.truncateText)
        : this.contentList
    },
    classes() {
      return `content-card${this.noGradient ? ' no-gradient' : ''}${
        this.darkBackground ? ' dark-background' : ''
      }${this.halfWidth ? ' half-width' : ''}${this.alignCenter ? ' align-center' : ''}${
        this.italic ? ' italic' : ''
      }${this.type !== '' ? ` ${this.type}` : ''}${
        this.noData || this.link.length === 0 ? ' no-touch' : ''
      }`
    }
  },
  methods: {
    redirectToSection() {
      if (this.noData || this.link.length === 0) {
        return
      }
      this.$nextTick(() => {
        this.$router
          .push({
            path: `/${this.link}`,
            query: this.$route.query
          })
          .catch(() => {})
      })
      return false
    },
    isNumber(val) {
      return _.isNumber(val)
    },
    formatNumber(value, format) {
      return numeral(_.toNumber(value)).format(format || '0,0')
    },
    contentIsList() {
      return _.isArray(this.content)
    },
    displayFullscreen(title, content) {
      FullscreenViewerService.events.emit('displayViewer', { title, content }, true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';
.content-card {
  .v-icon {
    &:not(.fullscreen-icon) {
      font-size: 4px !important;
    }
  }
  .fullscreen-icon {
    &.hidden {
      opacity: 0;
      touch-action: none;
      pointer-events: none;
    }
  }
  &.italic {
    .card-content {
      font-style: italic;
    }
  }
  &.dark-background {
    background-color: $dark-background;
  }
  &.compare {
    .card-title,
    .card-content content,
    .difference {
      display: flex;
      align-items: center;
    }
    .card-content content {
      justify-content: space-between;
    }
    .v-image {
      margin-left: $half-margin / 2;
    }
    .difference {
      font-weight: 400;
      font-size: $scale-vw * 12;
      line-height: $scale-vw * 15;
      &.up {
        color: $cyan;
      }
      &.down {
        color: $red;
      }
    }
  }
  &.no-touch {
    pointer-events: none;
    touch-action: none;
  }
  .no-data {
    font-style: italic;
  }
}
ul {
  padding-left: 0;
  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .v-icon {
      margin-right: $half-margin;
    }
  }
}
.card-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}

.number-highlight {
  .card-title,
  .card-content {
    color: white;
  }
  .card-title {
    font-style: italic;
    font-weight: 400;
    font-size: $scale-vw * 12;
    line-height: $scale-vw * 15;
  }
  .card-content {
    font-style: normal;
    font-weight: 700;
    font-size: $scale-vw * 19;
    line-height: $scale-vw * 24;
  }
}
</style>
