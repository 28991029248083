<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    right
    :content-class="contentClass"
    @input="onInputMenu"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        dense
        class="caption days-picker"
        :placeholder="'T_PICK_A_DATE' | translate"
        append-icon="mdi-calendar"
        readonly
        single-line
        tile
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <!-- <v-range-slider
      v-model="days"
      class="mt-10 mx-3"
      step="1"
      :min="0"
      :max="eventDays-1"
      ticks="always"
      tick-size="4"
      thumb-label="always"
    >
      <template #thumb-label="props">
        {{ label(props.value) }}
      </template>
    </v-range-slider> -->
    <div class="days-wrapper">
      <h1>{{ 'T_DAYS' | translate }}</h1>
      <div class="days">
        <v-btn
          v-for="(item, idx) in dayLabels"
          :key="`label-${item}`"
          class="day pa-0"
          :dark="days[1] ? idx >= days[0] && idx <= days[1] : idx == days[0]"
          elevation="0"
          fab
          small
          @click="onClickDay(idx)"
        >
          {{ item }}
        </v-btn>
      </div>
    </div>
  </v-menu>
</template>

<script>
import _ from 'lodash'
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    eventDays: {
      type: Array,
      default: () => []
    },
    isForDesktop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false,
      dayLabels: null,
      days: [0, 0]
    }
  },
  computed: {
    contentClass() {
      return `date-picker-menu${this.isForDesktop ? ' is-for-desktop' : ''}`
    },
    dateRangeText() {
      return _.map(this.days, (day) => {
        return `Day ${day + 1}`
      }).join(' ~ ')
    }
  },
  watch: {
    eventDays() {
      this.updateDays()
    }
  },
  mounted() {
    this.updateDays()
  },
  methods: {
    onClickDay(day) {
      switch (this.days.length) {
        case 1:
          this.days.push(day)
          this.days = _.sortBy(this.days)
          this.menu = false
          this.$emit('input', this.days)
          break
        case 2:
          this.days = [day]
          break
      }
    },
    label(value) {
      return value + 1
    },
    updateDays() {
      this.days = [0, this.eventDays - 1]
      this.dayLabels = _.map(_.range(this.eventDays), (day) => day + 1)
    },
    onInputMenu() {
      if (_.size(this.days) === 1) {
        const day = _.first(this.days)
        this.days = [day, day]
        this.$emit('input', this.days)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.days-wrapper {
  text-align: center;
  padding: 1.5vw 1.5vw;
  background-color: white;
  h1 {
    margin-bottom: 1.5vw;
  }
}
.days {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .day {
    margin: 4px;
  }
}
</style>
