var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.getDesktopWrapperClass(),attrs:{"id":"sales-section"}},[(_vm.loading)?_c('section-loader'):(_vm.primaryData)?_c('div',{staticClass:"sub-sections-wrapper"},[_c('div',{staticClass:"cards-row"},[_c('div',{staticClass:"card-wrapper"},[_c('card-top',{attrs:{"options":_vm.getDesktopTitle('T_LEADS'),"divider":""}}),(!_vm.canCompareSubSection('leads'))?_c('v-card',{staticClass:"sub-section no-data"},[_c('cannot-compare-data')],1):[_c('v-card',{staticClass:"sub-section"},[_c('card-top',{attrs:{"current-view":_vm.selectedSubView,"options":_vm.getSectionCardTop('leads')},on:{"update:currentView":function($event){_vm.selectedSubView=$event},"update:current-view":function($event){_vm.selectedSubView=$event}}}),_c('div',{staticClass:"leads-by-date"},[(_vm.graphHasData(_vm.lineLeads) && _vm.hasLeads(_vm.primaryData))?[_c('chart-selector',{attrs:{"data":_vm.getDataForChart(_vm.lineLeads, {
                      label: 'T_TOTAL_LEADS_GENERATED',
                      value: _vm.primaryData.total.leadsGeneration
                    }),"current-value":_vm.highlightedLeadDay,"center-label":_vm.secondaryData ? true : false},on:{"update:currentValue":function($event){_vm.highlightedLeadDay=$event},"update:current-value":function($event){_vm.highlightedLeadDay=$event}}}),(_vm.secondaryData)?_c('div',{staticClass:"compare-show-highlighted"},[_c('content-card',{attrs:{"no-gradient":"","dark-background":"","half-width":"","title":_vm.primaryData.pavilion.event.key,"content":_vm.getTotalForShow(_vm.primaryData, 'leadsByDate', _vm.highlightedLeadDay),"type":"compare","compare-data":{
                      circleColor: _vm.ChartsDataService.barColors[0],
                      primary: _vm.getTotalForShow(_vm.primaryData, 'leadsByDate', _vm.highlightedLeadDay),
                      secondary: _vm.getTotalForShow(_vm.secondaryData, 'leadsByDate', _vm.highlightedLeadDay)
                    },"italic":""}}),_c('v-divider',{attrs:{"vertical":""}}),_c('content-card',{attrs:{"no-gradient":"","dark-background":"","half-width":"","title":_vm.secondaryData.pavilion.event.key,"type":"compare","content":_vm.getTotalForShow(_vm.secondaryData, 'leadsByDate', _vm.highlightedLeadDay),"compare-data":{
                      circleColor: _vm.ChartsDataService.barColors[1]
                    },"italic":""}})],1):_vm._e(),(_vm.primaryData && _vm.hasLeads(_vm.primaryData))?_c('line-chart',{attrs:{"data":_vm.lineLeads.data,"options":_vm.lineLeads.options,"highlight-value":_vm.highlightedLeadDay}}):_vm._e(),(_vm.secondaryData)?[_c('div',{staticClass:"compare-show-highlighted"},[_c('content-card',{attrs:{"no-gradient":"","dark-background":"","half-width":"","title":_vm.primaryData.pavilion.event.key,"content":_vm.getTotalForShow(_vm.primaryData, 'leadsByDate', 0),"type":"compare","compare-data":{
                        circleColor: _vm.ChartsDataService.barColors[0],
                        primary: _vm.getTotalForShow(_vm.primaryData, 'leadsByDate', 0),
                        secondary: _vm.getTotalForShow(_vm.secondaryData, 'leadsByDate', 0)
                      },"italic":""}}),_c('v-divider',{attrs:{"vertical":""}}),_c('content-card',{attrs:{"no-gradient":"","dark-background":"","half-width":"","title":_vm.secondaryData.pavilion.event.key,"type":"compare","content":_vm.getTotalForShow(_vm.secondaryData, 'leadsByDate', 0),"compare-data":{
                        circleColor: _vm.ChartsDataService.barColors[1]
                      },"italic":""}})],1)]:_vm._e()]:_c('no-graph-data')],2)],1)]],2),_c('div',{staticClass:"card-wrapper"},[_c('card-top',{attrs:{"options":_vm.getDesktopTitle('T_ORDERS'),"divider":""}}),(!_vm.canCompareSubSection('orders'))?_c('v-card',{staticClass:"sub-section no-data"},[_c('cannot-compare-data')],1):_c('v-card',{staticClass:"sub-section"},[_c('card-top',{attrs:{"current-view":_vm.selectedSubView,"options":{
              type: 'hidden',
              states: [
                { title: 'T_ORDERS_GENERATION_BY_DATE', icon: '/img/calendar.a6935775.svg' }
              ]
            }},on:{"update:currentView":function($event){_vm.selectedSubView=$event},"update:current-view":function($event){_vm.selectedSubView=$event}}}),(_vm.graphHasData(_vm.lineOrders) && _vm.hasOrders(_vm.primaryData))?[(_vm.primaryData)?_c('div',{staticClass:"orders-by-date"},[_c('chart-selector',{attrs:{"data":_vm.getDataForChart(_vm.lineOrders, {
                    label: 'T_TOTAL_ORDERS_GENERATED',
                    value: _vm.primaryData.total.ordersGeneration
                  }),"center-label":_vm.secondaryData ? true : false,"current-value":_vm.highlightedOrderDay},on:{"update:currentValue":function($event){_vm.highlightedOrderDay=$event},"update:current-value":function($event){_vm.highlightedOrderDay=$event}}}),_c('line-chart',{attrs:{"data":_vm.lineOrders.data,"options":_vm.lineOrders.options,"highlight-value":_vm.highlightedOrderDay}})],1):_vm._e(),(_vm.secondaryData)?[_c('div',{staticClass:"compare-show-highlighted"},[_c('content-card',{attrs:{"no-gradient":"","dark-background":"","half-width":"","title":_vm.primaryData.pavilion.event.key,"content":_vm.getTotalForShow(_vm.primaryData, 'ordersByDate', 0),"type":"compare","compare-data":{
                    circleColor: _vm.ChartsDataService.barColors[0],
                    primary: _vm.getTotalForShow(_vm.primaryData, 'ordersByDate', 0),
                    secondary: _vm.getTotalForShow(_vm.secondaryData, 'ordersByDate', 0)
                  },"italic":""}}),_c('v-divider',{attrs:{"vertical":""}}),_c('content-card',{attrs:{"no-gradient":"","dark-background":"","half-width":"","title":_vm.secondaryData.pavilion.event.key,"type":"compare","content":_vm.getTotalForShow(_vm.secondaryData, 'ordersByDate', 0),"compare-data":{
                    circleColor: _vm.ChartsDataService.barColors[1]
                  },"italic":""}})],1)]:_vm._e()]:_c('no-graph-data')],2)],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }