<template>
  <div class="pie-chart-legend" :class="{ compare: compare, 'is-for-pdf': isForPdf }">
    <table>
      <tr v-for="(item, i) in items" :key="i">
        <td colspan="2" class="name-column">
          <span class="color-circle" :style="{ 'background-color': getColor(i) }" /><span
            class="name"
            >{{ item.label | translate }}</span
          >
        </td>
        <template v-if="onlyPercentages">
          <td class="percentage">{{ item.percentage }}</td>
        </template>
        <template v-else>
          <td>{{ item.value | numeral('0,0') }}</td>
        </template>
      </tr>
    </table>
  </div>
</template>
<script>
import _ from 'lodash'
import numeral from 'numeral'
import ChartsDataService from '@/services/ChartsDataService'

export default {
  props: {
    data: {
      type: [Object, Boolean],
      default: () => {}
    },
    compare: {
      type: Boolean,
      default: false
    },
    onlyPercentages: {
      type: Boolean,
      default: false
    },
    isForPdf: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    items() {
      const data = _.get(this.data, 'data.datasets[0].data', [])
      const formattedItems = _.map(data, (item, i) => {
        return {
          label: _.get(this.data.data, `indexes[${i}]`, ''),
          value: item
        }
      })
      if (this.onlyPercentages) {
        const total = _.sum(_.map(formattedItems, 'value'))
        _.each(formattedItems, (item) => {
          item.percentage = `${numeral((item.value / total) * 100).format('0')}%`
        })
      }
      return formattedItems
    }
  },
  methods: {
    getColor(i) {
      return this.isForPdf
        ? _.get(ChartsDataService, `pdfPieChartsColors[${i}]`, 'white')
        : _.get(ChartsDataService, `colors[${i}]`, 'white')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../vars.scss';
.pie-chart-legend {
  background: $dark-background;
  border-radius: $half-margin;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  padding: $half-margin $standard-margin;
  margin-top: $standard-margin;
  table {
    width: 100%;
  }
  td {
    height: 18px;
    padding: 2px 0;
    vertical-align: middle;
    &:first-child {
      > * {
        display: inline-block;
      }
      .v-image {
        margin-left: $half-margin / 2;
      }
      > span {
        max-width: $scale-vw * 190;
      }
    }
    &:nth-child(2),
    &:last-child {
      font-style: italic;
      text-align: right;
      padding-left: 4px;
    }
    &:last-child {
      color: $purple;
    }
  }
  tr {
    &:first-child {
      td:last-child {
        color: $purple;
      }
    }
  }
  .color-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
  }
  &.compare {
    .name-column {
      font-size: $scale-vw * 10;
    }
  }
  &.is-for-pdf {
    background-color: #e6e6e6;
    color: black;
    border-radius: 4px;
    font-size: 8px;
    line-height: 8px;
    padding: 4px 8px;
    margin-top: 8px;
    tr {
      height: 8px;
    }
    td {
      &:first-child {
        .v-image {
          margin-left: 4px / 2;
        }
        > span {
          max-width: 190px;
        }
      }
    }
  }
}
@media (min-width: 500px) {
  .pie-chart-legend {
    border-radius: $desktop-half-margin;
    font-size: $desktop-scale-vw * 16;
    line-height: $desktop-scale-vw * 18;
    padding: $desktop-half-margin $desktop-standard-margin;
    margin-top: $desktop-standard-margin;
    td {
      &:first-child {
        .v-image {
          margin-left: $desktop-half-margin / 2;
        }
        > span {
          max-width: $desktop-scale-vw * 190;
        }
      }
    }
    &.is-for-pdf {
      border-radius: 4px;
      font-size: 8px;
      line-height: 8px;
      padding: 4px 8px;
      margin-top: 8px;
      td {
        &:first-child {
          .v-image {
            margin-left: 4px / 2;
          }
          > span {
            max-width: 190px;
          }
        }
      }
    }
  }
}
</style>
