<template>
  <div class="main-page">
    <v-overlay :value="true">
      <v-progress-circular color="primary" indeterminate size="100" />
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'MainPage',
  components: {},
  data() {
    return {}
  },
  async mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.main-page {
}
</style>
