<template>
  <div class="bar-chart">
    <canvas ref="canvas" />
  </div>
</template>
<script>
import _ from 'lodash'
import numeral from 'numeral'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartsDataService from '@/services/ChartsDataService'

Chart.defaults.font.family = 'SourceSansPro-Bold'
Chart.register(ChartDataLabels)

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {}
    },
    highlightValue: {
      type: Number,
      default: 0
    },
    isForPdf: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localDates: []
    }
  },
  watch: {
    data() {
      this.updateData()
    },
    highlightValue() {
      this.updateData()
    }
  },
  async mounted() {
    const ctx = this.$refs.canvas.getContext('2d', { willReadFrequently: true })
    let options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          enabled: false
        },
        legend: {
          display: false
        },
        datalabels: {
          display: false
        },
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
      scales: {
        x: {
          ticks: {
            font: ChartsDataService.getDefaultFont(this.isForPdf),
            color: this.isForPdf ? 'black' : '#8094DD'
          },
          categoryPercentage: 0.6,
          barPercentage: 1
        },
        y: {
          ticks: {
            font: ChartsDataService.getDefaultFont(this.isForPdf),
            color: this.isForPdf ? 'black' : '#8094DD'
          },
          type: 'linear',
          grace: '25%',
          beginAtZero: true,
          grid: {
            borderDash: [3, 5],
            drawBorder: this.isForPdf,
            // color: '#8094DD',
            color: this.isForPdf ? this.gridColorForPdf : this.gridColor
          }
        }
      },
      backgroundColor: ChartsDataService.barColors
    }
    options = _.merge(options, this.options)
    if (this.isForPdf) {
      _.set(options, 'animation.duration', 0)
      if (!_.get(options, 'plugins.datalabels.labels.title.font.family', false)) {
        _.set(options, 'plugins.datalabels', {
          display: true,
          align: 'center',
          opacity: 1,
          labels: {
            title: {
              font: {
                family: 'SourceSansPro-Bold',
                size: 8,
                weight: 700
              },
              anchor: 'end',
              align: 'top',
              offset: '0',
              color: 'black',
              formatter: function (value, context) {
                const val = _.get(context, `dataset.data[${context.dataIndex}]`, 0)
                return val === 0 ? '' : numeral(val).format('0,0')
              }
            }
          }
        })
      }
    }
    _.set(options, 'scales.x.grid.display', false)
    _.set(options, 'scales.y.ticks.maxTicksLimit', 7)
    if (!_.get(options, 'scales.y.ticks.callback', false)) {
      _.set(options, 'scales.y.ticks.callback', ChartsDataService.formatYTicksLabels)
    }
    _.each(this.data.datasets, (dataset, datasetIndex) => {
      this.setDatasetBarsWidth(dataset)
      dataset.backgroundColor = this.getBackgroundColors(dataset, datasetIndex)
    })
    _.set(options, 'scales.y.type', 'linear')
    if (!_.get(options, 'scales.y.grace', false)) {
      _.set(options, 'scales.y.grace', '25%')
    }
    if (
      _.get(options, 'plugins.datalabels.labels.title.font.family', false) === 'weatherFont' &&
      !ChartsDataService.weatherFontLoaded
    ) {
      await ChartsDataService.loadWeatherFont()
    }
    // _.set(options, 'scales.y.max', ChartsDataService.getMaxValueForYAxis(this.data.datasets))
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: this.data,
      options
    })
  },
  methods: {
    gridColorForPdf(context) {
      return `rgba(0,0,0, ${context.tick.value === 0 ? 0 : 0.2})`
    },
    gridColor() {
      return '#8094DD'
    },
    setDatasetBarsWidth(dataset) {
      dataset.pointHitRadius = 50
      dataset.borderRadius = 50
      dataset.borderRadius = 50
      dataset.maxBarThickness = this.isForPdf ? 8 : 10
    },
    hasSameDates() {
      let sameDates = true
      if (_.get(this.localDates, 'length', 0) > 0) {
        _.each(this.data.dates, (date, i) => {
          if (_.get(this.localDates, `[${i}]`, false) !== date) {
            sameDates = false
            return false
          }
        })
      } else {
        this.localDates = _.cloneDeep(this.data.dates)
      }
      return sameDates
    },
    updateData() {
      if (!this.chart) {
        return
      }
      // this.chart.update()
      this.chart.activeElements = []
      if (
        _.get(this.data, 'datasets.length', 0) === 0 ||
        !_.get(this.chart, 'data.datasets', false)
      ) {
        return console.warn('barChart - updateData - no data')
      }
      const datasets = _.cloneDeep(this.data.datasets)
      this.chart.data.labels = []
      _.set(this.chart, 'data.datasets', [])
      this.chart.update('none')
      _.each(datasets, (dataset, i) => {
        this.chart.data.datasets.push(dataset)
        this.setDatasetBarsWidth(dataset)
        if (_.get(this.chart, 'data.datasets.length', 0) !== 0) {
          this.chart.data.datasets[i].backgroundColor = this.getBackgroundColors(dataset, i)
        }
        if (i === 0) {
          this.chart.options.scales.x.ticks.color = []
          this.chart.options.scales.x.ticks.color = this.getTickColor(dataset)
        }
      })
      if (_.get(this.chart.options, 'plugins.datalabels.font.family', false)) {
        // _.set(this.chart, 'options.plugins.datalabels.opacity', this.getIconsOpacity(this.data.datasets[0]))
        this.chart.options.plugins.datalabels.opacity = this.getIconsOpacity(
          _.get(this.data, 'datasets[0]', {})
        )
      }
      _.each(this.data.labels, (label) => {
        this.chart.data.labels.push(label)
      })
      this.chart.update('none')
    },
    getIconsOpacity(dataset) {
      const opacity = []
      if (this.highlightValue === 0) {
        _.each(dataset.data, () => {
          opacity.push(1)
        })
      } else {
        _.each(dataset.data, (d, i) => {
          opacity.push(i === this.highlightValue - 1 ? 1 : 0.2)
        })
      }
      return opacity
    },
    getTickColor(dataset) {
      const colors = []
      if (this.highlightValue === 0) {
        _.each(_.get(dataset, 'data', []), () => {
          colors.push('#8094DD')
        })
      } else {
        _.each(_.get(dataset, 'data', []), (data, i) => {
          colors.push(i !== this.highlightValue - 1 ? '#8094DD' : 'white')
        })
      }
      return colors
    },
    getBackgroundColors(dataset, datasetIndex) {
      const backgroundColors = []
      if (this.highlightValue === 0) {
        _.each(dataset.data, () => {
          backgroundColors.push(ChartsDataService.barColors[datasetIndex])
        })
      } else {
        _.each(dataset.data, (d, i) => {
          let baseColorIndex = datasetIndex * 2
          if (i !== this.highlightValue - 1) {
            baseColorIndex += 1
          }
          backgroundColors.push(
            _.get(ChartsDataService.barColorsHighlight, `[${baseColorIndex}]`, '#FFF')
          )
        })
      }
      return backgroundColors
    }
  }
}
</script>

<style scoped></style>
