import Emitter from 'tiny-emitter'

class LoadingService {
  constructor() {
    this.loading = true
    this.events = new Emitter()
  }
  setLoading(loadingState) {
    // console.warn(`LoadingService - loading state = ${loadingState}`)
    this.loading = loadingState
    this.events.emit('loading-state', this.loading)
  }

  isLoading() {
    return this.loading
  }
}

export default new LoadingService()
