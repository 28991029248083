<template>
  <div :class="`chart-selector${!playBtnCallback && centerLabel ? ' center-label' : ''}`">
    <template v-if="loading">
      <v-progress-circular size="28" indeterminate color="primary" />
    </template>
    <template v-else>
      <v-img
        v-if="data.length > 1"
        src="@/assets/icons/circle-arrow.svg"
        width="16"
        height="16"
        max-width="16"
        max-height="16"
        @click="changeData(-1)"
      />
      <span v-if="playBtnCallback">{{ getData('label') }}</span>
      <span v-else class="label">{{ getData('label') }}</span>
      <v-btn
        v-if="playBtnCallback"
        color="primary"
        rounded
        plain
        small
        :ripple="false"
        class="play-pause-btn"
        :class="{ 'v-btn--active': isPlaying }"
        @click="playBtn()"
      >
        <v-icon v-if="!isPlaying" color="white">mdi-play</v-icon>
        <v-icon v-else color="white">mdi-pause</v-icon>
      </v-btn>
      <template v-if="!centerLabel">
        <span v-if="!formatter" class="value">{{ getData('value') | numeral }}</span>
        <span v-else class="value">{{ formatter(data[currentValue]) }}</span>
      </template>
      <template v-else-if="playBtnCallback">
        <span class="value"></span>
      </template>
      <v-img
        v-if="data.length > 1"
        class="rotate"
        src="@/assets/icons/circle-arrow.svg"
        width="16"
        height="16"
        max-width="16"
        max-height="16"
        @click="changeData(1)"
      />
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
import TranslateService from '@/services/TranslateService'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    currentValue: {
      type: Number,
      default: 0
    },
    formatter: {
      type: [Function, Boolean],
      default: false
    },
    centerLabel: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    playBtnCallback: {
      type: [Function, Boolean],
      default: false
    }
  },
  data() {
    return {
      currentLocalValue: 0,
      isPlaying: false
    }
  },
  watch: {
    currentValue() {
      this.currentLocalValue = _.clone(this.currentValue)
    }
  },
  methods: {
    playBtn() {
      this.isPlaying = !this.isPlaying
      this.playBtnCallback()
    },
    getData(field) {
      return _.get(this.data, `[${this.currentValue}].${field}`)
    },
    changeData(direction) {
      const futureVal = this.currentValue + direction
      if (futureVal < 0 || futureVal > this.data.length - 1) {
        this.currentLocalValue = futureVal < 0 ? this.data.length - 1 : 0
      } else if (
        (direction === -1 && futureVal > 0) ||
        (direction === 1 && futureVal <= this.data.length - 1)
      ) {
        this.currentLocalValue = this.currentValue + direction
      } else {
        this.currentLocalValue = 0
      }
      if (this.isPlaying) {
        this.playBtn()
      }
      this.$emit('update:currentValue', this.currentLocalValue)
    },
    translateValue(value) {
      return _.startsWith(value, 'T_') ? TranslateService.get(value) : value
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';
.chart-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: $half-margin;
  gap: $standard-margin;
  background: $dark-background;
  border-radius: 50px;
  margin: $standard-margin 0;
  span {
    font-style: italic;
    font-weight: 400;
    font-size: $scale-vw * 12;
    line-height: $scale-vw * 15;
    text-align: left;
    color: white;
    width: 50%;
    &.label {
      width: 100%;
    }
  }
  .value {
    font-style: normal;
    font-weight: 700;
    font-size: $scale-vw * 19;
    line-height: $scale-vw * 24;
    text-align: right;
  }
  .rotate {
    transform: rotate(180deg);
  }
  &.center-label {
    span:not(.value) {
      width: 100%;
      text-align: center;
    }
  }
}
@media (min-width: 500px) {
  .chart-selector {
    padding: $desktop-half-margin;
    gap: $desktop-standard-margin;
    margin: $desktop-standard-margin 0;
    span {
      font-size: $desktop-scale-vw * 14;
      line-height: $desktop-scale-vw * 17;
    }
    .value {
      font-size: $desktop-scale-vw * 19;
      line-height: $desktop-scale-vw * 24;
    }
  }
}
@media (max-width: 500px) {
  .chart-selector {
    span {
      &.value {
        font-size: $scale-vw * 16;
        line-height: $scale-vw * 20;
      }
    }
  }
}
</style>
<style lang="scss">
@import '../vars.scss';
.chart-selector {
  user-select: none;
  span {
    pointer-events: none;
    touch-action: none;
  }
}
@media (min-width: 500px) {
  .v-image {
    cursor: pointer;
  }
  span {
    font-weight: 400;
    pointer-events: none;
    touch-action: none;
  }
}
</style>
