<template>
  <div class="pdf-bars">
    <div class="left-sidebar">
      <img class="logo-imagination" src="@/assets/imagination-logo.png" />
    </div>
    <div class="pdf-top-bar">
      <img :src="logo" class="logo" />
      <span class="event-name">{{ eventName | translate }}</span>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  props: {
    logo: {
      type: String,
      default: ''
    },
    eventName: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  methods: {
    getField(field, defaultVal = '') {
      return _.get(this, field, defaultVal)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';
.pdf-top-bar {
  width: 100%;
  padding: 8px 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  .logo {
    max-height: 16px;
    width: auto;
  }
  .event-name {
    font-style: italic;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }
}
</style>
