import _ from 'lodash'
import FontFaceObserver from 'fontfaceobserver'

class ChartsDataService {
  constructor() {
    this.colors = [
      '#7375FF',
      '#5191F0',
      '#4EA8DE',
      '#48BFE3',
      '#56CFE1',
      '#64DFDF',
      '#72EFDD',
      '#80FFDB',
      '#51ECC0',
      '#61FFA0'
    ]
    this.barColors = ['#E1E000', '#8DE971']
    this.barColorsHighlight = ['#E1E000', 'rgba(225,224,0,0.2)', '#8DE971', 'rgba(141,233,113,0.2)']
    this.radarColors = [
      '#E1E000',
      'rgba(225,224,0,0.2)',
      'rgba(225,224,0,0.5)',
      '#8DE971',
      'rgba(141,233,113,0.2)',
      'rgba(141,233,113,0.5)'
    ]
    this.radarColorsForSingleShow = ['#FF5C39', 'rgba(255, 92, 57, 0.5)']
    this.lineColors = [
      '#E1E000',
      '#8DE971',
      '#FF5C39',
      '#71DBD4',
      '#FF9BEF',
      '#FF9C55',
      '#7375FF',
      '#80FFDB'
    ]
    this.pieChartsColors = [
      '#7375FF',
      '#5191F0',
      '#4EA8DE',
      '#48BFE3',
      '#56CFE1',
      '#64DFDF',
      '#72EFDD',
      '#80FFDB',
      '#51ECC0',
      '#61FFA0',
      '#71F865',
      '#92FF5F',
      '#D5FF5F',
      '#FFF95F',
      '#F5D45F',
      '#ECAD4E',
      '#FF9C55',
      '#F37F4D',
      '#FFB08D',
      '#FFCFBA',
      '#FB6B62',
      '#FF9D97',
      '#F3578F',
      '#FF77A8',
      '#FF9BEF'
    ]
    // this.pdfPieChartsColors = ['#7375FF', '#4EA8DE', '#56CFE1', '#72EFDD', '#61FFA0']
    this.pdfPieChartsColors = this.pieChartsColors
    this.funnelChartColors = ['#E1E000', '#8DE971', '#FF5C39', '#71DBD4', '#5191F0', '#7375FF']
    this.weatherFont = new FontFaceObserver('weatherFont', { weight: 400 })
    this.weatherFontLoaded = false
    this.loadWeatherFont()
  }

  loadWeatherFont() {
    return new Promise((resolve) => {
      this.weatherFont.load().then(
        () => {
          this.weatherFontLoaded = true
          console.info(`Weather font sucessfully loaded`)
          resolve(true)
        },
        () => {
          console.error(
            'Can not render charts as normally as custom font is unavailable, will render without the weather font'
          )
          this.weatherFontLoaded = false
          resolve(false)
        }
      )
    })
  }

  getDefaultLabelsConf() {
    return {
      boxWidth: 12,
      font: this.getDefaultFont()
    }
  }

  getMaxValueForYAxis(datasets, multiplier = 25) {
    let max = _.max(_.get(_.first(datasets), 'data', []))
    if (_.get(datasets, '[1]', false)) {
      const max2 = _.max(_.get(datasets, '[1].data', []))
      if (max2 > max) {
        max = max2
      }
    }
    return max + (max / 100) * multiplier
  }

  formatYTicksLabels(value) {
    return value >= 1000 ? `${Math.round((value / 1000) * 100) / 100}k` : value
  }

  getDefaultFont(isForPdf = false) {
    return {
      family: 'SourceSansPro',
      size: isForPdf ? '9px' : '12px'
    }
  }
}

export default new ChartsDataService()
