<template>
  <div class="traffic-section-sub-view">
    <section-loader v-if="loading" />
    <template v-else-if="primaryData && secondaryData">
      <!-- TODO: hugo - adjust after debug -->
      <div v-if="false" class="sub-sections-wrapper">
        <div v-if="desktopLayoutReady" class="cards-row">
          <template v-for="(titleLine, i) in desktopTitles">
            <template v-for="(title, y) in titleLine">
              <card-top
                :key="`desktop-mode-card-top-${i}-${y}`"
                :full-width="title.fullWidth === true"
                :options="getDesktopTitle(title.title)"
                divider
              />
            </template>
            <template v-for="(subSection, y) in titleLine">
              <v-card
                :key="`desktop-mode-card-${i}-${y}`"
                class="sub-section"
                :class="{ 'full-width': subSection.id === 'neighbors' }"
              >
                <!-- Stand traffic -->
                <template v-if="subSection.id === 'stand-traffic'">
                  <card-top
                    :current-view.sync="selectedSubView"
                    :options="filterIfNoDataPerHour(getSectionCardTop('stand-traffic'))"
                  />
                  <div v-if="selectedSubView === 0" class="traffic-by-date">
                    <div class="info-box">
                      <chart-selector
                        :data="
                          getDataForChart(bar, {
                            label: 'T_TOTAL_TRAFFIC',
                            value: getTotalValue(primaryData, secondaryData, 'total.standTraffic')
                          })
                        "
                        :center-label="true"
                        :current-value.sync="highlightedTrafficValue"
                      />
                      <template v-if="graphHasData(bar)">
                        <bar-chart
                          :data="bar.data"
                          :options="bar.options"
                          :highlight-value="highlightedTrafficValue"
                        />
                      </template>
                      <no-graph-data v-else />

                      <div v-if="bar.data.datasets" class="compare-show-highlighted">
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="primaryData.pavilion.event.key"
                          :content="primaryData.total.eventVisitors"
                          type="compare"
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[0],
                            primary: getHighlightedValue(bar, 'primary', highlightedTrafficValue),
                            secondary: getHighlightedValue(
                              bar,
                              'secondary',
                              highlightedTrafficValue
                            )
                          }"
                          italic
                        />
                        <v-divider vertical />
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="secondaryData.pavilion.event.key"
                          type="compare"
                          :content="getHighlightedValue(bar, 'secondary', highlightedTrafficValue)"
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[1]
                          }"
                          italic
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else class="traffic-by-hour">
                    <chart-selector
                      :data="
                        getDataForChart(bar, {
                          label: 'T_TOTAL_TRAFFIC',
                          value: getTotalValue(primaryData, secondaryData, 'total.standTraffic')
                        })
                      "
                      center-label
                      :current-value.sync="highlightedTrafficValue"
                    />
                    <div v-if="barHour.data.datasets" class="compare-show-highlighted">
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="primaryData.pavilion.event.key"
                        :content="primaryData.total.experienceGenerated"
                        type="compare"
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[0],
                          primary: getHighlightedValue(
                            barHour,
                            'primary',
                            highlightedTrafficValue,
                            highlightedTrafficHourValue
                          ),
                          secondary: getHighlightedValue(
                            barHour,
                            'secondary',
                            highlightedTrafficValue,
                            highlightedTrafficHourValue
                          )
                        }"
                        italic
                      />
                      <v-divider vertical />
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="secondaryData.pavilion.event.key"
                        type="compare"
                        :content="
                          getHighlightedValue(
                            barHour,
                            'secondary',
                            highlightedTrafficValue,
                            highlightedTrafficHourValue
                          )
                        "
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[1]
                        }"
                        italic
                      />
                    </div>
                    <bar-chart
                      :data="barHour.data"
                      :options="barHour.options"
                      :highlight-value="highlightedTrafficHourValue"
                    />
                    <chart-selector
                      center-label
                      :data="getDataForChart(barHour, getTotalTrafficByHour(), true)"
                      :current-value.sync="highlightedTrafficHourValue"
                    />
                  </div>
                </template>
                <!-- Stay average -->
                <template v-else-if="subSection.id === 'stand-statistics'">
                  <card-top
                    :current-view.sync="selectedSubView2"
                    :options="filterIfNoDataPerHour(getSectionCardTop('stand-statistics'))"
                  />
                  <div v-if="selectedSubView2 === 0" class="stay-average-by-date">
                    <div class="info-box">
                      <template v-if="hasStayAverage(primaryData)">
                        <chart-selector
                          :center-label="true"
                          :data="
                            getDataForChart(barStayAverage, {
                              label: 'T_STAY_AVERAGE',
                              value: getStayAverage()
                            })
                          "
                          :formatter="formatStayAverage"
                          :current-value.sync="highlightedStayAverage"
                        />
                        <bar-chart
                          :data="barStayAverage.data"
                          :options="barStayAverage.options"
                          :highlight-value="highlightedStayAverage"
                        />
                        <div v-if="barStayAverage.data.datasets" class="compare-show-highlighted">
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="primaryData.pavilion.event.key"
                            :content="
                              getHighlightedValue(
                                barStayAverage,
                                'primary',
                                highlightedStayAverage,
                                undefined,
                                true
                              )
                            "
                            type="compare"
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[0],
                              primary: getHighlightedValue(
                                barStayAverage,
                                'primary',
                                highlightedStayAverage,
                                undefined,
                                true
                              ),
                              secondary: getHighlightedValue(
                                barStayAverage,
                                'secondary',
                                highlightedStayAverage,
                                undefined,
                                true
                              )
                            }"
                            italic
                          />
                          <v-divider vertical />
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="secondaryData.pavilion.event.key"
                            type="compare"
                            :content="
                              getHighlightedValue(
                                barStayAverage,
                                'secondary',
                                highlightedStayAverage,
                                undefined,
                                true
                              )
                            "
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[1]
                            }"
                            italic
                          />
                        </div>
                        <demography-pie-charts
                          :hide="[
                            primaryData.pavilion.hideGenders,
                            secondaryData.pavilion.hideGenders
                          ]"
                          :titles="['PER_GENDER']"
                          :pies="[pieGenders, pieGenders2]"
                          :highlights="[highlightedStayAverage]"
                          compare
                        />
                        <demography-pie-charts
                          :hide="[
                            primaryData.pavilion.hideAgeRanges,
                            secondaryData.pavilion.hideAgeRanges
                          ]"
                          :titles="['PER_AGE_GROUP']"
                          :pies="[pieAgeRanges, pieAgeRanges2]"
                          :highlights="[highlightedStayAverage]"
                          compare
                        />
                      </template>
                      <no-graph-data v-else />
                    </div>
                  </div>
                  <div v-else class="stay-average-by-hour">
                    <template v-if="hasStayAverage(primaryData)">
                      <chart-selector
                        :data="
                          getDataForChart(barStayAverage, {
                            label: 'T_STAY_AVERAGE',
                            value: getStayAverage()
                          })
                        "
                        :formatter="formatStayAverage"
                        center-label
                        :current-value.sync="highlightedStayAverage"
                      />
                      <bar-chart
                        :data="barStayAverageHour.data"
                        :options="barStayAverageHour.options"
                        :highlight-value="highlightedStayAverageHour"
                      />
                      <chart-selector
                        center-label
                        :data="
                          getDataForChart(barStayAverageHour, {
                            label: 'T_STAY_AVERAGE',
                            value: getStayAverage(true)
                          })
                        "
                        :formatter="formatStayAverage"
                        :current-value.sync="highlightedStayAverageHour"
                      />
                      <div v-if="barStayAverageHour.data.datasets" class="compare-show-highlighted">
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="primaryData.pavilion.event.key"
                          :content="
                            getHighlightedValue(
                              barStayAverageHour,
                              'primary',
                              highlightedStayAverage,
                              highlightedStayAverageHour,
                              true
                            )
                          "
                          type="compare"
                          :formatter="formatStayAverage"
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[0],
                            primary: getHighlightedValue(
                              barStayAverageHour,
                              'primary',
                              highlightedStayAverage,
                              highlightedStayAverageHour,
                              true
                            ),
                            secondary: getHighlightedValue(
                              barStayAverageHour,
                              'secondary',
                              highlightedStayAverage,
                              highlightedStayAverageHour,
                              true
                            )
                          }"
                          italic
                        />
                        <v-divider vertical />
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="secondaryData.pavilion.event.key"
                          type="compare"
                          :content="
                            getHighlightedValue(
                              barStayAverageHour,
                              'secondary',
                              highlightedStayAverage,
                              highlightedStayAverageHour,
                              true
                            )
                          "
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[1]
                          }"
                          italic
                        />
                      </div>
                      <demography-pie-charts
                        :hide="[
                          primaryData.pavilion.hideGenders,
                          secondaryData.pavilion.hideGenders
                        ]"
                        :titles="['PER_GENDER']"
                        :pies="[pieGendersByHour, pieGendersByHour2]"
                        :highlights="[highlightedStayAverage + highlightedStayAverageHour]"
                        compare
                      />
                      <demography-pie-charts
                        :hide="[
                          primaryData.pavilion.hideAgeRanges,
                          secondaryData.pavilion.hideAgeRanges
                        ]"
                        :titles="['PER_AGE_GROUP']"
                        :pies="[pieAgeRangesByHour, pieAgeRangesByHour2]"
                        :highlights="[highlightedStayAverage + highlightedStayAverageHour]"
                        compare
                      />
                    </template>
                    <no-graph-data v-else />
                  </div>
                </template>
                <!-- Demography -->
                <template v-else-if="subSection.id === 'area-traffic'"> </template>
                <!-- Area list -->
                <template v-else-if="subSection.id === 'area-list'"> </template>
                <!-- Heatmap -->
                <template v-else-if="subSection.id === 'heatmap'"> </template>
                <!-- Attractiveness -->
                <template v-else-if="subSection.id === 'attractiveness'"> </template>
                <!-- Neighbors -->
                <template v-else-if="subSection.id === 'neighbors'"> </template>
              </v-card>
            </template>
          </template>
        </div>
      </div>

      <div class="sub-sections-wrapper">
        <div class="cards-row">
          <card-top :options="getDesktopTitle('T_STAND_TRAFFIC')" divider :full-width="true" />
          <!-- Stand traffic -->
          <v-card class="sub-section">
            <card-top
              :current-view.sync="selectedSubView"
              :options="filterIfNoDataPerHour(getSectionCardTop('stand-traffic'))"
            />
            <div v-if="selectedSubView === 0" class="traffic-by-date">
              <div class="info-box">
                <chart-selector
                  :data="
                    getDataForChart(bar, {
                      label: 'T_TOTAL_TRAFFIC',
                      value: getTotalValue(primaryData, secondaryData, 'total.standTraffic')
                    })
                  "
                  :center-label="true"
                  :current-value.sync="highlightedTrafficValue"
                />
                <template v-if="graphHasData(bar)">
                  <bar-chart
                    :data="bar.data"
                    :options="bar.options"
                    :highlight-value="highlightedTrafficValue"
                  />
                </template>
                <no-graph-data v-else />

                <div v-if="bar.data.datasets" class="compare-show-highlighted">
                  <content-card
                    no-gradient
                    dark-background
                    half-width
                    :title="primaryData.pavilion.event.key"
                    :content="primaryData.total.eventVisitors"
                    type="compare"
                    :compare-data="{
                      circleColor: ChartsDataService.barColors[0],
                      primary: getHighlightedValue(bar, 'primary', highlightedTrafficValue),
                      secondary: getHighlightedValue(bar, 'secondary', highlightedTrafficValue)
                    }"
                    italic
                  />
                  <v-divider vertical />
                  <content-card
                    no-gradient
                    dark-background
                    half-width
                    :title="secondaryData.pavilion.event.key"
                    type="compare"
                    :content="getHighlightedValue(bar, 'secondary', highlightedTrafficValue)"
                    :compare-data="{
                      circleColor: ChartsDataService.barColors[1]
                    }"
                    italic
                  />
                </div>
              </div>
            </div>
            <div v-else class="traffic-by-hour">
              <chart-selector
                :data="
                  getDataForChart(bar, {
                    label: 'T_TOTAL_TRAFFIC',
                    value: getTotalValue(primaryData, secondaryData, 'total.standTraffic')
                  })
                "
                center-label
                :current-value.sync="highlightedTrafficValue"
              />
              <div v-if="barHour.data.datasets" class="compare-show-highlighted">
                <content-card
                  no-gradient
                  dark-background
                  half-width
                  :title="primaryData.pavilion.event.key"
                  :content="primaryData.total.experienceGenerated"
                  type="compare"
                  :compare-data="{
                    circleColor: ChartsDataService.barColors[0],
                    primary: getHighlightedValue(
                      barHour,
                      'primary',
                      highlightedTrafficValue,
                      highlightedTrafficHourValue
                    ),
                    secondary: getHighlightedValue(
                      barHour,
                      'secondary',
                      highlightedTrafficValue,
                      highlightedTrafficHourValue
                    )
                  }"
                  italic
                />
                <v-divider vertical />
                <content-card
                  no-gradient
                  dark-background
                  half-width
                  :title="secondaryData.pavilion.event.key"
                  type="compare"
                  :content="
                    getHighlightedValue(
                      barHour,
                      'secondary',
                      highlightedTrafficValue,
                      highlightedTrafficHourValue
                    )
                  "
                  :compare-data="{
                    circleColor: ChartsDataService.barColors[1]
                  }"
                  italic
                />
              </div>
              <bar-chart
                :data="barHour.data"
                :options="barHour.options"
                :highlight-value="highlightedTrafficHourValue"
              />
              <chart-selector
                center-label
                :data="getDataForChart(barHour, getTotalTrafficByHour(), true)"
                :current-value.sync="highlightedTrafficHourValue"
              />
            </div>
          </v-card>
          <v-card class="sub-section">
            <div class="cards-row">
              <compare-show-titles :primary-data="primaryData" :secondary-data="secondaryData" />
              <div class="compare-data">
                <div class="cards-row vertical">
                  <content-card
                    no-gradient
                    :title="'T_TOTAL_VISITORS' | translate"
                    dark-background
                    :content="primaryData.total.eventVisitors"
                    type="compare"
                    :compare-data="{
                      primary: primaryData.total.eventVisitors,
                      secondary: secondaryData.total.eventVisitors
                    }"
                    italic
                  />
                  <content-card
                    v-if="
                      (primaryData.total.standTraffic / primaryData.total.eventVisitors) * 100 &&
                      (secondaryData.total.standTraffic / secondaryData.total.eventVisitors) * 100
                    "
                    no-gradient
                    :title="'T_TOTAL_VISITORS_CONVERSION' | translate"
                    dark-background
                    type="compare"
                    :compare-data="{
                      primaryPercentage: reduceConversion(
                        primaryData.total.standTraffic,
                        primaryData.total.eventVisitors
                      ),
                      primary:
                        (primaryData.total.standTraffic / primaryData.total.eventVisitors) * 100,
                      secondary:
                        (secondaryData.total.standTraffic / secondaryData.total.eventVisitors) * 100
                    }"
                    conversion
                    italic
                  />
                  <content-card
                    v-else
                    no-gradient
                    dark-background
                    :title="'T_TOTAL_VISITORS_CONVERSION' | translate"
                    italic
                    no-data
                  />

                  <content-card
                    no-gradient
                    :title="'T_TOTAL_STAND_TRAFFIC' | translate"
                    dark-background
                    :content="primaryData.total.eventVisitors"
                    type="compare"
                    :compare-data="{
                      primary: primaryData.total.standTraffic,
                      secondary: secondaryData.total.standTraffic
                    }"
                    italic
                  />
                </div>
                <v-divider vertical inset />
                <div class="cards-row vertical">
                  <content-card
                    no-gradient
                    :title="'T_TOTAL_VISITORS' | translate"
                    dark-background
                    type="compare"
                    :content="secondaryData.total.eventVisitors"
                    italic
                  />
                  <content-card
                    v-if="secondaryData.total.standTraffic && secondaryData.total.eventVisitors"
                    no-gradient
                    :title="'T_TOTAL_VISITORS_CONVERSION' | translate"
                    type="compare"
                    dark-background
                    :content="
                      reduceConversion(
                        secondaryData.total.standTraffic,
                        secondaryData.total.eventVisitors
                      )
                    "
                    conversion
                    italic
                  />
                  <content-card
                    v-else
                    no-gradient
                    :title="'T_TOTAL_VISITORS_CONVERSION' | translate"
                    dark-background
                    italic
                    no-data
                  />

                  <content-card
                    no-gradient
                    :title="'T_TOTAL_STAND_TRAFFIC' | translate"
                    dark-background
                    type="compare"
                    :content="secondaryData.total.standTraffic"
                    italic
                  />
                </div>
              </div>
            </div>
          </v-card>
          <!-- Stay average -->
          <template v-if="hasStayAverage(primaryData)">
            <card-top :options="getDesktopTitle('T_STAY_AVERAGE')" divider />
            <div class="card-wrapper">
              <card-top :options="getDesktopTitle('T_STAY_AVERAGE')" divider />
              <v-card class="sub-section"> </v-card>
            </div>
          </template>
          <!-- Demography -->
          <div class="card-wrapper">
            <card-top :options="getDesktopTitle('T_DEMOGRAPHY')" divider />
            <v-card class="sub-section">
              <card-top
                :current-view.sync="selectedSubView3"
                :options="filterIfNoDataPerHour(getSectionCardTop('area-traffic'))"
              />
              <div v-if="selectedSubView3 === 0" class="demography-by-date">
                <div v-if="graphHasData(barArea)" class="info-box">
                  <bar-chart
                    :data="barArea.data"
                    :options="barArea.options"
                    :highlight-value="highlightedAreaDate"
                  />
                  <chart-selector
                    :center-label="true"
                    :data="
                      getDataForChart(barArea, {
                        label: 'T_ALL_DAYS',
                        value: getTotalForSelectedArea(primaryData, false, 0)
                      })
                    "
                    :current-value.sync="highlightedAreaDate"
                  />
                  <div v-if="barArea.data.datasets" class="compare-show-highlighted">
                    <content-card
                      no-gradient
                      dark-background
                      half-width
                      :title="primaryData.pavilion.event.key"
                      :content="
                        getHighlightedValue(
                          barArea,
                          'primary',
                          highlightedAreaDate,
                          undefined,
                          true
                        )
                      "
                      type="compare"
                      :compare-data="{
                        circleColor: ChartsDataService.barColors[0],
                        primary: getHighlightedValue(
                          barArea,
                          'primary',
                          highlightedAreaDate,
                          undefined,
                          true
                        ),
                        secondary: getHighlightedValue(
                          barArea,
                          'secondary',
                          highlightedAreaDate,
                          undefined,
                          true
                        )
                      }"
                      italic
                    />
                    <v-divider vertical />
                    <content-card
                      no-gradient
                      dark-background
                      half-width
                      :title="secondaryData.pavilion.event.key"
                      type="compare"
                      :content="
                        getHighlightedValue(
                          barArea,
                          'secondary',
                          highlightedAreaDate,
                          undefined,
                          true
                        )
                      "
                      :compare-data="{
                        circleColor: ChartsDataService.barColors[1]
                      }"
                      italic
                    />
                  </div>
                  <template v-if="hasStayAverage(primaryData)">
                    <h2>{{ 'T_STAY_AVERAGE' | translate }}</h2>
                    <div v-if="barArea.data.datasets" class="compare-show-highlighted">
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :formatter="formatStayAverage"
                        :title="primaryData.pavilion.event.key"
                        :content="getStayAverage(false, selectedArea)"
                        type="compare"
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[0],
                          primary: getStayAverage(false, selectedArea),
                          secondary: getStayAverage(false, selectedArea, secondaryData)
                        }"
                        italic
                      />
                      <v-divider vertical />
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="secondaryData.pavilion.event.key"
                        :formatter="formatStayAverage"
                        type="compare"
                        :content="getStayAverage(false, selectedArea, secondaryData)"
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[1]
                        }"
                        italic
                      />
                    </div>
                  </template>
                  <demography-pie-charts
                    :hide="[primaryData.pavilion.hideGenders, secondaryData.pavilion.hideGenders]"
                    :titles="['PER_GENDER']"
                    :pies="[pieGendersPerArea, pieGendersPerArea2]"
                    :highlights="[highlightedArea]"
                    compare
                  />
                  <demography-pie-charts
                    :hide="[
                      primaryData.pavilion.hideAgeRanges,
                      secondaryData.pavilion.hideAgeRanges
                    ]"
                    :titles="['PER_AGE_GROUP']"
                    :pies="[pieAgeRangesPerArea, pieAgeRangesPerArea2]"
                    :highlights="[highlightedArea + highlightedAreaDate]"
                    compare
                  />
                </div>
                <no-graph-data v-else />
              </div>
              <div v-else class="demography-by-hour">
                <chart-selector
                  :center-label="true"
                  :data="
                    getDataForChart(barArea, {
                      label: 'T_ALL_DAYS',
                      value: getTotalForSelectedArea(
                        primaryData,
                        selectedArea !== 0 ? selectedArea : areas2 && areas2[0]
                      )
                    })
                  "
                  :current-value.sync="highlightedAreaDate"
                />
                <div v-if="graphHasData(barAreaHour)" class="info-box">
                  <div class="graph-wrapper">
                    <bar-chart
                      :data="barAreaHour.data"
                      :options="barAreaHour.options"
                      :highlight-value="highlightedAreaHour"
                    />
                  </div>
                  <chart-selector
                    :data="getDataForChart(barAreaHour, getAverageForArea(primaryData), true)"
                    :current-value.sync="highlightedAreaHour"
                  />
                  <div v-if="barAreaHour.data.datasets" class="compare-show-highlighted">
                    <content-card
                      no-gradient
                      dark-background
                      half-width
                      :title="primaryData.pavilion.event.key"
                      :content="
                        getHighlightedValue(
                          barAreaHour,
                          'primary',
                          highlightedAreaHour,
                          undefined,
                          true
                        )
                      "
                      type="compare"
                      :compare-data="{
                        circleColor: ChartsDataService.barColors[0],
                        primary: getHighlightedValue(
                          barAreaHour,
                          'primary',
                          highlightedAreaHour,
                          undefined,
                          true
                        ),
                        secondary: getHighlightedValue(
                          barAreaHour,
                          'secondary',
                          highlightedAreaHour,
                          undefined,
                          true
                        )
                      }"
                      italic
                    />
                    <v-divider vertical />
                    <content-card
                      no-gradient
                      dark-background
                      half-width
                      :title="secondaryData.pavilion.event.key"
                      type="compare"
                      :content="
                        getHighlightedValue(
                          barAreaHour,
                          'secondary',
                          highlightedAreaHour,
                          undefined,
                          true
                        )
                      "
                      :compare-data="{
                        circleColor: ChartsDataService.barColors[1]
                      }"
                      italic
                    />
                  </div>
                  <template v-if="hasStayAverage(primaryData)">
                    <h2>{{ 'T_STAY_AVERAGE' | translate }}</h2>
                    <div v-if="barArea.data.datasets" class="compare-show-highlighted">
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :formatter="formatStayAverage"
                        :title="primaryData.pavilion.event.key"
                        :content="getStayAverage(false, selectedArea)"
                        type="compare"
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[0],
                          primary: getStayAverage(false, selectedArea),
                          secondary: getStayAverage(false, selectedArea, secondaryData)
                        }"
                        italic
                      />
                      <v-divider vertical />
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="secondaryData.pavilion.event.key"
                        :formatter="formatStayAverage"
                        type="compare"
                        :content="getStayAverage(false, selectedArea, secondaryData)"
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[1]
                        }"
                        italic
                      />
                    </div>
                  </template>
                  <demography-pie-charts
                    :hide="[primaryData.pavilion.hideGenders, secondaryData.pavilion.hideGenders]"
                    :titles="['PER_GENDER']"
                    :pies="[pieGendersByHourPerArea, pieGendersByHourPerArea2]"
                    :highlights="[highlightedArea + highlightedAreaDate]"
                    compare
                  />
                  <demography-pie-charts
                    :hide="[
                      primaryData.pavilion.hideAgeRanges,
                      secondaryData.pavilion.hideAgeRanges
                    ]"
                    :titles="['PER_AGE_GROUP']"
                    :pies="[pieAgeRangesByHourPerArea, pieAgeRangesByHourPerArea2]"
                    :highlights="[highlightedArea + highlightedAreaDate]"
                    compare
                  />
                </div>
                <no-graph-data v-else />
              </div>
            </v-card>
          </div>
          <!-- Vehicle list -->
          <div class="card-wrapper">
            <card-top :options="getDesktopTitle('T_AREA_LIST')" divider />
            <v-card class="sub-section">
              <card-top
                :current-view.sync="selectedSubView4"
                :options="getSectionCardTop('area-list')"
              />
              <div v-if="selectedSubView4 === 0" class="floorplans">
                <div class="show-name" :style="`color:${ChartsDataService.barColors[0]}`">
                  {{ primaryData.pavilion.event.name | translate }}
                </div>
                <axo-view :data="primaryData" />
                <areas-table v-if="!reloadAreasTable" :data="primaryData" />
                <div class="show-name" :style="`color:${ChartsDataService.barColors[1]}`">
                  {{ secondaryData.pavilion.event.name | translate }}
                </div>
                <axo-view :data="secondaryData" />
                <areas-table v-if="!reloadAreasTable" :data="secondaryData" />
              </div>
              <div v-else class="traffic-by-nameplate">
                <div class="compare-show-highlighted">
                  <div class="show">
                    <pie-chart
                      v-if="
                        pie &&
                        primaryData &&
                        primaryData.bestPerformingNameplate &&
                        primaryData.bestPerformingNameplate.traffic
                      "
                      :data="pie.data"
                      :options="pie.options"
                    />
                    <areas-table
                      v-if="!reloadAreasTable"
                      :data="primaryData"
                      show-unique-visitors
                    />
                  </div>
                  <v-divider vertical />
                  <div class="show">
                    <pie-chart
                      v-if="
                        pie2 &&
                        secondaryData.bestPerformingNameplate &&
                        secondaryData.bestPerformingNameplate.traffic
                      "
                      :data="pie2.data"
                      :options="pie2.options"
                    />
                    <areas-table
                      v-if="!reloadAreasTable"
                      :data="secondaryData"
                      show-unique-visitors
                    />
                  </div>
                </div>
              </div>
            </v-card>
          </div>

          <!-- Attractiveness -->
          <div class="card-wrapper">
            <card-top :options="getDesktopTitle('T_ATTRACTIVENESS')" divider />
            <template v-if="!canCompareSubSection('attractiveness')">
              <v-card class="sub-section no-data">
                <cannot-compare-data />
              </v-card>
            </template>
            <template v-else-if="primaryData && primaryData.pavilion">
              <v-card class="sub-section">
                <card-top
                  :current-view.sync="selectedSubView6"
                  :options="getSectionCardTop('attractiveness')"
                />
                <div v-if="selectedSubView6 === 0" class="info-box">
                  <div class="show-name" :style="`color:${ChartsDataService.barColors[0]}`">
                    {{ primaryData.pavilion.event.name | translate }}
                  </div>
                  <chart-selector
                    :play-btn-callback="playPauseBubble"
                    :center-label="true"
                    :loading="heatmapChartSelector.length === 0"
                    :data="heatmapChartSelector"
                    :formatter="formatPercentage"
                    :current-value.sync="highlightedBubbleDay"
                  />
                  <bubble-chart
                    :data="primaryData"
                    :reloading-bubble="reloadingBubble"
                    :chart-data="bubbleChart.data"
                    :data-per-day="bubbleForSelectedDay"
                    :background-image="getBubbleChartBackground(primaryData)"
                  />
                  <div class="show-name" :style="`color:${ChartsDataService.barColors[1]}`">
                    {{ secondaryData.pavilion.event.name | translate }}
                  </div>
                  <chart-selector
                    :play-btn-callback="playPauseBubble2"
                    :center-label="true"
                    :loading="heatmapChartSelector2.length === 0"
                    :data="heatmapChartSelector2"
                    :formatter="formatPercentage"
                    :current-value.sync="highlightedBubbleDay2"
                  />
                  <bubble-chart
                    :data="secondaryData"
                    :reloading-bubble="reloadingBubble2"
                    :chart-data="bubbleChart2.data"
                    :data-per-day="bubbleForSelectedDay2"
                    :background-image="getBubbleChartBackground(secondaryData)"
                  />
                </div>
                <div v-else class="info-box">
                  <bar-chart
                    :data="barAttractiveness.data"
                    :options="barAttractiveness.options"
                    :highlight-value="highlightedAttractivenessDate"
                  />
                  <chart-selector
                    :center-label="selectedAttractivenessDate === 0 || true"
                    :data="
                      getDataForChart(barAttractiveness, {
                        label: 'T_ALL_DAYS',
                        value: formatPercentage(getTotalForSelectedAttractiveness(primaryData))
                      })
                    "
                    :formatter="formatPercentage"
                    :current-value.sync="highlightedAttractivenessDate"
                  />
                </div>
              </v-card>
            </template>
          </div>

          <!-- Heatmap -->
          <div v-if="hasHeatmap(primaryData)" class="card-wrapper">
            <card-top :options="getDesktopTitle('T_HEATMAP')" divider />
            <template v-if="!canCompareSubSection('heatmap')">
              <v-card class="sub-section no-data">
                <cannot-compare-data />
              </v-card>
            </template>
            <template v-else-if="primaryData.pavilion && primaryData.pavilion.hasHeatmap">
              <v-card class="sub-section">
                <div class="info-box">
                  <card-top
                    :current-view.sync="selectedSubView5"
                    :options="getSectionCardTop('heatmap')"
                  />
                  <div class="show-name" :style="`color:${ChartsDataService.barColors[0]}`">
                    {{ primaryData.pavilion.event.name | translate }}
                  </div>
                  <chart-selector
                    :play-btn-callback="playPauseHeatmap"
                    :center-label="selectedHeatmapDay === 0"
                    :loading="heatmapChartSelector.length === 0"
                    :data="heatmapChartSelector"
                    :formatter="formatPercentage"
                    :current-value.sync="highlightedHeatmapDay"
                  />
                  <heatmap
                    :reloading-heatmap="reloadingHeatmap"
                    :data="primaryData"
                    :data-per-day="heatmapForSelectedDay"
                    :show-tooltips="selectedSubView5 === 1"
                  />
                </div>
                <div class="info-box">
                  <chart-selector
                    :play-btn-callback="playPauseHeatmap2"
                    :center-label="selectedHeatmapDay2 === 0"
                    :loading="heatmapChartSelector2.length === 0"
                    :data="heatmapChartSelector2"
                    :formatter="formatPercentage"
                    :current-value.sync="highlightedHeatmapDay2"
                  />
                  <div class="show-name" :style="`color:${ChartsDataService.barColors[1]}`">
                    {{ secondaryData.pavilion.event.name | translate }}
                  </div>
                  <heatmap
                    :reloading-heatmap="reloadingHeatmap2"
                    :data="secondaryData"
                    :data-per-day="heatmapForSelectedDay2"
                    :show-tooltips="selectedSubView5 === 1"
                  />
                </div>
              </v-card>
            </template>
          </div>

          <!-- Neighbors -->
          <div class="card-wrapper full-width">
            <card-top :options="getDesktopTitle('T_NEIGHBORS')" divider />
            <v-card class="sub-section">
              <card-top
                :current-view.sync="selectedSubView8"
                :options="getSectionCardTop('neighbors')"
              />
              <div class="info-box">
                <div class="show-name" :style="`color:${ChartsDataService.barColors[0]}`">
                  {{ primaryData.pavilion.event.name | translate }}
                </div>

                <image-loader
                  :src="primaryData.pavilion.hallView"
                  :lazy-src="getLazySrc(primaryData.pavilion.hallView, '500')"
                  min-height="400"
                  classes="map rounded"
                  gradient-loading
                  @click.native="displayFullscreenNeighbors(primaryData)"
                />

                <div class="show-name" :style="`color:${ChartsDataService.barColors[1]}`">
                  {{ secondaryData.pavilion.event.name | translate }}
                </div>
                <image-loader
                  min-height="400"
                  :src="secondaryData.pavilion.hallView"
                  :lazy-src="getLazySrc(secondaryData.pavilion.hallView, '500')"
                  gradient-loading
                  classes="map rounded"
                  @click.native="displayFullscreenNeighbors(secondaryData)"
                />
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseSection from '@/mixins/BaseSection'
import TrafficSection from '@/mixins/TrafficSection'
import AxoView from '@/components/AxoView'
import AreasTable from '@/components/AreasTable'

export default {
  name: 'TrafficSectionCompare',
  components: { AxoView, AreasTable },
  mixins: [BaseSection, TrafficSection],
  data() {
    return {
      desktopMode: 'compare'
    }
  }
}
</script>
