<template>
  <div id="traffic-section-page-2" class="pdf-sub-section">
    <div class="section-sidebar">
      <div class="section-title">
        {{ 'T_STAND_TRAFFIC' | translate }} - {{ 'T_AREA' | translate }} - {{ areaName }}
      </div>
    </div>
    <div v-if="primaryData" class="section-content">
      <div class="area-traffic-per-day">
        <div class="area-title">{{ 'T_AREA' | translate }}: {{ areaName }}</div>
        <div class="section-title">{{ 'T_AREA_TRAFFIC_BY_DATE' | translate }}</div>
        <p class="total-traffic">
          {{ 'T_ALL_DAYS' | translate }}:
          <span>{{ getTotalForSelectedArea(primaryData, false, pdfArea, true) | numeral }}</span>
          <template v-if="hasStayAverage(primaryData)">
            <span class="spacer" />
            {{ 'T_STAY_AVERAGE' | translate }}:
            <span>{{ formatStayAverage(getStayAverage(false, pdfArea, false, true)) }}</span>
          </template>
        </p>
        <bar-chart is-for-pdf :data="barArea.data" :options="barArea.options" />
      </div>
      <img class="pdf-divider" src="@/assets/divider.svg" />
      <div class="area-traffic-per-hour">
        <div class="section-title">{{ 'T_AREA_TRAFFIC_BY_HOUR' | translate }}</div>
        <bar-chart is-for-pdf :data="barAreaHour.data" :options="barAreaHour.options" />
      </div>
      <img class="pdf-divider" src="@/assets/divider.svg" />
      <div class="cards-row" :class="{ center: !graphHasData(pieAgeRangesPerArea) }">
        <div v-if="graphHasData(pieGendersPerArea)" class="per-gender">
          <div class="section-title">{{ 'T_DEMOGRAPHY_PER_GENDER' | translate }}</div>
          <pdf-pie-chart
            v-if="pieGendersPerArea"
            :data="pieGendersPerArea.data"
            :options="pieGendersPerArea.options"
          />
          <pie-chart-legend is-for-pdf :data="pieGendersPerArea" only-percentages />
        </div>
        <div v-if="graphHasData(pieAgeRangesPerArea)" class="per-age">
          <div class="section-title">{{ 'T_DEMOGRAPHY_PER_AGE_GROUP' | translate }}</div>
          <pdf-pie-chart
            v-if="pieAgeRangesPerArea"
            :data="pieAgeRangesPerArea.data"
            :options="pieAgeRangesPerArea.options"
          />
          <pie-chart-legend is-for-pdf :data="pieAgeRangesPerArea" only-percentages />
        </div>
      </div>
      <percentages-disclaimer
        v-if="graphHasData(pieGendersPerArea) || graphHasData(pieAgeRangesPerArea)"
        is-for-pdf
      />
    </div>
  </div>
</template>

<script>
import BaseSection from '@/mixins/BaseSection'
import TrafficSection from '@/mixins/TrafficSection'
import PdfPieChart from '@/components/Charts/PdfPieChart.vue'
import PercentagesDisclaimer from '@/components/PercentagesDisclaimer.vue'

export default {
  name: 'Traffic',
  components: {
    PdfPieChart,
    PercentagesDisclaimer
  },
  mixins: [BaseSection, TrafficSection],
  data() {
    return {
      pdfPage: 'traffic-area'
    }
  },
  computed: {
    areaName() {
      return this.getAreaFullName(this.primaryData, '_id', this.pdfArea)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';
#traffic-section-page-2 {
  .map {
    margin: 0;
    max-width: 100%;
    border-radius: 4px;
    width: 100%;
    &.rounded {
      border-radius: $half-margin;
    }
  }
  .show-name {
    margin: $standard-margin 0;
    text-align: left;
  }
  .sub-section {
    display: flex;
  }
  .floorplans {
    width: 50%;
  }
  .hall-view {
    width: 100%;
  }
  .total-traffic {
    font-style: italic;
    font-weight: normal;
    font-size: 8px;
    line-height: 10px;
    margin-bottom: 8px;
    span {
      font-weight: bold;
    }
  }
  .error-message {
    font-weight: normal;
    color: $grey;
  }
  .stand-statistics-per-day,
  .stand-statistics-per-hour {
    width: 100%;
  }
  .demography-pie {
    width: 47.5%;
  }
  .bar-chart,
  .pie-chart {
    height: 145px !important;
  }
  .area-title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .section-sidebar .section-title {
    margin-top: 650px !important;
    width: 650px !important;
  }
  .spacer {
    margin-right: 12px;
  }
}
</style>
