<template>
  <div
    v-if="checkNotEmpty()"
    class="card-top"
    :class="{ 'with-divider': divider, 'full-width': fullWidth }"
  >
    <h2 v-for="(state, i) in options.states" v-show="localCurrentView === i" :key="i">
      {{ state.title | translate }}
    </h2>
    <div v-if="divider" class="divider" />
    <div
      v-else-if="switchType !== 'hidden'"
      :class="'switch-view ' + switchType + toggleSwitchActiveClass()"
      @click="toggleView()"
    >
      <v-btn
        v-for="(state, i) in options.states"
        :key="i"
        rounded
        :ripple="false"
        fab
        text
        :class="{ 'v-btn--active': localCurrentView === i }"
      >
        <v-img
          :src="state.icon"
          :lazy-src="state.icon"
          height="auto"
          width="12"
          :class="{ hidden: switchType === 'toggle' && i !== localCurrentView }"
        />
      </v-btn>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          type: 'switch', // switch / toggle / hidden
          states: [
            {
              title: 'T_DAILY_TRAFFIC',
              icon: 'date'
            },
            {
              title: 'T_TOTAL_TRAFFIC',
              icon: 'nameplate'
            }
          ]
        }
      }
    },
    currentView: {
      type: Number,
      default: 0
    },
    divider: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localCurrentView: 0
    }
  },
  computed: {
    switchType() {
      return _.get(this.options, 'type', '')
    }
  },
  watch: {
    currentView() {
      this.localCurrentView = _.clone(this.currentView)
    }
  },
  mounted() {
    this.localCurrentView = _.clone(this.currentView)
  },
  methods: {
    checkNotEmpty() {
      return !_.isEmpty(this.options)
    },
    toggleSwitchActiveClass() {
      return this.switchType === 'toggle' && this.localCurrentView === 1 ? ' active' : ''
    },
    toggleView() {
      this.localCurrentView = this.localCurrentView === 0 ? 1 : 0
      this.$emit('update:currentView', this.localCurrentView)
    }
  }
}
</script>

<style lang="scss">
@import '../vars.scss';
.card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $standard-margin;
  min-height: 36px;
}
.switch-view {
  cursor: pointer;
  background-color: $primary;
  border-radius: 100px;
  padding: 4px;
  .v-btn {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }
  .v-btn {
    background-color: transparent;
    .v-btn__content {
      height: 28px;
      width: 28px;
      border-radius: 100px;
      padding: 5px;
      transition: background-color 0.3s;
    }
    &:hover::before,
    &:before {
      opacity: 0;
    }
    &.v-btn--active {
      .v-btn__content {
        background-color: $cyan;
      }
    }
    &.v-btn--fab.v-size--default {
      height: 28px;
      width: 28px;
      min-width: 28px;
      min-height: 28px;
    }
    &:first-child {
      margin-right: 6px;
    }
  }
  &.toggle {
    background-color: $dark-background;
    .v-btn {
      .v-image {
        display: none;
      }
      &.v-btn--active {
        .v-btn__content {
          background-color: $purple;
        }
        .v-image {
          display: block;
        }
      }
    }
    &.active {
      background-color: $cyan;
    }
  }
}
@media (min-width: 500px) {
  .card-top {
    margin-bottom: $desktop-standard-margin;
    h2 {
      font-style: italic;
      font-weight: 400;
      font-size: $desktop-scale-vw * 25;
      line-height: $desktop-scale-vw * 31;
      text-align: left;
      color: white;
    }
    &.with-divider {
      display: flex;
      flex-direction: column;
      h2 {
        width: 100%;
        margin-bottom: $desktop-scale-vw * 16;
      }
      .divider {
        width: 100%;
        border-bottom: 1px dashed #5b6897;
      }
    }
    &.full-width {
      width: 100%;
    }
  }
}
</style>
