<template>
  <div v-if="sections.length > 0" id="sections-nav-bar" :class="{ compare: compare }">
    <div class="mask"></div>
    <div class="sections-wrapper">
      <div
        v-for="section in sections"
        :key="section.key"
        class="section-link"
        :class="{ selected: getCurrentSectionKey() === section.key }"
        @click="goToSection(section.key)"
      >
        <div v-if="section.key === 'traffic' || section.key === 'sales'" class="icon-wrapper">
          <img :src="getSectionIcon(section, getCurrentSectionKey() === section.key)" />
        </div>
        <div v-else class="icon-wrapper">
          <img :src="getSectionIcon(section)" />
        </div>
        <div class="section-title">
          {{ section.title | translate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import PavilionSelectorService from '@/services/PavilionSelectorService'
export default {
  props: {
    compare: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sections: [],
      query: ''
    }
  },
  computed: {},
  mounted() {
    if (this.getCurrentSectionKey('none') === 'none') {
      this.goToSection('event')
    }
    PavilionSelectorService.events.on('filtered-sections', (sections) => {
      this.sections = sections
      const currentSectionKey = this.getCurrentSectionKey()
      if (
        currentSectionKey !== 'pdf' &&
        !_.includes(_.map(this.sections, 'key'), currentSectionKey)
      ) {
        console.warn(
          `section ${currentSectionKey} is not accessible, will redirect to event section`
        )
        this.goToSection(`event`)
      }
      this.$forceUpdate()
    })
  },
  methods: {
    getSectionIcon(section, active) {
      return require(`../assets/icons/${section.icon}${active ? '-active' : ''}.svg`)
    },
    getCurrentSectionKey(defaultVal = false) {
      return _.get(_.split(this.$route.path, '/'), [1], defaultVal ? defaultVal : 'event')
    },
    goToSection(key) {
      if (this.getCurrentSectionKey() === key) {
        return
      }
      this.$router
        .push({
          path: `/${key}`,
          query: this.$route.query
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';
#sections-nav-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: $scale-vh * 96;
  color: white;
  z-index: 3;
  &.compare {
    .sections-wrapper {
      background: linear-gradient(90deg, #71dbd4 0%, #5ab9dc 50%, #8094dd 100%);
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(20px);
    }
  }
}
.mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.sections-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(128, 148, 221, 0.5);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  border-radius: $scale-vw * 100;
  width: 90%;
  margin: 0 auto;
  margin-bottom: $standard-margin;
  overflow: hidden;
  text-align: center;
  padding: $half-margin $double-margin;
}

.section-link {
  .icon-wrapper {
    border-radius: $scale-vw * 50;
    width: $scale-vw * 32;
    height: $scale-vw * 32;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .section-title {
    font-weight: 400;
    font-size: $scale-vw * 12;
    line-height: $scale-vw * 15;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  &.selected {
    .section-title {
      font-weight: 700;
    }
    .icon-wrapper {
      background: linear-gradient(180deg, $orange 0%, $purple 100%);
    }
  }
}
</style>
