import _ from 'lodash'
import delay from 'delay'
import axios from 'axios'
import DeviceDetector from 'device-detector-js'
import Emitter from 'tiny-emitter'
import LoginService from '@/services/LoginService'
import DataService from '@/services/DataService'
import CompareDataService from '@/services/CompareDataService'
import TranslateService from '@/services/TranslateService'

class PavilionSelectorService {
  constructor() {
    this.query = ''
    this.events = new Emitter()
    this.brands = []
    this.selectedBrand = null
    this.selectedPrimaryPavilion = null
    this.selectedSecondaryPavilion = null
    this.primaryData = null
    this.secondaryData = null
    this.sections = [
      {
        title: 'T_EVENT',
        key: 'event',
        icon: 'event'
      },
      {
        title: 'T_OVERVIEW',
        key: 'overview',
        icon: 'overview'
      },
      {
        title: 'T_TRAFFIC',
        key: 'traffic',
        icon: 'traffic'
      },
      {
        title: 'T_EXPERIENCE',
        key: 'experience',
        icon: 'experience'
      },
      {
        title: 'T_SALES',
        key: 'sales',
        icon: 'sales'
      }
    ]
    this.filteredSections = []
    this.pavilionModules = [
      // Sales section
      'hasLeads', // show/hide sales - leads sub section
      'hasOrders', // show/hide sales - orders sub section
      // if both not here -> hide sales section
      // Traffic section
      'hasHeatmap', // show/hide traffic - heatmat sub section
      'hasStayAverage',
      'hasCamera', // show/hide traffic - cameras sub section
      // Experience section
      'hasExperiences', // show/hide experience section sub sections like interactions, souvenir
      'hasVoucher' // show/hide experience - voucher sub section
      // if all 3 not here -> hide the experience section
    ]
    CompareDataService.onChangeData(async (primaryData, secondaryData) => {
      this.primaryData = null
      this.secondaryData = null
      await delay(100)
      this.selectedPrimaryPavilion = primaryData && primaryData.pavilion
      this.selectedSecondaryPavilion = secondaryData && secondaryData.pavilion
      this.primaryData = primaryData
      this.secondaryData = secondaryData
      const newQuery = {
        primaryPavilion: primaryData.pavilion._id,
        secondaryPavilion: secondaryData && secondaryData.pavilion._id
      }
      this.query = _.extend({}, newQuery)
      // console.warn(`PavilionSelectorService - onChangeData CompareDataService`)
      this.sendEvent(true)
    })
  }

  isDesktop() {
    const deviceDetector = new DeviceDetector()
    return _.get(deviceDetector.parse(navigator.userAgent), 'device.type', 'mobile') === 'desktop'
  }

  sendEvent(updateQuery = false, returnValues = false) {
    const data = {
      brands: this.brands,
      selectedBrand: this.selectedBrand,
      selectedPrimaryPavilion: this.selectedPrimaryPavilion,
      selectedSecondaryPavilion: this.selectedSecondaryPavilion,
      primaryData: this.primaryData,
      secondaryData: this.secondaryData
    }
    if (updateQuery) {
      _.set(data, 'query', this.query)
    }
    if (returnValues) {
      return data
    }
    if (_.get(this.primaryData, 'pavilion', false)) {
      this.filterSections()
    }
    // console.warn(`PavilionSelectorService.sendEvent`)
    this.events.emit('update-pavilion-data', data)
  }

  hiddenSubSection(field) {
    return !_.get(this.primaryData, `pavilion.${field}`, false)
  }

  removeSection(sections, key) {
    // console.warn(`removeSection - ${key}`)
    return _.filter(sections, (section) => {
      return section.key !== key
    })
  }

  getDatesParams(selectedDates) {
    const startDate = _.get(
      selectedDates,
      '[0]',
      _.get(this.primaryData, 'pavilion.event.startDate', false)
    )
    const endDate = _.get(
      selectedDates,
      '[1]',
      _.get(this.primaryData, 'pavilion.event.endDate', false)
    )
    return `${startDate}/${endDate}`
  }

  async downloadPDF(selectedDates) {
    const { data } = await axios({
      method: 'get',
      timeout: 300 * 1000,
      url: `/api/pdf/${_.get(this.primaryData, 'pavilion._id', false)}/${this.getDatesParams(
        selectedDates
      )}/${TranslateService.currentLocale}`,
      responseType: 'blob'
    })
    return data
  }

  async downloadExcel(selectedDates) {
    const { data } = await axios({
      method: 'get',
      url: `/api/excel/${_.get(this.primaryData, 'pavilion._id', false)}/${this.getDatesParams(
        selectedDates
      )}`,
      responseType: 'blob'
    })
    return data
  }

  filterSections() {
    let filteredSections = _.cloneDeep(this.sections)
    if (this.hiddenSubSection('hasLeads') && this.hiddenSubSection('hasLeads')) {
      filteredSections = this.removeSection(filteredSections, 'sales')
    }
    if (this.hiddenSubSection('hasExperiences') && this.hiddenSubSection('hasVoucher')) {
      filteredSections = this.removeSection(filteredSections, 'experience')
    }
    this.filteredSections = filteredSections
    this.events.emit('filtered-sections', filteredSections)
  }

  async getData(query = false, updateQuery = false, forceUpdate = false) {
    if (!query && !updateQuery && !forceUpdate) {
      // console.warn(`PavilionSelectorService - getData - will send event directly`)
      return this.sendEvent(false, true)
    }

    if (!_.isEmpty(await LoginService.getStatus())) {
      this.brands = await DataService.getBrands(forceUpdate)
    } else {
      return
    }
    if (_.isEmpty(this.brands)) {
      throw new Error('brands is empty')
    }
    this.brands = _.filter(this.brands, (brand) => !_.isEmpty(brand.pavilions))
    if (_.isEmpty(this.brands)) {
      throw new Error('no brand has valid pavilion')
    }
    if (!_.isEmpty(query.primaryPavilion)) {
      _.each(this.brands, (brand) => {
        const pavilion = _.find(brand.pavilions, { _id: query.primaryPavilion })
        if (pavilion) {
          this.selectedBrand = brand
          this.selectedPrimaryPavilion = pavilion
        }
      })
    }
    if (!_.isEmpty(query.secondaryPavilion)) {
      _.each(this.brands, (brand) => {
        const pavilion = _.find(brand.pavilions, { _id: query.secondaryPavilion })
        if (pavilion) {
          this.selectedSecondaryPavilion = pavilion
        }
      })
    }
    if (!this.selectedBrand) {
      this.selectedBrand = _.first(this.brands)
    }
    if (!this.selectedPrimaryPavilion) {
      this.selectedPrimaryPavilion = _.last(this.selectedBrand.pavilions)
    }
    // console.warn(`PavilionSelectorService - getData `)
    if (forceUpdate) {
      // console.warn(
      //   `FORCE UPDATE DETECTED, WILL NOT SEND EVENT`,
      //   _.get(this.selectedPrimaryPavilion, 'voiceOfMedia.enUS', '')
      // )
      return
    }
    await CompareDataService.setPrimaryPavilion(
      this.selectedPrimaryPavilion,
      this.selectedSecondaryPavilion
    )
    // this.sendEvent()
  }
}

export default new PavilionSelectorService()
