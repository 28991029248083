<template>
  <div id="experience-section" class="pdf-sub-section">
    <div class="section-sidebar">
      <div class="section-title">
        {{ 'T_TOUCHPOINTS' | translate }}
      </div>
    </div>
    <div v-if="primaryData" class="section-content">
      <div class="section-title big">
        {{ 'T_TOUCHPOINTS' | translate }}
      </div>
      <!-- Interactions -->
      <div class="sub-section">
        <div class="interaction-days">
          <div class="section-title">
            {{ 'T_GUEST_INTERACTIONS_BY_DATE' | translate }}
          </div>
          <div class="section-total">
            {{ 'T_TOTAL_STAND_GUEST_INTERACTIONS' | translate }}:
            <span>{{ totalGuestInteractions | numeral }}</span>
          </div>
          <line-chart
            is-for-pdf
            :data="lineDaily.data"
            :options="lineDaily.options"
            :highlight-value="0"
          />
        </div>
        <img class="pdf-divider" src="@/assets/divider.svg" />
        <div class="interaction-hours">
          <div class="section-title">
            {{ 'T_GUEST_INTERACTIONS_BY_HOUR' | translate }}
          </div>
          <bar-chart is-for-pdf :data="bar.data" :options="bar.options" :highlight-value="0" />
        </div>
        <img class="pdf-divider" src="@/assets/divider.svg" />

        <!-- Voucher -->
        <div v-if="primaryData.pavilion.hasVoucher" class="section-title">
          {{ 'T_VOUCHERS_GENERATION_AND_REDEMPTION' | translate }}
        </div>
        <div v-if="primaryData.pavilion.hasVoucher" class="title-graph vertical">
          <div class="graph-total">
            {{ 'T_TOTAL_REDEMPTION_RATE' | translate }}:
            <span>{{ getVoucherRedemptionRate(primaryData) }}</span>
          </div>
          <div class="graph-total">
            {{ 'T_TOTAL_GENERATED_VOUCHERS' | translate }}:
            <span>{{ getTotalVoucher(primaryData, 'generated', false, true) }}</span>
          </div>
          <div class="graph-total">
            {{ 'T_TOTAL_REDEEMED_VOUCHERS' | translate }}:
            <span>{{ getTotalVoucher(primaryData, 'redeemed', false, true) }}</span>
          </div>
        </div>
        <div v-if="primaryData.pavilion.hasVoucher" class="sub-section">
          <div class="info-box">
            <bar-chart
              is-for-pdf
              :data="barVoucher.data"
              :options="barVoucher.options"
              :highlight-value="0"
            />
            <div class="data-table full-width">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th v-for="day in voucherTableHeaders" :key="day">{{ day }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(voucherLine, i) in voucherTable" :key="i">
                    <td
                      v-for="(voucherData, y) in voucherLine"
                      :key="`data-${y}`"
                      :class="{ time: y === 0 }"
                    >
                      <template v-if="y === 0">
                        <div class="flex-wrapper">
                          <span :class="{ yellow: i === 0, green: i === 1 }" />
                          {{ voucherData }}
                        </div>
                      </template>
                      <template v-else>
                        {{ voucherData }}
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import BaseSection from '@/mixins/BaseSection'
import LineChart from '@/components/Charts/LineChart.vue'
import BarChart from '@/components/Charts/BarChart.vue'
import ChartsDataService from '@/services/ChartsDataService'
import TranslateService from '@/services/TranslateService'

export default {
  name: 'Experience',
  components: {
    BarChart,
    LineChart
  },
  mixins: [BaseSection],
  data() {
    return {
      bar: {
        options: {
          plugins: {
            datalabels: {
              display: true,
              align: 'center',
              font: {
                family: 'SourceSansPro-Bold',
                size: 8
              },
              opacity: 1,
              labels: {
                value: {
                  formatter: function (value, context) {
                    const val = _.get(context, `dataset.data[${context.dataIndex}]`, 0)
                    return val === 0 ? '' : val
                  },
                  font: {
                    family: 'SourceSansPro-Bold',
                    size: '8px',
                    weight: '700'
                  },
                  anchor: 'end',
                  align: 'top',
                  offset: 0,
                  color: 'black'
                }
              }
            }
          }
        }
      },
      lineDaily: {
        options: {
          plugins: {
            datalabels: {
              display: true,
              align: 'center',
              font: {
                family: 'SourceSansPro-Bold',
                size: 8
              },
              opacity: 1,
              labels: {
                value: {
                  formatter: function (value, context) {
                    const val = _.get(context, `dataset.data[${context.dataIndex}]`, 0)
                    return val === 0 ? '' : val
                  },
                  font: {
                    family: 'SourceSansPro-Bold',
                    size: '8px',
                    weight: '700'
                  },
                  anchor: 'end',
                  align: 'top',
                  offset: 0,
                  color: 'black'
                }
              }
            }
          }
        }
      },
      line: false,
      voucherTableHeaders: [],
      voucherTable: [],
      lineChartsColors: ['#8DE971', '#E1E000', '#DD2700', '#71DBD4'],
      lineSouvenirs: false,
      totalGuestInteractions: 0,
      voucherList: false,
      souvenirList: false,
      barVoucher: false,
      ChartsDataService
    }
  },
  methods: {
    generateLabelsForLists() {
      this.voucherList = false
      this.souvenirList = false
      this.$nextTick(() => {
        this.voucherList = this.getList(this.barVoucher, this.highlightedVoucherDayValue)
        this.souvenirList = this.getList(this.lineSouvenir, this.highlightedSouvenirDayValue)
        this.$forceUpdate()
      })
    },
    getTotalVoucher(data, type, highlightedVoucherDayValue = false, formatVal = false) {
      let test = _.toNumber(
        _.sum(
          _.map(_.get(data, 'vouchersByDate', []), (item) =>
            _.get(item, `countByAction.${type}`, 0)
          )
        )
      )
      if (highlightedVoucherDayValue) {
        test = _.toNumber(
          _.get(
            data,
            `vouchersByDate[${this.highlightedVoucherDayValue - 1}.countByAction.${type}`,
            0
          )
        )
      }
      if (formatVal) {
        return numeral(test).format('0,0')
      }
      return test
    },
    getVoucherRedemptionRate(data, highlightedVoucherDayValue = false, returnAsNumber = false) {
      const redemptionRate = numeral(
        (this.getTotalVoucher(data, 'redeemed', highlightedVoucherDayValue) /
          this.getTotalVoucher(data, 'generated', highlightedVoucherDayValue)) *
          100
      ).format('0')
      if (returnAsNumber) {
        return redemptionRate
      }
      return `${redemptionRate}%`
    },
    filterIfNoDataPerHour(cardTopData) {
      const nbDataPerHour = _.sum(
        _.map(_.get(this.primaryData, 'experiencesByDate', []), (date) => {
          return _.get(date, 'visitsByHour.length', 0)
        })
      )
      if (nbDataPerHour === 0) {
        cardTopData.type = 'hidden'
        console.info(`Interactions per hour hidden since no data`)
      }
      return cardTopData
    },
    getTotalForDataset(chart, datasetIndex) {
      return _.sum(_.get(chart, `data.datasets[${datasetIndex}].data`, []))
    },
    getTotalForAllDays(data, obj, type) {
      return `${_.sum(
        _.map(_.get(data, obj, []), (d) => {
          return _.toNumber(_.get(d, `countByAction.${type}`, 0))
        })
      )}`
    },
    getTotalInteractionsByHour() {
      if (this.selectedHour === 0) {
        return {
          label: TranslateService.get('T_AVERAGE_PER_HOUR'),
          value: this.getAverageForDatasets(this.bar.data.datasets)
        }
      }
      let value = _.get(this.getOriginalTrafficData(this.primaryData), 'visits', 0)
      if (this.secondaryData) {
        value += _.get(this.getOriginalTrafficData(this.secondaryData), 'visits', 0)
      }
      return {
        label: this.selectedDate,
        value
      }
    },
    getList(chartObj, highlightValue) {
      return _.map(_.get(chartObj, 'data.datasets', []), (dataset, i) => {
        return {
          borderColor: ChartsDataService.lineColors[i],
          label: TranslateService.get(dataset.translationKey),
          value:
            highlightValue === 0
              ? _.sum(dataset.data)
              : _.get(dataset, `data[${highlightValue - 1}]`, 0)
        }
      })
    },
    getExperiencesInteractions(perHour = false, data = false) {
      let formattedData = []
      formattedData = this.getExperiencesDatasets(data ? data : this.primaryData, perHour)
      if (perHour) {
        return formattedData
      }
      formattedData = _.map(formattedData, (experience) => {
        return {
          borderColor: experience.borderColor,
          label: experience.label,
          value: _.sum(experience.data)
        }
      })
      return formattedData
    },
    getIcon(icon) {
      return require(`../../assets/icons/${icon}.svg`)
    },
    getVoucherData(data) {
      if (!data) {
        return false
      }
      const voucherData = {
        data: {
          labels: this.getLabels(this.primaryData, this.secondaryData, 'vouchersByDate', false),
          chartSelectorLabels: this.getLabels(
            this.primaryData,
            this.secondaryData,
            'vouchersByDate',
            true
          ),
          datasets: []
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            datalabels: {
              display: true,
              align: 'center',
              font: {
                size: 8
              },
              opacity: 1,
              labels: {
                value: {
                  formatter: function (value, context) {
                    const val = _.get(context, `dataset.data[${context.dataIndex}]`, '')
                    return val === 0 ? '' : val
                  },
                  anchor: 'end',
                  align: 'top',
                  offset: '0',
                  color: 'black'
                }
              }
            }
          }
        }
      }
      this.createVoucherDatasetsFromData(voucherData.data.datasets, data)
      return voucherData
    },
    createVoucherDatasetsFromData(datasets, data) {
      _.each(['generated', 'redeemed'], (key, i) => {
        const translationKey = `T_${_.toUpper(key)}`
        datasets.push({
          label: TranslateService.get(translationKey),
          translationKey,
          data: _.map(data.vouchersByDate, (voucherByDate) => {
            return _.get(voucherByDate, `countByAction.${key}`, 0)
          }),
          borderColor: this.lineChartsColors[i],
          barPercentage: 0.3,
          backgroundColor: this.lineChartsColors[i]
        })
      })
    },
    gotData() {
      this.bar.data = this.updateHourlyInteractionData()
      this.lineDaily.data = this.updateDailyInteractionData()
      this.barVoucher = this.getVoucherData(this.primaryData, this.secondaryData)
      this.totalGuestInteractions = this.getTotalForExperiences(this.primaryData)
      const experiencesByDate = _.get(this.primaryData, 'experiencesByDate', [])
      const times = []
      _.each(experiencesByDate, (date) => {
        _.each(_.get(date, 'visitsByHour', []), (hour) => {
          if (!_.includes(times, hour.time)) {
            times.push(hour.time)
          }
        })
      })
      this.loading = false
      _.each(_.map(_.get(this.primaryData, 'vouchersByDate', []), 'date'), (date) => {
        this.voucherTableHeaders.push(moment(date).format('MM-DD'))
      })
      const voucherData = _.map(_.get(this.primaryData, 'vouchersByDate', []), 'countByAction')
      _.each(['generated', 'redeemed'], (type) => {
        const newLine = _.concat(
          [TranslateService.get(`T_${type}`)],
          _.map(voucherData, (item) => {
            return numeral(_.get(item, type, 0)).format('0,0')
          })
        )
        while (newLine.length <= this.voucherTableHeaders.length) {
          newLine.push(0)
        }
        this.voucherTable.push(newLine)
      })
      this.generateLabelsForLists()
    },
    hasNoHourlyData(data) {
      return _.isEmpty(this.getSelectedDateTraffic(data))
    },
    updateDailyInteractionData() {
      const labels = this.getTrafficDaysLabels(this.primaryData)
      const data = {
        labels: labels,
        chartSelectorLabels: labels,
        datasets: []
      }
      _.each(_.compact([this.primaryData, this.secondaryData]), (d, i) => {
        data.datasets.push({
          label: _.get(d, 'pavilion.event.key', false),
          backgroundColor: ChartsDataService.lineColors[i],
          // data: _.map(this.getAllDaysTraffic(d), (val) => numeral(val).format('0,0')),
          data: this.getAllDaysTraffic(d),
          barPercentage: 0.3
        })
      })
      console.warn(`DAY GRAPH DATA - `, data)
      return data
    },
    updateHourlyInteractionData() {
      const labels = this.getTrafficHoursLabels(this.primaryData)
      const data = {
        labels: labels,
        chartSelectorLabels: labels,
        datasets: []
      }
      _.each(_.compact([this.primaryData, this.secondaryData]), (d, i) => {
        data.datasets.push({
          label: _.get(d, 'pavilion.event.key', false),
          backgroundColor: ChartsDataService.lineColors[i],
          // data: _.map(this.getSelectedDateTraffic(d), (val) => numeral(val).format('0,0')),
          data: this.getSelectedDateTraffic(d),
          barPercentage: 0.3
        })
      })
      console.warn(`HOUR GRAPH DATA - `, _.get(data, 'datasets[0].data', []))
      return data
    },
    getTrafficDates(data, secondaryData = false, obj) {
      if (secondaryData) {
        return _.map(
          this.getTrafficDates(secondaryData, false, obj),
          (date, i) => TranslateService.get('T_DAY') + ` ${i + 1}`
        )
      }
      const mappedData = _.map(_.get(data, obj), 'date')
      return _.isEmpty(mappedData)
        ? false
        : _.uniq(_.map(mappedData, (date) => moment(date).format('MM/DD/YYYY')))
    },
    getTrafficLabels(data, secondaryData = false, fullDate = false, obj) {
      if (secondaryData) {
        return _.map(
          this.getTrafficDates(data, false, obj),
          (date, i) => TranslateService.get('T_DAY') + ` ${i + 1}`
        )
      }
      if (fullDate) {
        return _.map(this.getTrafficDates(data, false, obj), (date) => {
          return moment(date, 'MM/DD/YYYY').format('DD/MM/YYYY')
        })
      }
      return _.map(this.getTrafficDates(data, false, obj), (date) => {
        return moment(date, 'MM/DD/YYYY').format('MM/DD')
      })
    },
    getInteractionsValueForExperience(experiencesByDate, experienceId) {
      // console.warn(`NOTE: all days total for interaction ${experienceId}`)
      return _.sum(
        _.map(experiencesByDate, (date) => {
          return this.getAsNumber(
            _.find(_.get(date, 'visitsByExperience', []), (e) => e.experience === experienceId),
            'count'
          )
        })
      )
    },
    getExperiencesDatasets(data, perHour = false, forceColors = false) {
      const datasets = []
      const experiencesByDate = _.get(data, 'experiencesByDate', [])
      const mappedData = {}
      let experiences = _.get(data, 'pavilion.experiences', [])
      if (perHour) {
        return _.map(experiences, (experience, i) => {
          return {
            label: experience.name,
            borderColor: forceColors ? forceColors[i] : ChartsDataService.lineColors[i],
            value: this.getInteractionsValueForExperience(experiencesByDate, experience._id)
          }
        })
      }
      _.each(experiencesByDate, (experiencesDate) => {
        _.each(experiences, (experience) => {
          mappedData[experience._id] = _.get(mappedData, experience._id, [])
          const subItem = _.find(
            _.get(experiencesDate, 'visitsByExperience', []),
            (e) => e.experience === experience._id
          )
          mappedData[experience._id].push(this.getAsNumber(subItem, 'count'))
        })
      })
      _.each(experiences, (experience, i) => {
        datasets.push({
          experience: experience._id,
          label: experience.name,
          data: _.map(_.get(mappedData, experience._id, []), (d) => numeral(d).format('0,0')),
          showLine: true,
          borderColor: forceColors ? forceColors[i] : ChartsDataService.lineColors[i],
          fill: false
        })
      })
      return datasets
    },
    getOriginalTrafficData(data) {
      return _.get(data, 'experiencesByDate', [])
    },
    getAllDaysTraffic(data) {
      return _.map(this.getOriginalTrafficData(data), 'visits')
    },
    getSelectedDateTraffic(data) {
      const allDaysData = this.getOriginalTrafficData(data)
      const formattedData = {}
      _.each(allDaysData, (e) => {
        _.each(_.get(e, 'visitsByHour', []), (hour) => {
          _.set(formattedData, hour.time, _.get(formattedData, hour.time, 0) + hour.count)
        })
      })
      // console.warn(`getSelectedDateTraffic - all days -----`, formattedData)
      return _.values(formattedData)
    },
    getTrafficDaysLabels(data) {
      return _.map(_.get(data, 'experiencesByDate', []), 'date')
    },
    getTrafficHoursLabels(data) {
      let experienceByDate = this.getOriginalTrafficData(data)
      if (_.isArray(experienceByDate)) {
        // console.warn(`getTrafficHoursLabels - is array`)
        experienceByDate = _.get(experienceByDate, `[0]`, {})
      }
      const perHourLabels = _.map(_.get(experienceByDate, 'visitsByHour', []), 'time')
      // console.warn(`PER HOUR LABELS = `, perHourLabels)
      return perHourLabels
    }
  }
}
</script>

<style lang="scss">
#pdf-export {
}
</style>
