<template>
  <div id="experience-section" :class="{ compare: secondaryData }">
    <section-loader v-if="loading" />
    <template v-else-if="primaryData">
      <div v-if="secondaryData" class="big-section-title">{{ 'T_EXPERIENCE' | translate }}</div>
      <v-chip-group
        v-if="filteredSubSections"
        v-model="currentSubSection"
        background-color="transparent"
        mandatory
        dark
        class="sub-sections"
      >
        <v-chip
          v-for="(subSection, i) in filteredSubSections"
          :key="i"
          :color="i == currentSubSection ? 'cyan' : 'primary'"
          link
          @click="goToTab(i)"
        >
          {{ subSection.title | translate }}
        </v-chip>
      </v-chip-group>
      <div class="sub-sections-wrapper">
        <v-tabs-items v-if="primaryData" v-model="currentSubSection">
          <!-- Activations -->
          <v-tab-item v-if="hasSubSection('activations')" class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter :current="1" :total="filteredSubSections.length" />
              <template v-if="!canCompareSubSection('activations')">
                <v-card class="sub-section no-data">
                  <cannot-compare-data />
                </v-card>
              </template>
              <template v-else>
                <template v-if="secondaryData">
                  <div class="info-box">
                    <div class="experiences-wrapper">
                      <compare-show-titles
                        :primary-data="primaryData"
                        :secondary-data="secondaryData"
                      />
                      <div class="vertical-activations">
                        <pavilion-experiences :pavilion="primaryData.pavilion" compare />
                        <v-divider vertical />
                        <pavilion-experiences :pavilion="secondaryData.pavilion" compare />
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <v-card class="sub-section">
                    <div class="info-box experiences-container" :class="{ displayed: allLoaded }">
                      <pavilion-experiences
                        :pavilion="primaryData.pavilion"
                        :all-loaded.sync="allLoaded"
                      />
                    </div>
                  </v-card>
                </template>
              </template>
            </div>
          </v-tab-item>
          <!-- Interactions -->
          <v-tab-item v-if="hasSubSection('interactions')" class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter
                :current="getIndexForSubSection('interactions')"
                :total="filteredSubSections.length"
              />
              <template v-if="!canCompareSubSection('interactions')">
                <v-card class="sub-section no-data">
                  <cannot-compare-data />
                </v-card>
              </template>
              <template v-else>
                <v-card class="sub-section">
                  <card-top
                    :current-view.sync="selectedSubView"
                    :options="filterIfNoDataPerHour(getSectionCardTop('interactions'))"
                  />
                  <template v-if="secondaryData">
                    <div v-if="selectedSubView === 0 && primaryData" class="interaction-days">
                      <template v-if="graphHasData(line)">
                        <chart-selector
                          :data="
                            getDataForChart(line, {
                              label: 'T_TOTAL_GUEST_INTERACTIONS',
                              value:
                                getTotalForExperiences(primaryData) +
                                getTotalForExperiences(secondaryData)
                            })
                          "
                          center-label
                          :current-value.sync="highlightedInteractionDayValue"
                        />
                        <div class="compare-show-highlighted">
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="primaryData.pavilion.event.key"
                            :content="primaryData.total.experienceGenerated"
                            type="compare"
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[0],
                              primary: getHighlightedValue(
                                line,
                                'primary',
                                highlightedInteractionDayValue
                              ),
                              secondary: getHighlightedValue(
                                line,
                                'secondary',
                                highlightedInteractionDayValue
                              )
                            }"
                            italic
                          />
                          <v-divider vertical />
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="secondaryData.pavilion.event.key"
                            type="compare"
                            :content="
                              getHighlightedValue(line, 'secondary', highlightedInteractionDayValue)
                            "
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[1]
                            }"
                            italic
                          />
                        </div>
                        <line-chart
                          :data="line.data"
                          :options="line.options"
                          :highlight-value="highlightedInteractionDayValue"
                        />
                        <custom-list
                          :items="getExperiencesInteractions(false, primaryData)"
                          :compare-show="primaryData.pavilion.event.key"
                          :circle-color="ChartsDataService.barColors[0]"
                        />
                        <custom-list
                          :items="getExperiencesInteractions(false, secondaryData)"
                          :compare-show="secondaryData.pavilion.event.key"
                          :circle-color="ChartsDataService.barColors[1]"
                        />
                      </template>
                      <no-graph-data v-else />
                    </div>
                    <div v-else class="interaction-hours">
                      <template v-if="graphHasData(bar)">
                        <chart-selector
                          :data="
                            getDataForChart(line, {
                              label: 'T_TOTAL_GUEST_INTERACTIONS',
                              value:
                                getTotalForExperiences(primaryData) +
                                getTotalForExperiences(secondaryData)
                            })
                          "
                          center-label
                          :current-value.sync="highlightedInteractionDayValue"
                        />
                        <div v-if="bar.data.datasets" class="compare-show-highlighted">
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="primaryData.pavilion.event.key"
                            :content="primaryData.total.experienceGenerated"
                            type="compare"
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[0],
                              primary: getHighlightedValue(
                                line,
                                'primary',
                                highlightedInteractionDayValue
                              ),
                              secondary: getHighlightedValue(
                                line,
                                'secondary',
                                highlightedInteractionDayValue
                              )
                            }"
                            italic
                          />
                          <v-divider vertical />
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="secondaryData.pavilion.event.key"
                            type="compare"
                            :content="
                              getHighlightedValue(line, 'secondary', highlightedInteractionDayValue)
                            "
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[1]
                            }"
                            italic
                          />
                        </div>
                        <bar-chart
                          :data="bar.data"
                          :options="bar.options"
                          :highlight-value="highlightedInteractionHourValue"
                        />
                        <chart-selector
                          :data="getDataForChart(bar, getTotalInteractionsByHour(), true)"
                          :current-value.sync="highlightedInteractionHourValue"
                        />
                        <custom-list
                          :items="getExperiencesInteractions(true, primaryData)"
                          :compare-show="primaryData.pavilion.event.key"
                          :circle-color="ChartsDataService.barColors[0]"
                        />
                        <custom-list
                          :items="getExperiencesInteractions(true, secondaryData)"
                          :compare-show="secondaryData.pavilion.event.key"
                          :circle-color="ChartsDataService.barColors[1]"
                        />
                      </template>
                      <no-graph-data v-else />
                    </div>
                  </template>
                  <template v-else>
                    <div v-if="selectedSubView === 0 && primaryData" class="interaction-days">
                      <template v-if="graphHasData(line)">
                        <chart-selector
                          :data="
                            getDataForChart(line, {
                              label: 'T_TOTAL_GUEST_INTERACTIONS',
                              value: getTotalForExperiences(primaryData)
                            })
                          "
                          :current-value.sync="highlightedInteractionDayValue"
                        />
                        <line-chart
                          :data="line.data"
                          :options="line.options"
                          :highlight-value="highlightedInteractionDayValue"
                        />
                        <custom-list :items="getExperiencesInteractions()" show-colors />
                      </template>
                      <no-graph-data v-else />
                    </div>
                    <div v-else class="interaction-hours">
                      <template v-if="graphHasData(bar)">
                        <chart-selector
                          :data="
                            getDataForChart(line, {
                              label: 'T_TOTAL_GUEST_INTERACTIONS',
                              value: getTotalForExperiences(primaryData)
                            })
                          "
                          :current-value.sync="highlightedInteractionDayValue"
                        />
                        <bar-chart
                          :data="bar.data"
                          :options="bar.options"
                          :highlight-value="highlightedInteractionHourValue"
                        />
                        <chart-selector
                          :data="getDataForChart(bar, getTotalInteractionsByHour(), true)"
                          :current-value.sync="highlightedInteractionHourValue"
                        />
                        <custom-list :items="getExperiencesInteractions(true)" />
                      </template>
                      <no-graph-data v-else />
                    </div>
                  </template>
                </v-card>
                <template v-if="secondaryData && graphHasData(bar)">
                  <compare-show-titles
                    :primary-data="primaryData"
                    :secondary-data="secondaryData"
                  />
                  <div class="compare-show-highlighted">
                    <content-card
                      no-gradient
                      half-width
                      :title="'T_TOTAL_GUEST_INTERACTIONS' | translate"
                      :content="getTotalForDataset(bar, 0)"
                      type="compare"
                      :compare-data="{
                        primary: getHighlightedValue(bar, 'primary', 0),
                        secondary: getHighlightedValue(bar, 'secondary', 0)
                      }"
                      italic
                    />
                    <v-divider vertical />
                    <content-card
                      no-gradient
                      half-width
                      :title="'T_TOTAL_GUEST_INTERACTIONS' | translate"
                      type="compare"
                      :content="getTotalForDataset(bar, 1)"
                      :compare-data="{}"
                      italic
                    />
                  </div>
                </template>
              </template>
            </div>
          </v-tab-item>
          <!-- Voucher -->
          <v-tab-item v-if="hasSubSection('voucher')" class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter
                :current="getIndexForSubSection('voucher')"
                :total="filteredSubSections.length"
              />
              <template v-if="!canCompareSubSection('voucher')">
                <v-card class="sub-section no-data">
                  <cannot-compare-data />
                </v-card>
              </template>
              <template v-else>
                <v-card>
                  <card-top
                    :current-view.sync="selectedSubView"
                    :options="getSectionCardTop('voucher')"
                  />
                  <div class="info-box">
                    <template v-if="secondaryData">
                      <template v-if="graphHasData(barVoucher)">
                        <chart-selector
                          :data="
                            getDataForChart(barVoucher, {
                              label: 'T_TOTAL_REDEMPTION_RATE',
                              value: getVoucherRedemptionRate(primaryData)
                            })
                          "
                          center-label
                          :current-value.sync="highlightedVoucherDayValue"
                        />
                        <sub-type-selector
                          :types="voucherSubTypes"
                          :current-type.sync="selectedVoucherType"
                        />
                        <div class="compare-show-highlighted">
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="primaryData.pavilion.event.key"
                            :content="
                              getTotalForShow(
                                primaryData,
                                'vouchersByDate',
                                highlightedVoucherDayValue,
                                selectedVoucherType
                              )
                            "
                            type="compare"
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[0],
                              primary: getTotalForShow(
                                primaryData,
                                'vouchersByDate',
                                highlightedVoucherDayValue,
                                selectedVoucherType
                              ),
                              secondary: getTotalForShow(
                                secondaryData,
                                'vouchersByDate',
                                highlightedVoucherDayValue,
                                selectedVoucherType
                              )
                            }"
                            italic
                          />
                          <v-divider vertical />
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="secondaryData.pavilion.event.key"
                            type="compare"
                            :content="
                              getTotalForShow(
                                secondaryData,
                                'vouchersByDate',
                                highlightedVoucherDayValue,
                                selectedVoucherType
                              )
                            "
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[1]
                            }"
                            italic
                          />
                        </div>
                        <line-chart
                          :data="barVoucher.data"
                          :options="barVoucher.options"
                          :highlight-value="highlightedVoucherDayValue"
                        />
                        <div class="vouchers-redemption-rate-title">
                          {{ 'T_VOUCHERS_REDEMPTION_RATE' | translate }}
                        </div>
                        <div class="compare-show-highlighted">
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="primaryData.pavilion.event.key"
                            :content="
                              getVoucherRedemptionRate(primaryData, highlightedVoucherDayValue)
                            "
                            type="compare"
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[0],
                              primaryPercentage: getVoucherRedemptionRate(
                                primaryData,
                                highlightedVoucherDayValue
                              ),
                              primary: getVoucherRedemptionRate(
                                primaryData,
                                highlightedVoucherDayValue,
                                true
                              ),
                              secondary: getVoucherRedemptionRate(
                                secondaryData,
                                highlightedVoucherDayValue,
                                true
                              )
                            }"
                            :no-data="
                              !getVoucherRedemptionRate(primaryData, highlightedVoucherDayValue)
                            "
                            italic
                          />
                          <v-divider vertical />
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="secondaryData.pavilion.event.key"
                            type="compare"
                            :content="
                              getVoucherRedemptionRate(
                                secondaryData,
                                highlightedVoucherDayValue,
                                true
                              )
                            "
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[1]
                            }"
                            italic
                            :no-data="
                              !getVoucherRedemptionRate(
                                secondaryData,
                                highlightedVoucherDayValue,
                                true
                              )
                            "
                          />
                        </div>
                      </template>
                      <no-graph-data v-else />
                    </template>
                    <template v-else>
                      <template v-if="graphHasData(barVoucher)">
                        <chart-selector
                          :formatter="formatPercentage"
                          :data="getVoucherChartSelectorData()"
                          :current-value.sync="highlightedVoucherDayValue"
                        />
                        <bar-chart
                          :data="barVoucher.data"
                          :options="barVoucher.options"
                          :highlight-value="highlightedVoucherDayValue"
                        />
                        <custom-list v-if="voucherList" :items="voucherList" show-colors />
                      </template>
                      <no-graph-data v-else />
                    </template>
                  </div>
                </v-card>
                <template v-if="secondaryData">
                  <compare-show-titles
                    :primary-data="primaryData"
                    :secondary-data="secondaryData"
                  />
                  <div class="compare-data">
                    <div class="cards-row vertical">
                      <content-card
                        no-gradient
                        :title="'T_TOTAL_REDEMPTION_RATE' | translate"
                        :content="getVoucherRedemptionRate(primaryData, false, true)"
                        type="compare"
                        :compare-data="{
                          primary: getVoucherRedemptionRate(primaryData, false, true),
                          primaryPercentage: getVoucherRedemptionRate(primaryData),
                          secondary: getVoucherRedemptionRate(secondaryData, false, true)
                        }"
                        italic
                        :no-data="!getVoucherRedemptionRate(secondaryData)"
                      />
                      <content-card
                        no-gradient
                        :title="'T_TOTAL_GENERATED_VOUCHERS' | translate"
                        type="compare"
                        :content="getTotalVoucher(primaryData, 'generated')"
                        :compare-data="{
                          primary: getTotalVoucher(primaryData, 'generated'),
                          secondary: getTotalVoucher(secondaryData, 'generated')
                        }"
                        italic
                        :no-data="!getTotalVoucher(primaryData, 'generated')"
                      />
                      <content-card
                        no-gradient
                        :title="'T_TOTAL_REDEEMED_VOUCHERS' | translate"
                        :content="getTotalVoucher(primaryData, 'redeemed')"
                        type="compare"
                        :compare-data="{
                          primary: getTotalVoucher(primaryData, 'redeemed'),
                          secondary: getTotalVoucher(secondaryData, 'redeemed')
                        }"
                        italic
                        :no-data="!getTotalVoucher(primaryData, 'redeemed')"
                      />
                    </div>
                    <v-divider vertical inset />
                    <div class="cards-row vertical">
                      <content-card
                        :no-data="!getVoucherRedemptionRate(secondaryData)"
                        no-gradient
                        :title="'T_TOTAL_REDEMPTION_RATE' | translate"
                        type="compare"
                        :content="getVoucherRedemptionRate(secondaryData)"
                        italic
                      />
                      <content-card
                        :no-data="!getTotalVoucher(secondaryData, 'generated')"
                        no-gradient
                        :title="'T_TOTAL_GENERATED_VOUCHERS' | translate"
                        type="compare"
                        :content="getTotalVoucher(secondaryData, 'generated')"
                        italic
                      />
                      <content-card
                        :no-data="!getTotalVoucher(secondaryData, 'generated')"
                        no-gradient
                        :title="'T_TOTAL_REDEEMED_VOUCHERS' | translate"
                        type="compare"
                        :content="getTotalVoucher(secondaryData, 'redeemed')"
                        italic
                      />
                    </div>
                  </div>
                </template>
                <div v-else class="cards-row">
                  <content-card
                    :no-data="!getTotalVoucher(primaryData, 'generated')"
                    :title="'T_TOTAL_GENERATED_VOUCHERS' | translate"
                    no-gradient
                    half-width
                    align-center
                    :content="getTotalVoucher(primaryData, 'generated')"
                  />
                  <content-card
                    :no-data="!getTotalVoucher(primaryData, 'redeemed')"
                    :title="'T_TOTAL_REDEEMED_VOUCHERS' | translate"
                    no-gradient
                    half-width
                    align-center
                    :content="getTotalVoucher(primaryData, 'redeemed')"
                  />
                </div>
              </template>
            </div>
          </v-tab-item>
          <!-- Souvenir -->
          <v-tab-item v-if="hasSubSection('souvenir')" class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter
                :current="getIndexForSubSection('souvenir')"
                :total="filteredSubSections.length"
              />
              <template v-if="!canCompareSubSection('souvenir')">
                <v-card class="sub-section no-data">
                  <cannot-compare-data />
                </v-card>
              </template>
              <template v-else>
                <v-card class="sub-section">
                  <card-top
                    :current-view.sync="selectedSubView"
                    :options="getSectionCardTop('souvenir')"
                  />
                  <div class="info-box">
                    <template v-if="graphHasData(lineSouvenir)">
                      <chart-selector
                        v-if="!secondaryData"
                        :data="
                          getTouchpointsList(primaryData, {
                            label: 'T_TOUCHPOINTS'
                          })
                        "
                        center-label
                        :current-value.sync="highlightedSouvenirTouchpointValue"
                      />
                      <sub-type-selector
                        v-if="secondaryData"
                        :types="souvenirSubTypes"
                        :current-type.sync="selectedSouvenirType"
                      />
                      <div v-if="secondaryData" class="compare-show-highlighted">
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="primaryData.pavilion.event.key"
                          :content="
                            getTotalForShow(
                              primaryData,
                              'souvenirsByDate',
                              highlightedSouvenirDayValue,
                              selectedSouvenirType
                            )
                          "
                          type="compare"
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[0],
                            primary: getTotalForShow(
                              primaryData,
                              'souvenirsByDate',
                              highlightedSouvenirDayValue,
                              selectedSouvenirType
                            ),
                            secondary: getTotalForShow(
                              secondaryData,
                              'souvenirsByDate',
                              highlightedSouvenirDayValue,
                              selectedSouvenirType
                            )
                          }"
                          italic
                        />
                        <v-divider vertical />
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="secondaryData.pavilion.event.key"
                          type="compare"
                          :content="
                            getTotalForShow(
                              secondaryData,
                              'souvenirsByDate',
                              highlightedSouvenirDayValue,
                              selectedSouvenirType
                            )
                          "
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[1]
                          }"
                          italic
                        />
                      </div>
                      <line-chart
                        :data="lineSouvenir.data"
                        :options="lineSouvenir.options"
                        :highlight-value="highlightedSouvenirDayValue"
                      />
                      <chart-selector
                        :data="
                          getDataForChart(line, {
                            label: 'T_OVERVIEW'
                          })
                        "
                        center-label
                        :current-value.sync="highlightedSouvenirDayValue"
                      />
                      <custom-list
                        v-if="!secondaryData && souvenirList"
                        :items="souvenirList"
                        show-colors
                      />
                    </template>
                    <no-graph-data v-else />
                  </div>
                </v-card>
                <template v-if="graphHasData(lineSouvenir)">
                  <template v-if="secondaryData">
                    <compare-show-titles
                      :primary-data="primaryData"
                      :secondary-data="secondaryData"
                    />
                    <div class="compare-data">
                      <div class="cards-row vertical">
                        <content-card
                          v-for="type in souvenirSubTypes"
                          :key="type.key"
                          no-gradient
                          :title="('T_TOTAL_SOUVENIR_' + type.key) | translate"
                          :content="getTotalForShow(primaryData, 'souvenirsByDate', 0, type.key)"
                          type="compare"
                          :compare-data="{
                            primary: getTotalForShow(primaryData, 'souvenirsByDate', 0, type.key),
                            secondary: getTotalForShow(
                              secondaryData,
                              'souvenirsByDate',
                              0,
                              type.key
                            )
                          }"
                          italic
                        />
                      </div>
                      <v-divider vertical inset />
                      <div class="cards-row vertical">
                        <content-card
                          v-for="type in souvenirSubTypes"
                          :key="type.key"
                          no-gradient
                          :title="('T_TOTAL_SOUVENIR_' + type.key) | translate"
                          type="compare"
                          :content="getTotalForShow(secondaryData, 'souvenirsByDate', 0, type.key)"
                          italic
                        />
                      </div>
                    </div>
                  </template>
                  <div v-else class="cards-row">
                    <content-card
                      :title="'T_TOTAL_SOUVENIR_RETRIEVED' | translate"
                      no-gradient
                      half-width
                      align-center
                      :content="getTotalForAllDays(primaryData, 'souvenirsByDate', 'retrieved')"
                    />
                    <content-card
                      :title="'T_TOTAL_SOUVENIR_PLAYED' | translate"
                      no-gradient
                      half-width
                      align-center
                      :content="getTotalForAllDays(primaryData, 'souvenirsByDate', 'played')"
                    />
                  </div>
                </template>
                <no-graph-data v-else />
              </template>
            </div>
          </v-tab-item>
          <!-- Conversion -->
          <v-tab-item v-if="hasSubSection('conversion')" class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter
                :current="getIndexForSubSection('conversion')"
                :total="filteredSubSections.length"
              />
              <v-card class="sub-section">
                <card-top
                  v-if="!secondaryData"
                  :current-view.sync="selectedSubView"
                  :options="getSectionCardTop('conversion')"
                />
                <div v-if="secondaryData" class="cards-row funnel-charts">
                  <compare-show-titles
                    :primary-data="primaryData"
                    :secondary-data="secondaryData"
                  />
                  <div class="compare-show-highlighted">
                    <funnel-chart v-if="funnel1" :data="funnel1" compare />
                    <v-divider vertical />
                    <funnel-chart v-if="funnel2" :data="funnel2" compare />
                  </div>
                </div>
                <div v-else class="cards-row funnel-charts">
                  <chart-selector
                    :data="
                      getTouchpointsList(
                        primaryData,
                        {
                          label: 'T_TOUCHPOINTS'
                        },
                        true
                      )
                    "
                    center-label
                    :current-value.sync="highlightedSouvenirTouchpointValue2"
                  />
                  <funnel-chart v-if="funnel1" :data="funnel1" />
                </div>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>
  </div>
</template>

<script>
import BaseSection from '@/mixins/BaseSection'
import ExperienceSection from '@/mixins/ExperienceSection'

export default {
  name: 'ExperienceSection',
  components: {},
  mixins: [BaseSection, ExperienceSection]
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';
#experience-section {
  .experiences-container {
    opacity: 0;
    transition: 0.5s opacity;
    &.displayed {
      opacity: 1;
    }
  }
  .funnel-charts {
    display: inline-block;
    width: 100%;
    margin-top: 0;
    .chart-selector {
      margin-top: 0;
    }
  }
  &.compare {
    .section {
      padding-top: $double-margin;
    }
    .pavilion-experiences {
      margin-bottom: 0;
    }
    .vouchers-redemption-rate-title {
      width: 100%;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: $scale-vw * 14;
      line-height: $scale-vw * 18;
      color: $purple;
      margin: $standard-margin 0;
    }
    .compare-data {
      margin-top: $half-margin;
    }
    .funnel-charts {
      .compare-show-titles {
        margin-bottom: $standard-margin;
      }
    }
  }
}
.vertical-activations {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .pavilion-experiences {
    width: 100%;
    max-width: $scale-vw * 175;
    margin-bottom: $half-margin;
  }
}
</style>

<style lang="scss">
#experience-section {
  &.compare {
    .pavilion-experiences {
      .experience {
        &:last-child {
          .v-card {
            margin-bottom: 0;
          }
        }
      }
    }
    .compare-data {
      .cards-row.vertical {
        .v-card {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
