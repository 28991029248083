<template>
  <v-container class="main-container text-center" :class="{ 'is-for-desktop': isForDesktop }">
    <v-form v-model="isFormValid" @keyup.enter="onClickLogin">
      <v-row align="center" justify="center">
        <div class="welcome-title mx-8 mb-4">
          {{ 'T_WELCOME_TITLE' | translate }}
        </div>
        <v-sheet
          height="auto"
          :width="isForDesktop ? '640' : '410'"
          outlined
          rounded
          class="pa-10"
          :class="{ 'is-for-desktop': isForDesktop }"
        >
          <v-text-field
            v-model="username"
            color="white"
            class="mt-6"
            :label="'T_USERNAME' | translate"
            :placeholder="'T_ENTER_YOUR_USERNAME' | translate"
            :rules="[(v) => !!v || $options.filters.translate('T_REQUIRED')]"
            required
            @input="formatUsername()"
          />
          <v-text-field
            v-model="password"
            color="white"
            class="mt-6"
            :label="'T_PASSWORD' | translate"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            name="input-10-2"
            value="wqfasds"
            :error-messages="errorMessage ? [errorMessage] : null"
            :rules="[(v) => !!v || $options.filters.translate('T_REQUIRED')]"
            required
            @input="errorMessage = null"
            @click:append="showPassword = !showPassword"
            @keyup.enter="onEnterKey"
          />

          <v-row class="mt-2 align-right">
            <v-col>
              <v-btn
                rounded
                height="40"
                color="secondary"
                class="text-capitalize"
                :disabled="!isFormValid"
                @click="onClickLogin"
              >
                {{ 'T_SIGN_IN' | translate }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col>
              <div class="forgot-password">
                <div class="bold">
                  {{ 'T_FORGOT_YOUR_PASSWORD' | translate }}
                </div>
                <div class="description">
                  {{ 'T_FORGOT_YOUR_PASSWORD_DESCRIPTION' | translate }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import _ from 'lodash'
import LoginService from '@/services/LoginService'

export default {
  props: {
    isForDesktop: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: () => {}
    },
    successLoginCallback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      username: null,
      password: null,
      errorMessage: null,
      showPassword: false,
      isFormValid: false
    }
  },
  mounted() {
    if (_.startsWith(window.location.host, 'localhost')) {
      this.username = 'admin'
      this.password = 'admin'
    }
  },
  methods: {
    formatUsername() {
      this.errorMessage = null
      this.username = _.toLower(this.username)
    },
    onEnterKey() {
      this.onClickLogin()
    },
    async onClickLogin() {
      try {
        if (!this.isFormValid) {
          console.warn(`form not valid`)
          return
        }
        this.errorMessage = null
        const user = await LoginService.login(this.username, this.password)
        this.$emit('update:user', user)
        this.successLoginCallback()
      } catch (error) {
        this.errorMessage = _.get(error, 'response.data.error', error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';

.main-container {
  max-height: 100vh;
  overflow: hidden;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  color: white;

  .v-sheet {
    background: rgba(128, 148, 221, 0.2);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 16px !important;
    width: 90% !important;
    color: white;
  }
  .align-right {
    text-align: right;
  }
  .forgot-password {
    color: white;
    font-size: 3vw;
    .bold {
      font-weight: bold;
    }
    .description {
      font-style: italic;
      font-size: 2.5vw;
    }
  }
  .welcome-title {
    font-style: normal;
    font-weight: 700;
    font-size: $scale-vw * 59;
    line-height: $scale-vw * 74;
    text-align: left;
    width: 100%;
  }
  &.is-for-desktop {
    max-width: 640px;
    .v-sheet {
      width: $scale-vw * 640 !important;
    }
    .welcome-title {
      font-size: 59px;
      line-height: 74px;
    }
    .text-capitalize {
      margin-top: 20px;
    }
    .forgot-password {
      font-size: 13px;
      margin-top: 268px;
      .description {
        font-size: 13px;
      }
    }
  }
}
</style>
