<template>
  <div class="pie-chart">
    <canvas ref="canvas" />
  </div>
</template>
<script>
import _ from 'lodash'
import { Chart } from 'chart.js'
import ChartsDataService from '@/services/ChartsDataService'
// NOTE: adds custom center text drawing option for doughnut charts
Chart.register({
  id: 'doughnut-centertext-for-pdf',
  doughnutCenterTextDefaults: {
    label: {
      color: '#000000',
      sidePadding: 0,
      fontWeight: 400,
      fontStyle: 'italic',
      minFontSize: 15,
      lineHeight: 11,
      offsetY: -10
    },
    value: {
      color: '#000000',
      fontWeight: 700,
      sidePadding: 0,
      minFontSize: 19,
      lineHeight: 19,
      offsetY: 10
    }
  },
  drawCenterText: function (chart, config, offset = 0, baseFontsize = 30) {
    const ctx = chart.ctx
    const fontStyle = config.fontStyle || ''
    const fontFamily = config.fontFamily || 'SourceSansPro'
    const txt = config.text
    const color = 'white'
    const maxFontSize = config.maxFontSize || 75
    const sidePadding = config.sidePadding || 20
    const innerRadius = _.get(
      chart,
      `_metasets[${chart._metasets.length - 1}].data[0].innerRadius`,
      0
    )
    const sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2)
    // Start with a base font of 30px
    ctx.font = `${baseFontsize}px ` + fontFamily
    // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
    const stringWidth = ctx.measureText(txt).width
    const elementWidth = innerRadius * 2 - sidePaddingCalculated
    // Find out how much the font can grow in width.
    const widthRatio = elementWidth / stringWidth
    const newFontSize = Math.floor(20 * widthRatio)
    // console.warn('new font size: ', newFontSize)
    const elementHeight = innerRadius * 2
    // Pick a new font size so it will not be larger than the height of label.
    const fontSize = Math.min(newFontSize, elementHeight, maxFontSize)
    const lineHeight = config.lineHeight || 25
    const wrapText = false
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    const centerX = (chart.chartArea.left + chart.chartArea.right) / 2
    let centerY =
      (chart.chartArea.top + chart.chartArea.bottom) / 2 + (offset + _.get(config, 'offsetY', 0))
    ctx.font = (fontStyle ? fontStyle + ' ' : '') + fontSize + 'px ' + fontFamily
    ctx.fillStyle = color
    if (!wrapText) {
      ctx.fillText(txt, centerX, centerY)
      return
    }
    let words = txt
    if (_.isString(txt)) {
      words = _.split(txt, ' ')
    }
    let line = ''
    const lines = []
    // Break words up into multiple lines if necessary
    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' '
      const metrics = ctx.measureText(testLine)
      const testWidth = metrics.width
      if (testWidth > elementWidth && n > 0) {
        lines.push(line)
        line = words[n] + ' '
      } else {
        line = testLine
      }
    }
    // Move the center up depending on line height and number of lines
    centerY -= (lines.length / 2) * lineHeight
    for (let n = 0; n < lines.length; n++) {
      ctx.fillText(lines[n], centerX, centerY)
      centerY += lineHeight
    }
    ctx.fillText(line, centerX, centerY - lineHeight / 2)
    return lineHeight
  },
  drawCenterBackground(chart) {
    chart.ctx.save()
    const halfWidth = 53 // 212 / 4
    const halfHeight = 32 // 128 / 4
    chart.ctx.translate(halfWidth, halfHeight)
    const radius = _.get(chart, `_metasets[${chart._metasets.length - 1}].data[0].innerRadius`, 0)
    chart.ctx.beginPath()
    chart.ctx.arc(halfWidth, halfHeight, radius, 0, 2 * Math.PI, false)
    chart.ctx.fillStyle = 'black'
    chart.ctx.fill()
    chart.ctx.restore()
  },
  beforeDraw: function (chart) {
    const centerConfig = _.get(chart, 'config.options.elements.center', false)
    const isForPdf = _.get(chart, 'config.options.elements.center.isForPdf', false)
    if (!centerConfig || !isForPdf) {
      return
    }
    const labelConfig = _.merge(
      _.get(centerConfig, 'label', {}),
      this.doughnutCenterTextDefaults.label
    )
    this.drawCenterBackground(chart)
    const lineHeight = this.drawCenterText(chart, labelConfig, 0, 20)
    let valueConfig = _.get(centerConfig, 'value', false)
    if (valueConfig) {
      valueConfig = _.merge(valueConfig, this.doughnutCenterTextDefaults.value)
      this.drawCenterText(chart, valueConfig, lineHeight)
    }
  }
})
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    const ctx = this.$refs.canvas.getContext('2d', { willReadFrequently: true })
    let options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0
      },
      plugins: {
        legend: {
          display: false
        },
        datalabels: this.getDataLabelsStyle()
      },
      backgroundColor: ChartsDataService.pdfPieChartsColors
    }
    options = _.merge(options, this.options)
    if (_.get(options, 'elements.center.value', false)) {
      if (window.innerWidth < window.innerHeight || window.innerWidth < 1025) {
        _.set(options, 'elements.center.label.minFontSize', 12)
        _.set(options, 'elements.center.label.sidePadding', 0)
        _.set(options, 'elements.center.label.lineHeight', 20)
        _.set(options, 'elements.center.label.offsetY', 5)
        _.set(options, 'elements.center.value.minFontSize', 19)
        _.set(options, 'elements.center.value.sidePadding', 0)
        _.set(options, 'elements.center.value.lineHeight', 10)
      }
    }
    this.chart = new Chart(ctx, {
      type: _.get(this.data, 'type', 'pie'),
      data: this.data,
      options
    })
  },
  methods: {
    getDataLabelsStyle() {
      const obj = {
        display: true,
        font: ChartsDataService.getDefaultFont(),
        color: '#000000',
        anchor: 'end',
        align: 'start',
        labels: {
          title: {
            formatter: (value, context) =>
              _.get(context, `dataset.index[${context.dataIndex}]`, ''),
            offset: 10
          }
        }
      }
      if (window.innerWidth > window.innerHeight && window.innerWidth > 1024) {
        obj.backgroundColor = 'rgba(255,255,255, 0.4)'
      }
      return obj
    }
  }
}
</script>

<style scoped>
.pie-chart {
  position: relative;
  width: 100%;
}
</style>
