<template>
  <div class="cannot-compare-data" :class="{ 'no-data': noData }">
    <v-img
      src="@/assets/icons/no-info.svg"
      lazy-src="@/assets/icons/no-info.svg"
      width="80"
      height="80"
      max-height="80"
    />
    <div v-if="noData" class="description">{{ 'T_NO_DATA' | translate }}</div>
    <div v-else class="description">{{ 'T_CANNOT_COMPARE_DATA' | translate }}</div>
  </div>
</template>

<script>
export default {
  props: {
    noData: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';
.cannot-compare-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
  .description {
    color: $purple;
    font-style: italic;
    font-weight: 400;
    font-size: $scale-vw * 10;
    line-height: $scale-vw * 13;
    margin-top: $standard-margin;
  }
  &.no-data {
    margin: auto 0;
  }
}
</style>
