<template>
  <div
    ref="refresher"
    class="refresher"
    :style="{ top: position, opacity: opacity }"
    :class="{ dragging: dragging }"
  >
    <template v-if="position > 0 || opacity > 0">
      <div class="progress">
        <v-progress-circular
          color="primary"
          size="38"
          :value="progress"
          :indeterminate="indeterminate"
        />
      </div>
      <div class="label">{{ label | translate }}</div>
    </template>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  props: {
    targetQuery: {
      type: String,
      default: '.v-app-bar'
    },
    containerQuery: {
      type: String,
      default: 'body'
    },
    labelPullToRefresh: {
      type: String,
      default: 'T_PULL_TO_REFRESH'
    },
    labelRefreshing: {
      type: String,
      default: 'T_REFRESHING'
    },
    labelReleaseToRefresh: {
      type: String,
      default: 'T_RELEASE_TO_REFRESH'
    },
    opacityThreshold: {
      type: Number,
      default: 30
    },
    threshold: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      position: 0,
      opacity: 0,
      startY: 0,
      progress: 0,
      dragging: false,
      indeterminate: false,
      label: '',
      container: null
    }
  },
  mounted() {
    this.label = this.labelPullToRefresh
    this.container = window.document.querySelector(this.containerQuery)
    this.container.classList.add('has-refresher')
    this.container.addEventListener('touchstart', this.touchstart)
    this.container.addEventListener('touchend', this.touchend)
    this.container.addEventListener('touchmove', this.touchmove, { passive: false })
  },
  unmounted() {
    this.container.removeEventListener('touchstart', this.touchstart)
    this.container.removeEventListener('touchend', this.touchend)
    this.container.removeEventListener('touchmove', this.touchmove, { passive: false })
  },
  methods: {
    touchstart($event) {
      const hasTarget =
        _.get($event, 'touches[0].target.closest', false) !== false
          ? $event.touches[0].target.closest(this.targetQuery)
          : null
      if (hasTarget) {
        this.startY = $event.touches[0].pageY
      }
    },
    touchend() {
      if (this.opacity > 0) {
        this.dragging = false
        this.startY = 0
        const refresher = this.$refs['refresher']
        if (refresher) {
          if (!this.indeterminate) {
            this.opacity = 0
            this.position = `0px`
            this.progress = 0
          } else {
            this.label = this.labelRefreshing
            setTimeout(() => {
              window.location.reload(true)
            }, 2000)
          }
        }
        return false
      }
      return true
    },
    touchmove($event) {
      const refresher = this.$refs['refresher']
      const y = $event.touches[0].pageY
      if (refresher && y > this.startY) {
        const hasTarget =
          _.get($event, 'touches[0].target.closest', false) !== false
            ? $event.touches[0].target.closest(this.targetQuery)
            : null
        if (hasTarget) {
          if ($event.cancelable) {
            $event.stopPropagation()
            $event.preventDefault()
            this.dragging = true
            this.progress = Math.round(((y - this.startY) / this.threshold) * 100)
            this.opacity = this.progress / this.opacityThreshold
            if (this.progress >= 100) {
              this.position = `${this.threshold}px`
              this.label = this.labelReleaseToRefresh
              this.indeterminate = true
            } else {
              this.position = `${y - this.startY}px`

              this.label = this.labelPullToRefresh
              this.indeterminate = false
            }
            return false
          }
        }
      }
      return true
    }
  }
}
</script>
<style lang="scss">
@import '../vars.scss';

html:has(body.has-refresher),
body.has-refresher {
  height: 100%;
  width: 100%;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
  overflow: auto;
}

.refresher .v-progress-circular__overlay {
  transition: all 0.05s linear;
}

.refresher {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  margin: 0 auto;
  transition: all 300ms cubic-bezier(0, 0, 0.2, 1);
  pointer-events: none;
  touch-action: none;
  &.dragging {
    transition: opacity 300ms cubic-bezier(0, 0, 0.2, 1), top 0.05s linear;
    pointer-events: all;
    touch-action: none;
  }
  will-change: transform, opacity;
  z-index: 1000;
  .progress {
    box-shadow: 0px 0px 20px $background, 0px 0px 20px $background;
    background: rgba(91 104 151 / 0.95);
    width: 54px;
    height: 54px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .label {
    color: white;
    font-size: $scale-vw * 14;
    text-shadow: 0px 0px 20px $background, 0px 0px 20px $background, 0px 0px 20px $background,
      0px 0px 20px $background, 0px 0px 20px $background, 0px 0px 20px $background;
    margin-top: 2px;
  }
}
</style>
