<template>
  <div id="overview-page-2" class="pdf-sub-section">
    <div class="section-sidebar">
      <div class="section-title">
        {{ 'T_OVERVIEW' | translate }}
      </div>
    </div>
    <div v-if="primaryData" class="section-content">
      <div class="traffic-by-date">
        <div class="info-box">
          <div class="section-title big">{{ 'T_AXO_VIEW' | translate }}</div>
          <axo-view :data="primaryData" />
        </div>
      </div>
      <img class="pdf-divider" src="@/assets/divider.svg" />
      <div class="section-title big">
        {{ 'T_AREA_LIST' | translate }}
      </div>
      <div class="cards-row">
        <areas-table :list="tableData" show-unique-visitors />
        <areas-table
          v-if="tableData2"
          :list="tableData2"
          show-unique-visitors
          :starting-rank="10"
        />
      </div>
      <div v-if="showMaxDataReached" class="max-data-reached">
        {{ 'T_MAX_DATA_REACHED' | translate }}
      </div>
      <img class="pdf-divider" src="@/assets/divider.svg" />
      <div class="section-title big">
        {{ 'T_NEIGHBORS' | translate }}
      </div>
      <div class="cards-row">
        <img
          v-if="primaryData && primaryData.pavilion && primaryData.pavilion.hallView"
          :src="primaryData.pavilion.hallView"
          class="neighbors"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import BaseSection from '@/mixins/BaseSection'
import AxoView from '@/components/AxoView'
import AreasTable from '@/components/AreasTable.vue'
import TranslateService from '@/services/TranslateService'

export default {
  name: 'Traffic',
  components: { AxoView, AreasTable },
  mixins: [BaseSection],
  data() {
    return {
      pdfPage: 'traffic-2',
      pieAreas: false,
      tableHeaders: ['T_RANK', 'T_AREA_NAME', 'T_UNIQUE_VISITORS'],
      maxAreasToDisplay: 20,
      showMaxDataReached: false,
      tableData: false,
      tableData2: false
    }
  },
  watch: {},
  methods: {
    getVehicleName(area, vehicle) {
      if (!_.get(vehicle, 'nameplate', false)) {
        return area.abdName
      }
      return _.join(
        _.compact(
          _.map(['nameplate', 'series', 'color'], (key) => {
            const val = _.get(vehicle, key, false)
            return val ? TranslateService.get(val) : false
          })
        ),
        ' - '
      )
    },
    findByAreaId(data, areaId) {
      return _.find(data, (item) => item.abd.areaId === areaId)
    },
    getVisualIdentifier(poi, vehicle) {
      return _.get(poi, 'visualIdentifier', _.get(vehicle, 'visualIdentifier', ''))
    },
    getAreaName(area, poi, vehicle) {
      return _.get(poi, 'name', this.getVehicleName(area, vehicle))
    },
    async gotData() {
      const areas = _.get(this.primaryData, 'abdAreas', [])
      const pois = _.get(this.primaryData, 'pois', false)
      const vehicles = _.get(this.primaryData, 'vehicles', false)
      let trafficByArea = _.groupBy(_.get(this.primaryData, 'abdTrafficAreas', []), 'area')
      let rank = 0
      trafficByArea = _.compact(
        _.map(trafficByArea, (data, areaId) => {
          const area = _.find(areas, { _id: areaId })
          if (_.isUndefined(area)) {
            return false
          }
          const poi = this.findByAreaId(pois, area.id)
          const vehicle = this.findByAreaId(vehicles, area.id)
          const areaName = this.getAreaName(area, poi, vehicle)
          return {
            rank: ++rank,
            visualIdentifier: this.getVisualIdentifier(poi, vehicle),
            id: this.getVisualIdentifier(poi, vehicle),
            name: `${areaName}`,
            uniqueVisitors: _.sum(_.map(data, 'count'))
          }
        })
      )
      if (_.get(trafficByArea, 'length', 0) > this.maxAreasToDisplay) {
        trafficByArea = _.take(trafficByArea, this.maxAreasToDisplay)
        this.showMaxDataReached = true
      }
      trafficByArea = _.orderBy(trafficByArea, ['uniqueVisitors'], ['desc'])
      const ranks = _.map(trafficByArea, 'rank')
      this.pieAreas = {
        data: {
          indexes: ranks,
          labels: ranks,
          datasets: [
            {
              index: ranks,
              data: _.map(trafficByArea, 'value')
            }
          ]
        }
      }
      this.tableData = _.take(trafficByArea, this.maxAreasToDisplay / 2)
      if (this.tableData.length === this.maxAreasToDisplay / 2) {
        this.tableData2 = _.takeRight(trafficByArea, trafficByArea.length - this.tableData.length)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';
#overview-page-2 {
  .heatmap-wrapper {
    display: block;
    width: 50%;
    margin: 0 auto;
  }
  .neighbors {
    width: 80%;
  }
}
</style>
