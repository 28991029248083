import moment from 'moment'
import axios from 'axios'
import numeral from 'numeral'
import _ from 'lodash'
import Emitter from 'tiny-emitter'
import DataService from './DataService'
import LoadingService from './LoadingService'
import LoginService from './LoginService'

class AnalyticDataService {
  constructor() {
    this.isForPdf = false
    this.pdfDates = []
    this.pavilions = []
    this.requestingData = []
    this.heatmapCache = []
    this.events = new Emitter()
  }

  calculateAbdTrafficPerArea(pavilion, abdAreas) {
    const totalTrafficAreas = []
    const groupedByAreas = _.groupBy(pavilion.abdTrafficAreas, 'area')
    _.each(groupedByAreas, (trafficAreaRecords, areaId) => {
      const abdArea = _.find(abdAreas, { _id: areaId })
      totalTrafficAreas.push({
        id: areaId,
        count: _.sum(_.map(trafficAreaRecords, 'count')),
        ..._.pick(abdArea, ['abdName', 'rect'])
      })
    })
    return totalTrafficAreas
  }

  getConversionFunnel(experiences, experiencePerformances, showOpenDuration) {
    const conversionFunnel = {}
    const fieldList = [
      {
        label: 'T_ACTIVATION_COMPLETED',
        field: 'generated'
      },
      {
        label: 'T_SOUVENIR_RETRIEVED',
        field: 'retrieved'
      },
      {
        label: 'T_SOUVENIR_PLAYED',
        field: 'played'
      },
      {
        label: 'T_SOUVENIR_SHARED',
        field: 'shared'
      },
      {
        label: 'T_SOUVENIR_DOWNLOADED',
        field: 'downloaded'
      }
    ]
    _.each(fieldList, (item) => {
      const count = _.sum(
        _.map(experiencePerformances, (performance) =>
          _.toNumber(_.get(performance, `${item.field}.count`, 0))
        )
      )
      _.set(conversionFunnel, item.field, count)
    })
    const hourDuration = 3600
    conversionFunnel.capacity = Math.round(
      _.sum(
        _.compact(
          _.map(experiences, (experience) => {
            if (_.isUndefined(experience.touchpointDuration)) {
              return 0
            }
            const maxCapacityPerHour =
              (hourDuration / experience.touchpointDuration) * experience.simultaneousUsers
            return Math.round(maxCapacityPerHour * showOpenDuration)
          })
        )
      )
    )
    return conversionFunnel
  }

  getAsNumber(item, key) {
    let value = Number(_.get(item, key, 0))
    if (_.isNaN(value)) {
      value = 0
    }
    return value
  }

  checkIfAlreadyHaveData(pavilion, dates) {
    return _.get(this.pavilions, this.getPavilionKeyForDates(pavilion, dates), false)
  }
  checkIfAlreadyRequestingData(pavilion, dates) {
    return _.get(this.requestingData, this.getPavilionKeyForDates(pavilion, dates), false)
  }

  getPavilionKeyForDates(pavilion, dates) {
    return `${_.get(pavilion, '_id', '??')}-${_.join(dates, '-')}`
  }

  checkIfTotalTrafficZero(key, data, forceKey = false) {
    if (_.sum(_.map(data, forceKey ? forceKey : 'count')) === 0) {
      if (forceKey) {
        return console.error(`${key} - Total of ${forceKey} is zero`)
      }
      console.error(`${key} - Total traffic is zero`)
    }
  }

  async getData(pavilion, dates, forceUpdate = false) {
    const timeList = _.get(pavilion, 'event.hoursRange', [])
    const cachedData = forceUpdate ? false : this.checkIfAlreadyHaveData(pavilion, dates)
    const alreadyRequestingData = this.checkIfAlreadyRequestingData(pavilion, dates)
    if (alreadyRequestingData) {
      console.info(
        `AnalyticDataService - getData - already requesting data for ${this.getPavilionKeyForDates(
          pavilion,
          dates
        )}`
      )
      return false
    }
    if (cachedData) {
      console.info(
        `AnalyticDataService - getData - already have data for ${this.getPavilionKeyForDates(
          pavilion,
          dates
        )}`
      )
      return cachedData
    }
    _.set(this.requestingData, this.getPavilionKeyForDates(pavilion, dates), true)
    let datesArray = dates
    if (this.isForPdf && _.get(this.pdfDates, 'length', 0) !== 0) {
      datesArray = this.pdfDates
    }
    const [startDate, endDate] = datesArray
    let data = {}
    try {
      const url = `/api/pavilions/${pavilion._id}/analytics/${startDate}/${endDate}/${
        this.isForPdf
      }${forceUpdate ? `?refresh=${+new Date()}` : ''}`
      const response = await axios.get(url)
      data = response.data
    } catch (error) {
      console.error(`getData - error: `, error)
      LoginService.handleRequestError()
      return false
    }
    let {
      weather,
      event,
      experiencePerformances,
      pavilionPerformances,
      experiences,
      vehicles,
      pois,
      abdShow,
      abdAreas,
      abdTraffic,
      abdTrafficAreaHours,
      abdTrafficAreas,
      abdTrafficHours,
      heatmapPointsList,
      heatmapPointsListMin,
      heatmapPointsListMax,
      abdDeviceLastPictures,
      abdDevices
    } = _.cloneDeep(data)
    vehicles = DataService.restructureData(vehicles)
    pois = DataService.restructureData(pois)
    abdShow = DataService.restructureData(abdShow)
    weather = this.filterByDates(weather, dates)
    abdTraffic = this.filterByDates(abdTraffic, dates)
    abdTrafficHours = this.filterByDates(abdTrafficHours, dates)
    abdTrafficAreas = this.filterByDates(abdTrafficAreas, dates)
    abdTrafficAreaHours = this.filterByDates(abdTrafficAreaHours, dates)
    pavilionPerformances = this.filterByDates(pavilionPerformances, dates)
    experiencePerformances = this.filterByDates(experiencePerformances, dates)
    abdDeviceLastPictures = this.filterByDates(abdDeviceLastPictures, dates)
    this.checkIfTotalTrafficZero('abdTraffic', abdTraffic)
    this.checkIfTotalTrafficZero('abdTraffic', abdTraffic, 'stayAverage')
    this.checkIfTotalTrafficZero('abdTrafficHours', abdTrafficHours)
    this.checkIfTotalTrafficZero('abdTrafficHours', abdTrafficHours, 'stayAverage')
    this.checkIfTotalTrafficZero('abdTrafficAreas', abdTrafficAreas)
    this.checkIfTotalTrafficZero('abdTrafficAreaHours', abdTrafficAreaHours)
    const duration = moment(endDate).endOf('day').diff(moment(startDate).startOf('day'))
    const days = moment.duration(duration).asDays()
    const dateList = _.map(_.range(days), (day) =>
      moment(startDate).add(day, 'd').format('YYYY-MM-DD')
    )
    let showOpenDuration = 0
    try {
      // console.warn(`event = `, event, event.hoursRange)
      const lastDayEndHour = moment(
        `${event.endDate} ${_.get(event, 'lastDayEndHour', _.get(event, 'endHour', '18:59:59'))}`,
        'YYYY-MM-DD HH:mm:ss'
      )
      const endTime = moment(
        `${event.endDate} ${_.get(event, 'endHour', '18:59:59')}`,
        'YYYY-MM-DD HH:mm:ss'
      )
      const startTime = moment(
        `${event.endDate} ${_.get(event, 'startHour', '18:59:59')}`,
        'YYYY-MM-DD HH:mm:ss'
      )
      let minusDismantleHours = 0
      if (startTime.diff(lastDayEndHour) > 0) {
        console.info(
          `Dismantle hour is ${Math.round(
            moment.duration(startTime.diff(lastDayEndHour)).asHours()
          )} hours before event's starting hour`
        )
        minusDismantleHours = _.get(event, 'hoursRange.length', 8) * -1
      } else if (endTime.diff(lastDayEndHour) > 0) {
        console.info(
          `Dismantle hour is ${moment
            .duration(endTime.diff(lastDayEndHour))
            .asHours()} hours before event's ending hour`
        )
        minusDismantleHours = moment.duration(lastDayEndHour.subtract(endTime)).asHours()
      }
      showOpenDuration = _.get(event, 'hoursRange.length', 8) * days - minusDismantleHours
      const hourDuration = 3600
      _.each(experiences, (experience) => {
        if (_.isUndefined(experience.touchpointDuration)) {
          experience.capacity = 0
        }
        const maxCapacityPerHour =
          (hourDuration / experience.touchpointDuration) * experience.simultaneousUsers
        experience.capacity = Math.round(maxCapacityPerHour * showOpenDuration)
      })
    } catch (error) {
      console.error(`Error while trying to calculate experiences capacity:`, error)
    }
    abdTraffic = _.map(dateList, (date) => {
      const item = _.find(abdTraffic, { date })
      return item ? item : { date, count: 0 }
    })
    // total
    const totalEventVisitors = event.visitors
    const totalStandTraffic = _.sum(_.map(abdTraffic, 'count'))
    const totalLeadsGeneration = _.sum(_.map(pavilionPerformances, 'leads'))
    const totalOrdersGeneration = _.sum(_.map(pavilionPerformances, 'orders'))
    const totalSocialMediaReach = _.sum(_.map(pavilionPerformances, 'socialMediaReach'))
    const totalVoucherRedeemed = _.sum(_.map(pavilionPerformances, 'voucher.redeemed'))
    const totalVoucherGenerated = _.sum(_.map(pavilionPerformances, 'voucher.generated'))
    const totalSouvenirShared = _.sum(
      _.map(experiencePerformances, (item) => _.get(item, 'shared.count', 0))
    )
    const totalExperienceGenerated = _.sum(
      _.map(experiencePerformances, (item) => _.get(item, 'generated.count', 0))
    )
    const totalWechatVisit = _.sum(
      _.map(pavilionPerformances, (item) => _.get(item, 'wechat.visits', 0))
    )
    const totalWechatUniqueVisit = _.sum(
      _.map(pavilionPerformances, (item) => _.get(item, 'wechat.uniqueVisits', 0))
    )

    const averageWechatSessionTime =
      _.sum(
        _.map(
          pavilionPerformances,
          (item) =>
            _.get(item, 'wechat.uniqueVisits', 0) * _.get(item, 'wechat.averageSessionTime', 0)
        )
      ) / totalWechatUniqueVisit

    const getSumTrafficInfo = (trafficList) => {
      const demography = {}
      const count = _.sum(_.map(trafficList, 'count'))
      const stayAverage = _.sum(_.map(trafficList, 'stayAverage'))
      _.each(trafficList, (item) => {
        _.each(item.demography, (map, key1) => {
          demography[key1] = demography[key1] || {}
          _.each(map, (value, key2) => {
            demography[key1][key2] = demography[key1][key2] || 0
            demography[key1][key2] += value
          })
        })
      })
      return {
        count,
        stayAverage,
        demography
      }
    }
    let trafficByVehicle = _.compact(
      _.map(vehicles, (vehicle) => {
        const abdArea = _.find(abdAreas, { id: _.get(vehicle, 'abd.areaId') })
        if (_.isUndefined(abdArea)) {
          console.error(
            `Couldn't find abdArea with id:${_.get(vehicle, 'abd.areaId')} for vehicle:`,
            vehicle
          )
          return false
        }
        const trafficList = _.filter(abdTrafficAreas, { area: abdArea._id })
        const trafficHourList = _.filter(abdTrafficAreaHours, { area: abdArea._id })
        const dates = _.uniq(_.map(trafficList, 'date'))
        const info = getSumTrafficInfo(trafficList)
        return {
          vehicle: {
            ...vehicle,
            rect: abdArea.rect
          },
          ...info,
          percentage: `${numeral((info.count / totalStandTraffic) * 100).format('0.0')}%`,
          byDate: _.map(dates, (date) => {
            const subTrafficList = _.filter(trafficList, { date })
            const info = getSumTrafficInfo(subTrafficList)
            const data = {
              date,
              count: _.sum(_.map(subTrafficList, 'count')),
              ...info
            }
            if (pavilion.trafficDataSource === 'abd') {
              data.byHour = _.map(timeList, (time) => {
                const subSubTrafficList = _.filter(trafficHourList, { date, time })
                return {
                  time,
                  count: _.sum(_.map(subSubTrafficList, 'count'))
                }
              })
            }
            return data
          })
        }
      })
    )

    // ordersByVehicle = _.sortBy(ordersByVehicle, item => -1 * _.get(item, 'count', 0))
    // leadsByVehicle = _.sortBy(leadsByVehicle, item => -1 * _.get(item, 'count', 0))
    trafficByVehicle = _.sortBy(trafficByVehicle, (item) => -1 * _.get(item, 'count', 0))
    // souvenirSharedByVehicle = _.sortBy(souvenirSharedByVehicle, item => -item.count)

    const mostTrafficInOneDay = _.maxBy(abdTraffic, 'count')

    // orders, leads, traffic by date
    let ordersByDate = _.map(dateList, (date) => {
      const count = _.get(_.find(pavilionPerformances, { date }), 'orders')
      return {
        date,
        count,
        percentage: `${numeral((count / totalOrdersGeneration) * 100).format('0.0')}%`
      }
    })
    let leadsByDate = _.map(dateList, (date) => {
      const count = _.get(_.find(pavilionPerformances, { date }), 'leads')
      return {
        date,
        count,
        percentage: `${numeral((count / totalLeadsGeneration) * 100).format('0.0')}%`
      }
    })

    let trafficByDate = _.map(dateList, (date) => {
      const count = _.get(_.find(abdTraffic, { date }), 'count', 0)
      const weatherItem = _.find(weather, { date })
      return {
        date,
        count,
        weather: _.get(weatherItem, 'status'),
        percentage: `${numeral((count / totalStandTraffic) * 100).format('0.0')}%`
      }
    })

    let experiencesByDate = _.map(dateList, (date) => {
      const experienceDataList = _.filter(experiencePerformances, { date })
      const visits = _.sum(
        _.map(experienceDataList, (item) => this.getAsNumber(item, 'generated.count'))
      )
      const visitsByExperience = []
      _.each(experienceDataList, (item) => {
        visitsByExperience.push({
          experience: item.experience,
          count: this.getAsNumber(item, 'generated.count'),
          downloaded: this.getAsNumber(item, 'downloaded.count'),
          played: this.getAsNumber(item, 'played.count'),
          retrieved: this.getAsNumber(item, 'retrieved.count'),
          shared: this.getAsNumber(item, 'shared.count')
        })
      })
      const visitsByHour = _.map(timeList, (time) => {
        return {
          time,
          count: 0,
          visitsByExperience: []
          // isFuture: `${date} ${time}` > moment().format('YYYY-MM-DD HH:mm')
        }
      })
      _.each(experienceDataList, (item) => {
        _.each(item.generated.byHour, (subItem) => {
          const timeItem = _.find(visitsByHour, { time: subItem.time })
          if (!_.isUndefined(timeItem)) {
            const experienceCount = {
              experience: item.experience,
              count: this.getAsNumber(subItem, 'count')
            }
            timeItem.count += this.getAsNumber(subItem, 'count')
            timeItem.visitsByExperience.push(experienceCount)
          }
        })
      })
      return {
        date,
        visits,
        visitsByExperience,
        visitsByHour,
        percentage: `${numeral((visits / totalWechatVisit) * 100).format('0.0')}%`
      }
    })
    let abdByDate = _.map(dateList, (date) => {
      const abdTrafficDataList = _.filter(abdTraffic, { date })
      return {
        date,
        visits: _.sum(_.map(abdTrafficDataList, (item) => _.get(item, 'count', 0))),
        visitsByHour: _.map(timeList, (time) => _.find(abdTrafficHours, { date, time }))
      }
    })
    // NOTE: Generates missing records for attractiveness bar chart
    const formattedAbdTrafficAreas = []
    _.each(dateList, (date) => {
      _.each(abdAreas, (area) => {
        const foundRecord = _.find(abdTrafficAreas, { date: date, area: area._id })
        formattedAbdTrafficAreas.push({
          area: area._id,
          date,
          pavilion: area.pavilion,
          show: area.show,
          stayAverage: _.get(foundRecord, 'stayAverage', 0),
          count: _.get(foundRecord, 'count', 0)
        })
        _.each(event.hoursRange, (hour) => {
          if (
            _.isUndefined(_.find(abdTrafficAreaHours, { area: area._id, date: date, time: hour }))
          ) {
            abdTrafficAreaHours.push({
              area: area._id,
              date,
              time: hour,
              pavilion: area.pavilion,
              show: area.show,
              stayAverage: 0,
              count: 0
            })
          }
        })
      })
    })
    abdTrafficAreas = formattedAbdTrafficAreas
    const experiencesWithSouvenir = _.map(_.filter(experiences, { hasSouvenir: true }), '_id')
    let souvenirsByDate = _.map(dateList, (date) => {
      const countByAction = {}
      const filteredExperiencePerformances = _.filter(experiencePerformances, (performance) => {
        return (
          performance.date === date && _.includes(experiencesWithSouvenir, performance.experience)
        )
      })
      _.each(filteredExperiencePerformances, (item) => {
        _.each(['generated', 'retrieved', 'played', 'shared', 'downloaded'], (key) => {
          const subCount = this.getAsNumber(item, `${key}.count`)
          const count = this.getAsNumber(countByAction, key) + subCount
          _.set(countByAction, key, count)
          countByAction.byExperience = countByAction.byExperience || []
          countByAction.byExperience.push({
            experience: item.experience,
            count: subCount
          })
        })
      })
      return {
        date,
        countByAction
      }
    })

    let vouchersByDate = _.map(dateList, (date) => {
      const countByAction = _.get(_.find(pavilionPerformances, { date }), 'voucher')
      return {
        date,
        countByAction
      }
    })

    ordersByDate = _.sortBy(ordersByDate, (item) => item.date)
    leadsByDate = _.sortBy(leadsByDate, (item) => item.date)
    trafficByDate = _.sortBy(trafficByDate, (item) => item.date)
    experiencesByDate = _.sortBy(experiencesByDate, (item) => item.date)
    souvenirsByDate = _.sortBy(souvenirsByDate, (item) => item.date)
    vouchersByDate = _.sortBy(vouchersByDate, (item) => item.date)

    // traffic by hour
    let trafficByHour = _.map(timeList, (time) => {
      const count = _.sum(_.map(_.filter(abdTrafficHours, { time }), 'count')) || 0
      return {
        time,
        count,
        percentage: `${numeral((count / totalStandTraffic) * 100).format('0.0')}%`
      }
    })
    // conversion funnel
    const conversionFunnel = this.getConversionFunnel(
      experiences,
      experiencePerformances,
      showOpenDuration
    )

    const totalActivationCapacity = conversionFunnel.capacity
    const totalActivationCompleted = conversionFunnel.generated

    // set cameras
    const cameras = _.compact(
      _.map(abdDevices, (item) => {
        if (item.deviceCode === _.get(abdShow, 'camera360Device', false)) {
          return
        }
        const historyItems = _.filter(abdDeviceLastPictures, { device: item._id })
        const dateList = _.sortBy(_.uniq(_.map(historyItems, 'date')), (item) => item)
        return {
          deviceCode: item.deviceCode,
          picture: item.picture,
          byDate: _.compact(
            _.map(dateList, (date) => {
              let subHistoryItemList = _.filter(historyItems, { date })
              if (_.isEmpty(subHistoryItemList)) {
                return
              }
              subHistoryItemList = _.sortBy(subHistoryItemList, (item) => item.time)
              return {
                date,
                byHour: _.map(subHistoryItemList, (subItem) => {
                  const attach = _.find(subItem._attachments, { _name: 'lastPicture' })
                  return {
                    time: subItem.time,
                    url: attach && attach.url
                  }
                })
              }
            })
          )
        }
      })
    )

    let camera360
    const deviceCode = _.get(abdShow, 'camera360Device', '?')
    const camer360Device = _.find(abdDevices, { deviceCode: deviceCode })
    if (camer360Device) {
      const historyItems = _.filter(abdDeviceLastPictures, { device: camer360Device._id })
      camera360 = {
        deviceCode: camer360Device.deviceCode,
        picture: camer360Device.picture,
        byDate: _.compact(
          _.map(dateList, (date) => {
            let subHistoryItemList = _.filter(historyItems, { date })
            if (_.isEmpty(subHistoryItemList)) {
              return
            }
            subHistoryItemList = _.sortBy(subHistoryItemList, (item) => item.time)
            return {
              date,
              byHour: _.map(subHistoryItemList, (subItem) => {
                const attach = _.find(subItem._attachments, { _name: 'lastPicture' })
                return {
                  time: subItem.time,
                  url: attach && attach.url
                }
              })
            }
          })
        )
      }
    }

    const returnData = {
      timeList,
      cameras,
      camera360,
      experiences,
      vehicles,
      pois,
      abdShow,
      pavilion,
      dates,
      hoursRange: _.get(event, 'hoursRange', []),
      total: {
        eventVisitors: totalEventVisitors,
        standTraffic: totalStandTraffic,
        leadsGeneration: totalLeadsGeneration,
        ordersGeneration: totalOrdersGeneration,
        socialMediaReach: totalSocialMediaReach,
        voucherGenerated: totalVoucherGenerated,
        voucherRedeemed: totalVoucherRedeemed,
        souvenirShared: totalSouvenirShared,
        wechatVisit: totalWechatVisit,
        wechatUniqueVisit: totalWechatUniqueVisit,
        experienceGenerated: totalExperienceGenerated,
        activationCapacity: totalActivationCapacity,
        activationCompleted: totalActivationCompleted
      },
      average: {
        wechatSessionTime: averageWechatSessionTime
      },
      bestPerformingNameplate: {
        traffic: _.first(trafficByVehicle)
      },
      // by vehicle
      // ordersByVehicle,
      // leadsByVehicle,
      trafficByVehicle,
      experiencePerformances,
      // souvenirSharedByVehicle,
      // by date
      ordersByDate,
      leadsByDate,
      trafficByDate,
      experiencesByDate,
      souvenirsByDate,
      vouchersByDate,
      // by hour
      trafficByHour,
      // conversion funnel
      conversionFunnel,
      mostTrafficInOneDay,
      abdTraffic,
      weather,
      abdAreas,
      abdTrafficAreas,
      abdTrafficAreaHours,
      abdTrafficHours,
      abdByDate,
      // abdTrafficPoints,
      // heatmapPointsListPerDay,
      heatmapPointsList,
      heatmapPointsListMin,
      heatmapPointsListMax
    }
    _.set(this.pavilions, this.getPavilionKeyForDates(pavilion, dates), returnData)
    _.set(this.requestingData, this.getPavilionKeyForDates(pavilion, dates), false)
    if (_.get(_.keys(this.pavilions), 'length', 0) === 1) {
      // NOTE: First load
      LoadingService.setLoading(false)
    }
    console.log('AnalyticDataService - data - ', returnData)
    return returnData
  }

  async getHeatmapPerDay(pavilion, dates, forceUpdate = false) {
    try {
      const [startDate, endDate] = dates
      const url = `/api/pavilions/${pavilion._id}/heatmap/${startDate}/${endDate}`
      if (!forceUpdate) {
        const foundCache = _.find(this.heatmapCache, { url: url })
        if (!_.isUndefined(foundCache)) {
          // console.warn(`found cache !`)
          return foundCache.data
        }
      }
      const { data } = await axios.get(`${url}${forceUpdate ? `?refresh=${+new Date()}` : ''}`)
      this.heatmapCache.push({
        url,
        data
      })
      if (this.heatmapCache.length > 10) {
        this.heatmapCache.unshift()
      }
      // console.warn(`${url} added to cache`)
      return data
    } catch (error) {
      console.error(`getHeatmapPerDay - error: `, error)
      LoginService.handleRequestError()
      return false
    }
  }

  filterByDates(list, dates) {
    if (_.isEmpty(dates)) {
      return list
    }

    return _.filter(list, (item) => {
      const [startDate, endDate] = dates
      return item.date >= startDate && item.date <= endDate
    })
  }
}

export default new AnalyticDataService()
