<template>
  <div class="card-wrapper">
    <content-card
      v-if="content && !noData"
      :type="type"
      :title="title | translate"
      :content="content"
      no-gradient
      dark-background
      :conversion="conversion"
      :half-width="halfWidth"
      :italic="italic"
      :compare-data="compareData"
    />
    <content-card
      v-else
      no-gradient
      dark-background
      no-data
      :conversion="conversion"
      :half-width="halfWidth"
      :italic="italic"
      :title="title | translate"
    />
  </div>
</template>

<script>
import ContentCard from '@/components/ContentCard'

export default {
  components: { ContentCard },
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: [String, Number, Boolean],
      default: '0'
    },
    contentList: {
      type: Array,
      default: () => {
        return []
      }
    },
    formatter: {
      type: [Function, Boolean],
      default: false
    },
    showListTotal: {
      type: Boolean,
      default: false
    },
    isForPdf: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: ''
    },
    alignCenter: {
      type: Boolean,
      default: false
    },
    noData: {
      type: [Boolean, Number],
      default: false
    },
    hasListBullet: {
      type: Boolean,
      default: false
    },
    halfWidth: {
      type: Boolean,
      default: false
    },
    darkBackground: {
      type: Boolean,
      default: false
    },
    italic: {
      type: Boolean,
      default: false
    },
    canExpand: {
      type: Boolean,
      default: false
    },
    noGradient: {
      type: Boolean,
      default: false
    },
    conversion: {
      type: Boolean,
      default: false
    },
    truncateText: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    compareData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';
.card-wrapper {
}
</style>
