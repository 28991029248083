<script>
import _ from 'lodash'
import CardTop from '@/components/CardTop.vue'
import LineChart from '@/components/Charts/LineChart.vue'
import CannotCompareData from '@/components/CannotCompareData.vue'
import ContentCard from '@/components/ContentCard.vue'
import ChartSelector from '@/components/ChartSelector.vue'
import ChartsDataService from '@/services/ChartsDataService'
import SubSectionsCounter from '@/components/SubSectionsCounter.vue'
import CompareShowTitles from '@/components/CompareShowTitles.vue'

export default {
  name: 'SalesSectionMixin',
  components: {
    LineChart,
    ChartSelector,
    CannotCompareData,
    ContentCard,
    CardTop,
    SubSectionsCounter,
    CompareShowTitles
  },
  data() {
    return {
      subSections: [
        {
          title: 'T_LEADS',
          id: 'leads',
          cardTop: {
            type: 'hidden',
            states: [
              {
                title: 'T_LEADS_GENERATION_BY_DATE',
                icon: this.getIcon('calendar')
              }
            ]
          }
        },
        {
          title: 'T_ORDERS',
          id: 'orders',
          cardTop: {
            type: 'hidden',
            states: [
              {
                title: 'T_ORDERS_GENERATION_BY_DATE',
                icon: this.getIcon('calendar')
              }
            ]
          }
        }
      ],
      lineLeads: false,
      lineOrders: false,
      selectedDate: 0,
      highlightedLeadDay: 0,
      highlightedOrderDay: 0,
      ChartsDataService
    }
  },
  watch: {
    highlightedLeadDay() {
      this.selectedDate = _.get(
        this.lineLeads,
        `data.chartSelectorLabels[${this.highlightedLeadDay - 1}]`,
        0
      )
    },
    highlightedOrderDay() {
      this.selectedDate = _.get(
        this.lineOrders,
        `data.chartSelectorLabels[${this.highlightedOrderDay - 1}]`,
        0
      )
    }
  },
  methods: {
    resetChartsHighlights(keepValues = false) {
      if (!keepValues) {
        this.highlightedLeadDay = 0
        this.highlightedOrderDay = 0
      }
      this.$forceUpdate()
    },
    getIcon(icon) {
      return require(`../assets/icons/${icon}.svg`)
    },
    gotData() {
      this.lineLeads = this.getGraphData(
        'leadsByDate',
        'highlightedLeadDay',
        'selectedDate',
        'lineLeads'
      )
      this.lineOrders = this.getGraphData(
        'ordersByDate',
        'highlightedOrderDay',
        'selectedDate',
        'lineOrders'
      )
    },
    getData(data, obj) {
      return _.map(_.get(data, obj, []), (i) => _.get(i, 'count', 0))
    },
    getGraphData(objKey, highlightedKey, selectedKey, graphKey) {
      return {
        data: {
          labels: this.getLabels(this.primaryData, this.secondaryData, objKey, false),
          chartSelectorLabels: this.getLabels(this.primaryData, this.secondaryData, objKey, true),
          datasets: _.map(_.compact([this.primaryData, this.secondaryData]), (d, i) => {
            return {
              label: _.get(d, 'pavilion.event.key', false),
              backgroundColor: ChartsDataService.lineColors[i],
              data: this.getData(d, objKey),
              borderColor: ChartsDataService.lineColors[i],
              barPercentage: 0.5
            }
          })
        },
        options: {
          drawLineSelection: true,
          onClick: (evt, activeElements) => {
            if (activeElements.length === 0) {
              return
            }
            this[highlightedKey] = activeElements[0].index + 1
            this[selectedKey] = _.get(
              this[graphKey],
              `data.chartSelectorLabels[${activeElements[0].index}]`,
              0
            )
          }
        }
      }
    }
  }
}
</script>
