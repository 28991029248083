<template>
  <div id="sales-section" class="pdf-sub-section">
    <div class="section-sidebar">
      <div class="section-title">
        {{ 'T_EXPERIENCE' | translate }}
      </div>
    </div>
    <div v-if="primaryData" class="section-content">
      <!-- Leads bar chart -->
      <div v-if="lineLeads && hasLeads(primaryData)" class="section">
        <div class="section-title">
          {{ 'T_LEADS_GENERATION_BY_DATE' | translate }}
        </div>
        <div class="title-graph vertical">
          <div class="graph-total">
            {{ 'T_TOTAL_LEADS_GENERATED' | translate }}:
            <span>{{ getTotalForChart(lineLeads) | numeral }}</span>
          </div>
        </div>

        <div class="sub-section">
          <div class="leads-by-date">
            <line-chart
              force-color="#8DE971"
              is-for-pdf
              :data="lineLeads.data"
              :options="lineLeads.options"
            />
          </div>
        </div>
      </div>
      <!-- Orders line chart -->
      <img
        v-if="hasLeads(primaryData) && hasOrders(primaryData)"
        class="pdf-divider"
        src="@/assets/divider.svg"
      />
      <div v-if="lineOrders && hasOrders(primaryData)" class="section">
        <div class="section-title">
          {{ 'T_ORDERS_GENERATION_BY_DATE' | translate }}
        </div>
        <div class="title-graph vertical">
          <div class="graph-total">
            {{ 'T_TOTAL_ORDERS_GENERATED' | translate }}:
            <span>{{ getTotalForChart(lineOrders) | numeral }}</span>
          </div>
        </div>
        <div class="sub-section">
          <div class="orders-by-date">
            <line-chart
              force-color="#8DE971"
              is-for-pdf
              :data="lineOrders.data"
              :options="lineOrders.options"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import BaseSection from '@/mixins/BaseSection'
import LineChart from '@/components/Charts/LineChart.vue'
import TranslateService from '@/services/TranslateService'
import ChartsDataService from '@/services/ChartsDataService'

export default {
  name: 'Sales',
  components: {
    LineChart
  },
  mixins: [BaseSection],
  data() {
    return {
      subSections: [
        {
          title: 'T_LEADS',
          id: 'leads',
          cardTop: {
            type: 'hidden',
            states: [
              {
                title: 'T_LEADS_GENERATION_BY_DATE',
                icon: this.getIcon('calendar')
              }
            ]
          }
        },
        {
          title: 'T_ORDERS',
          id: 'orders',
          cardTop: {
            type: 'hidden',
            states: [
              {
                title: 'T_ORDERS_GENERATION_BY_DATE',
                icon: this.getIcon('calendar')
              }
            ]
          }
        }
      ],
      lineLeads: false,
      lineOrders: false,
      pie: false,
      pie2: false,
      ChartsDataService
    }
  },
  watch: {},
  methods: {
    getNameplatesTop5(type) {
      console.warn(`getNameplatesTop5 ${type}`)
      return _.take(_.get(this.primaryData, 'trafficByVehicle', []), 5)
    },
    getPieData(data, obj, totalTranslationKey, totalObj) {
      const foundData = _.get(data, obj, [])
      return {
        data: {
          type: 'doughnut',
          datasets: [
            _.map(data, (d) => {
              if (!d) {
                return false
              }
              const foundObj = _.get(d, obj, [])
              return {
                index: _.map(foundObj, (item, i) => i + 1),
                percentages: _.map(foundObj, 'percentage'),
                data: _.map(foundObj, 'count'),
                borderWidth: 0
              }
            })
          ],
          labels: _.map(foundData, (item) => TranslateService.get(item.vehicle.nameplate)),
          options: this.getDoughnutCenterTextOptions(
            totalTranslationKey,
            _.get(data, totalObj, 0),
            true
          )
        }
      }
    },
    getTotalForAllDays(data, obj, type) {
      return `${_.sum(
        _.map(_.get(data, obj, []), (d) => {
          return _.toNumber(_.get(d, `countByAction.${type}`, 0))
        })
      )}`
    },
    getIcon(icon) {
      return require(`../../assets/icons/${icon}.svg`)
    },
    gotData() {
      this.lineLeads = this.getLeadsLineData()
      this.lineOrders = this.getLineData()
      this.loading = false
    },
    getLeadsLineData() {
      return {
        data: {
          labels: this.getLabels(this.primaryData, this.secondaryData, 'leadsByDate', false),
          chartSelectorLabels: this.getLabels(
            this.primaryData,
            this.secondaryData,
            'leadsByDate',
            true
          ),
          datasets: _.compact(
            _.map([this.primaryData, this.secondaryData], (d) => {
              if (!d) {
                return false
              }
              return {
                label: _.get(d, 'pavilion.event.key', false),
                backgroundColor: '#8DE971',
                borderColor: this.secondaryData ? 'transparent' : '#8DE971',
                data: this.getData(d, 'leadsByDate'),
                barPercentage: 0.5
              }
            })
          )
        },
        options: {
          plugins: {
            datalabels: {
              display: true,
              align: 'center',
              font: {
                family: 'SourceSansPro-Bold',
                size: 8
              },
              opacity: 1,
              labels: {
                value: {
                  formatter: function (value, context) {
                    const val = _.get(context, `dataset.data[${context.dataIndex}]`, 0)
                    return val === 0 ? '' : val
                  },
                  font: {
                    family: 'SourceSansPro-Bold',
                    size: '8px',
                    weight: '700'
                  },
                  anchor: 'end',
                  align: 'top',
                  offset: 0,
                  color: 'black'
                }
              }
            }
          }
        }
      }
    },
    getData(data, obj, percentage = false) {
      return _.map(_.get(data, obj, []), (i) =>
        _.get(i, percentage ? 'percentage' : 'count', percentage ? `0%` : 0)
      )
    },
    getLineData() {
      return {
        data: {
          labels: this.getLabels(this.primaryData, this.secondaryData, 'ordersByDate', false),
          chartSelectorLabels: this.getLabels(
            this.primaryData,
            this.secondaryData,
            'ordersByDate',
            true
          ),
          datasets: _.map([this.primaryData], (d) => {
            if (!d) {
              return false
            }
            return {
              label: _.get(d, 'pavilion.event.key', false),
              backgroundColor: '#8DE971',
              data: this.getData(d, 'ordersByDate'),
              borderColor: this.secondaryData ? 'transparent' : '#8DE971',
              barPercentage: 0.5
            }
          })
        },
        options: {
          plugins: {
            datalabels: {
              display: true,
              align: 'center',
              font: {
                family: 'SourceSansPro-Bold',
                size: 8
              },
              opacity: 1,
              labels: {
                value: {
                  formatter: function (value, context) {
                    const val = _.get(context, `dataset.data[${context.dataIndex}]`, 0)
                    return val === 0 ? '' : val
                  },
                  font: {
                    family: 'SourceSansPro-Bold',
                    size: '8px',
                    weight: '700'
                  },
                  anchor: 'end',
                  align: 'top',
                  offset: 0,
                  color: 'black'
                }
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';
#sales-section.pdf-sub-section {
  .section {
    width: 100%;
  }
  .bar-chart,
  .line-chart {
    height: 173px !important;
    width: 100%;
  }
}
</style>
