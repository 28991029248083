import _ from 'lodash'
import autoBind from 'auto-bind'
import Emitter from 'tiny-emitter'
import axios from 'axios'
import Mustache from 'mustache'

class TranslateService {
  constructor() {
    console.log('TranslateService.constructor')
    autoBind(this)
    this.dict = {}
    this.events = new Emitter()
    this.currentLocale = 'zhCN'
  }

  async init() {
    console.log('TranslateService.init')
    let response
    response = await axios.get('/cms/resources')
    const resources = response.data
    const resource = _.find(resources, { name: 'translations' })
    try {
      response = await axios.get('/api/translations')
      const list = response.data
      _.each(list, (item) => {
        _.each(resource.locales, (locale) => {
          const value = _.get(item, `${locale}.value`)
          this.dict[item.key] = this.dict[item.key] || {}
          this.dict[item.key][locale] = value
        })
      })
      this.events.emit('gotTranslations')
    } catch (error) {
      console.error(`Failed to get translations: ${_.get(error, 'message', error)}`)
    }
  }

  setLocale(locale) {
    this.currentLocale = locale
    this.events.emit('changeLanguage', locale)
  }

  get(key, params) {
    if (_.isString(key)) {
      if (_.isEmpty(key)) {
        return ''
      }
      key = _.toUpper(key)
      if (!_.startsWith(key, 'T_')) {
        // console.warn(`key ${key} doesn't have T_ prefix`)
        key = `T_${key}`
      }
      if (this.dict[key] && this.dict[key][this.currentLocale]) {
        const value = this.dict[key][this.currentLocale]
        return Mustache.render(value, params)
      }
      console.info(`Did not find any translation for ${key}`)
      return key
    }
    if (_.isString(params) && !_.isEmpty(params)) {
      return _.get(key, `${this.currentLocale}.${params}`, '')
    }
    return _.get(key, this.currentLocale, '')
  }
}

export default new TranslateService()
