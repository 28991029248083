<script>
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import AnalyticDataService from '@/services/AnalyticDataService'
import PavilionSelectorService from '@/services/PavilionSelectorService'
import ChartsDataService from '@/services/ChartsDataService'
import TranslateService from '@/services/TranslateService'
import LoadingService from '@/services/LoadingService'
import SectionLoader from '@/components/SectionLoader'
import NoGraphData from '@/components/NoGraphData'

export default {
  name: 'BaseSection',
  components: { SectionLoader, NoGraphData },
  props: {},
  data() {
    return {
      selectedBrand: null,
      selectedPrimaryPavilion: null,
      selectedSecondaryPavilion: null,
      selectedSubView: 0,
      primaryData: null,
      secondaryData: null,
      selectedDates: null,
      selectedDays: null,
      loading: true,
      currentSubSection: 0,
      subSections: [],
      filteredSubSections: [],
      filteredSecondarySubSections: [],
      swipeDirective: {
        left: () => {
          this.selectedSubView = 0
          this.checkCallResetChartsHighlights()
          this.changeRouteParam(1)
          this.$forceUpdate()
        },
        right: () => {
          this.selectedSubView = 0
          this.checkCallResetChartsHighlights()
          this.changeRouteParam(-1)
          this.$forceUpdate()
        }
      }
    }
  },
  watch: {
    selectedSubView() {
      // NOTE: to avoid loosing selection of day
      this.checkCallResetChartsHighlights(_.includes(this.$route.path, 'interactions'))
    },
    // loading() {
    //   console.warn(`watch loading ${this.loading}`, this.$vnode.tag)
    // },
    primaryData() {
      if (this.desktopMode === '') this.filterSubSections(this.primaryData)
    },
    secondaryData() {
      if (this.secondaryData) {
        this.filterSubSections(this.secondaryData, true)
      }
    }
  },
  async mounted() {
    PavilionSelectorService.events.on('update-pavilion-data', this.setData)
    AnalyticDataService.isForPdf = _.includes(this.$route.path, '/pdf')
    if (
      AnalyticDataService.isForPdf &&
      _.get(this.$route, 'query.startDate', false) &&
      _.get(this.$route, 'query.endDate', false)
    ) {
      // console.warn(`route params = `, this.$route)
      AnalyticDataService.pdfDates = [this.$route.query.startDate, this.$route.query.endDate]
    }
    LoadingService.events.on('loading-state', (loading) => {
      // console.warn('BaseSection - loading-state event =', loading)
      this.loading = loading
    })
    const data = await PavilionSelectorService.getData(false, false)
    if (data.primaryData) {
      this.setData(data)
    }
    this.filteredSubSections = _.cloneDeep(this.subSections)
    this.redirectToCurrentSection()
  },
  beforeDestroy() {
    PavilionSelectorService.events.off('update-pavilion-data', this.setData)
  },
  methods: {
    hasLeads(data) {
      return _.get(data, 'pavilion.hasLeads', false)
    },
    hasOrders(data) {
      return _.get(data, 'pavilion.hasOrders', false)
    },
    graphHasData(graph) {
      return (
        _.sum(
          _.map(_.get(graph, 'data.datasets', []), (dataset) => _.sum(_.get(dataset, 'data', [])))
        ) !== 0
      )
    },
    getAverageForDatasets(datasets) {
      const data = []
      _.each(datasets, (dataset) => {
        _.each(dataset.data, (d) => {
          data.push(d)
        })
      })
      return this.getAverage(data)
    },
    getAverage(data) {
      const val = _.round(_.mean(data))
      return _.isNaN(val) ? 0 : val
    },
    labelToDate(label) {
      return _.replace(label, /\//g, '-')
    },
    dateToLabel(label) {
      return _.replace(label, /-/g, '/')
    },
    hasField(obj, path) {
      return _.get(obj, `${path}.${TranslateService.currentLocale}`, false)
    },
    redirectToCurrentSection() {
      const section = _.get(
        this.$route,
        'params.section',
        _.get(_.first(this.filteredSubSections), 'id', false)
      )
      if (!section) {
        return
      }
      const foundIndex = _.findIndex(this.filteredSubSections, { id: section })
      this.currentSubSection = foundIndex === -1 ? 0 : foundIndex
    },
    translateArray(obj, path) {
      return _.get(obj, `${path}.${TranslateService.currentLocale}`, [])
    },
    getTotalForChart(chart) {
      return _.sum(
        _.map(_.get(chart, 'data.datasets[0].data', []), (d) => {
          return _.toNumber(_.replace(d, /,/g, ''))
        })
      )
    },
    // reduceConversion(numerator, denominator, noRounding = false) {
    reduceConversion(numerator, denominator) {
      if (numerator === 0) {
        console.warn(
          `reduceConversion - no stand total traffic found ${denominator} / ${numerator}`
        )
        return ``
      }
      return `${numeral((numerator / denominator) * 100).format('0.00')}%`
      // return numeral((numerator / denominator) * 100).format('0,0')
      // if (denominator / numerator <= 0.5) {
      //   return `1:2`
      // }
      // return `1:${noRounding ? denominator / numerator : Math.round(denominator / numerator)}`
    },
    getHighlightedValue(
      chart,
      data,
      highlightedValue,
      highlightedHourValue = undefined,
      average = false
    ) {
      const dataFromChart = _.get(chart, `data.datasets[${data === 'primary' ? 0 : 1}].data`, [])
      if (!_.isUndefined(highlightedHourValue)) {
        if (highlightedHourValue === 0) {
          if (average) {
            return _.mean(dataFromChart)
          }
          return _.sum(dataFromChart)
        }
        return _.get(dataFromChart, `[${highlightedHourValue - 1}]`, 0)
      }
      if (highlightedValue === 0) {
        if (average) {
          return _.mean(dataFromChart)
        }
        return _.sum(dataFromChart)
      }
      return _.get(dataFromChart, `[${highlightedValue - 1}]`, 0)
    },
    getDoughnutCenterTextOptions(label, value, isForPdf = false) {
      return {
        elements: {
          center: {
            label: {
              text: TranslateService.get(label)
            },
            value: {
              text: value
            },
            isForPdf: isForPdf
          }
        }
      }
    },
    getTop5Show(data, showIndex) {
      return {
        label: `${_.get(data, 'pavilion.event.key', '')} - ${TranslateService.get('T_TOP5')}`,
        circleColor: ChartsDataService.barColors[showIndex]
      }
    },
    getTotalForShow(data, obj, highlightedValue, selectedType = false) {
      const endPath = selectedType ? `countByAction.${selectedType}` : `count`
      if (highlightedValue !== 0) {
        return _.get(data, `${obj}[${highlightedValue - 1}].${endPath}`, 0)
      }
      return _.sum(
        _.map(_.get(data, obj, []), (subObj) => {
          return _.get(subObj, endPath, 0)
        })
      )
    },
    getLabels(data, secondaryData = false, obj, fullDate = false) {
      if (secondaryData) {
        return _.map(
          this.getDates(data, obj),
          (date, i) => TranslateService.get('T_DAY') + ` ${i + 1}`
        )
      }
      const labels = _.map(this.getDates(data, obj), (date) =>
        moment(date).format(fullDate ? 'YYYY/MM/DD' : 'MM/DD')
      )
      return labels
    },
    getDates(data, obj) {
      // console.warn(`get dates ${obj}`, _.get(data, obj))
      return _.compact(_.map(_.get(data, obj), (i) => _.get(i, 'date', false)))
    },
    getTotalValue(data, secondaryData = false, obj) {
      return secondaryData
        ? _.get(data, obj, 0) + _.get(secondaryData, obj, 0)
        : _.get(data, obj, 0)
    },
    hasSubSection(id, subSections = false) {
      return _.isUndefined(_.find(subSections ? subSections : this.filteredSubSections, { id: id }))
        ? false
        : true
    },
    canCompareSubSection(id) {
      if (!this.secondaryData) {
        return true
      }
      return _.isUndefined(_.find(this.filteredSecondarySubSections, { id: id })) ? false : true
    },
    removeSubSection(subSections, id) {
      // console.warn(`will remove sub section ${id}`)
      return _.filter(subSections, (subSection) => subSection.id !== id)
    },
    shouldRemoveSubSection(subSections, modules, val, key) {
      return !_.get(modules, key, false) && this.hasSubSection(val, subSections)
    },
    filterSubSections(data, forSecondaryData = false) {
      let subSections = _.cloneDeep(this.subSections)
      const modules = _.pick(data.pavilion, PavilionSelectorService.pavilionModules)
      const subSectionsMapping = {
        hasLeads: 'leads',
        hasOrders: 'orders',
        hasStayAverage: 'stand-statistics',
        hasHeatmap: 'heatmap',
        hasVoucher: 'voucher'
      }
      _.each(subSectionsMapping, (val, key) => {
        if (this.shouldRemoveSubSection(subSections, modules, val, key)) {
          subSections = this.removeSubSection(subSections, val)
        }
      })
      if (
        this.shouldRemoveSubSection(subSections, modules, 'cameras', 'hasCamera') ||
        this.secondaryData
      ) {
        subSections = this.removeSubSection(subSections, 'cameras')
      }
      if (this.shouldRemoveSubSection(subSections, modules, 'activations', 'hasExperiences')) {
        subSections = this.removeSubSection(subSections, 'activations')
        subSections = this.removeSubSection(subSections, 'interactions')
        subSections = this.removeSubSection(subSections, 'souvenir')
        subSections = this.removeSubSection(subSections, 'conversion')
      }
      if (forSecondaryData) {
        this.filteredSecondarySubSections = subSections
        return
      }
      this.filteredSubSections = subSections
      this.redirectToCurrentSection()
    },
    msToTime(value) {
      let tmp = 0
      const negativeVal = value < 0
      if (negativeVal) {
        value = value * -1
      }
      tmp = numeral(value / 1000).format('00:00:00')
      if (_.get(_.split(tmp, ':'), '[0].length', 0) === 1) {
        return `${negativeVal ? '-' : ''}0${tmp}`
      }
      return negativeVal ? tmp * -1 : tmp
    },
    getDesktopWrapperClass() {
      return `${this.secondaryData ? 'compare' : ''} section`
    },
    getDesktopTitle(translationKey) {
      return {
        states: [
          {
            title: translationKey
          }
        ]
      }
    },
    getDataForChart(chart, totalData = false, noTranslationForLabels = false) {
      const data = []
      if (totalData) {
        data.push({
          label: noTranslationForLabels ? totalData.label : TranslateService.get(totalData.label),
          value: totalData.value
        })
      }
      _.each(chart.data.datasets, (dataset) => {
        // console.warn(`chart labels = `, chart.data.chartSelectorLabels)
        _.each(dataset.data, (d, i) => {
          data.push({
            label: _.get(
              chart,
              noTranslationForLabels ? `data.labels[${i}]` : `data.chartSelectorLabels[${i}]`,
              'T_NO_LABEL'
            ),
            value: d
          })
        })
      })
      if (chart.data.datasets.length > 1) {
        // NOTE: Sums up the values between the different datasets
        return _.map(_.groupBy(data, 'label'), (date, label) => {
          return {
            label,
            value: _.sumBy(date, 'value')
          }
        })
      }
      return data
    },
    getIndexForSubSection(id) {
      const foundIndex = _.findIndex(this.filteredSubSections, { id: id })
      return foundIndex === -1 ? 1 : foundIndex + 1
    },
    getSectionCardTop(id) {
      return _.get(_.find(this.filteredSubSections, { id: id }), 'cardTop', {})
    },
    getAsNumber(item, key) {
      let value = Number(_.get(item, key, 0))
      if (_.isNaN(value)) {
        value = 0
      }
      return value
    },
    getTotalForExperiences(data) {
      const experiences = _.map(_.get(data, 'experiences', []), '_id')
      return _.sum(
        _.map(data.experiencesByDate, (date) => {
          return _.sum(
            _.map(date.visitsByExperience, (visitByExperience) => {
              return _.includes(experiences, visitByExperience.experience)
                ? visitByExperience.count
                : 0
            })
          )
        })
      )
    },
    setData(data) {
      if (!_.get(data, 'primaryData', false)) {
        return
      }
      _.each(data, (val, key) => {
        _.set(this, key, val)
      })
      if (!_.isNull(this.primaryData)) {
        // console.warn(`BaseSection - setData - loading = false`)
        if (!LoadingService.isLoading()) {
          this.loading = false
        }
        this.gotData()
        this.$forceUpdate()
      }
    },
    goToTab(tabIndex) {
      this.currentSubSection = tabIndex
      this.selectedSubView = 0
      this.checkCallResetChartsHighlights()
      this.changeRouteParam()
    },
    checkCallResetChartsHighlights() {
      if (_.isFunction(this.resetChartsHighlights)) {
        this.resetChartsHighlights()
      }
    },
    changeRouteParam(direction = false) {
      this.$route.params.section = this.$router
        .replace({
          params: _.merge(this.$route.params, {
            section: _.get(
              this.filteredSubSections,
              `[${!direction ? this.currentSubSection : this.currentSubSection + direction}].id`
            )
          }),
          query: this.$route.query
        })
        .catch(() => {})
    },
    gotData() {}
  }
}
</script>
