import axios from 'axios'
axios.defaults.headers.common['Authorization'] = ''
import _ from 'lodash'
import TranslateService from '@/services/TranslateService'
import DataService from '@/services/DataService'
class LoginService {
  constructor() {
    this.user = null
    this.logoutCallbackList = []
    setInterval(async () => {
      this.checkStatus()
    }, 1000 * 15)
    this.firstStatus = true
  }

  handleRequestError() {
    // NOTE: Deletes cookies
    console.warn(`Detected error during request, will delete cookies and redirect to homepage`)
    const cookies = document.cookie.split(';')
    for (var i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    }
    // NOTE: Redirects to /
    window.location.href = '/'
  }

  async getStatus() {
    try {
      const { data } = await axios.get('/api/login/status')
      this.user = data
      axios.defaults.headers.common['Authorization'] = _.get(data, 'jwt', '')
      if (this.firstStatus) {
        this.firstStatus = false
        TranslateService.setLocale(_.get(data, 'defaultLocale', 'zhCN'))
      }
    } catch (error) {
      this.user = null
      return false
    }
    return this.user
  }

  async checkStatus() {
    if (this.user) {
      let status
      try {
        status = await this.getStatus()
      } catch (error) {
        console.error(error)
      }
      if (_.isEmpty(status)) {
        await this.logout()
      }
    }
  }

  async login(username, password) {
    const { data } = await axios.post('/api/login', { username, password })
    this.user = data
    TranslateService.setLocale(_.get(data, 'defaultLocale', 'zhCN'))
    return data
  }

  async logout() {
    console.warn(`LOGOUT !`)
    this.user = null
    DataService.resetBrands()
    await axios.get('/api/logout')
    _.each(this.logoutCallbackList, (callback) => {
      callback()
    })
  }

  onLogout(callback) {
    this.logoutCallbackList.push(callback)
  }

  getPermission(key) {
    return _.get(this.user.access, key)
  }
}

export default new LoginService()
