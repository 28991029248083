<template>
  <v-app
    :class="[TranslateService.currentLocale, !user ? 'gradient-background' : '']"
    class="mobile"
  >
    <v-progress-linear v-if="!isLoaded" indeterminate />
    <template v-else>
      <nav-bar v-if="currentSectionKey !== 'pdf'" :user="user" :selected-dates="selectedDates" />
      <refresher v-if="isOnIOS() && currentSectionKey !== 'pdf'"></refresher>
      <v-main
        :class="{
          'no-scroll': showFullscreenOverlay,
          'no-margin-bottom': currentSectionKey === 'pdf'
        }"
      >
        <login-page v-if="!user" :user.sync="user" :success-login-callback="onLoginCallback" />
        <template v-else>
          <section v-if="currentSectionKey !== 'pdf'" id="date-picker">
            <pavilion-selector
              v-if="$router.currentRoute.name !== 'NotFoundPage'"
              :brands="brands"
              :selected-brand="selectedBrand"
              :selected-primary-pavilion="selectedPrimaryPavilion"
              :selected-secondary-pavilion="selectedSecondaryPavilion"
              :selected-dates.sync="selectedDates"
              :selected-days="selectedDays"
            ></pavilion-selector>
          </section>
          <router-view ref="routerView" />
          <sections-nav-bar
            v-if="$router.currentRoute.name !== 'NotFoundPage' && currentSectionKey !== 'pdf'"
            :compare="selectedSecondaryPavilion ? true : false"
          />
          <viewer-fullscreen
            :current-section-key="currentSectionKey"
            :current-fullscreen-slide="currentFullscreenSlide"
            :show-fullscreen-overlay="showFullscreenOverlay"
            :fullscreen-image="fullscreenImage"
            :fullscreen-content="fullscreenContent"
            :fullscreen-items="fullscreenItems"
            :close-callback="closeFullscreenViewer"
          />
        </template>
      </v-main>
    </template>
  </v-app>
</template>

<script>
import TranslateService from '@/services/TranslateService'

import Refresher from '@/components/Refresher'
import NavBar from '@/components/NavBar'
import SectionsNavBar from '@/components/SectionsNavBar'
import AppMixin from './mixins/AppMixin.vue'

export default {
  name: 'App',
  components: {
    Refresher,
    NavBar,
    SectionsNavBar
  },
  mixins: [AppMixin],
  data() {
    return {
      pullDownConfig: {
        errorLabel: TranslateService.get('T_ERROR_TEXT'),
        startLabel: TranslateService.get('T_PULL_TO_REFRESH'),
        readyLabel: TranslateService.get('T_RELEASE_TO_REFRESH'),
        loadingLabel: TranslateService.get('T_REFRESHING'),
        pullDownHeight: 150
      }
    }
  },
  methods: {
    isOnIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent)
    }
  }
}
</script>

<style lang="scss">
@import './vars.scss';
@import './app.scss';
.v-application.mobile {
  &.gradient-background {
    background: linear-gradient(180deg, #1b1f2d 0%, #8094dd 100%);
    .v-application--wrap {
      background-color: transparent;
    }
    .v-main {
      background-color: transparent;
    }
  }
}
</style>
