<template>
  <div id="sales-section">
    <section-loader v-if="loading" />
    <template v-else-if="primaryData">
      <div v-if="secondaryData" class="big-section-title">{{ 'T_SALES' | translate }}</div>
      <v-chip-group
        v-model="currentSubSection"
        background-color="transparent"
        mandatory
        dark
        class="sub-sections"
      >
        <v-chip
          v-for="(subSection, i) in filteredSubSections"
          :key="i"
          :color="i == currentSubSection ? 'cyan' : 'primary'"
          link
          @click="goToTab(i)"
        >
          {{ subSection.title | translate }}
        </v-chip>
      </v-chip-group>
      <div class="sub-sections-wrapper">
        <v-tabs-items v-if="primaryData" v-model="currentSubSection">
          <!-- Leads -->
          <v-tab-item class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter :current="1" :total="filteredSubSections.length" />
              <template v-if="!canCompareSubSection('leads')">
                <v-card class="sub-section no-data">
                  <cannot-compare-data />
                </v-card>
              </template>
              <template v-else>
                <v-card class="sub-section">
                  <card-top
                    :current-view.sync="selectedSubView"
                    :options="getSectionCardTop('leads')"
                  />
                  <div v-if="lineLeads" class="leads-by-date">
                    <template v-if="graphHasData(lineLeads)">
                      <chart-selector
                        :data="
                          getDataForChart(lineLeads, {
                            label: 'T_TOTAL_LEADS_GENERATED',
                            value: primaryData.total.leadsGeneration
                          })
                        "
                        :current-value.sync="highlightedLeadDay"
                        :center-label="secondaryData ? true : false"
                      />
                      <div v-if="secondaryData" class="compare-show-highlighted">
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="primaryData.pavilion.event.key"
                          :content="getTotalForShow(primaryData, 'leadsByDate', highlightedLeadDay)"
                          type="compare"
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[0],
                            primary: getTotalForShow(
                              primaryData,
                              'leadsByDate',
                              highlightedLeadDay
                            ),
                            secondary: getTotalForShow(
                              secondaryData,
                              'leadsByDate',
                              highlightedLeadDay
                            )
                          }"
                          italic
                        />
                        <v-divider vertical />
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="secondaryData.pavilion.event.key"
                          type="compare"
                          :content="
                            getTotalForShow(secondaryData, 'leadsByDate', highlightedLeadDay)
                          "
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[1]
                          }"
                          italic
                        />
                      </div>
                      <line-chart
                        v-if="primaryData && hasLeads(primaryData)"
                        :data="lineLeads.data"
                        :options="lineOrders.options"
                        :highlight-value="highlightedLeadDay"
                      />
                    </template>
                    <no-graph-data v-else />
                  </div>
                </v-card>
                <template v-if="secondaryData">
                  <compare-show-titles
                    :primary-data="primaryData"
                    :secondary-data="secondaryData"
                  />
                  <div class="compare-show-highlighted">
                    <content-card
                      no-gradient
                      dark-background
                      half-width
                      :title="primaryData.pavilion.event.key"
                      :content="getTotalForShow(primaryData, 'leadsByDate', 0)"
                      type="compare"
                      :compare-data="{
                        circleColor: ChartsDataService.barColors[0],
                        primary: getTotalForShow(primaryData, 'leadsByDate', 0),
                        secondary: getTotalForShow(secondaryData, 'leadsByDate', 0)
                      }"
                      italic
                    />
                    <v-divider vertical />
                    <content-card
                      no-gradient
                      dark-background
                      half-width
                      :title="secondaryData.pavilion.event.key"
                      type="compare"
                      :content="getTotalForShow(secondaryData, 'leadsByDate', 0)"
                      :compare-data="{
                        circleColor: ChartsDataService.barColors[1]
                      }"
                      italic
                    />
                  </div>
                </template>
              </template>
            </div>
          </v-tab-item>
          <!-- Orders -->
          <v-tab-item class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter :current="2" :total="filteredSubSections.length" />
              <template v-if="!canCompareSubSection('orders')">
                <v-card class="sub-section no-data">
                  <cannot-compare-data />
                </v-card>
              </template>
              <template v-else>
                <v-card class="sub-section">
                  <card-top
                    :current-view.sync="selectedSubView"
                    :options="getSectionCardTop('orders')"
                  />
                  <div v-if="primaryData" class="orders-by-date">
                    <template v-if="graphHasData(lineOrders) && hasOrders(primaryData)">
                      <chart-selector
                        :data="
                          getDataForChart(lineOrders, {
                            label: 'T_TOTAL_ORDERS_GENERATED',
                            value: primaryData.total.ordersGeneration
                          })
                        "
                        :center-label="secondaryData ? true : false"
                        :current-value.sync="highlightedOrderDay"
                      />
                      <line-chart
                        :data="lineOrders.data"
                        :options="lineOrders.options"
                        :highlight-value="highlightedOrderDay"
                      />
                    </template>
                    <no-graph-data v-else />
                  </div>
                </v-card>
                <template v-if="secondaryData && graphHasData(lineOrders)">
                  <compare-show-titles
                    :primary-data="primaryData"
                    :secondary-data="secondaryData"
                  />
                  <div class="compare-show-highlighted">
                    <content-card
                      no-gradient
                      dark-background
                      half-width
                      :title="primaryData.pavilion.event.key"
                      :content="getTotalForShow(primaryData, 'ordersByDate', 0)"
                      type="compare"
                      :compare-data="{
                        circleColor: ChartsDataService.barColors[0],
                        primary: getTotalForShow(primaryData, 'ordersByDate', 0),
                        secondary: getTotalForShow(secondaryData, 'ordersByDate', 0)
                      }"
                      italic
                    />
                    <v-divider vertical />
                    <content-card
                      no-gradient
                      dark-background
                      half-width
                      :title="secondaryData.pavilion.event.key"
                      type="compare"
                      :content="getTotalForShow(secondaryData, 'ordersByDate', 0)"
                      :compare-data="{
                        circleColor: ChartsDataService.barColors[1]
                      }"
                      italic
                    />
                  </div>
                </template>
              </template>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>
  </div>
</template>

<script>
import BaseSection from '@/mixins/BaseSection'
import SalesSection from '@/mixins/SalesSection'

export default {
  name: 'SalesSection',
  mixins: [BaseSection, SalesSection]
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';
#sales-section {
  .funnel-charts {
    display: inline-block;
    width: 100%;
  }
}
</style>
