<template>
  <div id="sales-section" :class="getDesktopWrapperClass()">
    <section-loader v-if="loading" />
    <div v-else-if="primaryData" class="sub-sections-wrapper">
      <div class="cards-row">
        <!-- Leads -->
        <div class="card-wrapper">
          <card-top :options="getDesktopTitle('T_LEADS')" divider />
          <v-card v-if="!canCompareSubSection('leads')" class="sub-section no-data">
            <cannot-compare-data />
          </v-card>
          <template v-else>
            <v-card class="sub-section">
              <card-top
                :current-view.sync="selectedSubView"
                :options="getSectionCardTop('leads')"
              />
              <div class="leads-by-date">
                <template v-if="graphHasData(lineLeads) && hasLeads(primaryData)">
                  <chart-selector
                    :data="
                      getDataForChart(lineLeads, {
                        label: 'T_TOTAL_LEADS_GENERATED',
                        value: primaryData.total.leadsGeneration
                      })
                    "
                    :current-value.sync="highlightedLeadDay"
                    :center-label="secondaryData ? true : false"
                  />
                  <div v-if="secondaryData" class="compare-show-highlighted">
                    <content-card
                      no-gradient
                      dark-background
                      half-width
                      :title="primaryData.pavilion.event.key"
                      :content="getTotalForShow(primaryData, 'leadsByDate', highlightedLeadDay)"
                      type="compare"
                      :compare-data="{
                        circleColor: ChartsDataService.barColors[0],
                        primary: getTotalForShow(primaryData, 'leadsByDate', highlightedLeadDay),
                        secondary: getTotalForShow(secondaryData, 'leadsByDate', highlightedLeadDay)
                      }"
                      italic
                    />
                    <v-divider vertical />
                    <content-card
                      no-gradient
                      dark-background
                      half-width
                      :title="secondaryData.pavilion.event.key"
                      type="compare"
                      :content="getTotalForShow(secondaryData, 'leadsByDate', highlightedLeadDay)"
                      :compare-data="{
                        circleColor: ChartsDataService.barColors[1]
                      }"
                      italic
                    />
                  </div>
                  <line-chart
                    v-if="primaryData && hasLeads(primaryData)"
                    :data="lineLeads.data"
                    :options="lineLeads.options"
                    :highlight-value="highlightedLeadDay"
                  />
                  <template v-if="secondaryData">
                    <div class="compare-show-highlighted">
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="primaryData.pavilion.event.key"
                        :content="getTotalForShow(primaryData, 'leadsByDate', 0)"
                        type="compare"
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[0],
                          primary: getTotalForShow(primaryData, 'leadsByDate', 0),
                          secondary: getTotalForShow(secondaryData, 'leadsByDate', 0)
                        }"
                        italic
                      />
                      <v-divider vertical />
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="secondaryData.pavilion.event.key"
                        type="compare"
                        :content="getTotalForShow(secondaryData, 'leadsByDate', 0)"
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[1]
                        }"
                        italic
                      />
                    </div>
                  </template>
                </template>
                <no-graph-data v-else />
              </div>
            </v-card>
          </template>
        </div>
        <!-- Orders -->
        <div class="card-wrapper">
          <card-top :options="getDesktopTitle('T_ORDERS')" divider />
          <v-card v-if="!canCompareSubSection('orders')" class="sub-section no-data">
            <cannot-compare-data />
          </v-card>
          <v-card v-else class="sub-section">
            <card-top
              :current-view.sync="selectedSubView"
              :options="{
                type: 'hidden',
                states: [
                  { title: 'T_ORDERS_GENERATION_BY_DATE', icon: '/img/calendar.a6935775.svg' }
                ]
              }"
            />
            <template v-if="graphHasData(lineOrders) && hasOrders(primaryData)">
              <div v-if="primaryData" class="orders-by-date">
                <chart-selector
                  :data="
                    getDataForChart(lineOrders, {
                      label: 'T_TOTAL_ORDERS_GENERATED',
                      value: primaryData.total.ordersGeneration
                    })
                  "
                  :center-label="secondaryData ? true : false"
                  :current-value.sync="highlightedOrderDay"
                />
                <line-chart
                  :data="lineOrders.data"
                  :options="lineOrders.options"
                  :highlight-value="highlightedOrderDay"
                />
              </div>
              <template v-if="secondaryData">
                <div class="compare-show-highlighted">
                  <content-card
                    no-gradient
                    dark-background
                    half-width
                    :title="primaryData.pavilion.event.key"
                    :content="getTotalForShow(primaryData, 'ordersByDate', 0)"
                    type="compare"
                    :compare-data="{
                      circleColor: ChartsDataService.barColors[0],
                      primary: getTotalForShow(primaryData, 'ordersByDate', 0),
                      secondary: getTotalForShow(secondaryData, 'ordersByDate', 0)
                    }"
                    italic
                  />
                  <v-divider vertical />
                  <content-card
                    no-gradient
                    dark-background
                    half-width
                    :title="secondaryData.pavilion.event.key"
                    type="compare"
                    :content="getTotalForShow(secondaryData, 'ordersByDate', 0)"
                    :compare-data="{
                      circleColor: ChartsDataService.barColors[1]
                    }"
                    italic
                  />
                </div>
              </template>
            </template>
            <no-graph-data v-else />
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSection from '@/mixins/BaseSection'
import SalesSection from '@/mixins/SalesSection'

export default {
  name: 'SalesSection',
  mixins: [BaseSection, SalesSection]
}
</script>

<style lang="scss" scoped>
@import '../../varsDesktop.scss';
#sales-section {
  .funnel-charts {
    display: inline-block;
    width: 100%;
  }
  .compare-show-highlighted {
    margin-top: 16px;
  }
}
</style>
