<template>
  <div class="percentages-disclaimer" :class="{ 'is-for-pdf': isForPdf }">
    {{ 'T_DEMOGRAPHY_PERCENTAGES_DISCLAIMER' | translate }}
  </div>
</template>
<script>
export default {
  props: {
    isForPdf: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../varsDesktop.scss';
.percentages-disclaimer {
  text-align: center;
  font-style: italic;
  color: $primary;
  font-size: 10px;
  line-height: 14px;
  &.is-for-pdf {
    color: black;
    font-size: 8px;
    line-height: 12px;
  }
  @media (max-width: 500px) {
    font-size: $scale-vw * 35;
    line-height: $scale-vw * 70;
    margin-bottom: 2.5vh;
  }
}
</style>
