<template>
  <div
    v-if="currentSectionKey !== 'pdf'"
    id="viewer-fullscreen"
    ref="viewer-fullscreen"
    :class="{ displayed: showFullscreenOverlay, 'is-for-desktop': isForDesktop }"
  >
    <div
      class="backdrop"
      :class="{ disabled: fullscreenItems.length > 0 }"
      v-on="fullscreenItems.length == 0 ? { click: closeCallback } : {}"
    >
      <div class="header" :class="{ 'on-top': fullscreenItems.length > 0 }">
        <div v-if="fullscreenContent.title" class="title">
          {{ fullscreenContent.title }}
        </div>
        <div v-else-if="fullscreenItems.length > 0" class="current-slide">
          {{ selectedSlide + 1 }}/{{ fullscreenItems.length }}
        </div>
        <v-spacer v-else></v-spacer>
        <div class="icon-close" @click="closeCallback()">
          <v-img
            src="@/assets/icons/close.svg"
            width="32"
            height="32"
            max-width="32"
            max-height="32"
          />
        </div>
      </div>
      <PinchScrollZoom
        v-if="fullscreenImage"
        :width="getViewer('width')"
        :height="getViewer('height')"
        min-scale="1"
        max-scale="5"
        :scale="1"
        style="border: 1px solid black"
      >
        <v-img
          :src="fullscreenImage"
          :lazy-src="fullscreenImage"
          class="img"
          :contain="isForDesktop"
        />
      </PinchScrollZoom>
      <div v-else-if="fullscreenContent.content" class="wrapper">
        <v-card>
          <div class="content">
            <template v-if="isArray(fullscreenContent.content)">
              <ul>
                <li v-for="(line, i) in fullscreenContent.content" :key="i">
                  <v-icon color="white" x-small max-width="8" class="mr-2">mdi-circle</v-icon
                  >{{ line }}
                </li>
              </ul>
            </template>
            <template v-else>
              {{ fullscreenContent.content }}
            </template>
          </div>
        </v-card>
      </div>
      <div v-else class="wrapper slideshow">
        <v-carousel
          ref="carousel"
          v-model="selectedSlide"
          hide-delimiters
          :show-arrows="isForDesktop"
          :height="isForDesktop ? '100%' : '100vh'"
          :touchless="isForDesktop"
          :contain="isForDesktop"
          @change="changedSlide"
        >
          <v-carousel-item v-for="(item, i) in fullscreenItems" :key="i" :contain="isForDesktop">
            <div class="carousel-item-wrapper">
              <template v-if="isType(item, 'video')">
                <div class="video-wrapper">
                  <div class="mask" :class="{ displayed: !fullScreenVideoPlaying }" />
                  <div
                    class="video-play-btn"
                    :class="{ displayed: !fullScreenVideoPlaying }"
                    @click="toggleVideoPlaying('video_' + i)"
                  >
                    <v-img src="@/assets/icons/play.svg" lazy-src="@/assets/icons/play.svg" />
                  </div>
                  <video :ref="'video_' + i" :src="item.url"></video>
                </div>
              </template>
              <v-img
                v-else-if="isType(item, 'image')"
                :src="item.url"
                :lazy-src="item.url"
                :contain="isForDesktop"
                max-height="100%"
                max-width="100%"
              />
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import PinchScrollZoom from '@coddicat/vue-pinch-scroll-zoom'
import Carousel from '@/mixins/Carousel.vue'

export default {
  components: {
    PinchScrollZoom
  },
  mixins: [Carousel],
  props: {
    currentSectionKey: {
      type: [String, Boolean],
      default: false
    },
    currentFullscreenSlide: {
      type: Number,
      default: 0
    },
    showFullscreenOverlay: {
      type: Boolean,
      default: false
    },
    fullscreenImage: {
      type: [String, Boolean],
      default: false
    },
    fullscreenContent: {
      type: [Object, Boolean],
      default: false
    },
    fullscreenItems: {
      type: [Array, Boolean],
      default: false
    },
    closeCallback: {
      type: Function,
      default: () => {}
    },
    isForDesktop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedSlide: 0,
      fullScreenVideoPlaying: false
    }
  },
  watch: {
    showFullscreenOverlay() {
      this.selectedSlide = this.currentFullscreenSlide
    },
    selectedSlide() {
      this.toggleVideoPlaying(false, false)
    }
  },
  mounted() {},
  methods: {
    isType(item, type) {
      return _.includes(_.get(item, '_contentType', false), type)
    },
    getViewer(prop) {
      const val = _.get(
        this.$refs,
        `['viewer-fullscreen'].${prop === 'width' ? 'clientWidth' : 'clientHeight'}`,
        0
      )
      return prop === 'height' ? val * 0.85 : val
    },
    toggleVideoPlaying(refId, forceState) {
      if (_.isUndefined(forceState)) {
        this.fullScreenVideoPlaying = !this.fullScreenVideoPlaying
      } else {
        this.fullScreenVideoPlaying = forceState
      }
      if (refId === false) {
        _.each(this.fullscreenItems, (item, i) => {
          if (this.isType(_.get(item, '_contentType', false), 'video')) {
            this.videoPlay(`video_${i}`, false)
          }
        })
        return
      }
      if (!_.get(this.$refs, refId, false)) {
        return console.warn(`No elem found`)
      }
      this.videoPlay(refId, this.fullScreenVideoPlaying)
    },
    isArray(content) {
      return _.isArray(content)
    },
    slideChanged() {
      this.toggleVideoPlaying(false, false)
    },
    videoPlay(refId, play) {
      if (play) {
        this.$refs[`${refId}`][0].play()
      } else {
        this.$refs[`${refId}`][0].pause()
        this.$refs[`${refId}`][0].currentTime = 0
      }
    },
    changedSlide(slideIndex) {
      if (slideIndex < 0) {
        slideIndex = _.get(this.fullscreenItems, 'length', 1) - 1
      } else if (slideIndex >= _.get(this.fullscreenItems, 'length', 0)) {
        if (_.get(this.fullscreenItems, 'length', 0) !== 0) {
          slideIndex = 0
        }
      }
      this.selectedSlide = _.isUndefined(slideIndex) ? 0 : slideIndex
      this.stopVideo()
    },
    stopVideo() {
      if (this.fullscreenItems.length > 0) {
        _.each(this.fullscreenItems, (item, i) => {
          if (
            _.get(item, '_contentType', '').indexOf('video') >= 0 &&
            _.get(this.$refs, `video_${i}`, false)
          ) {
            try {
              const ref = this.$refs[`video_${i}`]
              ref[0].pause()
              ref[0].currentTime = 0
            } catch (error) {
              console.warn(`couldn't stop video`, error)
            }
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../vars.scss';

#viewer-fullscreen {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  touch-action: none;
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
  background: rgba(32, 38, 60, 0.5);
  backdrop-filter: blur(50px);

  .backdrop {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  .img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    min-width: 50vw;
    max-width: 90vw;
    max-height: 90vh;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $standard-margin;

    .title {
      font-style: italic;
      font-weight: 400;
      font-size: $scale-vw * 25;
      line-height: $scale-vw * 31;
    }

    &.on-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 2;
    }
  }

  .v-card {
    padding: $standard-margin;
  }

  .wrapper {
    width: 90%;
    margin: 0 auto;

    .content {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
    }

    &.slideshow {
      width: 100%;
    }
  }

  video {
    max-width: 100vw;
    max-height: 100vh;
    margin: 0 auto;
  }

  &.displayed {
    pointer-events: auto;
    touch-action: auto;
    opacity: 1;
  }
  &.is-for-desktop {
    .header {
      padding: $desktop-standard-margin;
      .title {
        font-size: $desktop-scale-vw * 25;
        line-height: $desktop-scale-vw * 31;
      }
    }
    .v-card {
      padding: $desktop-standard-margin;
    }
  }
}
</style>
<style lang="scss">
#viewer-fullscreen {
  .icon-close {
    filter: drop-shadow(0px 0px 1px rgb(0 0 0));
  }
  &.is-for-desktop {
    .v-image__image--contain {
      background-size: contain;
      max-height: 100vh;
    }
    .wrapper.slideshow {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
