<script>
import _ from 'lodash'
import TranslateService from '@/services/TranslateService'
import ChartsDataService from '@/services/ChartsDataService'
import SubSectionsCounter from '@/components/SubSectionsCounter.vue'
import RadarChart from '@/components/Charts/RadarChart'
import CardTop from '@/components/CardTop'
import CompareShowTitles from '@/components/CompareShowTitles'
import CardWrapper from '@/components/CardWrapper'
import ContentCard from '@/components/ContentCard'
import BaseSection from '@/mixins/BaseSection'

export default {
  name: 'OverviewSectionMixin',
  components: {
    ChartsDataService,
    SubSectionsCounter,
    RadarChart,
    CardTop,
    CompareShowTitles,
    CardWrapper,
    ContentCard,
    BaseSection
  },
  data() {
    return {
      labels: false,
      circleColors: [ChartsDataService.barColors[0], ChartsDataService.barColors[1]],
      labelItems: [
        // {
        //   key: 'stand-traffic',
        //   label: 'T_STAND_TRAFFIC',
        //   field: 'total.standTraffic',
        //   link: 'traffic'
        // },
        {
          key: 'touchpoints-sessions',
          label: 'T_TOUCHPOINTS_SESSIONS',
          field: 'getTotalForExperiences',
          link: 'experience/interactions'
        },
        {
          key: 'orders',
          label: 'T_ORDERS',
          field: 'total.ordersGeneration',
          link: 'sales/orders'
        },
        {
          key: 'souvenir-retrieved',
          label: 'T_SOUVENIR_RETRIEVED',
          field: 'conversionFunnel.retrieved',
          link: 'experience/souvenir'
        },
        {
          key: 'voucher-redemption',
          label: 'T_VOUCHER_REDEMPTION',
          field: 'total.voucherRedeemed',
          link: 'experience/voucher'
        },
        {
          key: 'wechat-unique-visitors',
          label: 'T_WECHAT_UNIQUE_VISITORS',
          field: 'total.wechatUniqueVisit'
        },
        {
          key: 'leads-generation',
          label: 'T_LEADS_GENERATION',
          field: 'total.leadsGeneration',
          link: 'sales/leads'
        }
      ],
      dataList: false,
      subSections: [
        {
          title: 'T_PERFORMANCE',
          id: 'performance',
          cardTop: {
            type: 'hidden',
            states: [
              {
                title: 'T_PERFORMANCE'
              }
            ]
          }
        },
        {
          title: 'T_HIGHLIGHTS',
          id: 'highlights',
          cardTop: {
            type: 'hidden',
            states: [
              {
                title: 'T_HIGHLIGHTS'
              }
            ]
          }
        }
      ]
    }
  },
  async mounted() {
    // this.formatLabels()
    TranslateService.events.on('changeLanguage', () => {
      this.gotData()
    })
  },
  methods: {
    getTotalVal(data, key) {
      return _.get(data, `total.${key}`, false)
    },
    getMostPopularTouchpoint(data) {
      const experiences = _.map(
        _.groupBy(_.get(data, 'experiencePerformances', []), 'experience'),
        (records, experience) => {
          return {
            experience,
            total: _.sum(_.map(records, (record) => record.generated.count))
          }
        }
      )
      const foundExperience = _.find(_.get(data, 'experiences', []), {
        _id: _.get(_.maxBy(experiences, 'total'), 'experience', false)
      })
      return _.get(foundExperience, `${TranslateService.currentLocale}.name`, '')
    },
    formatLabels(hideOrders, hideLeads) {
      const labels = _.compact(
        _.map(this.labelItems, (item) => {
          if (_.includes(['orders', 'leads-generation'], item.key)) {
            const shouldHide = item.key === 'orders' ? hideOrders : hideLeads
            if (shouldHide) {
              return false
            }
          }
          return TranslateService.get(item.label)
        })
      )
      const newlabels = []
      // const invisibleSpace = TranslateService.currentLocale === 'zhCN' ? '\u3800' : '\u2000'
      const invisibleSpace = '\u2000'
      // console.warn(`invisible space =\u2000  ${invisibleSpace}`)
      const maxLen = this.getLabelMaxLength(labels)
      const half = _.get(labels, 'length', 0) % 2 === 0 ? labels.length / 2 : false
      _.each(labels, (label, i) => {
        if (i === 0 || (half !== false && i === half)) {
          newlabels.push(label)
        } else {
          newlabels.push(_.padEnd(labels[i], maxLen, invisibleSpace))
        }
      })
      this.labels = newlabels
    },
    getLabelMaxLength(labels) {
      return _.max(_.map(labels, (l) => _.get(l, 'length', 1) - 1))
    },
    getBestNameplate(data) {
      const nameplate = _.get(data, 'bestPerformingNameplate.traffic.vehicle.nameplate', false)
      const color = _.get(data, 'bestPerformingNameplate.traffic.vehicle.color', false)
      const nameParts = []
      if (_.get(nameplate, TranslateService.currentLocale, false)) {
        nameParts.push(_.get(nameplate, TranslateService.currentLocale, false))
      }
      if (_.get(nameplate, TranslateService.currentLocale, false)) {
        nameParts.push(_.get(color, TranslateService.currentLocale, false))
      }
      return _.join(nameParts, ' - ')
    },
    getConversion(conversionString) {
      return _.toNumber(_.get(_.split(conversionString, ':'), `[1]`, '1'))
    },
    rank(num, array) {
      return (
        _.clone(array)
          .sort((a, b) => b - a)
          .indexOf(num) + 1
      )
    },
    gotData() {
      this.dataList = false
      this.labels = false
      const radarData = this.secondaryData
        ? [this.primaryData, this.secondaryData]
        : [this.primaryData]
      let hideOrders = !this.hasOrders(_.first(radarData))
      let hideLeads = !this.hasLeads(_.first(radarData))
      this.dataList = _.map(radarData, (data) => {
        const eventYear = _.get(data, 'pavilion.event.year', false)
        if (!eventYear) {
          return false
        }
        let datasetData = []
        _.each(this.labelItems, (item) => {
          if (_.get(item, 'field', false) === 'getTotalForExperiences') {
            datasetData.push(this.getTotalForExperiences(data))
          } else if (
            (item.key === 'orders' && hideOrders) ||
            (item.key === 'leads-generation' && hideLeads)
          ) {
            console.info(`${item.key} ignored since pavilion option = false`)
          } else {
            datasetData.push(Number(_.get(data, item.field, 0)))
          }
        })
        // const maxVal = _.max(datasetData)
        const divider =
          Math.pow(10, `${_.min(datasetData)}`.split('.').length) *
          _.first(`${_.get(datasetData, 'length', 1)}`)
        const roundedData = _.map(
          datasetData,
          (val) => (val > 0 ? Math.round(val / divider) * divider : 0) + 1
        )
        datasetData = _.map(roundedData, (val) => {
          const countIf = _.filter(roundedData, (v) => v === val).length
          const rank = this.rank(val, roundedData)
          const currentRank = rank + countIf - 1
          return datasetData.length + 1 - currentRank
        })
        return {
          label: _.get(data, 'pavilion.event.key', eventYear),
          data: datasetData,
          contentCards: _.compact(
            _.map(this.labelItems, (item) => {
              if (_.get(item, 'field', false) === 'getTotalForExperiences') {
                return { item, value: this.getTotalForExperiences(data) }
              } else if (
                (item.key === 'orders' && hideOrders) ||
                (item.key === 'leads-generation' && hideLeads)
              ) {
                return false
              }
              return { item, value: _.get(data, item.field, 0) }
            })
          )
        }
      })
      this.formatLabels(hideOrders, hideLeads)
    }
  }
}
</script>
