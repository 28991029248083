<template>
  <div id="nav-bar">
    <a ref="download-excel" download class="download-excel hidden" href="" />
    <a ref="download-pdf" download class="download-pdf hidden" href="" />

    <v-menu
      offset-y
      dark
      tile
      min-width="100%"
      content-class="elevation-0"
      :close-on-content-click="false"
    >
      <template #activator="{ attrs, on }">
        <v-app-bar flat app dense height="56px">
          <!-- Login menu -->
          <div v-if="!user" class="menu-wrapper no-user">
            <v-img
              class="logo-imagination"
              src="@/assets/imagination-logo.svg"
              contain
              max-width="90"
              max-height="24"
              v-bind="attrs"
              v-on="on"
            />
            <div class="button" @click="onClickLanguage()">
              <v-img
                v-if="TranslateService.currentLocale === 'zhCN'"
                src="@/assets/icons/lang-cn.svg"
                max-height="24"
                max-width="24"
              />
              <v-img v-else src="@/assets/icons/lang-en.svg" max-height="24" max-width="24" />
            </div>
          </div>
          <!-- Menu when user logged in -->
          <div v-else-if="selectedBrand" class="menu-wrapper dark-background">
            <!-- Menu when nothing open -->
            <div
              v-if="!showMainMenu && !showEventsMenu && selectedBrand && !selectedSecondaryPavilion"
              class="button-canvas"
            >
              <div class="button switch" @click="toggleEventsMenu()">
                <v-img src="@/assets/icons/switch.svg" max-height="56" max-width="40" />
              </div>
              <div
                class="event-title"
                :class="{
                  'only-admin': selectedPrimaryPavilion && selectedPrimaryPavilion.disabled
                }"
              >
                <image-loader
                  v-if="selectedBrand.logo && selectedBrand.logo.topBar.small"
                  :src="selectedBrand.logo.topBar.small"
                  classes="brand-logo 3"
                  contain
                  max-width="40"
                  max-height="25"
                />
                <div
                  v-if="selectedPrimaryPavilion.event && selectedPrimaryPavilion.event.name"
                  class="name"
                >
                  {{ selectedPrimaryPavilion.event.name | translate }}
                  <template v-if="selectedPrimaryPavilion && selectedPrimaryPavilion.disabled">
                    <br />
                    <span>({{ 'T_ONLY_VISIBLE_FOR_ADMINS' | translate }})</span>
                  </template>
                </div>
              </div>
              <div v-if="shouldDisplayMenuIcon" class="button" @click="toggleMainMenu()">
                <div class="wrapper">
                  <v-img :src="require('@/assets/icons/menu.svg')" width="24" height="24" />
                </div>
              </div>
              <div v-else class="button" @click="returnToHomepage()">
                <v-img src="@/assets/icons/home.svg" max-width="24" max-height="24" />
              </div>
            </div>
            <!-- Menu for compare mode -->
            <div
              v-else-if="
                !showMainMenu && !showEventsMenu && selectedSecondaryPavilion && !showCompareMenu
              "
              class="button-canvas"
            >
              <div class="button switch" @click="onClickCompare()">
                <v-img src="@/assets/icons/switch.svg" max-height="56" max-width="40" />
              </div>
              <div class="compare-title">
                {{ 'T_COMPARE_EVENTS' | translate }}
                <div class="button" :class="{ hidden: changingPavilion }" @click="onClickClear()">
                  <v-img src="@/assets/icons/close.svg" max-width="24" max-height="24" />
                </div>
              </div>
            </div>
            <div v-else-if="showMainMenu" class="button-canvas">
              <div class="button" @click="onClickLanguage()">
                <v-img
                  v-if="TranslateService.currentLocale === 'zhCN'"
                  src="@/assets/icons/lang-cn.svg"
                  max-height="24"
                  max-width="24"
                />
                <v-img v-else src="@/assets/icons/lang-en.svg" max-height="24" max-width="24" />
              </div>
              <image-loader
                v-if="selectedBrand.logo && selectedBrand.logo.topBar.large"
                :src="selectedBrand.logo.topBar.large"
                contain
                classes="brand-logo 1 large"
                max-width="90"
                max-height="25"
              />
              <div class="button" :class="{ hidden: changingPavilion }" @click="toggleMainMenu()">
                <v-img src="@/assets/icons/close.svg" max-width="24" max-height="24" />
              </div>
            </div>
            <div v-else-if="showEventsMenu || showCompareMenu" class="button-canvas">
              <div class="button" @click="onClickLanguage()">
                <v-img
                  v-if="TranslateService.currentLocale === 'zhCN'"
                  src="@/assets/icons/lang-cn.svg"
                  max-height="24"
                  max-width="24"
                />
                <v-img v-else src="@/assets/icons/lang-en.svg" max-height="24" max-width="24" />
              </div>
              <image-loader
                v-if="selectedBrand.logo && selectedBrand.logo.topBar.large"
                :src="selectedBrand.logo.topBar.large"
                classes="brand-logo 2"
                contain
                max-width="90"
                max-height="25"
              />
              <div
                v-if="showEventsMenu"
                class="button"
                :class="{ hidden: changingPavilion }"
                @click="toggleEventsMenu()"
              >
                <v-img src="@/assets/icons/close.svg" max-width="24" max-height="24" />
              </div>
              <div v-else-if="showCompareMenu" class="button" @click="toggleMainMenu()">
                <v-img src="@/assets/icons/menu.svg" max-width="24" max-height="24" />
              </div>
            </div>
          </div>
        </v-app-bar>
      </template>
    </v-menu>
    <v-navigation-drawer
      v-model="showEventsMenu"
      color="white--text"
      fixed
      floating
      stateless
      class="blurred-blue"
      width="100%"
    >
      <brands-list
        v-if="getBrands().length > 1 && menuSelectedBrand"
        :brands="getBrands()"
        :selected-brand="menuSelectedBrand"
        :selected-brand-callback="setMenuSelectedBrand"
      />
      <events-menu
        v-if="getPavilionsPerYear()"
        :data="getPavilionsPerYear()"
        :selected-pavilion-callback="onClickPrimaryPavilion"
        :selected-pavilion="menuClickedPrimaryPavilion"
        :loading="changingPavilion"
        single-event
      />
      <div
        v-if="selectedPrimaryPavilion"
        class="compare-btn-wrapper"
        :class="{ displayed: menuClickedPrimaryPavilion && selectedPrimaryPavilion }"
      >
        <v-btn
          rounded
          color="secondary"
          :loading="changingPavilion"
          @click="
            menuClickedPrimaryPavilion._id === selectedPrimaryPavilion._id
              ? toggleEventsMenu()
              : confirmPrimaryPavilion()
          "
          >{{
            menuClickedPrimaryPavilion._id === selectedPrimaryPavilion._id
              ? 'T_CLOSE'
              : 'T_CONFIRM' | translate
          }}</v-btn
        >
      </div>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showMainMenu"
      color="white--text"
      fixed
      floating
      stateless
      width="100%"
      right
    >
      <div class="main-menu">
        <div class="menu-link" @click="onClickCompare()">
          <v-img src="@/assets/icons/compare.svg" max-width="64" max-height="64" />
          <span>{{ 'T_COMPARE' | translate }}</span>
        </div>
        <div class="menu-link" @click="onClickDownload()">
          <v-progress-circular
            v-if="downloadingFile"
            size="64"
            indeterminate
            color="white"
          ></v-progress-circular>
          <v-img
            v-else
            src="@/assets/icons/download-pdf.svg"
            lazy-src="@/assets/icons/download-pdf.svg"
            max-width="64"
            max-height="64"
          />

          <span>{{
            (downloadingFile === 'pdf' ? 'T_GENERATING_PDF' : 'T_DOWNLOAD_PDF') | translate
          }}</span>
          <span v-if="filteredDates" class="filtered">{{ 'T_FILTERED' | translate }}</span>
        </div>
        <div class="menu-link" @click="onClickDownloadExcel()">
          <v-progress-circular
            v-if="downloadingFile"
            size="64"
            indeterminate
            color="white"
          ></v-progress-circular>
          <v-img
            v-else
            src="@/assets/icons/download-excel.svg"
            lazy-src="@/assets/icons/download-excel.svg"
            max-width="64"
            max-height="64"
          />
          <span>{{
            (downloadingFile === 'excel' ? 'T_GENERATING_EXCEL' : 'T_DOWNLOAD_EXCEL') | translate
          }}</span>
          <span v-if="filteredDates" class="filtered">{{ 'T_FILTERED' | translate }}</span>
        </div>
        <div class="menu-link bottom" @click="onClickLogout()">
          <v-progress-circular
            v-if="downloadingFile"
            indeterminate
            color="white"
          ></v-progress-circular>
          <span v-else>{{ 'T_LOGOUT' | translate }}</span>
        </div>
      </div>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showCompareMenu"
      fixed
      bottom
      color="white--text"
      floating
      stateless
      class="compare-menu"
      width="100%"
    >
      <brands-list
        v-if="getBrands().length > 1 && menuSelectedBrand"
        :brands="getBrands()"
        :selected-brand="menuSelectedBrand"
        :selected-brand-callback="setMenuSelectedBrand"
      />
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="primary-pavilion">
            <div
              v-if="
                menuSelectedBrand && selectedBrand && menuSelectedBrand._id !== selectedBrand._id
              "
              class="event-name"
            >
              {{ 'T_SELECT_AN_AUTOSHOW' | translate }}
            </div>
            <div v-else-if="comparePavilions.primary" class="event-name">
              {{ comparePavilions.primary.event.name | translate }}
            </div>
            <div class="select-an-autoshow">
              {{ 'T_SELECT_AN_AUTOSHOW' | translate }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <events-menu
              v-if="getPavilionsPerYear()"
              :compare="comparePavilions.secondary"
              :loading="changingPavilion"
              :data="getPavilionsPerYear()"
              :selected-pavilion-callback="changeComparePrimaryPavilion"
              :selected-pavilion="comparePavilions.primary"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="compare-with">
              {{ 'T_COMPARE_WITH' | translate }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <events-menu
              v-if="getPavilionsPerYear()"
              :data="getPavilionsPerYear()"
              :loading="changingPavilion"
              :compare="comparePavilions.primary"
              :selected-pavilion-callback="changeCompareSecondaryPavilion"
              :selected-pavilion="comparePavilions.secondary"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div
        class="compare-btn-wrapper"
        :class="{ displayed: comparePavilions.primary && comparePavilions.secondary }"
      >
        <v-btn rounded :loading="changingPavilion" color="secondary" @click="compareShows()">{{
          'T_COMPARE' | translate
        }}</v-btn>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import TranslateService from '@/services/TranslateService'
import DataService from '@/services/DataService'
import LoginService from '@/services/LoginService'
import CompareDataService from '@/services/CompareDataService'
import PavilionSelectorService from '@/services/PavilionSelectorService'
import EventsMenu from '@/components/EventsMenu'
import BrandsList from '@/components/BrandsList'
import ImageLoader from '@/components/ImageLoader'

export default {
  components: { EventsMenu, BrandsList, ImageLoader },
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    selectedDates: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showEventsMenu: false,
      showMainMenu: false,
      showCompareMenu: false,
      selectedSecondEvent: false,
      query: null,
      TranslateService,
      CompareDataService,
      brands: [],
      selectedBrand: null,
      menuSelectedBrand: null,
      selectedPrimaryPavilion: null,
      selectedSecondaryPavilion: null,
      primaryData: null,
      shouldDisplayMenuIcon: true,
      secondaryData: null,
      comparePavilions: {
        primary: false,
        secondary: false
      },
      changingPavilion: false,
      menuClickedPrimaryPavilion: false,
      previousSection: false,
      downloadingFile: false
    }
  },
  computed: {
    filteredDates() {
      return (
        _.get(this.selectedPrimaryPavilion, 'event.startDate', false) !==
          _.get(this.selectedDates, '[0]', false) ||
        _.get(this.selectedPrimaryPavilion, 'event.endDate', false) !==
          _.get(this.selectedDates, '[1]', false)
      )
    }
  },
  watch: {
    '$route.query': function () {
      this.updateQuery()
    }
  },
  async mounted() {
    PavilionSelectorService.events.on('update-pavilion-data', this.setData)
    this.updateQuery()
    if (!_.isEmpty(await LoginService.getStatus())) {
      this.brands = await DataService.getBrands()
      // console.warn(`got brands !`, this.brands)
    }
  },
  beforeDestroy() {
    PavilionSelectorService.events.off('update-pavilion-data', this.setData)
  },
  methods: {
    changeComparePrimaryPavilion(pavilion) {
      this.comparePavilions.primary = pavilion
    },
    changeCompareSecondaryPavilion(pavilion) {
      this.comparePavilions.secondary = pavilion
    },
    setMenuSelectedBrand(brand) {
      // console.warn('setMenuSelectedBrand', brand.key)
      this.menuSelectedBrand = brand
      // this.selectedBrand = brand
    },
    getBrands() {
      return _.get(PavilionSelectorService, 'brands', false)
    },
    async compareShows() {
      if (!this.comparePavilions.primary || !this.comparePavilions.secondary) {
        return
      }
      this.changingPavilion = true
      await CompareDataService.setPrimaryPavilion(
        this.comparePavilions.primary,
        this.comparePavilions.secondary,
        true
      )
      this.updateCompareDataServiceData()
      this.changingPavilion = false
      this.closeAllMenus()
    },
    returnToHomepage() {
      this.$router.push('/event').catch(() => {})
    },
    setData(data) {
      _.each(data, (val, key) => {
        _.set(this, key, val)
      })
      this.isLoading = false
      this.$forceUpdate()
    },
    closeAllMenus() {
      this.showMainMenu = false
      this.showEventsMenu = false
      this.showCompareMenu = false
    },
    toggleMainMenu() {
      this.showMainMenu = !this.showMainMenu
      this.showCompareMenu = false
    },
    toggleEventsMenu() {
      this.selectedBrand = CompareDataService.primaryPavilion.brand
      this.menuSelectedBrand = _.cloneDeep(this.selectedBrand)
      this.menuClickedPrimaryPavilion = _.cloneDeep(this.selectedPrimaryPavilion)
      console.info(`brands to show `, this.brands)
      this.showEventsMenu = !this.showEventsMenu
    },
    getPavilionsPerYear() {
      const brand = _.get(this, 'menuSelectedBrand', this.selectedBrand)
      if (!_.get(brand, 'pavilions', false)) {
        return false
      }
      const tmp = _.groupBy(brand.pavilions, (p) => _.get(p, 'event.year', false))
      const orderedPavilions = []
      const years = _.reverse(_.sortBy(_.keys(tmp), (year) => year))
      _.each(years, (year) => {
        orderedPavilions.push({
          year,
          pavilions: _.reverse(_.sortBy(tmp[year], (pavilion) => _.get(pavilion, 'event.endDate')))
        })
      })
      return orderedPavilions
    },
    updateQuery() {
      this.shouldDisplayMenuIcon = this.$router.currentRoute.name !== 'NotFoundPage'
      const queryList = _.map(this.$route.query, (value, key) => `${key}=${value}`)
      this.query = `?${queryList.join('&')}`
    },
    async onClickComparePavilions() {
      this.showEventsMenu = false
    },
    async onClickClear() {
      await CompareDataService.setSecondaryPavilion(null)
      this.updateCompareDataServiceData()
    },
    async onClickPrimaryPavilion(pavilion) {
      if (this.changingPavilion) {
        return
      }
      this.menuClickedPrimaryPavilion = pavilion
    },
    async confirmPrimaryPavilion() {
      this.selectedPrimaryPavilion = this.menuClickedPrimaryPavilion
      this.changingPavilion = true
      await PavilionSelectorService.getData(
        {
          primaryPavilion: _.get(this.menuClickedPrimaryPavilion, '_id', false),
          secondaryPavilion: null
        },
        true,
        true
      )
      this.brands = PavilionSelectorService.brands
      const foundPavilion = this.findUpdatedPavilion(this.brands, this.menuClickedPrimaryPavilion)
      await CompareDataService.setPrimaryPavilion(foundPavilion, false, true)
      PavilionSelectorService.selectedPrimaryPavilion = foundPavilion
      this.updateCompareDataServiceData()
      this.changingPavilion = false
      this.toggleEventsMenu()
    },
    findUpdatedPavilion(brands, pavilion) {
      let foundPavilion = false
      _.each(brands, (brand) => {
        foundPavilion = _.find(brand.pavilions, { _id: _.get(pavilion, '_id', false) })
        if (!_.isUndefined(foundPavilion)) {
          return false
        }
      })
      return _.isUndefined(foundPavilion) ? false : foundPavilion
    },
    updateCompareDataServiceData() {
      this.selectedBrand = CompareDataService.primaryPavilion.brand
      this.menuSelectedBrand = _.cloneDeep(this.selectedBrand)

      this.comparePavilions.primary = _.get(CompareDataService, 'primaryPavilion', false)
      this.comparePavilions.secondary = _.get(CompareDataService, 'secondaryPavilion', false)
      this.selectedPrimaryPavilion = _.get(CompareDataService, 'primaryPavilion', false)
      this.selectedSecondaryPavilion = _.get(CompareDataService, 'secondaryPavilion', false)
      // console.warn('selectedPrimaryPavilion: ', this.selectedPrimaryPavilion)
      // console.warn('selectedSecondaryPavilion: ', this.selectedSecondaryPavilion)
      // NOTE: Redirects after show selection
      this.$router.push('/event').catch(() => {})
      console.warn(`will redirect to event section`)
      this.$forceUpdate()
    },
    onClickCompare() {
      this.showCompareMenu = !this.showCompareMenu
      this.updateCompareDataServiceData()
    },
    async onClickDownload() {
      if (this.downloadingFile) {
        return
      }
      try {
        this.downloadingFile = 'pdf'
        const blob = await PavilionSelectorService.downloadPDF(this.selectedDates)
        if (blob instanceof Blob) {
          const blobUrl = URL.createObjectURL(blob)
          const link = this.$refs['download-pdf']
          link.href = blobUrl
          link.download = this.getExportFilename('pdf')
          link.click()
        }
      } catch (error) {
        console.error(`onClickDownload - Error:`, error)
      }
      this.downloadingFile = false
    },
    async onClickDownloadExcel() {
      if (this.downloadingFile) {
        return
      }
      try {
        this.downloadingFile = 'excel'
        const blob = await PavilionSelectorService.downloadExcel(this.selectedDates)
        if (blob instanceof Blob) {
          const blobUrl = URL.createObjectURL(blob)
          const link = this.$refs['download-excel']
          link.href = blobUrl
          link.download = this.getExportFilename('xlsx')
          link.click()
        }
      } catch (error) {
        console.error(`onClickDownloadExcel - Error:`, error)
      }
      this.downloadingFile = false
    },
    getExportFilename(type) {
      return `${_.get(this.primaryData, 'pavilion.brand.key', 'export')}-${_.get(
        this.primaryData,
        'pavilion.event.key',
        false
      )}-${moment().format('YYYY-MM-DD')}.${type}`
    },
    drawerWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? 440 : 307
    },
    async onClickLogout() {
      if (this.downloadingFile) {
        return
      }
      try {
        await LoginService.logout()
        this.$router.push({ path: '/', query: {} }).catch(() => {})
        this.$router.go()
      } catch (error) {
        console.error(error)
      }
    },
    async onClickLanguage() {
      TranslateService.setLocale(TranslateService.currentLocale === 'enUS' ? 'zhCN' : 'enUS')
    }
  }
}
</script>

<style lang="scss">
@import '../vars.scss';
@import '../navBar.scss';
.compare-menu {
  .events-menu {
    .theme--light.v-btn {
      color: $primary;
    }
  }
}
#nav-bar .menu-wrapper.no-user {
  > *:first-child {
    margin-left: $scale-vw * 32;
  }
  > *:last-child {
    margin-right: $scale-vw * 32;
  }
}
#nav-bar .main-menu .menu-link span {
  &.filtered {
    font-size: $scale-vw * 12;
    // line-height: $scale-vw * 24;
    margin-left: $standard-margin;
  }
}
</style>
