<template>
  <div class="chart-selector disabled">
    <v-img
      src="@/assets/icons/circle-arrow.svg"
      width="16"
      height="16"
      max-width="16"
      max-height="16"
    />
    <span>{{ label | translate }}</span>
    <span class="value">{{ value }}</span>
    <v-img
      src="@/assets/icons/circle-arrow.svg"
      width="16"
      height="16"
      max-width="16"
      max-height="16"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';
.chart-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: $half-margin;
  gap: $standard-margin;
  background: $dark-background;
  border-radius: 50px;
  margin: $standard-margin 0;
  span {
    font-style: italic;
    font-weight: 400;
    font-size: $scale-vw * 12;
    line-height: $scale-vw * 15;
    text-align: left;
    color: white;
    width: 50%;
  }
  .value {
    font-style: normal;
    font-weight: 700;
    font-size: $scale-vw * 19;
    line-height: $scale-vw * 24;
    text-align: right;
  }
}
@media (min-width: 500px) {
  .chart-selector {
    padding: $desktop-half-margin;
    gap: $desktop-standard-margin;
    margin: $desktop-standard-margin 0;
    span {
      font-size: $desktop-scale-vw * 14;
      line-height: $desktop-scale-vw * 17;
    }
    .value {
      font-size: $desktop-scale-vw * 19;
      line-height: $desktop-scale-vw * 24;
    }
  }
}
</style>
<style lang="scss">
@import '../vars.scss';
.chart-selector.disabled {
  span {
    pointer-events: none;
    touch-action: none;
  }
  .v-image {
    touch-action: none;
    pointer-events: none;
    opacity: 0;
  }
}

@media (min-width: 500px) {
  span {
    font-weight: 400;
    pointer-events: none;
    touch-action: none;
  }
}
</style>
