<template>
  <div class="page-number">
    <div class="page-number-wrapper">
      <img src="@/assets/icons/card-counter-grey.png" height="25" width="25" />
      <span class="text">{{ pageNumber }}/{{ totalPages }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pageNumber: {
      type: Number,
      default: 1
    },
    totalPages: {
      type: Number,
      default: 5
    }
  }
}
</script>

<style scoped lang="scss">
.page-number {
  position: absolute;
  right: 0;
  bottom: 7px;
  color: white;
  transform: translateY(7px);
  z-index: 2;
  .page-number-wrapper {
    position: relative;
    width: 25px;
    height: 25px;
  }
  .v-image,
  .text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .text {
    z-index: 1000;
    // transform: translate(10%, 20%);
    display: inline-block;
    padding-top: 4px;
    padding-left: 6px;
    font-style: normal;
    font-family: 'SourceSansPro-Bold' !important;
    font-weight: 700;
    font-size: 6px;
    line-height: 25px;
    text-align: center;
    color: black;
  }
}
</style>
