<template>
  <div class="brands" :class="{ 'is-for-desktop': isForDesktop }">
    <v-chip-group v-model="localSelectedBrand" background-color="transparent" mandatory dark>
      <div
        v-for="brand in brands"
        :key="brand._id"
        class="brand"
        :class="{ selected: localSelectedBrand._id === brand._id }"
        @click="selectedBrandCallback(brand)"
      >
        <v-img
          v-if="brand.logo && brand.logo.light && localSelectedBrand._id === brand._id"
          max-height="56"
          max-width="120"
          :src="brand.logo.light"
          :lazy-src="brand.logo.light"
        />
        <v-img
          v-else-if="brand.logo && brand.logo.dark"
          max-height="56"
          max-width="120"
          :src="brand.logo.dark"
          :lazy-src="brand.logo.dark"
        />
      </div>
    </v-chip-group>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  props: {
    brands: {
      type: Array,
      default: () => []
    },
    selectedBrand: {
      type: Object,
      default: () => {}
    },
    selectedBrandCallback: {
      type: Function,
      default: () => {}
    },
    isForDesktop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localSelectedBrand: 0
    }
  },
  computed: {},
  watch: {
    selectedBrand() {
      this.localSelectedBrand = _.cloneDeep(this.selectedBrand)
    }
  },
  mounted() {
    this.localSelectedBrand = _.cloneDeep(this.selectedBrand)
  },
  methods: {}
}
</script>

<style scoped lang="scss">
@import '../vars.scss';
.brands {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .brand {
    background-color: white;
    transition: background-color 0.3s;
    min-height: $scale-vw * 56;
    min-width: $scale-vw * 120;
    display: flex;
    align-items: center;
    justify-content: center;
    &.selected {
      background-color: black;
    }
  }
  &.is-for-desktop {
    .brand {
      min-height: $desktop-scale-vw * 56;
      min-width: $desktop-scale-vw * 120;
    }
  }
}
</style>

<style lang="scss">
.brands {
  .v-chip-group .v-slide-group__content {
    padding: 0;
  }
}
</style>
