<template>
  <div v-if="primaryData" id="pdf-event-section" class="pdf-sub-section">
    <div class="section-sidebar">
      <div class="section-title">
        {{ 'T_EVENT_INFORMATION' | translate }}
      </div>
    </div>
    <div class="section-content">
      <div class="cards-row">
        <div v-if="primaryData.pavilion && primaryData.pavilion.gallery" class="pavilion-gallery">
          <img
            v-if="primaryData.pavilion.gallery[0]"
            class="first-image"
            :src="primaryData.pavilion.gallery[0]"
          />
          <div class="other-images">
            <img v-if="primaryData.pavilion.gallery[0]" :src="primaryData.pavilion.gallery[0]" />
            <img v-if="primaryData.pavilion.gallery[1]" :src="primaryData.pavilion.gallery[1]" />
          </div>
        </div>
        <div class="info-wrapper">
          <div class="title-show" :class="{ zhCN: getCurrentLocale() === 'zhCN' }">
            {{ getTitle(primaryData.pavilion.name) }}
          </div>
          <pavilion-info
            :pavilion="primaryData.pavilion"
            is-for-pdf
            :filtered-dates="filteredDates"
          />
        </div>
      </div>
      <img class="pdf-divider" src="@/assets/divider.svg" />
      <!-- Voices of media -->
      <!-- <div v-if="primaryData.pavilion" class="cards-row">
        <div
          v-if="primaryData.pavilion && primaryData.pavilion.voiceOfMedia"
          class="voices-of-media"
        >
          <div class="section-title">{{ 'T_VOICES_OF_MEDIA' | translate }}</div>
          <div class="content">
            {{ primaryData.pavilion.voiceOfMedia | translate }}
          </div>
        </div>
        <div class="press-and-media">
          <div v-if="hasField(primaryData.pavilion, 'pressConference')" class="press-conference">
            <div class="section-title">{{ 'T_PRESS_CONFERENCE' | translate }}</div>
            <div class="content">
              <ul>
                <li
                  v-for="(item, i) in translateArray(primaryData.pavilion, 'pressConference')"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
          <div v-if="hasField(primaryData.pavilion, 'mediaAttendees')" class="media-attendancees">
            <div class="section-title">{{ 'T_MEDIA_ATTENDANCEES' | translate }}</div>
            <div class="content">
              <ul>
                <li
                  v-for="(item, i) in translateArray(primaryData.pavilion, 'mediaAttendees')"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <img class="pdf-divider" src="@/assets/divider.svg" /> -->
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import CompareDataService from '@/services/CompareDataService'
import AnalyticDataService from '@/services/AnalyticDataService'
import TranslateService from '@/services/TranslateService'
import BaseSection from '@/mixins/BaseSection'
import PavilionInfo from '@/views/pavilion/PavilionInfo.vue'

export default {
  name: 'Event',
  components: {
    PavilionInfo
  },
  mixins: [BaseSection],
  data() {
    return {
      CompareDataService,
      filteredDates: false
    }
  },
  async mounted() {
    this.filteredDates = AnalyticDataService.pdfDates
  },
  methods: {
    gotData() {},
    getCurrentLocale() {
      return TranslateService.currentLocale
    },
    getTitle(name) {
      return _.get(name, TranslateService.currentLocale, '')
    }
  }
}
</script>

<style lang="scss">
@import '../../vars.scss';

#pdf-event-section {
  .section-sidebar {
    .section-title {
      margin-top: 100px;
    }
  }
  .cards-row {
    margin: 0;
  }
  .pavilion-gallery,
  .info-wrapper {
    width: 45%;
    min-height: 101px;
  }
  .pavilion-gallery {
    max-width: 268px;
  }
  .info-wrapper {
    max-width: 219px;
  }
  .pavilion-gallery {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    img {
      border-radius: 4px;
      // height: 101px;
      // width: auto;
    }
    .first-image {
      margin-right: 4px;
      width: 180px;
      height: 101px;
    }
    .other-images {
      display: flex;
      flex-direction: column;
      img {
        height: 48px;
        max-width: 84px;
        width: auto;
        &:first-child {
          margin-bottom: 4px;
        }
      }
    }
  }
  .title-show {
    font-family: SourceSansPro;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    &.zhCN {
      margin-bottom: 8px;
    }
  }
  .media-attendancees {
    width: 70%;
  }
  .press-conference {
    width: 30%;
    font-style: italic;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    ul {
      list-style: none; /* Remove default bullets */
      padding-left: 16px;
    }

    ul li::before {
      content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: black; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  }

  .pavilion-info {
    .info-box {
      .v-image {
        max-width: 8px !important;
        max-height: 8px !important;
        margin-right: 4px;
      }
      p {
        font-family: SourceSansPro;
        font-style: normal;
        font-weight: 400;
        font-size: 8px;
        line-height: 10px;
        margin-bottom: 4px;
      }
    }
  }
  .voices-of-media {
    font-style: italic;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
  }
  .voices-of-media {
    width: calc((100% - 5%) / 3);
  }
  .press-and-media {
    width: calc((100% - 5%) / 3 * 2);
    display: flex;
    justify-content: space-between;
  }
}
</style>
