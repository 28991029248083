<template>
  <div id="traffic-section-page-4" class="pdf-sub-section">
    <div class="section-sidebar">
      <div class="section-title">
        {{ 'T_STAND_TRAFFIC' | translate }}
      </div>
    </div>
    <div v-if="primaryData && hasStayAverage(primaryData)" class="section-content">
      <div class="info-box">
        <div class="section-title">{{ 'T_STAY_AVERAGE_PER_DAY' | translate }}</div>
        <p class="total-traffic">
          {{ 'T_AVERAGE_PER_DAY' | translate }}:
          <span>{{ getStayAverage() }}</span>
        </p>
        <bar-chart
          is-for-pdf
          :data="barStayAverage.data"
          :options="barStayAverage.options"
          :highlight-value="0"
        />
      </div>
      <img class="pdf-divider" src="@/assets/divider.svg" />
      <div class="section-title">
        {{ 'T_STAY_AVERAGE_PER_HOUR' | translate }}
      </div>
      <bar-chart
        is-for-pdf
        :data="barStayAverageHour.data"
        :options="barStayAverageHour.options"
        :highlight-value="0"
      />
    </div>
  </div>
</template>

<script>
// import _ from 'lodash'
import BaseSection from '@/mixins/BaseSection'
import TrafficSection from '@/mixins/TrafficSection'
import BarChart from '@/components/Charts/BarChart.vue'

export default {
  name: 'Traffic',
  components: { BarChart },
  mixins: [BaseSection, TrafficSection],
  data() {
    return {
      pdfPage: 'traffic-4',
      barStayAverage: false,
      barStayAverageHour: false
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';
#traffic-section-page-4 {
  .sub-section {
    display: flex;
    align-items: flex-start;
  }
  .total-traffic {
    font-style: italic;
    font-weight: normal;
    font-size: 8px;
    line-height: 10px;
    margin-bottom: 8px;
    span {
      font-weight: bold;
    }
  }
  .bar-chart {
    height: 145px !important;
  }
}
</style>
