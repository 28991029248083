<template>
  <div id="traffic-section-page-2" class="pdf-sub-section">
    <div class="section-sidebar">
      <div class="section-title">
        {{ 'T_STAND_TRAFFIC' | translate }}
      </div>
    </div>
    <div v-if="primaryData" class="section-content">
      <template v-if="hasHeatmap(primaryData)">
        <div class="traffic-by-date">
          <div class="info-box">
            <div class="section-title big">{{ 'T_HEATMAP' | translate }}</div>
            <heatmap :is-for-pdf="true" :data="primaryData" :show-tooltips="false" />
          </div>
        </div>
        <img class="pdf-divider" src="@/assets/divider.svg" />
      </template>
      <div class="section-title big">
        {{ 'T_AREA_POPULARITY' | translate }}
      </div>
      <div class="cards-row">
        <div v-if="graphHasData(pieAreas)" class="per-area">
          <pdf-pie-chart v-if="pieAreas" :data="pieAreas.data" :options="pieAreas.options" />
        </div>
        <areas-table v-if="primaryData" :data="primaryData" show-unique-visitors />
        <div v-if="showMaxDataReached" class="max-data-reached">
          {{ 'T_MAX_DATA_REACHED' | translate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Heatmap from '@/components/Heatmap.vue'
import BaseSection from '@/mixins/BaseSection'
import PdfPieChart from '@/components/Charts/PdfPieChart.vue'
import AreasTable from '@/components/AreasTable'

export default {
  name: 'Traffic',
  components: {
    PdfPieChart,
    Heatmap,
    AreasTable
  },
  mixins: [BaseSection],
  data() {
    return {
      pdfPage: 'traffic-2',
      pieAreas: false,
      tableHeaders: ['T_RANK', 'T_AREA_NAME', 'T_UNIQUE_VISITORS'],
      showMaxDataReached: false,
      maxAreasToDisplay: 20,
      tableData: false
    }
  },
  methods: {
    hasHeatmap(data) {
      return _.get(data, 'pavilion.hasHeatmap', false)
    },
    gotData() {
      const areas = _.get(this.primaryData, 'abdAreas', [])
      let trafficByArea = _.groupBy(_.get(this.primaryData, 'abdTrafficAreas', []), 'area')
      let rank = 0
      trafficByArea = _.map(trafficByArea, (data, areaId) => {
        const foundArea = _.find(areas, { _id: areaId })
        return {
          rank: ++rank,
          name: _.get(foundArea, 'name', _.get(foundArea, 'abdName', '')),
          value: _.sum(_.map(data, 'count'))
        }
      })
      if (_.get(trafficByArea, 'length', 0) > this.maxAreasToDisplay) {
        trafficByArea = _.take(trafficByArea, this.maxAreasToDisplay)
        this.showMaxDataReached = true
      }
      trafficByArea = _.orderBy(trafficByArea, ['value'], ['desc'])
      _.each(trafficByArea, (area, i) => {
        area.rank = i + 1
      })
      const ranks = _.map(trafficByArea, 'rank')
      this.pieAreas = {
        data: {
          indexes: ranks,
          labels: ranks,
          datasets: [
            {
              index: ranks,
              data: _.map(trafficByArea, 'value')
            }
          ]
        }
      }
      this.tableData = trafficByArea
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';
#traffic-section-page-2 {
  .heatmap-wrapper {
    display: block;
    width: 50%;
    margin: 0 auto;
  }
}
</style>
