<script>
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import TranslateService from '@/services/TranslateService'
import ChartsDataService from '@/services/ChartsDataService'
import CardTop from '@/components/CardTop.vue'
import SubSectionsCounter from '@/components/SubSectionsCounter.vue'
import LineChart from '@/components/Charts/LineChart.vue'
import BarChart from '@/components/Charts/BarChart.vue'
import FunnelChart from '@/components/FunnelChart.vue'
import CustomList from '@/components/CustomList.vue'
import CannotCompareData from '@/components/CannotCompareData.vue'
import PavilionExperiences from '@/views/pavilion/PavilionExperiences.vue'
import ContentCard from '@/components/ContentCard.vue'
import CardWrapper from '@/components/CardWrapper.vue'
import SubTypeSelector from '@/components/SubTypeSelector.vue'
import ChartSelector from '@/components/ChartSelector.vue'
import CompareShowTitles from '@/components/CompareShowTitles'

export default {
  name: 'ExperienceSectionMixin',
  components: {
    BarChart,
    LineChart,
    CustomList,
    PavilionExperiences,
    ContentCard,
    CardWrapper,
    CannotCompareData,
    ChartSelector,
    CompareShowTitles,
    CardTop,
    SubTypeSelector,
    FunnelChart,
    SubSectionsCounter
  },
  props: {
    pdfArea: {
      type: [String, Boolean],
      default: false
    },
    pdfExperience: {
      type: [String, Boolean],
      default: false
    }
  },
  data() {
    return {
      subSections: [
        {
          title: 'T_ACTIVATIONS',
          id: 'activations'
        },
        {
          title: 'T_INTERACTIONS',
          id: 'interactions',
          cardTop: {
            type: 'switch',
            states: [
              {
                title: 'T_GUEST_INTERACTIONS_BY_DATE',
                icon: this.getIcon('calendar')
              },
              {
                title: 'T_GUEST_INTERACTIONS_BY_HOUR',
                icon: this.getIcon('hour')
              }
            ]
          }
        },
        {
          title: 'T_VOUCHER',
          id: 'voucher',
          cardTop: {
            type: 'hidden',
            states: [
              {
                title: 'T_VOUCHERS_GENERATION_AND_REDEMPTION'
              }
            ]
          }
        },
        {
          title: 'T_SOUVENIR',
          id: 'souvenir',
          cardTop: {
            type: 'hidden',
            states: [
              {
                title: 'T_SOUVENIR_INFORMATION'
              }
            ]
          }
        },
        {
          title: 'T_CONVERSION',
          id: 'conversion',
          cardTop: {
            type: 'hidden',
            states: [
              {
                title: 'T_CONVERSION_FUNNEL'
              }
            ]
          }
        }
      ],
      heatmap: false,
      heatmap2: false,
      bar: {},
      barVoucher: {},
      line: false,
      lineSouvenir: false,
      funnel1: false,
      funnel2: false,
      highlightedInteractionDayValue: 0,
      selectedDate: 0,
      highlightedInteractionHourValue: 0,
      selectedHour: 0,
      highlightedVoucherDayValue: 0,
      selectedVoucherDate: 0,
      selectedVoucherType: 'generated',
      voucherSubTypes: [
        {
          label: 'T_GENERATED_VOUCHERS',
          key: 'generated'
        },
        {
          label: 'T_REDEEMED_VOUCHERS',
          key: 'redeemed'
        }
      ],
      highlightedSouvenirDayValue: 0,
      selectedSouvenirDate: 0,
      highlightedSouvenirTouchpointValue: 0,
      highlightedSouvenirTouchpointValue2: 0,
      allLoaded: false,
      selectedSouvenirTouchpoint: 0,
      selectedSouvenirTouchpoint2: 0,
      selectedSouvenirType: 'generated',
      souvenirSubTypes: [
        {
          label: 'T_GENERATED',
          key: 'generated'
        },
        {
          label: 'T_RETRIEVED',
          key: 'retrieved'
        },
        {
          label: 'T_PLAYED',
          key: 'played'
        },
        {
          label: 'T_SHARED',
          key: 'shared'
        },
        {
          label: 'T_DOWNLOADED',
          key: 'downloaded'
        }
      ],
      voucherList: false,
      souvenirList: false,
      ChartsDataService
    }
  },

  watch: {
    highlightedInteractionHourValue() {
      this.selectedHour = _.get(
        this.bar,
        `data.chartSelectorLabels[${this.highlightedInteractionHourValue - 1}]`,
        0
      )
    },
    highlightedInteractionDayValue() {
      this.selectedDate = _.get(
        this.line,
        `data.chartSelectorLabels[${this.highlightedInteractionDayValue - 1}]`,
        0
      )
    },
    highlightedVoucherDayValue() {
      this.selectedVoucherDate = _.get(
        this.barVoucher,
        `data.chartSelectorLabels[${this.highlightedVoucherDayValue - 1}]`,
        0
      )
      this.barVoucher = this.getVoucherData(this.primaryData, this.secondaryData)
      this.generateLabelsForLists()
    },
    highlightedSouvenirDayValue() {
      this.selectedSouvenirDate = _.get(
        this.lineSouvenir,
        `data.chartSelectorLabels[${this.highlightedSouvenirDayValue - 1}]`,
        0
      )
      this.generateLabelsForLists()
    },
    highlightedSouvenirTouchpointValue() {
      this.selectedSouvenirTouchpoint = _.get(
        this.getExperiencesWithSouvenir(this.primaryData),
        `[${this.highlightedSouvenirTouchpointValue - 1}]._id`,
        0
      )
      this.generateLabelsForLists()
      this.lineSouvenir = this.getLineSouvenirData(this.primaryData, this.secondaryData)
    },
    highlightedSouvenirTouchpointValue2() {
      this.selectedSouvenirTouchpoint2 = _.get(
        this.primaryData,
        `experiences[${this.highlightedSouvenirTouchpointValue2 - 1}]._id`,
        0
      )
      this.funnel1 = this.getFunnel(this.primaryData)
      this.funnel2 = this.getFunnel(this.secondaryData)
    },
    selectedHour() {
      this.bar.data = this.updateHourlyInteractionData()
    },
    selectedDate() {
      this.bar.data = this.updateHourlyInteractionData()
    },
    selectedVoucherType() {
      this.barVoucher = this.getVoucherData(this.primaryData, this.secondaryData)
    },
    selectedSouvenirType() {
      this.lineSouvenir = this.getLineSouvenirData(this.primaryData, this.secondaryData)
    },
    filteredSubSections() {
      if (this.hasNoHourlyData(this.primaryData) && this.hasSubSection('interactions')) {
        const index = this.getIndexForSubSection('interactions')
        this.filteredSubSections[index].cardTop.type = 'hidden'
        this.filteredSubSections[index].cardTop.states = [
          {
            title: 'T_GUEST_INTERACTIONS_BY_DATE',
            icon: this.getIcon('calendar')
          }
        ]
      }
    }
  },
  mounted() {
    TranslateService.events.on('changeLanguage', this.generateLabelsForLists)
  },
  methods: {
    getExperiencesWithSouvenir(data) {
      return _.filter(
        _.get(data, 'experiences', []),
        (experience) => _.get(experience, 'souvenirTypes.length', 0) > 0
      )
    },
    getTouchpointsList(data, allDataLabel, getAllExperiences = false) {
      let experiences = []
      if (getAllExperiences) {
        experiences = _.get(data, 'experiences', [])
      } else {
        experiences = this.getExperiencesWithSouvenir(data)
      }
      const touchpointsList = _.map(experiences, (experience) => {
        return {
          label: _.get(experience, `${TranslateService.currentLocale}.name`, ''),
          value: 0
        }
      })
      if (experiences.length === 1) {
        return touchpointsList
      }
      return _.concat(
        [
          {
            label: TranslateService.get(allDataLabel.label)
          }
        ],
        touchpointsList
      )
    },
    formatPercentage(val) {
      return `${Math.round(_.get(val, 'value', val) * 100) / 100}%`
    },
    generateLabelsForLists() {
      this.voucherList = false
      this.souvenirList = false
      this.funnel1 = false
      if (this.secondaryData) {
        this.funnel2 = false
      }
      this.$nextTick(() => {
        this.voucherList = this.getList(this.barVoucher, this.highlightedVoucherDayValue)
        this.souvenirList = this.getList(this.lineSouvenir, this.highlightedSouvenirDayValue)
        this.funnel1 = this.getFunnel(this.primaryData)
        if (this.secondaryData) {
          this.funnel2 = this.getFunnel(this.secondaryData)
        }
        this.$forceUpdate()
      })
    },
    filterIfNoDataPerHour(cardTopData) {
      const nbDataPerHour = _.sum(
        _.map(_.get(this.primaryData, 'experiencesByDate', []), (date) => {
          return _.get(date, 'visitsByHour.length', 0)
        })
      )
      if (nbDataPerHour === 0) {
        cardTopData.type = 'hidden'
        console.info(`Interactions per hour hidden since no data`)
      }
      return cardTopData
    },
    getTotalVoucher(data, type, highlightedVoucherDayValue = false) {
      if (highlightedVoucherDayValue) {
        return _.toNumber(
          _.get(
            data,
            `vouchersByDate[${this.highlightedVoucherDayValue - 1}.countByAction.${type}`,
            0
          )
        )
      }
      return _.toNumber(
        _.sum(
          _.map(_.get(data, 'vouchersByDate', []), (item) =>
            _.get(item, `countByAction.${type}`, 0)
          )
        )
      )
    },
    getVoucherChartSelectorData() {
      const data = [
        {
          label: TranslateService.get('T_TOTAL_REDEMPTION_RATE'),
          value: this.getVoucherRedemptionRate(
            this.primaryData,
            this.highlightedVoucherDayValue,
            true
          )
        }
      ]
      _.each(_.get(this.barVoucher, 'data.datasets[0].data', []), (generated, i) => {
        const redeemed = _.get(this.barVoucher, `data.datasets[1].data[${i}]`, 0)
        const value = (redeemed / generated) * 100
        data.push({
          label: _.get(this.barVoucher, `data.labels[${i}]`, 'NO LABEL'),
          value: value > 100 ? 100 : value
        })
      })
      return data
    },
    getVoucherRedemptionRate(data, highlightedVoucherDayValue = false, returnAsNumber = false) {
      const redemptionRate = numeral(
        (this.getTotalVoucher(data, 'redeemed', highlightedVoucherDayValue) /
          this.getTotalVoucher(data, 'generated', highlightedVoucherDayValue)) *
          100
      ).format('0.00')
      return returnAsNumber ? redemptionRate : `${redemptionRate}%`
    },
    getTotalForDataset(chart, datasetIndex) {
      return _.sum(_.get(chart, `data.datasets[${datasetIndex}].data`, []))
    },
    getFunnel(data) {
      if (!data) {
        return false
      }
      return {
        labels: this.getConversionFunnelLabels(),
        data: this.getFunnelData(data)
      }
    },
    getConversionFunnelLabels() {
      let labels = [
        'T_CAPACITY',
        'T_ACTIVATION_COMPLETED',
        'T_SOUVENIR_RETRIEVED',
        'T_SOUVENIR_PLAYED',
        'T_SOUVENIR_SHARED',
        'T_SOUVENIR_DOWNLOADED'
      ]
      if (!this.shouldDisplaySouvenirs(this.primaryData)) {
        labels = ['T_CAPACITY', 'T_ACTIVATION_COMPLETED']
      } else if (!this.shouldDisplayPlayedSouvenirs(this.primaryData)) {
        labels = _.filter(labels, (label) => label !== 'T_SOUVENIR_PLAYED')
      }
      return _.map(labels, (label) => TranslateService.get(label))
    },
    getFunnelData(data) {
      let { capacity, generated, retrieved, played, shared, downloaded } = _.get(
        data,
        'conversionFunnel',
        {}
      )
      const hasSelectedATouchpoint =
        this.selectedSouvenirTouchpoint2 !== 0 || _.get(data, 'experiences.length', 0) === 1
      if (hasSelectedATouchpoint) {
        const experienceDataPerDay = _.filter(_.get(data, 'experiencePerformances', []), {
          experience: this.selectedSouvenirTouchpoint2
        })
        const totalForExperience = {}
        _.each(experienceDataPerDay, (day) => {
          _.map(['generated', 'retrieved', 'played', 'shared', 'downloaded'], (key) => {
            _.set(
              totalForExperience,
              key,
              _.get(totalForExperience, key, 0) + _.get(day, `${key}.count`, 0)
            )
          })
        })
        capacity = _.get(
          _.find(_.get(data, 'experiences', []), { _id: this.selectedSouvenirTouchpoint2 }),
          'capacity',
          0
        )
        generated = totalForExperience.generated
        retrieved = totalForExperience.retrieved
        played = totalForExperience.played
        shared = totalForExperience.shared
        downloaded = totalForExperience.downloaded
      }
      if (!this.shouldDisplaySouvenirs(this.primaryData)) {
        return [capacity, generated]
      } else if (!this.shouldDisplayPlayedSouvenirs(this.primaryData)) {
        return [capacity, generated, retrieved, shared, downloaded]
      }
      return [capacity, generated, retrieved, played, shared, downloaded]
    },
    getTotalForAllDays(data, obj, type) {
      return `${_.sum(
        _.map(_.get(data, obj, []), (d) => {
          return _.toNumber(_.get(d, `countByAction.${type}`, 0))
        })
      )}`
    },
    getTotalInteractionsByHour() {
      if (this.selectedHour === 0) {
        return {
          label: TranslateService.get('T_AVERAGE_PER_HOUR'),
          value: this.getAverageForDatasets(this.bar.data.datasets)
        }
      }
      let value = _.get(this.getOriginalTrafficData(this.primaryData), 'visits', 0)
      if (this.secondaryData) {
        value += _.get(this.getOriginalTrafficData(this.secondaryData), 'visits', 0)
      }
      return {
        label: this.selectedDate,
        value
      }
    },
    getList(chartObj, highlightValue) {
      return _.map(_.get(chartObj, 'data.datasets', []), (dataset, i) => {
        return {
          borderColor: ChartsDataService.lineColors[i],
          label: TranslateService.get(dataset.translationKey),
          value:
            highlightValue === 0
              ? _.sum(dataset.data)
              : _.get(dataset, `data[${highlightValue - 1}]`, 0)
        }
      })
    },
    getVoucherData(data, secondaryData = false) {
      if (!data) {
        return false
      }
      const voucherData = {
        data: {
          labels: this.getLabels(this.primaryData, this.secondaryData, 'vouchersByDate', false),
          chartSelectorLabels: this.getLabels(
            this.primaryData,
            this.secondaryData,
            'vouchersByDate',
            true
          ),
          datasets: []
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          },
          onClick: (evt, activeElements) => {
            if (activeElements.length === 0) {
              return
            }
            this.highlightedVoucherDayValue = activeElements[0].index + 1
            this.selectedVoucherDate = _.get(
              this.barVoucher,
              `data.labels[${activeElements[0].index}]`,
              0
            )
          }
        }
      }
      this.createVoucherDatasetsFromData(voucherData.data.datasets, data)
      if (secondaryData) {
        this.createVoucherDatasetsFromData(voucherData.data.datasets, secondaryData)
        _.set(voucherData.options, 'drawLineSelection', true)
        _.each(voucherData.data.datasets, (dataset, i) => {
          dataset.borderColor = ChartsDataService.barColors[i]
        })
      }
      return voucherData
    },
    createVoucherDatasetsFromData(datasets, data) {
      const types = [this.selectedVoucherType || 'generated']
      if (!this.secondaryData) {
        types.push('redeemed')
      }
      _.each(types, (key, i) => {
        const translationKey = `T_${_.toUpper(key)}`
        datasets.push({
          label: TranslateService.get(translationKey),
          translationKey,
          data: _.map(data.vouchersByDate, (voucherByDate) => {
            return _.get(voucherByDate, `countByAction.${key}`, 0)
          }),
          backgroundColor: ChartsDataService.barColors[i]
        })
      })
    },
    getExperiencesInteractions(perHour = false, data = false) {
      let formattedData = []
      formattedData = this.getExperiencesDatasets(
        data ? data : this.primaryData,
        data ? false : this.secondaryData,
        perHour
      )
      // console.warn(`getExperiencesInteractions `, perHour, data, formattedData)
      if (perHour) {
        return formattedData
      }
      formattedData = _.map(formattedData, (experience) => {
        return {
          borderColor: experience.borderColor,
          label: experience.label,
          value:
            this.highlightedInteractionDayValue === 0
              ? _.sum(experience.data)
              : _.get(experience, `data[${this.highlightedInteractionDayValue - 1}]`, 0)
        }
      })
      return formattedData
    },
    resetChartsHighlights(keepValues = false) {
      if (!keepValues) {
        this.highlightedInteractionDayValue = 0
        this.highlightedInteractionHourValue = 0
        this.highlightedVoucherDayValue = 0
        this.highlightedSouvenirDayValue = 0
      }
      this.$forceUpdate()
    },
    getIcon(icon) {
      return require(`../assets/icons/${icon}.svg`)
    },
    gotData() {
      const experiencesWithSouvenir = this.getExperiencesWithSouvenir(this.primaryData)
      if (_.get(experiencesWithSouvenir, 'length', 0) === 1) {
        this.selectedSouvenirTouchpoint = _.get(experiencesWithSouvenir, `[0]._id`, 0)
      }
      if (_.get(this.primaryData, 'experiences.length', 0) === 1) {
        this.selectedSouvenirTouchpoint2 = _.get(this.primaryData.experiences, `[0]._id`, 0)
      }
      this.bar.data = this.updateHourlyInteractionData()
      this.barVoucher = this.getVoucherData(this.primaryData, this.secondaryData)
      this.funnel1 = this.getFunnel(this.primaryData)
      this.funnel2 = this.getFunnel(this.secondaryData)
      this.line = this.getLineData(this.primaryData, this.secondaryData)
      this.lineSouvenir = this.getLineSouvenirData(this.primaryData, this.secondaryData)
      this.getSouvenirSubTypes(this.primaryData, this.secondaryData)
      this.generateLabelsForLists()
    },
    getLineSouvenirData(data, secondaryData = false) {
      return {
        data: {
          labels: this.getLabels(this.primaryData, this.secondaryData, 'souvenirsByDate', false),
          chartSelectorLabels: this.getLabels(
            this.primaryData,
            this.secondaryData,
            'souvenirsByDate',
            true
          ),
          datasets: this.getSouvenirDatasets(data, secondaryData)
        },
        options: {
          drawLineSelection: true,
          onClick: (evt, activeElements) => {
            if (activeElements.length === 0) {
              return
            }
            this.highlightedSouvenirDayValue = activeElements[0].index + 1
            this.selectedSouvenirDate = _.get(
              this.line,
              `data.chartSelectorLabels[${activeElements[0].index}]`,
              0
            )
          }
        }
      }
    },
    getLineData(data, secondaryData = false) {
      return {
        data: {
          labels: this.getLabels(this.primaryData, this.secondaryData, 'experiencesByDate', false),
          chartSelectorLabels: this.getLabels(
            this.primaryData,
            this.secondaryData,
            'experiencesByDate',
            true
          ),
          datasets: this.getExperiencesDatasets(data, secondaryData)
        },
        options: {
          drawLineSelection: true,
          onClick: (evt, activeElements) => {
            if (activeElements.length === 0) {
              return
            }
            this.highlightedInteractionDayValue = activeElements[0].index + 1
            this.selectedDate = _.get(
              this.line,
              `data.chartSelectorLabels[${activeElements[0].index}]`,
              0
            )
          }
        }
      }
    },
    hasNoHourlyData(data) {
      return _.isEmpty(this.getSelectedDateTraffic(data))
    },
    updateHourlyInteractionData() {
      const labels = this.getTrafficHoursLabels(this.primaryData)
      const data = {
        labels: labels,
        chartSelectorLabels: labels,
        datasets: []
      }
      _.each(_.compact([this.primaryData, this.secondaryData]), (d, i) => {
        data.datasets.push({
          label: _.get(d, 'pavilion.event.key', false),
          backgroundColor: ChartsDataService.lineColors[i],
          data: this.getSelectedDateTraffic(d),
          barPercentage: 0.5
        })
      })
      // console.warn(`HOUR GRAPH DATA - `, data)
      return data
    },
    getTrafficDates(data, secondaryData = false, obj) {
      if (secondaryData) {
        return _.map(
          this.getTrafficDates(secondaryData, false, obj),
          (date, i) => TranslateService.get('T_DAY') + ` ${i + 1}`
        )
      }
      const mappedData = _.map(_.get(data, obj), 'date')
      return _.isEmpty(mappedData)
        ? false
        : _.uniq(_.map(mappedData, (date) => moment(date).format('MM/DD/YYYY')))
    },
    getTrafficLabels(data, secondaryData = false, fullDate = false, obj) {
      if (secondaryData) {
        return _.map(
          this.getTrafficDates(data, false, obj),
          (date, i) => TranslateService.get('T_DAY') + ` ${i + 1}`
        )
      }
      if (fullDate) {
        return _.map(this.getTrafficDates(data, false, obj), (date) => {
          return moment(date, 'MM/DD/YYYY').format('DD/MM/YYYY')
        })
      }
      return _.map(this.getTrafficDates(data, false, obj), (date) => {
        return moment(date, 'MM/DD/YYYY').format('DD')
      })
    },
    getInteractionsValueForExperience(experiencesByDate, experienceId) {
      if (this.highlightedInteractionDayValue === 0 && this.highlightedInteractionHourValue === 0) {
        // console.warn(`NOTE: all days total for interaction ${experienceId}`)
        return _.sum(
          _.map(experiencesByDate, (date) => {
            return this.getAsNumber(
              _.find(_.get(date, 'visitsByExperience', []), (e) => e.experience === experienceId),
              'count'
            )
          })
        )
      }
      if (this.highlightedInteractionDayValue === 0 && this.highlightedInteractionHourValue !== 0) {
        // console.warn('NOTE: total of the hour for all days')
        return _.sum(
          _.map(experiencesByDate, (date) => {
            return this.getAsNumber(
              _.find(
                _.get(
                  date,
                  `visitsByHour[${this.highlightedInteractionHourValue - 1}].visitsByExperience`,
                  []
                ),
                (e) => e.experience === experienceId
              ),
              'count'
            )
          })
        )
      }
      if (this.highlightedInteractionDayValue !== 0 && this.highlightedInteractionHourValue === 0) {
        // console.warn('NOTE: total of the day')
        const subItem = _.get(
          experiencesByDate,
          `[${this.highlightedInteractionDayValue - 1}].visitsByExperience`,
          []
        )
        return this.getAsNumber(
          _.find(subItem, (e) => e.experience === experienceId),
          'count'
        )
      }
      const subItem = _.get(
        experiencesByDate,
        `[${this.highlightedInteractionDayValue - 1}].visitsByHour[${
          this.highlightedInteractionHourValue - 1
        }]`,
        {}
      )
      // console.warn('NOTE: total for the hour of a specific day')
      return this.getAsNumber(
        _.find(_.get(subItem, 'visitsByExperience', []), (e) => e.experience === experienceId),
        'count'
      )
    },
    getSouvenirDatasets(data, secondaryData = false) {
      if (secondaryData) {
        return _.map([data, secondaryData], (d, i) => {
          return {
            label: _.get(d, 'pavilion.event.key', ''),
            data: _.map(_.get(d, 'souvenirsByDate', []), (date) => {
              return _.toNumber(_.get(date, `countByAction.${this.selectedSouvenirType}`, 0))
            }),
            showLine: true,
            orderColor: ChartsDataService.lineColors[i],
            borderColor: ChartsDataService.lineColors[i],
            backgroundColor: ChartsDataService.lineColors[i],
            fill: false
          }
        })
      }
      const souvenirsByDate = _.get(data, 'souvenirsByDate', [])
      let souvenirSubTypes = ['generated', 'retrieved', 'shared', 'downloaded']
      if (this.shouldDisplayPlayedSouvenirs(data)) {
        souvenirSubTypes = ['generated', 'retrieved', 'played', 'shared', 'downloaded']
      }
      const datasets = _.map(souvenirSubTypes, (type, i) => {
        const translationKey = `T_${_.toUpper(type)}`
        let formattedData = []
        if (this.selectedSouvenirTouchpoint !== 0) {
          const experiencesByDate = _.get(data, 'experiencesByDate', [])
          formattedData = _.map(experiencesByDate, (date) => {
            const foundExperienceData = _.find(_.get(date, 'visitsByExperience', []), {
              experience: this.selectedSouvenirTouchpoint
            })
            return _.toNumber(_.get(foundExperienceData, type === 'generated' ? 'count' : type, 0))
          })
        } else {
          formattedData = _.map(souvenirsByDate, (date) => {
            return _.toNumber(_.get(date, `countByAction.${type}`, 0))
          })
        }
        return {
          label: TranslateService.get(translationKey),
          translationKey,
          data: formattedData,
          showLine: true,
          borderColor: ChartsDataService.lineColors[i],
          backgroundColor: ChartsDataService.lineColors[i],
          fill: false
        }
      })
      return datasets
    },
    getSouvenirSubTypes(data, secondaryData = false) {
      let displayAll = false
      _.each([data, secondaryData], (d) => {
        if (!d) {
          return false
        }
        if (this.shouldDisplayPlayedSouvenirs(d)) {
          displayAll = true
          return false
        }
      })
      if (displayAll) {
        return
      }
      this.souvenirSubTypes = _.filter(this.souvenirSubTypes, (type) => type.key !== 'played')
    },
    getSelectedExperiences(data) {
      let experiences = _.get(data, 'experiences', [])
      if (this.selectedSouvenirTouchpoint !== 0) {
        experiences = [_.find(experiences, { _id: this.selectedSouvenirTouchpoint })]
      }
      return _.compact(experiences)
    },
    shouldDisplaySouvenirs(data) {
      if (this.selectedSouvenirTouchpoint2 !== 0) {
        const selectedTouchpointSouvenirTypes = _.get(
          _.find(_.get(data, 'experiences', []), { _id: this.selectedSouvenirTouchpoint2 }),
          'souvenirTypes',
          []
        )
        if (selectedTouchpointSouvenirTypes.length > 0) {
          return true
        }
      }
      const experiencesWithSouvenir = this.getExperiencesWithSouvenir(data)
      if (this.selectedSouvenirTouchpoint2 === 0 && experiencesWithSouvenir.length > 0) {
        return true
      }
      return false
    },
    shouldDisplayPlayedSouvenirs(data) {
      let displayPlayed = false
      const experiences = this.getSelectedExperiences(data)
      _.each(experiences, (experience) => {
        if (_.includes(experience.souvenirTypes, 'video')) {
          displayPlayed = true
          return false
        }
      })
      return displayPlayed
    },
    getExperiencesDatasets(data, secondaryData = false, perHour = false) {
      const datasets = []
      if (secondaryData) {
        _.each([data, secondaryData], (d, i) => {
          let dataForGraph = []
          let experiences = _.get(d, 'pavilion.experiences', [])
          if (this.highlightedInteractionDayValue !== 0) {
            const experiencesForSelectedHour = []
            _.each(experiences, (experience, i) => {
              experiencesForSelectedHour.push({
                label: experience.name,
                borderColor: ChartsDataService.lineColors[i],
                value: this.getInteractionsValueForExperience(experiencesByDate, experience._id)
              })
            })
          } else {
            const experiencesByDate = _.get(d, 'experiencesByDate', [])
            const experiencesIds = _.map(experiences, '_id')
            dataForGraph = _.map(experiencesByDate, (date) => {
              return _.sum(
                _.map(_.get(date, 'visitsByExperience', []), (visitByExperience) => {
                  return _.includes(experiencesIds, visitByExperience.experience)
                    ? visitByExperience.count
                    : 0
                })
              )
            })
          }
          datasets.push({
            label: _.get(d, 'pavilion.event.key', ''),
            data: dataForGraph,
            showLine: true,
            borderColor: ChartsDataService.lineColors[i],
            fill: false
          })
        })
        return datasets
      }
      const experiencesByDate = _.get(data, 'experiencesByDate', [])
      const mappedData = {}
      let experiences = _.get(data, 'pavilion.experiences', [])
      if (perHour) {
        return _.map(experiences, (experience, i) => {
          return {
            label: experience.name,
            borderColor: ChartsDataService.lineColors[i],
            value: this.getInteractionsValueForExperience(experiencesByDate, experience._id)
          }
        })
      } else {
        _.each(experiencesByDate, (experiencesDate) => {
          _.each(experiences, (experience) => {
            mappedData[experience._id] = _.get(mappedData, experience._id, [])
            const subItem = _.find(
              _.get(experiencesDate, 'visitsByExperience', []),
              (e) => e.experience === experience._id
            )
            mappedData[experience._id].push(this.getAsNumber(subItem, 'count'))
          })
        })
      }
      _.each(experiences, (experience, i) => {
        datasets.push({
          label: experience.name,
          data: _.get(mappedData, experience._id, []),
          showLine: true,
          borderColor: ChartsDataService.lineColors[i],
          fill: false
        })
      })
      return datasets
    },
    getOriginalTrafficData(data) {
      const experiencesByDate = _.get(data, 'experiencesByDate', [])
      if (this.selectedDate === 0) {
        // console.warn(`getOriginalTrafficData - 2`, experiencesByDate)
        return experiencesByDate
      }
      return _.get(experiencesByDate, `[${this.highlightedInteractionDayValue - 1}]`, {})
    },
    getSelectedDateTraffic(data) {
      if (this.selectedDate === 0) {
        const allDaysData = this.getOriginalTrafficData(data)
        const formattedData = {}
        _.each(allDaysData, (e) => {
          _.each(_.get(e, 'visitsByHour', []), (hour) => {
            _.set(
              formattedData,
              hour.time,
              _.get(formattedData, hour.time, 0) + _.get(hour, 'count', 0)
            )
          })
        })
        return _.values(formattedData)
      }
      return _.map(_.get(this.getOriginalTrafficData(data), 'visitsByHour', []), 'count')
    },
    getTrafficHoursLabels(data) {
      let experienceByDate = this.getOriginalTrafficData(data)
      if (_.isArray(experienceByDate)) {
        // console.warn(`getTrafficHoursLabels - is array`)
        experienceByDate = _.get(experienceByDate, `[0]`, {})
      }
      const perHourLabels = _.map(_.get(experienceByDate, 'visitsByHour', []), 'time')
      // console.warn(`PER HOUR LABELS = `, perHourLabels)
      return perHourLabels
    }
  }
}
</script>
