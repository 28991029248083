<template>
  <v-app
    :class="[
      TranslateService.currentLocale,
      !user ? 'gradient-background' : '',
      currentSectionKey === 'pdf' ? 'is-for-pdf' : ''
    ]"
    class="desktop"
  >
    <v-progress-linear v-if="!isLoaded" indeterminate />
    <template v-else>
      <v-main
        class="main-container"
        :class="{
          'no-scroll': showFullscreenOverlay,
          'no-margin-bottom': currentSectionKey === 'pdf'
        }"
      >
        <nav-bar v-if="currentSectionKey !== 'pdf'" :user="user" />
        <login-page
          v-if="!user"
          is-for-desktop
          :user.sync="user"
          :success-login-callback="onLoginCallback"
        />
        <template v-else>
          <template v-if="currentSectionKey === 'pdf'">
            <router-view />
          </template>
          <template v-else>
            <sidebar-menu v-if="currentSectionKey !== 'pdf'" :user="user" />
            <section id="content-wrapper">
              <router-view />
              <viewer-fullscreen
                is-for-desktop
                :current-section-key="currentSectionKey"
                :current-fullscreen-slide="currentFullscreenSlide"
                :show-fullscreen-overlay="showFullscreenOverlay"
                :fullscreen-image="fullscreenImage"
                :fullscreen-content="fullscreenContent"
                :fullscreen-items="fullscreenItems"
                :close-callback="closeFullscreenViewer"
              />
            </section>
          </template>
        </template>
      </v-main>
    </template>
  </v-app>
</template>

<script>
import NavBar from '@/components/Desktop/NavBar'
import SidebarMenu from '@/components/Desktop/SidebarMenu'
import AppMixin from './mixins/AppMixin.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    SidebarMenu
  },
  mixins: [AppMixin],
  data() {
    return {}
  }
}
</script>

<style lang="scss">
@import './vars.scss';
@import './appDesktop.scss';
</style>

<style lang="scss">
@import './vars.scss';

.v-application.desktop {
  max-height: 100vh;

  &:not(.is-for-pdf) {
    overflow: hidden;
    &.gradient-background {
      background: linear-gradient(180deg, #1b1f2d 0%, #8094dd 100%);
      .v-application--wrap {
        background-color: transparent;
      }
    }
  }
  .v-application--wrap .theme--light {
    &.v-btn__content {
      font-size: $desktop-scale-vw * 16;
    }
    &.v-card {
      padding: 8px 16px;
      .v-main {
        margin-bottom: 0;
      }
    }
    &.v-navigation-drawer {
      width: 100% !important;
      backdrop-filter: none;
      background: none;
    }
  }
  .main-container > div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  #content-wrapper {
    width: $desktop-scale-vw * 1448;
    height: calc(100vh - 40px);
    overflow-y: auto;
    > .section {
      min-height: calc(100vh - 40px - (#{$desktop-scale-vw * 64}));
    }
  }
  #overview-section,
  #traffic-section,
  #experience-section,
  #sales-section {
    .sub-sections-wrapper {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      align-items: stretch;
      justify-content: space-between;
      .sub-section {
        margin-bottom: 32px;
      }
    }
    .section {
      width: $desktop-scale-vw * 672;
      margin-bottom: $desktop-standard-margin;
      &.full-width {
        width: 100%;
      }
    }
  }
  .content-card {
    &.compare {
      .difference {
        font-size: $desktop-scale-vw * 16;
        line-height: $desktop-scale-vw * 16;
      }
    }
  }
  .pie-chart-legend {
    &.compare {
      .name-column {
        font-size: $desktop-scale-vw * 16;
        line-height: $desktop-scale-vw * 16;
      }
    }
  }
  #overview-section,
  #traffic-section,
  #experience-section,
  #sales-section {
    .cards-row {
      > .card-top:not(.full-width) {
        width: calc(50% - #{$desktop-half-margin});
      }
      &.center {
        justify-content: center;
      }
    }
  }
  .card-wrapper {
    width: calc(50% - #{$desktop-half-margin});
    .v-card {
      width: 100%;
    }
    &.full-width {
      width: 100%;
    }
  }
}
</style>
