import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import LoginService from './LoginService'

class DataService {
  constructor() {
    console.info(`DataService - constructor`)
    this.resetBrands()
  }

  resetBrands() {
    console.info(`DataService - resetBrands`)
    this.brands = false
    this.requestingBrands = false
  }

  async getBrands(forceUpdate = false) {
    try {
      if (!forceUpdate && this.brands) {
        console.info(`DataService - getBrands - already have brands data`)
        return this.brands
      }
      if (this.requestingBrands) {
        console.info(`DataService - getBrands - already requesting brands`)
        return false
      }
      this.requestingBrands = true
      axios.defaults.headers.common['Authorization'] = _.get(LoginService.user, 'jwt', '')
      const { data } = await axios.get(`/api/brands${forceUpdate ? `?refresh=${+new Date()}` : ''}`)
      this.restructureData(data)
      _.each(data, (item) => {
        this.restructureData(item.pavilions)
        _.each(item.pavilions, (pavilion) => {
          this.restructureData(pavilion.event)
          this.restructureData(pavilion.event.city)
          this.restructureData(pavilion.experiences)
          this.restructureData(pavilion.abdShow)

          const duration = moment(pavilion.event.endDate)
            .endOf('day')
            .diff(moment(pavilion.event.startDate).startOf('day'))
          pavilion.event.days = moment.duration(duration).asDays()
          pavilion.brand = item
        })
      })
      this.brands = data
      this.requestingBrands = false
      return data
    } catch (error) {
      console.error(`getBrands - error: `, error)
      LoginService.handleRequestError()
    }
    this.requestingBrands = false
    return false
  }

  restructureData(items) {
    let isArray = true
    if (!_.isArray(items)) {
      isArray = false
      items = _.compact([items])
    }
    _.each(items, (item) => {
      _.each(['enUS', 'zhCN'], (locale) => {
        _.each(item[locale], (value, key) => {
          _.set(item, `${key}.${locale}`, value)
        })
        delete item[locale]
      })

      _.each(item._attachments, (attach) => {
        let value = attach.url
        const oldValue = _.get(item, attach._name, [])
        if (_.includes(['gallery'], attach._name)) {
          oldValue.push(value)
          value = oldValue
        }
        _.set(item, attach._name, value)
      })
    })
    if (isArray) {
      return items
    } else {
      return _.first(items)
    }
  }
}

export default new DataService()
