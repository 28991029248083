<template>
  <div ref="bubbleChartWrapper" class="bubble-chart" :class="{ displayed: showChart }">
    <img
      v-if="isForPdf"
      :height="style.height"
      :width="style.width"
      :src="backgroundImage"
      classes="loading-background"
    />
    <image-loader
      v-else
      :height="style.height"
      :width="style.width"
      :src="backgroundImage"
      :lazy-src="getLazySrc(backgroundImage, '500')"
      classes="loading-background"
      gradient-loading
    />
    <canvas ref="canvas" :style="style" />
  </div>
</template>
<script>
import _ from 'lodash'
import { Chart } from 'chart.js'
import ImageLoader from '@/components/ImageLoader.vue'
import ChartsDataService from '@/services/ChartsDataService'

Chart.defaults.font.family = 'SourceSansPro-Bold'
export default {
  components: { ImageLoader },
  props: {
    chartData: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    },
    dataPerDay: {
      type: [Object, Boolean],
      default: false
    },
    backgroundImage: {
      type: [String, Boolean],
      default: false
    },
    isForPdf: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {}
    },
    reloadingBubble: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showChart: false,
      currentBackgroundImage: null,
      localDates: [],
      style: {},
      sizeOptions: {},
      pdfStyle: {
        width: 'auto',
        height: '240px'
      }
    }
  },
  watch: {
    reloadingBubble() {
      this.updateData()
    }
  },
  async mounted() {
    this.createBubble()
  },
  // created() {
  // window.addEventListener('resize', this.resizeCanvas)
  // },
  // unmounted() {
  // window.removeEventListener('resize', this.resizeCanvas)
  // },
  methods: {
    updateData() {
      if (!this.chart) {
        return console.info(`No bubble chart`)
      }
      const dataObj = _.cloneDeep(_.get(this, 'dataPerDay.data', this.chartData))
      _.each(dataObj.datasets, (dataset) => {
        dataset.backgroundColor = this.formatColors(ChartsDataService.pieChartsColors)
      })
      dataObj.datasets = _.uniqBy(dataObj.datasets, 'datasetIndex')
      this.chart.data.datasets.forEach((dataset) => {
        dataset.data.pop()
      })
      _.each(this.chart.data.datasets, () => {
        this.chart.data.datasets.pop()
      })
      _.each(dataObj.datasets, (dataset) => {
        this.chart.data.datasets.push(dataset)
      })
      const datasets = _.cloneDeep(dataObj.datasets)
      this.chart.data.labels = []
      _.set(this.chart, 'data.datasets', [])
      _.each(datasets, (dataset) => {
        this.chart.data.datasets.push(dataset)
      })
      this.chart.update('none')
    },
    getDefaultOptions() {
      return {
        animation: {
          numbers: { duration: 0 },
          colors: {
            type: 'color',
            duration: 1000,
            from: 'transparent'
          }
        },
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: this.sizeOptions.width / this.sizeOptions.height,
        isForPdf: this.isForPdf,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                return context.raw.label
              }
            }
          },
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            align: 'center',
            textStrokeColor: 'black',
            textStrokeWidth: this.isForPdf ? 1 : 3,
            opacity: 1,
            labels: {
              title: {
                font: {
                  family: 'SourceSansPro-Bold',
                  size: 10,
                  weight: 700
                },
                textAlign: 'center',
                anchor: 'center',
                align: 'center',
                offset: '0',
                color: 'white',
                formatter: function (value, context) {
                  return _.get(context, `dataset.data[${context.dataIndex}].label`, '')
                }
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        },
        layout: {
          autoPadding: false,
          padding: 0
        },
        scales: {
          x: {
            display: false, // Put to true to debug grid
            min: 0,
            max: this.sizeOptions.width,
            beginAtZero: true,
            position: 'top',
            color: 'red',
            ticks: {
              display: true,
              color: 'red'
            },
            grid: {
              display: true,
              color: 'red'
            }
          },
          y: {
            display: false, // Put to true to debug grid
            min: 0,
            max: this.sizeOptions.height,
            beginAtZero: true,
            reverse: true,
            color: 'red',
            ticks: {
              display: true,
              color: 'red'
            },
            grid: {
              display: true,
              color: 'red'
            }
          }
        },
        backgroundColor: this.formatColors(ChartsDataService.pieChartsColors)
      }
    },
    getLazySrc(url, resizeOptions) {
      return `${url}?resize=${resizeOptions}`
    },
    createBubble() {
      this.showChart = false
      const ctx = this.$refs.canvas.getContext('2d', { willReadFrequently: true })
      let mapHeight = 200
      let mapWidth = 200
      if (_.get(this.data, 'pavilion.trafficDataSource', 'manual') === 'manual') {
        mapHeight = _.get(this.data, 'pavilion.gaHeight', mapHeight)
        mapWidth = _.get(this.data, 'pavilion.gaWidth', mapWidth)
      } else {
        mapHeight = _.get(this.data, 'abdShow.height', mapHeight)
        mapWidth = _.get(this.data, 'abdShow.width', mapWidth)
      }
      this.sizeOptions = {
        width: mapWidth,
        height: mapHeight
      }
      this.style.width = this.getMapWidth()
      this.style.height = this.getMapHeight()
      const options = _.merge(this.getDefaultOptions(), this.options)
      const dataObj = _.get(this, 'dataPerDay.data', this.chartData)
      _.each(dataObj.datasets, (dataset) => {
        dataset.backgroundColor = this.formatColors(ChartsDataService.pieChartsColors)
        if (this.isForPdf) {
          console.warn(`dataset = `, dataset)
          _.each(dataset.data, (d) => {
            d.r = d.r / 2
          })
        }
      })
      this.currentBackgroundImage = new Image()
      let backgroundImageUrl = _.get(this, 'backgroundImage', false)
      if (backgroundImageUrl === false) {
        return console.warn(`No background image found`)
      }
      backgroundImageUrl = window.location.origin + backgroundImageUrl
      this.currentBackgroundImage.src = backgroundImageUrl
      this.chart = new Chart(ctx, {
        type: 'bubble',
        plugins: [
          {
            // beforeDraw: chart => {
            //   if (_.isEmpty(backgroundImageUrl)) {
            //     return
            //   }
            // const ctx = chart.ctx
            // ctx.save()
            // ctx.drawImage(
            //   this.currentBackgroundImage,
            //   chart.chartArea.left,
            //   chart.chartArea.top,
            //   chart.chartArea.width,
            //   chart.chartArea.height
            // )
            // ctx.restore()
            // }
          }
        ],
        data: dataObj,
        options
      })
      this.$nextTick(() => {
        // this.resizeCanvas()
        setTimeout(() => {
          this.showChart = true
          if (this.isForPdf) {
            setTimeout(() => {
              this.resizeCanvas()
            }, 50)
          }
        }, 50)
      })
    },
    getResponsiveMapWidth() {
      if (this.getWindowWidth() < 1024) {
        return this.compare ? 43.6 : 93.6
      }
      return 43.8
    },
    getWindowWidth() {
      return this.getPixelValue('innerWidth', 'clientWidth')
    },
    getWindowHeight() {
      return this.getPixelValue('innerHeight', 'clientHeight')
    },
    getPixelValue(windowVal, clientVal) {
      return (
        _.get(window, windowVal, false) ||
        _.get(document, `documentElement.${clientVal}`, false) ||
        _.get(document, `body.${clientVal}`, false)
      )
    },
    roundNumber(number) {
      return Math.round(number * 100) / 100
    },
    calculateMapWidth() {
      if (_.get(this.$refs, 'bubbleChartWrapper.clientWidth', 0)) {
        return this.$refs.bubbleChartWrapper.clientWidth
      }
      return (this.getWindowWidth() / 100) * this.getResponsiveMapWidth()
    },
    calculateMapHeight() {
      return this.roundNumber(
        this.calculateMapWidth() *
          (_.get(this.sizeOptions, 'height', 0) / _.get(this.sizeOptions, 'width', 1))
      )
    },
    getMapWidth() {
      let width = this.roundNumber(this.calculateMapWidth())
      const clientWidth = _.get(this.$refs, 'bubbleChartWrapper.clientWidth', false)
      if (clientWidth && width > clientWidth) {
        width = this.roundNumber(clientWidth)
      }
      return `${width}px`
    },
    getMapHeight() {
      const height = this.roundNumber(this.calculateMapHeight())
      return `${height > this.sizeOptions.height ? this.sizeOptions.height : height}px`
    },
    resizeCanvas() {
      this.$nextTick(() => {
        if (!this.chart) {
          return
        }
        this.style = {}
        _.set(this.style, 'width', this.getMapWidth())
        _.set(this.style, 'height', this.getMapHeight())
        this.$nextTick(() => {
          this.chart.update('none')
          this.$forceUpdate()
        })
      })
    },
    formatColors(colors) {
      return _.map(colors, (color) => this.hexToRgbA(color))
    },
    hexToRgbA(hex) {
      let c
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.5)'
      }
      throw new Error('Bad Hex')
    }
  }
}
</script>

<style scoped lang="scss">
.bubble-chart {
  position: relative;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  &.displayed {
    opacity: 1;
  }
  > img,
  > .v-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  canvas {
    z-index: 2;
    position: relative;
  }
}
</style>
