<template>
  <div id="experience-section" :class="getDesktopWrapperClass()">
    <section-loader v-if="loading" />
    <div v-else-if="primaryData" class="sub-sections-wrapper">
      <!-- Activations -->
      <div class="card-wrapper full-width">
        <card-top :options="getDesktopTitle('T_ACTIVATIONS')" divider />
        <template v-if="!canCompareSubSection('activations')">
          <v-card class="sub-section no-data">
            <cannot-compare-data />
          </v-card>
        </template>
        <template v-else-if="hasSubSection('activations')">
          <div v-if="secondaryData" class="cards-row">
            <div class="sub-section full-width">
              <div class="info-box">
                <div class="experiences-wrapper">
                  <compare-show-titles
                    :primary-data="primaryData"
                    :secondary-data="secondaryData"
                  />
                  <div class="vertical-activations">
                    <pavilion-experiences :pavilion="primaryData.pavilion" is-for-desktop compare />
                    <v-divider vertical />
                    <pavilion-experiences
                      :pavilion="secondaryData.pavilion"
                      is-for-desktop
                      compare
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <v-card v-else class="sub-section full-width">
            <div class="info-box experiences-container" :class="{ displayed: allLoaded }">
              <pavilion-experiences
                is-for-desktop
                :pavilion="primaryData.pavilion"
                :all-loaded.sync="allLoaded"
              />
            </div>
          </v-card>
        </template>
      </div>
      <div class="cards-row">
        <!-- Interactions -->
        <div
          v-if="!canCompareSubSection('interactions') || hasSubSection('interactions')"
          class="card-wrapper"
        >
          <card-top :options="getDesktopTitle('T_GUEST_INTERACTIONS')" divider />
          <template v-if="!canCompareSubSection('interactions')">
            <v-card class="sub-section no-data">
              <cannot-compare-data />
            </v-card>
          </template>
          <template v-else-if="hasSubSection('interactions')">
            <v-card class="sub-section">
              <card-top
                :current-view.sync="selectedSubView"
                :options="filterIfNoDataPerHour(getSectionCardTop('interactions'))"
              />
              <template v-if="secondaryData">
                <div v-if="selectedSubView === 0 && primaryData" class="interaction-days">
                  <template v-if="graphHasData(line)">
                    <chart-selector
                      :data="
                        getDataForChart(line, {
                          label: 'T_TOTAL_GUEST_INTERACTIONS',
                          value:
                            getTotalForExperiences(primaryData) +
                            getTotalForExperiences(secondaryData)
                        })
                      "
                      center-label
                      :current-value.sync="highlightedInteractionDayValue"
                    />
                    <div class="compare-show-highlighted">
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="primaryData.pavilion.event.key"
                        :content="primaryData.total.experienceGenerated"
                        type="compare"
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[0],
                          primary: getHighlightedValue(
                            line,
                            'primary',
                            highlightedInteractionDayValue
                          ),
                          secondary: getHighlightedValue(
                            line,
                            'secondary',
                            highlightedInteractionDayValue
                          )
                        }"
                        italic
                      />
                      <v-divider vertical />
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="secondaryData.pavilion.event.key"
                        type="compare"
                        :content="
                          getHighlightedValue(line, 'secondary', highlightedInteractionDayValue)
                        "
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[1]
                        }"
                        italic
                      />
                    </div>
                    <line-chart
                      :data="line.data"
                      :options="line.options"
                      :highlight-value="highlightedInteractionDayValue"
                    />
                    <div class="cards-row">
                      <custom-list
                        :items="getExperiencesInteractions(false, primaryData)"
                        :compare-show="primaryData.pavilion.event.key"
                        :circle-color="ChartsDataService.barColors[0]"
                      />
                      <custom-list
                        :items="getExperiencesInteractions(false, secondaryData)"
                        :compare-show="secondaryData.pavilion.event.key"
                        :circle-color="ChartsDataService.barColors[1]"
                      />
                    </div>
                  </template>
                  <no-graph-data v-else />
                </div>
                <div v-else class="interaction-hours">
                  <template v-if="graphHasData(bar)">
                    <chart-selector
                      :data="
                        getDataForChart(line, {
                          label: 'T_TOTAL_GUEST_INTERACTIONS',
                          value:
                            getTotalForExperiences(primaryData) +
                            getTotalForExperiences(secondaryData)
                        })
                      "
                      center-label
                      :current-value.sync="highlightedInteractionDayValue"
                    />
                    <div v-if="bar.data.datasets" class="compare-show-highlighted">
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="primaryData.pavilion.event.key"
                        :content="primaryData.total.experienceGenerated"
                        type="compare"
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[0],
                          primary: getHighlightedValue(
                            line,
                            'primary',
                            highlightedInteractionDayValue
                          ),
                          secondary: getHighlightedValue(
                            line,
                            'secondary',
                            highlightedInteractionDayValue
                          )
                        }"
                        italic
                      />
                      <v-divider vertical />
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="secondaryData.pavilion.event.key"
                        type="compare"
                        :content="
                          getHighlightedValue(line, 'secondary', highlightedInteractionDayValue)
                        "
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[1]
                        }"
                        italic
                      />
                    </div>
                    <bar-chart
                      :data="bar.data"
                      :options="bar.options"
                      :highlight-value="highlightedInteractionHourValue"
                    />
                    <chart-selector
                      :data="getDataForChart(bar, getTotalInteractionsByHour(), true)"
                      :current-value.sync="highlightedInteractionHourValue"
                    />
                    <div class="cards-row">
                      <custom-list
                        :items="getExperiencesInteractions(true, primaryData)"
                        :compare-show="primaryData.pavilion.event.key"
                        :circle-color="ChartsDataService.barColors[0]"
                      />
                      <custom-list
                        :items="getExperiencesInteractions(true, secondaryData)"
                        :compare-show="secondaryData.pavilion.event.key"
                        :circle-color="ChartsDataService.barColors[1]"
                      />
                    </div>
                  </template>
                  <no-graph-data v-else />
                </div>
                <div v-if="graphHasData(bar)" class="compare-show-highlighted">
                  <content-card
                    no-gradient
                    half-width
                    dark-background
                    :title="'T_TOTAL_GUEST_INTERACTIONS' | translate"
                    :content="getTotalForDataset(bar, 0)"
                    type="compare"
                    :compare-data="{
                      primary: getHighlightedValue(bar, 'primary', 0),
                      secondary: getHighlightedValue(bar, 'secondary', 0)
                    }"
                    italic
                  />
                  <v-divider vertical />
                  <content-card
                    no-gradient
                    half-width
                    :title="'T_TOTAL_GUEST_INTERACTIONS' | translate"
                    dark-background
                    type="compare"
                    :content="getTotalForDataset(bar, 1)"
                    :compare-data="{}"
                    italic
                  />
                </div>
                <no-graph-data v-else />
              </template>
              <template v-else>
                <div v-if="selectedSubView === 0 && primaryData" class="interaction-days">
                  <template v-if="graphHasData(line)">
                    <chart-selector
                      :data="
                        getDataForChart(line, {
                          label: 'T_TOTAL_GUEST_INTERACTIONS',
                          value: getTotalForExperiences(primaryData)
                        })
                      "
                      :current-value.sync="highlightedInteractionDayValue"
                    />
                    <line-chart
                      :data="line.data"
                      :options="line.options"
                      :highlight-value="highlightedInteractionDayValue"
                    />
                    <custom-list :items="getExperiencesInteractions()" show-colors />
                  </template>
                  <no-graph-data v-else />
                </div>
                <div v-else class="interaction-hours">
                  <template v-if="graphHasData(bar)">
                    <chart-selector
                      :data="
                        getDataForChart(line, {
                          label: 'T_TOTAL_GUEST_INTERACTIONS',
                          value: getTotalForExperiences(primaryData)
                        })
                      "
                      :current-value.sync="highlightedInteractionDayValue"
                    />
                    <bar-chart
                      :data="bar.data"
                      :options="bar.options"
                      :highlight-value="highlightedInteractionHourValue"
                    />
                    <chart-selector
                      :data="getDataForChart(bar, getTotalInteractionsByHour(), true)"
                      :current-value.sync="highlightedInteractionHourValue"
                    />
                    <custom-list :items="getExperiencesInteractions(true)" />
                  </template>
                  <no-graph-data v-else />
                </div>
              </template>
            </v-card>
          </template>
        </div>
        <!-- Voucher -->
        <div
          v-if="!canCompareSubSection('voucher') || hasSubSection('voucher')"
          class="card-wrapper"
        >
          <card-top :options="getDesktopTitle('T_VOUCHER_GENERATION_AND_REDEMPTION')" divider />
          <template v-if="!canCompareSubSection('voucher')">
            <v-card class="sub-section no-data">
              <cannot-compare-data />
            </v-card>
          </template>
          <template v-else-if="hasSubSection('voucher')">
            <v-card class="sub-section">
              <card-top :current-view="0" :options="getSectionCardTop('voucher')" />
              <div class="info-box">
                <template v-if="graphHasData(barVoucher)">
                  <template v-if="secondaryData">
                    <chart-selector
                      :data="getVoucherChartSelectorData()"
                      center-label
                      :current-value.sync="highlightedVoucherDayValue"
                    />
                    <sub-type-selector
                      is-for-desktop
                      :types="voucherSubTypes"
                      :current-type.sync="selectedVoucherType"
                    />
                    <div class="compare-show-highlighted">
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="primaryData.pavilion.event.key"
                        :content="
                          getTotalForShow(
                            primaryData,
                            'vouchersByDate',
                            highlightedVoucherDayValue,
                            selectedVoucherType
                          )
                        "
                        type="compare"
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[0],
                          primary: getTotalForShow(
                            primaryData,
                            'vouchersByDate',
                            highlightedVoucherDayValue,
                            selectedVoucherType
                          ),
                          secondary: getTotalForShow(
                            secondaryData,
                            'vouchersByDate',
                            highlightedVoucherDayValue,
                            selectedVoucherType
                          )
                        }"
                        italic
                      />
                      <v-divider vertical />
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="secondaryData.pavilion.event.key"
                        type="compare"
                        :content="
                          getTotalForShow(
                            secondaryData,
                            'vouchersByDate',
                            highlightedVoucherDayValue,
                            selectedVoucherType
                          )
                        "
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[1]
                        }"
                        italic
                      />
                    </div>
                    <line-chart
                      :data="barVoucher.data"
                      :options="barVoucher.options"
                      :highlight-value="highlightedVoucherDayValue"
                    />
                    <div class="vouchers-redemption-rate-title">
                      {{ 'T_VOUCHERS_REDEMPTION_RATE' | translate }}
                    </div>
                    <div class="compare-show-highlighted">
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="primaryData.pavilion.event.key"
                        :content="getVoucherRedemptionRate(primaryData, highlightedVoucherDayValue)"
                        type="compare"
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[0],
                          primaryPercentage: getVoucherRedemptionRate(
                            primaryData,
                            highlightedVoucherDayValue
                          ),
                          primary: getVoucherRedemptionRate(
                            primaryData,
                            highlightedVoucherDayValue,
                            true
                          ),
                          secondary: getVoucherRedemptionRate(
                            secondaryData,
                            highlightedVoucherDayValue,
                            true
                          )
                        }"
                        :no-data="
                          !getVoucherRedemptionRate(primaryData, highlightedVoucherDayValue)
                        "
                        italic
                      />
                      <v-divider vertical />
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="secondaryData.pavilion.event.key"
                        :formatter="formatPercentage"
                        type="compare"
                        :content="
                          getVoucherRedemptionRate(secondaryData, highlightedVoucherDayValue, true)
                        "
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[1]
                        }"
                        italic
                        :no-data="
                          !getVoucherRedemptionRate(secondaryData, highlightedVoucherDayValue, true)
                        "
                      />
                    </div>
                  </template>
                  <template v-else>
                    <chart-selector
                      :formatter="formatPercentage"
                      :data="getVoucherChartSelectorData()"
                      :current-value.sync="highlightedVoucherDayValue"
                    />
                    <bar-chart
                      :data="barVoucher.data"
                      :options="barVoucher.options"
                      :highlight-value="highlightedVoucherDayValue"
                    />
                    <custom-list v-if="voucherList" :items="voucherList" show-colors />
                  </template>
                </template>
                <no-graph-data v-else />
              </div>
            </v-card>
            <template v-if="graphHasData(barVoucher)">
              <compare-show-titles
                v-if="secondaryData"
                :primary-data="primaryData"
                :secondary-data="secondaryData"
              />
              <div v-if="secondaryData" class="compare-data">
                <div class="cards-row vertical">
                  <content-card
                    no-gradient
                    :title="'T_TOTAL_REDEMPTION_RATE' | translate"
                    :content="getVoucherRedemptionRate(primaryData, false, true)"
                    type="compare"
                    :compare-data="{
                      primary: getVoucherRedemptionRate(primaryData, false, true),
                      primaryPercentage: getVoucherRedemptionRate(primaryData),
                      secondary: getVoucherRedemptionRate(secondaryData, false, true)
                    }"
                    italic
                    :no-data="!getVoucherRedemptionRate(secondaryData)"
                  />
                  <content-card
                    no-gradient
                    :title="'T_TOTAL_GENERATED_VOUCHERS' | translate"
                    type="compare"
                    :content="getTotalVoucher(primaryData, 'generated')"
                    :compare-data="{
                      primary: getTotalVoucher(primaryData, 'generated'),
                      secondary: getTotalVoucher(secondaryData, 'generated')
                    }"
                    italic
                    :no-data="!getTotalVoucher(primaryData, 'generated')"
                  />
                  <content-card
                    no-gradient
                    :title="'T_TOTAL_REDEEMED_VOUCHERS' | translate"
                    :content="getTotalVoucher(primaryData, 'redeemed')"
                    type="compare"
                    :compare-data="{
                      primary: getTotalVoucher(primaryData, 'redeemed'),
                      secondary: getTotalVoucher(secondaryData, 'redeemed')
                    }"
                    italic
                    :no-data="!getTotalVoucher(primaryData, 'redeemed')"
                  />
                </div>
                <v-divider vertical inset />
                <div class="cards-row vertical">
                  <content-card
                    no-gradient
                    :title="'T_TOTAL_REDEMPTION_RATE' | translate"
                    :content="getVoucherRedemptionRate(secondaryData, false, true)"
                    type="compare"
                    :formatter="formatPercentage"
                    italic
                    :no-data="!getVoucherRedemptionRate(secondaryData)"
                  />
                  <content-card
                    :no-data="!getTotalVoucher(secondaryData, 'generated')"
                    no-gradient
                    :title="'T_TOTAL_GENERATED_VOUCHERS' | translate"
                    type="compare"
                    :content="getTotalVoucher(secondaryData, 'generated')"
                    italic
                  />
                  <content-card
                    :no-data="!getTotalVoucher(secondaryData, 'generated')"
                    no-gradient
                    :title="'T_TOTAL_REDEEMED_VOUCHERS' | translate"
                    type="compare"
                    :content="getTotalVoucher(secondaryData, 'redeemed')"
                    italic
                  />
                </div>
              </div>
              <div v-else class="cards-row">
                <content-card
                  :no-data="!getTotalVoucher(primaryData, 'generated')"
                  :title="'T_TOTAL_GENERATED_VOUCHERS' | translate"
                  no-gradient
                  half-width
                  align-center
                  :content="getTotalVoucher(primaryData, 'generated')"
                />
                <content-card
                  :no-data="!getTotalVoucher(primaryData, 'redeemed')"
                  :title="'T_TOTAL_REDEEMED_VOUCHERS' | translate"
                  no-gradient
                  half-width
                  align-center
                  :content="getTotalVoucher(primaryData, 'redeemed')"
                />
              </div>
            </template>
            <no-graph-data v-else />
          </template>
        </div>
        <!-- Souvenir -->
        <div
          v-if="!canCompareSubSection('souvenir') || hasSubSection('souvenir')"
          class="card-wrapper"
        >
          <card-top :options="getDesktopTitle('T_SOUVENIR')" divider />
          <template v-if="!canCompareSubSection('souvenir')">
            <v-card class="sub-section no-data">
              <cannot-compare-data />
            </v-card>
          </template>
          <template v-else-if="hasSubSection('souvenir')">
            <v-card class="sub-section">
              <card-top :current-view="0" :options="getSectionCardTop('souvenir')" />
              <div class="info-box">
                <template v-if="graphHasData(lineSouvenir)">
                  <chart-selector
                    v-if="!secondaryData"
                    :data="
                      getTouchpointsList(primaryData, {
                        label: 'T_TOUCHPOINTS'
                      })
                    "
                    center-label
                    :current-value.sync="highlightedSouvenirTouchpointValue"
                  />
                  <sub-type-selector
                    v-if="secondaryData"
                    is-for-desktop
                    :types="souvenirSubTypes"
                    :current-type.sync="selectedSouvenirType"
                  />
                  <div v-if="secondaryData" class="compare-show-highlighted">
                    <content-card
                      no-gradient
                      dark-background
                      half-width
                      :title="primaryData.pavilion.event.key"
                      :content="
                        getTotalForShow(
                          primaryData,
                          'souvenirsByDate',
                          highlightedSouvenirDayValue,
                          selectedSouvenirType
                        )
                      "
                      type="compare"
                      :compare-data="{
                        circleColor: ChartsDataService.barColors[0],
                        primary: getTotalForShow(
                          primaryData,
                          'souvenirsByDate',
                          highlightedSouvenirDayValue,
                          selectedSouvenirType
                        ),
                        secondary: getTotalForShow(
                          secondaryData,
                          'souvenirsByDate',
                          highlightedSouvenirDayValue,
                          selectedSouvenirType
                        )
                      }"
                      italic
                    />
                    <v-divider vertical />
                    <content-card
                      no-gradient
                      dark-background
                      half-width
                      :title="secondaryData.pavilion.event.key"
                      type="compare"
                      :content="
                        getTotalForShow(
                          secondaryData,
                          'souvenirsByDate',
                          highlightedSouvenirDayValue,
                          selectedSouvenirType
                        )
                      "
                      :compare-data="{
                        circleColor: ChartsDataService.barColors[1]
                      }"
                      italic
                    />
                  </div>
                  <line-chart
                    :data="lineSouvenir.data"
                    :options="lineSouvenir.options"
                    :highlight-value="highlightedSouvenirDayValue"
                  />
                  <chart-selector
                    :data="
                      getDataForChart(line, {
                        label: 'T_OVERVIEW'
                      })
                    "
                    center-label
                    :current-value.sync="highlightedSouvenirDayValue"
                  />
                  <custom-list
                    v-if="!secondaryData && souvenirList"
                    :items="souvenirList"
                    show-colors
                  />
                  <compare-show-titles
                    v-if="secondaryData"
                    :primary-data="primaryData"
                    :secondary-data="secondaryData"
                  />
                  <div v-if="secondaryData" class="compare-data">
                    <div class="cards-row vertical">
                      <content-card
                        v-for="type in souvenirSubTypes"
                        :key="type.key"
                        dark-background
                        no-gradient
                        :title="('T_TOTAL_SOUVENIR_' + type.key) | translate"
                        :content="getTotalForShow(primaryData, 'souvenirsByDate', 0, type.key)"
                        type="compare"
                        :compare-data="{
                          primary: getTotalForShow(primaryData, 'souvenirsByDate', 0, type.key),
                          secondary: getTotalForShow(secondaryData, 'souvenirsByDate', 0, type.key)
                        }"
                        italic
                      />
                    </div>
                    <v-divider vertical inset />
                    <div class="cards-row vertical">
                      <content-card
                        v-for="type in souvenirSubTypes"
                        :key="type.key"
                        dark-background
                        no-gradient
                        :title="('T_TOTAL_SOUVENIR_' + type.key) | translate"
                        type="compare"
                        :content="getTotalForShow(secondaryData, 'souvenirsByDate', 0, type.key)"
                        italic
                      />
                    </div>
                  </div>
                  <div v-else class="cards-row mt-4">
                    <content-card
                      :title="'T_TOTAL_SOUVENIR_RETRIEVED' | translate"
                      no-gradient
                      half-width
                      dark-background
                      align-center
                      :content="getTotalForAllDays(primaryData, 'souvenirsByDate', 'retrieved')"
                    />
                    <content-card
                      :title="'T_TOTAL_SOUVENIR_PLAYED' | translate"
                      no-gradient
                      dark-background
                      half-width
                      align-center
                      :content="getTotalForAllDays(primaryData, 'souvenirsByDate', 'played')"
                    />
                  </div>
                </template>
                <no-graph-data v-else />
              </div>
            </v-card>
          </template>
        </div>
        <!-- Conversion -->
        <div
          v-if="!canCompareSubSection('conversion') || hasSubSection('conversion')"
          class="card-wrapper"
        >
          <card-top :options="getDesktopTitle('T_CONVERSION_FUNNEL')" divider />
          <v-card v-if="hasSubSection('conversion')" class="sub-section funnel">
            <card-top
              v-if="!secondaryData"
              :current-view="0"
              :options="getSectionCardTop('conversion')"
            />
            <div v-if="secondaryData" class="cards-row funnel-charts">
              <compare-show-titles :primary-data="primaryData" :secondary-data="secondaryData" />
              <div class="compare-show-highlighted">
                <funnel-chart v-if="funnel1" :data="funnel1" compare is-for-desktop />
                <v-divider vertical />
                <funnel-chart v-if="funnel2" :data="funnel2" compare is-for-desktop />
              </div>
            </div>
            <div v-else class="cards-row funnel-charts">
              <chart-selector
                :data="
                  getTouchpointsList(
                    primaryData,
                    {
                      label: 'T_TOUCHPOINTS'
                    },
                    true
                  )
                "
                center-label
                :current-value.sync="highlightedSouvenirTouchpointValue2"
              />
              <funnel-chart v-if="funnel1" :data="funnel1" is-for-desktop />
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSection from '@/mixins/BaseSection'
import ExperienceSection from '@/mixins/ExperienceSection'

export default {
  name: 'ExperienceSection',
  components: {},
  mixins: [BaseSection, ExperienceSection]
}
</script>

<style lang="scss" scoped>
@import '../../varsDesktop.scss';
#experience-section {
  .experiences-container {
    opacity: 0;
    transition: 0.5s opacity;
    &.displayed {
      opacity: 1;
    }
  }
  .funnel-charts {
    display: inline-block;
    width: 100%;
    margin-top: 0;
    .chart-selector {
      margin-top: 0;
    }
  }
  &.compare {
    .section {
      padding-top: $double-margin;
    }
    .pavilion-experiences {
      margin-bottom: 0;
    }
    .vouchers-redemption-rate-title {
      width: 100%;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: $scale-vw * 14;
      line-height: $scale-vw * 18;
      color: $purple;
      margin: $standard-margin 0;
    }
    .compare-data {
      margin-top: $half-margin;
    }
    .funnel-charts {
      .compare-show-titles {
        // margin-bottom: $standard-margin;
      }
    }
  }
}
.vertical-activations {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .pavilion-experiences {
    width: 100%;
    margin-bottom: $half-margin;
  }
}
.sub-section {
  &.full-width {
    width: 100%;
  }
}
</style>

<style lang="scss">
#experience-section {
  &.compare {
    .pavilion-experiences {
      .experience {
        &:last-child {
          .v-card {
            margin-bottom: 0;
          }
        }
      }
    }
    .compare-data {
      .cards-row.vertical {
        .v-card {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .sub-section {
      &.funnel {
        width: 100%;
      }
    }
  }
}
</style>
