<template>
  <v-menu
    offset-y
    dark
    tile
    min-width="100%"
    content-class="elevation-0"
    :close-on-content-click="false"
    @input="onInputMenu"
  >
    <template #activator="{}">
      <v-toolbar
        v-if="!selectedSecondaryPavilion"
        flat
        dark
        class="pavilion-selector first-bar"
        :class="{ 'is-for-desktop': isForDesktop }"
      >
        <div v-if="selectedPrimaryPavilion && selectedPrimaryPavilion.event" class="normal-toolbar">
          <div v-if="!isForDesktop" class="section-title">
            {{ currentSectionTitle() | translate }}
          </div>
          <div class="date-picker-container" :class="{ 'is-for-desktop': isForDesktop }">
            <event-date-picker
              v-if="!selectedSecondaryPavilion"
              v-model="dates"
              :is-for-desktop="isForDesktop"
              :event="selectedPrimaryPavilion.event"
              :pavilion="selectedPrimaryPavilion"
              @input="onChangeDates"
            />
            <event-days-picker
              v-else
              v-model="days"
              :is-for-desktop="isForDesktop"
              :event-days="eventDays"
              @input="onChangeDays"
            />
          </div>
        </div>
      </v-toolbar>
    </template>
  </v-menu>
</template>

<script>
import _ from 'lodash'
import EventDatePicker from './EventDatePicker'
import EventDaysPicker from './EventDaysPicker'
import CompareDataService from '@/services/CompareDataService'
import PavilionSelectorService from '@/services/PavilionSelectorService'
import LoadingService from '../services/LoadingService'

export default {
  components: {
    EventDatePicker,
    EventDaysPicker
  },
  props: {
    brands: {
      type: [Array, Boolean],
      default: false
    },
    selectedBrand: {
      type: Object,
      default: () => {}
    },
    selectedPrimaryPavilion: {
      type: Object,
      default: () => {}
    },
    selectedSecondaryPavilion: {
      type: Object,
      default: () => {}
    },
    selectedDates: {
      type: Array,
      default: () => []
    },
    selectedDays: {
      type: Array,
      default: () => []
    },
    isForDesktop: {
      type: Boolean,
      default: false
    },
    changingPavilion: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      days: this.selectedDays,
      dates: this.selectedDates,
      datePickerMenu: false,
      showPrimary: true,
      showSecondary: true,
      eventDays: 1
    }
  },
  watch: {
    selectedPrimaryPavilion() {
      this.updateEventDays()
    },
    selectedSecondaryPavilion() {
      this.updateEventDays()
    }
  },
  mounted() {
    this.updateEventDays()
  },
  methods: {
    currentSectionTitle() {
      const foundSection = _.find(PavilionSelectorService.sections, {
        key: _.get(_.split(this.$route.path, '/'), [1], 'event')
      })
      return _.get(foundSection, 'title', '')
    },
    updateEventDays() {
      this.eventDays = _.max([
        _.get(this.selectedPrimaryPavilion, 'event.days', 1),
        _.get(this.selectedSecondaryPavilion, 'event.days', 1)
      ])
    },
    onClickOpen() {
      if (this.selectedSecondaryPavilion) {
        return
      }
      this.onInputMenu()
    },
    onInputMenu() {
      if (this.isPavilionMenuOpen) {
        this.isPavilionUpdated = false
      } else if (this.isPavilionUpdated) {
        this.$emit('update:selectedDates', this.dates)
      }
    },
    async onChangeDates() {
      LoadingService.setLoading(true)
      const days = CompareDataService.eventDatesToDays(this.selectedPrimaryPavilion, this.dates)
      await CompareDataService.setDays(days)
      this.$emit('update:selectedDates', this.dates)
      this.$emit('changeDate')
      this.$nextTick(() => {
        if (!this.changingPavilion) {
          LoadingService.setLoading(false)
        }
        this.$forceUpdate()
      })
      // console.warn(`onChangeDates - `, days, this.dates)
    },
    async onChangeDays() {
      LoadingService.setLoading(true)
      await CompareDataService.setDays(this.days)
      this.$emit('update:selectedDays', this.days)
      this.$emit('changeDate')
      this.$nextTick(() => {
        if (!this.changingPavilion) {
          LoadingService.setLoading(false)
        }
        this.$forceUpdate()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.event-name {
  font-style: italic;
}
// .v-toolbar {
//   border: 1px red solid;
// }
.date-picker-container {
  width: 100%;
  text-align: right;
  &.is-for-desktop {
    text-align: left;
  }
}
.open-icon {
  transition: all 0.2s;
  &.rotate90 {
    transform: rotate(90deg);
  }
  &.disabled {
    opacity: 0.5;
    touch-action: none;
    pointer-events: none;
  }
}

.v-toolbar__content {
  cursor: pointer;
}
</style>

<style lang="scss">
@import '../vars.scss';
.normal-toolbar,
.responsive-compare-toolbar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.responsive-compare-toolbar {
  display: none;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: space-between;
  > .wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    height: 40px;
    &:first-child {
      margin-top: 20px;
    }
  }
}
.pavilion-selector {
  padding-left: $double-margin;
  padding-right: $standard-margin;
  &.responsive {
    display: none;
  }
  .section-title {
    font-family: SourceSansPro;
    font-style: italic;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    width: 100%;
  }
  &.is-for-desktop {
    padding: 0;
  }
}
@media (max-width: 480px) {
  .normal-toolbar {
    .event-name {
      width: 90%;
      margin-left: 0 !important;
    }
  }
  .responsive-compare-toolbar {
    display: flex;
    .date-picker-container {
      width: auto;
    }
    .show-wrapper {
      display: flex;
      flex-direction: row;
      width: 50%;
      justify-content: flex-start;
      align-items: center;
      .event-name {
        margin-left: 0px !important;
      }
      &:last-child {
        justify-content: flex-end;
      }
    }
  }
  .pavilion-selector {
    &.responsive {
      display: flex;
    }
  }
  .v-toolbar__content {
    height: 80px;
    padding: 0;
    // .logo-imagination {
    //   max-width: 16vw !important;
    // }
    .event-name {
      font-size: 3vw;
      margin-right: 0 !important;
    }
    // .v-image {
    //   margin: 0 8px;
    // }
  }
  .v-date-picker-title__date > div {
    display: none;
  }
  .v-date-picker-title__year {
    color: black;
    opacity: 1 !important;
  }
  .v-date-picker-title__date {
    border-bottom: 1px dashed $light-grey !important;
  }
  .v-picker__body {
    width: 100% !important;
  }
  .v-date-picker-header,
  .v-date-picker-table {
    padding: 0 $standard-margin;
  }
  .v-date-picker-header {
    .v-btn--icon.v-size--default {
      height: $standard-margin;
      width: $standard-margin;
    }
  }
  .v-date-picker-header__value {
    button {
      font-weight: 400;
      font-size: $scale-vw * 12;
      line-height: $scale-vw * 15;
    }
  }
  .v-picker__title {
    padding: 12px $standard-margin;
    pointer-events: none;
    touch-action: none;
  }
  .v-date-picker-title__year {
    font-style: italic;
    font-weight: 400;
    font-size: $scale-vw * 12;
    line-height: $scale-vw * 15;
  }
  .v-date-picker-table--date {
    height: auto;
    th {
      padding: 0;
      padding-bottom: 4px;
    }
    .v-btn {
      height: $double-margin;
      width: $double-margin;
    }
  }
  .theme--light.v-date-picker-table th,
  .theme--light.v-date-picker-table .v-date-picker-table--date__week {
    color: black;
    font-style: italic;
    font-weight: 400;
    font-size: $scale-vw * 12;
    line-height: $scale-vw * 15;
    text-align: center;
  }
  .theme--light.v-btn.v-btn--icon,
  .theme--light.v-date-picker-header
    .v-date-picker-header__value:not(.v-date-picker-header__value--disabled)
    button:not(:hover):not(:focus) {
    color: black;
  }

  .date-picker-menu,
  .v-picker--date,
  .v-card > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
    border-radius: 0;
    box-shadow: none !important;
  }
  .v-date-picker-table--date td {
    padding-bottom: 4px;
  }
  .v-application .white--text.v-date-picker-table__current {
    color: black !important;
  }
  .v-btn .v-btn__content {
    font-style: normal;
    font-weight: 700;
    font-size: $scale-vw * 14;
    line-height: $scale-vw * 18;
    text-align: center;
  }
}
.v-date-picker-table .v-btn.v-btn--active {
  color: white !important;
  background-color: $cyan !important;
}
.v-picker.incomplete-range .v-date-picker-table .v-btn.v-btn--active {
  &::before {
    opacity: 0 !important;
  }
  color: $background !important;
  background-color: white !important;
  border-color: $cyan !important;
  box-sizing: border-box;
  border: 2px solid $cyan !important;
}

@media (min-width: 500px) {
  .v-date-picker-header,
  .v-date-picker-table {
    padding: 0 $desktop-standard-margin;
  }
  .v-date-picker-header {
    .v-btn--icon.v-size--default {
      height: $desktop-standard-margin;
      width: $desktop-standard-margin;
    }
  }
  .v-date-picker-header__value {
    button {
      font-weight: 400;
      font-size: $desktop-scale-vw * 14;
      line-height: $desktop-scale-vw * 17;
    }
  }
  .v-picker__title {
    padding: 12px $desktop-standard-margin;
    pointer-events: none;
    touch-action: none;
  }
  .v-date-picker-title__year {
    font-style: italic;
    font-weight: 400;
    font-size: $desktop-scale-vw * 14;
    line-height: $desktop-scale-vw * 17;
  }
  .v-date-picker-title__date > div {
    display: none;
  }
  .v-date-picker-title__year {
    color: black;
    opacity: 1 !important;
  }
  .v-date-picker-title__date {
    border-bottom: 1px dashed $light-grey !important;
  }
  .v-date-picker-table--date {
    height: auto;
    th {
      padding: 0;
      padding-bottom: 4px;
    }
    .v-btn {
      height: $desktop-double-margin;
      width: $desktop-double-margin;
    }
  }
  .theme--light.v-date-picker-table th,
  .theme--light.v-date-picker-table .v-date-picker-table--date__week {
    color: black;
    font-style: italic;
    font-weight: 400;
    font-size: $desktop-scale-vw * 14;
    line-height: $desktop-scale-vw * 17;
    text-align: center;
  }
  .date-picker-container {
    .caption {
      width: 100%;
      .v-image {
        max-width: 24px;
      }
    }
  }
}
</style>
