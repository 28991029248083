<template>
  <div class="axo-view-wrapper" :style="getStyle()">
    <img
      v-if="data && data.pavilion && data.pavilion.axoImage"
      ref="axoImg"
      :src="data.pavilion.axoImage"
      class="axo-view"
    />
    <div class="areas-wrapper">
      <div class="axo-areas" :style="getStyle()">
        <div
          v-for="(area, i) in areas"
          :key="i"
          class="axo-area"
          :style="getPosition(area)"
          :class="{ displayed: area.visualIdentifier.length > 0 }"
        >
          <span class="name">
            {{ area.visualIdentifier }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      areas: [],
      axoSize: {
        width: 0,
        height: 0
      }
    }
  },
  async mounted() {
    await this.formatAreasForAxo(this.data)
  },
  methods: {
    getPosition(area) {
      return {
        width: area.width,
        height: area.height,
        left: area.left,
        top: area.top
      }
    },
    async getImageSize() {
      return new Promise((resolve) => {
        const img = new Image()
        img.onload = function () {
          // console.warn(`image size = `, this.width + 'x' + this.height)
          resolve({ width: this.width, height: this.height })
        }
        img.src = _.get(this.data, 'pavilion.axoImage', '')
      })
    },
    getStyle() {
      if (this.axoSize.width === 0) {
        return {}
      }
      return { width: `${this.axoSize.width}px`, height: `${this.axoSize.height}px` }
    },
    getPosVal(axo, field, coef) {
      return `${_.get(axo, field, 0) / coef}px`
    },
    findByAreaId(data, areaId) {
      return _.find(data, (item) => item.abd.areaId === areaId)
    },
    getVisualIdentifier(poi, vehicle) {
      return _.get(poi, 'visualIdentifier', _.get(vehicle, 'visualIdentifier', ''))
    },
    async formatAreasForAxo(data) {
      const imageSize = await this.getImageSize(data)
      this.axoSize = {
        width: _.get(this.$refs, `['axoImg'].clientWidth`, 0),
        height: _.get(this.$refs, `['axoImg'].clientHeight`, 0)
      }
      const x = imageSize.width / this.axoSize.width
      const y = imageSize.height / this.axoSize.height
      const areas = _.get(data, 'abdAreas', [])
      const pois = _.get(data, 'pois', false)
      const vehicles = _.get(data, 'vehicles', false)
      const formattedAreas = _.map(areas, (area) => {
        const poi = this.findByAreaId(pois, area.id)
        const vehicle = this.findByAreaId(vehicles, area.id)
        if (_.isUndefined(poi) && _.isUndefined(vehicle)) {
          console.info(`Area ${area.abdName}-${area.id} has no axo region`)
        }
        const axo = _.get(poi, 'axo', _.get(vehicle, 'axo', false))
        return {
          visualIdentifier: this.getVisualIdentifier(poi, vehicle),
          width: this.getPosVal(axo, 'width', x),
          height: this.getPosVal(axo, 'height', y),
          left: this.getPosVal(axo, 'x', x),
          top: this.getPosVal(axo, 'y', y)
        }
      })
      this.areas = _.filter(formattedAreas, (area) => area.id !== false)
      // console.warn(`AXO areas:`, this.areas)
    }
  }
}
</script>

<style lang="scss" scoped>
.axo-view-wrapper {
  position: relative;
  text-align: center;
  max-height: 200px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.axo-view {
  margin: 0 auto;
  width: auto;
  max-height: 200px;
  max-width: 100%;
}
.axo-area,
.areas-wrapper,
.name {
  position: absolute;
  top: 0;
  left: 0;
}
.axo-areas {
  position: relative;
  width: 100%;
  height: 100%;
  .axo-area {
    overflow: visible;
    opacity: 0;
    .name {
      display: inline-block;
      position: relative;
      color: black;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      background: white;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
      font-size: 9px;
      line-height: 9px;
      border-radius: 2px;
      padding: 3px;
    }
    &.displayed {
      opacity: 1;
    }
  }
  @media (min-width: 500px) {
  }
}
</style>
