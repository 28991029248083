var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.primaryData)?_c('div',{class:_vm.secondaryData ? 'compare' : 'section',attrs:{"id":"event-section"}},[(_vm.loading)?_c('section-loader'):(_vm.primaryData)?[(!_vm.secondaryData)?[_c('v-card',{staticClass:"first-card"},[_c('h2',[_vm._v(_vm._s(_vm._f("translate")(_vm.primaryData.pavilion.event.name)))]),_c('pavilion-gallery',{attrs:{"pavilion":_vm.primaryData.pavilion}}),_c('pavilion-info',{attrs:{"pavilion":_vm.primaryData.pavilion}})],1),(_vm.hasField(_vm.primaryData.pavilion, 'voiceOfMedia'))?_c('content-card',{attrs:{"title":_vm._f("translate")('T_VOICES_OF_MEDIA'),"content":_vm._f("translate")(_vm.primaryData.pavilion.voiceOfMedia),"can-expand":"","italic":"","truncate-text":"60"}}):_c('content-card',{attrs:{"title":_vm._f("translate")('T_VOICES_OF_MEDIA'),"no-data":"","italic":""}}),_c('div',{staticClass:"cards-row"},[(
            _vm.hasField(_vm.primaryData.pavilion, 'pressConference') &&
            _vm.translateArray(_vm.primaryData.pavilion, 'pressConference').length > 0
          )?_c('content-card',{attrs:{"title":_vm._f("translate")('T_PRESS_CONFERENCE'),"content-list":_vm.translateArray(_vm.primaryData.pavilion, 'pressConference'),"italic":"","can-expand":"","truncate-text":"60"}}):_c('content-card',{attrs:{"title":_vm._f("translate")('T_PRESS_CONFERENCE'),"no-data":"","italic":""}}),(
            _vm.hasField(_vm.primaryData.pavilion, 'mediaAttendees') &&
            _vm.translateArray(_vm.primaryData.pavilion, 'mediaAttendees').length > 0
          )?_c('content-card',{attrs:{"title":_vm._f("translate")('T_MEDIA_ATTENDANCEES'),"content-list":_vm.translateArray(_vm.primaryData.pavilion, 'mediaAttendees'),"can-expand":"","has-list-bullet":"","truncate-text":"5"}}):_c('content-card',{attrs:{"title":_vm._f("translate")('T_MEDIA_ATTENDANCEES'),"no-data":"","can-expand":""}})],1)]:[(_vm.secondaryData)?_c('compare-show-titles',{attrs:{"primary-data":_vm.primaryData,"secondary-data":_vm.secondaryData}}):_vm._e(),_c('div',{staticClass:"cards-row"},[_c('v-card',[_c('pavilion-gallery',{attrs:{"pavilion":_vm.primaryData.pavilion,"compare":""}}),_c('pavilion-info',{attrs:{"pavilion":_vm.primaryData.pavilion,"compare":""}})],1),_c('v-card',[_c('pavilion-gallery',{attrs:{"pavilion":_vm.secondaryData.pavilion,"compare":""}}),_c('pavilion-info',{attrs:{"pavilion":_vm.secondaryData.pavilion,"compare":""}})],1),(_vm.hasField(_vm.primaryData.pavilion, 'voiceOfMedia'))?_c('content-card',{attrs:{"title":_vm._f("translate")('T_VOICES_OF_MEDIA'),"content":_vm.hasField(_vm.primaryData.pavilion, 'voiceOfMedia'),"italic":"","can-expand":"","truncate-text":"60"}}):_c('content-card',{attrs:{"title":_vm._f("translate")('T_VOICES_OF_MEDIA'),"italic":"","no-data":""}}),(_vm.hasField(_vm.secondaryData.pavilion, 'voiceOfMedia'))?_c('content-card',{attrs:{"title":_vm._f("translate")('T_VOICES_OF_MEDIA'),"content":_vm.hasField(_vm.secondaryData.pavilion, 'voiceOfMedia'),"italic":"","can-expand":"","truncate-text":"60"}}):_c('content-card',{attrs:{"title":_vm._f("translate")('T_VOICES_OF_MEDIA'),"italic":"","no-data":""}}),(
            _vm.hasField(_vm.primaryData.pavilion, 'pressConference') &&
            _vm.translateArray(_vm.primaryData.pavilion, 'pressConference').length > 0
          )?_c('content-card',{attrs:{"title":_vm._f("translate")('T_PRESS_CONFERENCE'),"content-list":_vm.translateArray(_vm.primaryData.pavilion, 'pressConference'),"show-list-total":"","italic":"","can-expand":"","truncate-text":"60"}}):_c('content-card',{attrs:{"title":_vm._f("translate")('T_PRESS_CONFERENCE'),"italic":"","no-data":""}}),(
            _vm.hasField(_vm.secondaryData.pavilion, 'pressConference') &&
            _vm.translateArray(_vm.secondaryData.pavilion, 'pressConference').length > 0
          )?_c('content-card',{attrs:{"title":_vm._f("translate")('T_PRESS_CONFERENCE'),"content-list":_vm.translateArray(_vm.secondaryData.pavilion, 'pressConference'),"show-list-total":"","italic":"","can-expand":"","truncate-text":"60"}}):_c('content-card',{attrs:{"title":_vm._f("translate")('T_PRESS_CONFERENCE'),"italic":"","no-data":""}}),(
            _vm.hasField(_vm.primaryData.pavilion, 'mediaAttendees') &&
            _vm.translateArray(_vm.primaryData.pavilion, 'mediaAttendees').length > 0
          )?_c('content-card',{attrs:{"title":_vm._f("translate")('T_MEDIA_ATTENDANCEES'),"content-list":_vm.translateArray(_vm.primaryData.pavilion, 'mediaAttendees'),"show-list-total":"","can-expand":"","has-list-bullet":"","truncate-text":"5"}}):_c('content-card',{attrs:{"title":_vm._f("translate")('T_MEDIA_ATTENDANCEES'),"no-data":"","can-expand":""}}),(
            _vm.hasField(_vm.secondaryData.pavilion, 'mediaAttendees') &&
            _vm.translateArray(_vm.secondaryData.pavilion, 'mediaAttendees').length > 0
          )?_c('content-card',{attrs:{"title":_vm._f("translate")('T_MEDIA_ATTENDANCEES'),"content-list":_vm.translateArray(_vm.secondaryData.pavilion, 'mediaAttendees'),"show-list-total":"","can-expand":"","has-list-bullet":"","truncate-text":"5"}}):_c('content-card',{attrs:{"title":_vm._f("translate")('T_MEDIA_ATTENDANCEES'),"no-data":"","can-expand":""}})],1)]]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }