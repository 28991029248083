<template>
  <v-img
    v-if="!reset"
    :max-height="maxHeight"
    :max-width="maxWidth"
    :min-height="minHeight"
    :min-width="minWidth"
    :height="height"
    :width="width"
    :aspect-ratio="aspectRatio"
    :lazy-src="lazySrc"
    :src="src"
    :contain="contain"
    :eager="eager"
    :class="classes"
  >
    <template #placeholder>
      <v-row v-if="!gradientLoading" class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular size="15" indeterminate color="grey lighten-5"></v-progress-circular>
      </v-row>
      <v-row v-else class="fill-height ma-0 gradient-loading" align="center" justify="center">
      </v-row>
    </template>
  </v-img>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: undefined
    },
    height: {
      type: String,
      default: undefined
    },
    maxHeight: {
      type: String,
      default: undefined
    },
    minHeight: {
      type: String,
      default: undefined
    },
    width: {
      type: String,
      default: undefined
    },
    maxWidth: {
      type: String,
      default: undefined
    },
    minWidth: {
      type: String,
      default: undefined
    },
    contain: {
      type: Boolean,
      default: false
    },
    eager: {
      type: Boolean,
      default: false
    },
    lazySrc: {
      type: String,
      default: ''
    },
    aspectRatio: {
      type: Number,
      default: undefined
    },
    gradientLoading: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      reset: false
    }
  },
  watch: {
    src() {
      this.resetLoading()
    }
  },
  mounted() {},
  methods: {
    resetLoading() {
      this.reset = true
      this.$nextTick(() => {
        this.reset = false
        this.$forceUpdate()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';
$offset: 160;
.gradient-loading {
  min-height: 200px;
  width: 100%;
  background-color: rgba(63, 92, 198, 0.1);
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, #71dbd4 0%, rgba(63, 92, 198, 0.1) 100%);
    background-size: 250% 250%;
    animation: gradient-loading 3s infinite;
  }
}
@keyframes gradient-loading {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.v-image {
  height: 100%;
}
</style>
