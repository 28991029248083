<template>
  <div class="areas-table">
    <table>
      <template v-if="showUniqueVisitors">
        <thead>
          <th>{{ 'T_RANK' | translate }}</th>
          <th>{{ 'T_AREA_NAME' | translate }}</th>
          <th>{{ 'T_UNIQUE_VISITORS' | translate }}</th>
        </thead>
        <tr v-for="(area, i) in getSortedAreas()" :key="i">
          <td>{{ i + 1 + startingRank }}</td>
          <td>{{ area.id }} - {{ area.name }}</td>
          <td>{{ area.uniqueVisitors | numeral('0,0') }}</td>
        </tr>
      </template>
      <template v-else>
        <thead>
          <th>{{ 'T_ID' | translate }}</th>
          <th>{{ 'T_AREA_NAME' | translate }}</th>
        </thead>
        <tr v-for="(area, i) in areas" :key="i">
          <td>{{ area.id }}</td>
          <td>{{ area.name }}</td>
        </tr>
      </template>
    </table>
  </div>
</template>
<script>
import _ from 'lodash'
import TranslateService from '@/services/TranslateService'

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    startingRank: {
      type: Number,
      default: 0
    },
    list: {
      type: [Array, Boolean],
      default: () => false
    },
    showUniqueVisitors: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      areas: []
    }
  },
  async mounted() {
    if (this.list) {
      this.areas = this.list
      return
    }
    await this.formatAreasForAxo(this.data)
  },
  methods: {
    getUniqueVisitorsForArea(data, area) {
      return _.sum(_.map(_.filter(_.get(data, 'abdTrafficAreas', []), { area: area._id }), 'count'))
    },
    getSortedAreas() {
      return _.orderBy(this.areas, ['uniqueVisitors'], ['desc'])
    },
    findByAreaId(data, areaId) {
      return _.find(data, (item) => item.abd.areaId === areaId)
    },
    getVisualIdentifier(poi, vehicle) {
      return _.get(poi, 'visualIdentifier', _.get(vehicle, 'visualIdentifier', ''))
    },
    getVehicleName(area, vehicle) {
      if (!_.get(vehicle, 'nameplate', false)) {
        return area.abdName
      }
      let names = _.compact([
        _.get(vehicle, 'nameplate', false),
        _.get(vehicle, 'series', false),
        _.get(vehicle, 'color', false)
      ])
      names = _.map(names, (name) => TranslateService.get(name))
      names = _.compact(_.map(names, (name) => (name === '' ? false : name)))
      return names.join(' - ')
    },
    getAreaName(area, poi, vehicle) {
      const poiName = _.get(poi, 'name', false)
      if (poiName) {
        return TranslateService.get(poiName)
      }
      return this.getVehicleName(area, vehicle)
    },
    async formatAreasForAxo(data) {
      const areas = _.get(data, 'abdAreas', [])
      const pois = _.get(data, 'pois', false)
      const vehicles = _.get(data, 'vehicles', false)
      let formattedAreas = _.map(areas, (area) => {
        const poi = this.findByAreaId(pois, area.id)
        const vehicle = this.findByAreaId(vehicles, area.id)
        if (_.isUndefined(poi) && _.isUndefined(vehicle)) {
          console.info(`Area ${area.abdName}-${area.id} has no axo region`)
        }
        const formattedArea = {
          id: this.getVisualIdentifier(poi, vehicle),
          name: this.getAreaName(area, poi, vehicle),
          uniqueVisitors: this.getUniqueVisitorsForArea(data, area)
        }
        return formattedArea
      })
      formattedAreas = _.filter(formattedAreas, (area) => area.id !== false)
      if (!this.showUniqueVisitors) {
        formattedAreas.sort((a, b) =>
          `${_.get(a, 'id', _.get(a, 'name', '?'))}`.localeCompare(
            `${_.get(b, 'id', _.get(b, 'name', '??'))}`,
            'en',
            { numeric: true }
          )
        )
      }
      this.areas = formattedAreas
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../varsDesktop.scss';
.areas-table {
  table {
    width: 90%;
    margin: $standard-margin auto;
    font-style: italic;
    font-weight: 400;
    font-size: $scale-vw * 14;
    line-height: $scale-vw * 26;
    text-align: center;
    color: white;
  }

  th,
  td {
    border: $table-border;
  }
  tr {
    background: $table-odd-row-background;
    &:nth-child(even) {
      background: $table-even-row-background;
    }
  }
  @media (max-width: 500px) {
    table {
      font-size: $scale-vw * 35;
      line-height: $scale-vw * 70;
    }
  }
}
</style>
