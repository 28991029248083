<script>
import _ from 'lodash'
import LoginService from '@/services/LoginService'
import PavilionSelectorService from '@/services/PavilionSelectorService'
import FullscreenViewerService from '@/services/FullscreenViewerService'
import CompareDataService from '@/services/CompareDataService'
import TranslateService from '@/services/TranslateService'
import PavilionSelector from '@/components/PavilionSelector'
import ViewerFullscreen from '@/components/ViewerFullscreen'

import LoginPage from '@/views/LoginPage.vue'
export default {
  name: 'AppMixin',
  components: {
    LoginPage,
    ViewerFullscreen,
    PavilionSelector
  },
  props: {},
  data() {
    return {
      isLoaded: false,
      showFullscreenOverlay: false,
      fullscreenImage: false,
      fullscreenContent: {},
      fullscreenItems: false,
      fullScreenVideoPlaying: false,
      user: null,
      brands: [],
      selectedBrand: null,
      selectedPrimaryPavilion: null,
      selectedSecondaryPavilion: null,
      primaryData: null,
      secondaryData: null,
      selectedDates: null,
      selectedDays: null,
      currentFullscreenSlide: 0,
      currentSectionKey: false,
      CompareDataService,
      TranslateService
    }
  },
  watch: {},
  async mounted() {
    this.isLoaded = false
    await this.preloadImages()
    try {
      await TranslateService.init()
    } catch (error) {
      console.error(`Error: `, error)
    }
    console.debug('Translations are loaded')
    this.currentSectionKey = this.getCurrentSectionKey()
    if (this.currentSectionKey !== 'pdf' && !this.currentRouteIsValid()) {
      this.$router.push('event').catch(() => {})
    }
    const user = await LoginService.getStatus()
    if (!_.isEmpty(user)) {
      this.user = user
    }
    this.isLoaded = true
    console.debug('Page loaded')
    LoginService.onLogout(() => {
      this.user = null
    })
    TranslateService.events.on('gotTranslations', this.changeWebsiteTitle)
    TranslateService.events.on('changeLanguage', this.changeWebsiteTitle)
    PavilionSelectorService.events.on('update-pavilion-data', (data) => {
      if (!_.get(data, 'query', false)) {
        this.isLoaded = true
        console.debug('update-pavilion-data Page loaded')
        return
      }
      // console.warn(`received pavilion data = `, data)
      _.each(data, (val, key) => {
        if (key === 'query') {
          if (!_.isEqual(this.$route.query, val)) {
            if (_.get(this.$route.query, 'username', false)) {
              _.set(val, 'username', this.$route.query.username)
            }
            if (_.get(this.$route.query, 'pass', false)) {
              _.set(val, 'pass', this.$route.query.pass)
            }
            this.$router.replace({ query: _.cloneDeep(val) }).catch(() => {})
          }
        } else {
          _.set(this, key, val)
        }
      })
      this.isLoaded = true
      this.$forceUpdate()
    })
    PavilionSelectorService.getData(this.$route.query, true)
    FullscreenViewerService.events.on('displayViewer', (data, display = false) => {
      if (!data) {
        return
      }
      if (_.isString(data)) {
        this.fullscreenImage = data
      } else if (_.get(data, 'items', false)) {
        this.currentFullscreenSlide = _.get(data, 'progress', 0)
        this.fullscreenItems = _.get(data, 'items', false)
        // console.warn(`received displayViewer event with data = `, data)
      } else if (_.isObject(data)) {
        this.fullscreenContent = data
      }
      this.showFullscreenOverlay = display
    })
  },
  methods: {
    currentRouteIsValid() {
      const l = this.$router.resolve({ name: this.$route.name })
      return l.resolved.matched.length > 0
    },
    async preloadImages() {
      try {
        await this.$imagePreload([
          require('@/assets/imagination-logo.svg'),
          require('@/assets/logo.png'),
          require('@/assets/logo.svg'),
          require('@/assets/logo-imagination.png'),
          require('@/assets/icons/404.svg'),
          require('@/assets/icons/area.svg'),
          require('@/assets/icons/arrow.svg'),
          require('@/assets/icons/calendar-purple.svg'),
          require('@/assets/icons/calendar.svg'),
          require('@/assets/icons/card-counter.svg'),
          require('@/assets/icons/circle-arrow.svg'),
          require('@/assets/icons/close.svg'),
          require('@/assets/icons/cloud.svg'),
          require('@/assets/icons/compare.svg'),
          require('@/assets/icons/date.svg'),
          require('@/assets/icons/download-pdf.svg'),
          require('@/assets/icons/eraser.svg'),
          require('@/assets/icons/event.svg'),
          require('@/assets/icons/expand.svg'),
          require('@/assets/icons/experience.svg'),
          require('@/assets/icons/floorplan.svg'),
          require('@/assets/icons/home.svg'),
          require('@/assets/icons/hour.svg'),
          require('@/assets/icons/indicator-down.svg'),
          require('@/assets/icons/indicator-up.svg'),
          require('@/assets/icons/info.svg'),
          require('@/assets/icons/jump-arrow.svg'),
          require('@/assets/icons/lang-cn.svg'),
          require('@/assets/icons/lang-en.svg'),
          require('@/assets/icons/list.svg'),
          require('@/assets/icons/location.svg'),
          require('@/assets/icons/log-out.svg'),
          require('@/assets/icons/menu.svg'),
          require('@/assets/icons/nameplate.svg'),
          require('@/assets/icons/neighbors.svg'),
          require('@/assets/icons/overview.svg'),
          require('@/assets/icons/play.svg'),
          require('@/assets/icons/rain.svg'),
          require('@/assets/icons/sales-active.svg'),
          require('@/assets/icons/sales.svg'),
          require('@/assets/icons/snow.svg'),
          require('@/assets/icons/souvenir.svg'),
          require('@/assets/icons/star.svg'),
          require('@/assets/icons/star-black.svg'),
          require('@/assets/icons/sun.svg'),
          require('@/assets/icons/switch.svg'),
          require('@/assets/icons/thunder.svg'),
          require('@/assets/icons/traffic-active.svg'),
          require('@/assets/icons/traffic.svg'),
          require('@/assets/icons/view-off.svg'),
          require('@/assets/icons/view-on.svg')
        ])
      } catch (error) {
        console.warn('Failed to load an asset:', error)
      }
      console.debug('All images are loaded')
    },
    getCurrentSectionKey(defaultVal = false) {
      return _.get(_.split(this.$route.path, '/'), [1], defaultVal ? defaultVal : 'event')
    },
    closeFullscreenViewer() {
      this.currentFullscreenSlide = 0
      this.fullscreenImage = false
      this.fullscreenContent = false
      this.fullscreenItems = false
      this.showFullscreenOverlay = false
    },
    async onLoginCallback() {
      await PavilionSelectorService.getData(this.$route.query, true)
    },
    changeWebsiteTitle() {
      document.title = TranslateService.get('T_WEBSITE_TITLE')
    }
  }
}
</script>
