<template>
  <div id="overview-section" :class="{ compare: secondaryData }">
    <section-loader v-if="loading" />
    <template v-else-if="primaryData">
      <div v-if="secondaryData" class="big-section-title">{{ 'T_OVERVIEW' | translate }}</div>
      <v-chip-group
        v-model="currentSubSection"
        background-color="transparent"
        mandatory
        dark
        class="sub-sections"
      >
        <v-chip
          v-for="(subSection, i) in filteredSubSections"
          :key="i"
          :color="i == currentSubSection ? 'cyan' : 'primary'"
          link
          @click="goToTab(i)"
        >
          {{ subSection.title | translate }}
        </v-chip>
      </v-chip-group>
      <div class="sub-sections-wrapper">
        <v-tabs-items v-if="primaryData" v-model="currentSubSection">
          <!-- Performance -->
          <v-tab-item class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter :current="1" :total="filteredSubSections.length" />
              <v-card class="sub-section no-card-top-mb">
                <card-top
                  :current-view.sync="selectedSubView"
                  :options="getSectionCardTop('performance')"
                />
                <radar-chart v-if="dataList" :labels="labels" :data-list="dataList" />
                <div v-if="secondaryData" class="custom-legend-wrapper">
                  <div class="custom-legend">
                    <div class="data-label">
                      <span
                        class="circle-color"
                        :style="`background-color:${circleColors[0]}`"
                      ></span>
                      <span class="label">{{ primaryData.pavilion.event.key }}</span>
                    </div>
                    <div class="data-label">
                      <span
                        class="circle-color"
                        :style="`background-color:${circleColors[1]}`"
                      ></span>
                      <span class="label">{{ secondaryData.pavilion.event.key }}</span>
                    </div>
                  </div>
                  <compare-show-titles
                    v-if="secondaryData"
                    :primary-data="primaryData"
                    :secondary-data="secondaryData"
                  />
                  <div v-if="dataList" class="compare-data">
                    <div class="cards-row vertical">
                      <content-card
                        v-for="(contentCard, i) in dataList[0].contentCards"
                        :key="i"
                        no-gradient
                        :title="contentCard.item.label | translate"
                        dark-background
                        :content="contentCard.value"
                        type="compare"
                        :compare-data="{
                          primary: contentCard.value,
                          secondary: dataList[1].contentCards[i].value
                        }"
                        italic
                        :no-data="!contentCard.value"
                      />
                    </div>
                    <v-divider vertical inset />
                    <div class="cards-row vertical">
                      <content-card
                        v-for="(contentCard, i) in dataList[1].contentCards"
                        :key="i"
                        no-gradient
                        :title="contentCard.item.label | translate"
                        dark-background
                        :content="contentCard.value"
                        type="compare"
                        italic
                        :no-data="!contentCard.value"
                      />
                    </div>
                  </div>
                </div>
                <div v-else-if="dataList" class="cards-row">
                  <content-card
                    v-for="(contentCard, i) in dataList[0].contentCards"
                    :key="i"
                    no-gradient
                    :link="contentCard.item.link"
                    :title="contentCard.item.label | translate"
                    :content="contentCard.value"
                    italic
                    :no-data="!contentCard.value"
                  />
                </div>
              </v-card>
            </div>
          </v-tab-item>
          <!-- Highlights -->
          <v-tab-item class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter :current="2" :total="filteredSubSections.length" />
              <v-card class="sub-section">
                <card-top
                  :current-view.sync="selectedSubView"
                  :options="getSectionCardTop('highlights')"
                />
                <compare-show-titles
                  v-if="secondaryData"
                  :primary-data="primaryData"
                  :secondary-data="secondaryData"
                />
                <h2>{{ 'T_TRAFFIC' | translate }}</h2>
                <!-- Compare mode -->
                <div v-if="secondaryData" class="cards-row">
                  <content-card
                    v-if="primaryData.total.eventVisitors"
                    no-gradient
                    :title="'T_TOTAL_EVENT_VISITOR' | translate"
                    :content="primaryData.total.eventVisitors"
                    type="compare"
                    :compare-data="{
                      primary: primaryData.total.eventVisitors,
                      secondary: secondaryData.total.eventVisitors
                    }"
                    italic
                  />
                  <content-card
                    v-else
                    no-gradient
                    :title="'T_TOTAL_EVENT_VISITOR' | translate"
                    no-data
                    italic
                  />
                  <content-card
                    v-if="secondaryData.total.eventVisitors"
                    no-gradient
                    :title="'T_TOTAL_EVENT_VISITOR' | translate"
                    :content="secondaryData.total.eventVisitors | numeral"
                  />
                  <content-card
                    v-else
                    no-gradient
                    :title="'T_TOTAL_EVENT_VISITOR' | translate"
                    no-data
                    italic
                  />

                  <content-card
                    v-if="primaryData.total.standTraffic"
                    no-gradient
                    :title="'T_TOTAL_STAND_VISITOR' | translate"
                    :content="primaryData.total.standTraffic"
                    type="compare"
                    :compare-data="{
                      primary: primaryData.total.standTraffic,
                      secondary: secondaryData.total.standTraffic
                    }"
                    italic
                  />
                  <content-card
                    v-else
                    :title="'T_TOTAL_STAND_VISITOR' | translate"
                    no-gradient
                    no-data
                    italic
                  />
                  <content-card
                    v-if="secondaryData.total.standTraffic"
                    no-gradient
                    :title="'T_TOTAL_STAND_VISITOR' | translate"
                    :content="secondaryData.total.standTraffic"
                  />
                  <content-card
                    v-else
                    :title="'T_TOTAL_STAND_VISITOR' | translate"
                    no-gradient
                    no-data
                    italic
                  />

                  <content-card
                    v-if="(primaryData.total.standTraffic / primaryData.total.eventVisitors) * 100"
                    no-gradient
                    :title="'T_TOTAL_VISITORS_CONVERSION' | translate"
                    type="compare"
                    :compare-data="{
                      primaryPercentage: reduceConversion(
                        primaryData.total.standTraffic,
                        primaryData.total.eventVisitors
                      ),
                      primary:
                        (primaryData.total.standTraffic / primaryData.total.eventVisitors) * 100,
                      secondary:
                        (secondaryData.total.standTraffic / secondaryData.total.eventVisitors) * 100
                    }"
                    conversion
                    italic
                  />
                  <content-card
                    v-else
                    :title="'T_TOTAL_VISITORS_CONVERSION' | translate"
                    no-gradient
                    no-data
                    italic
                  />
                  <content-card
                    v-if="secondaryData.total.standTraffic && secondaryData.total.eventVisitors"
                    no-gradient
                    :title="'T_TOTAL_VISITORS_CONVERSION' | translate"
                    type="compare"
                    :content="
                      reduceConversion(
                        secondaryData.total.standTraffic,
                        secondaryData.total.eventVisitors
                      )
                    "
                    conversion
                    italic
                  />
                  <content-card
                    v-else
                    :title="'T_TOTAL_VISITORS_CONVERSION' | translate"
                    no-gradient
                    no-data
                    italic
                  />
                  <template v-if="hasLeads(primaryData)">
                    <content-card
                      v-if="hasLeads(primaryData)"
                      no-gradient
                      :title="'T_TOTAL_LEADS_GENERATION' | translate"
                      :content="primaryData.total.leadsGeneration"
                      type="compare"
                      :compare-data="{
                        primary: primaryData.total.leadsGeneration,
                        secondary: secondaryData.total.leadsGeneration
                      }"
                      :no-data="!getTotalVal(primaryData, 'leadsGeneration')"
                    />
                    <content-card
                      v-if="hasLeads(secondaryData)"
                      no-gradient
                      :title="'T_TOTAL_LEADS_GENERATION' | translate"
                      :content="secondaryData.total.leadsGeneration"
                      :no-data="!getTotalVal(secondaryData, 'leadsGeneration')"
                    />
                  </template>
                  <template v-if="hasOrders(primaryData)">
                    <content-card
                      no-gradient
                      :title="'T_TOTAL_ORDERS_GENERATION' | translate"
                      :content="primaryData.total.ordersGeneration"
                      type="compare"
                      :compare-data="{
                        primary: primaryData.total.ordersGeneration,
                        secondary: secondaryData.total.ordersGeneration
                      }"
                      :no-data="!getTotalVal(primaryData, 'ordersGeneration')"
                    />
                    <content-card
                      no-gradient
                      :title="'T_TOTAL_ORDERS_GENERATION' | translate"
                      :content="secondaryData.total.ordersGeneration"
                      :no-data="!getTotalVal(secondaryData, 'ordersGeneration')"
                    />
                  </template>

                  <content-card
                    v-if="getBestNameplate(primaryData)"
                    no-gradient
                    :title="'T_BEST_PERFORMING_NAMEPLATE' | translate"
                    :content="getBestNameplate(primaryData)"
                  />
                  <content-card
                    v-else
                    no-gradient
                    :title="'T_BEST_PERFORMING_NAMEPLATE' | translate"
                    no-data
                  />
                  <content-card
                    v-if="getBestNameplate(secondaryData)"
                    no-gradient
                    :title="'T_BEST_PERFORMING_NAMEPLATE' | translate"
                    :content="getBestNameplate(secondaryData)"
                  />
                  <content-card
                    v-else
                    no-gradient
                    :title="'T_BEST_PERFORMING_NAMEPLATE' | translate"
                    no-data
                  />
                </div>
                <!-- View show mode -->
                <div v-else class="cards-row">
                  <content-card
                    v-if="primaryData.total.eventVisitors"
                    no-gradient
                    :title="'T_TOTAL_EVENT_VISITOR' | translate"
                    :content="primaryData.total.eventVisitors | numeral"
                    :no-data="!primaryData.total.eventVisitors"
                  />

                  <content-card
                    v-if="primaryData.total.standTraffic"
                    no-gradient
                    :title="'T_TOTAL_STAND_VISITOR' | translate"
                    :content="primaryData.total.standTraffic"
                    :no-data="!primaryData.total.standTraffic"
                  />
                  <content-card
                    v-if="primaryData.total.standTraffic && primaryData.total.eventVisitors"
                    no-gradient
                    :title="'T_TOTAL_VISITORS_CONVERSION' | translate"
                    :content="
                      reduceConversion(
                        primaryData.total.standTraffic,
                        primaryData.total.eventVisitors
                      )
                    "
                    conversion
                    :no-data="!primaryData.total.standTraffic || !primaryData.total.eventVisitors"
                  />
                  <content-card
                    v-if="hasLeads(primaryData)"
                    no-gradient
                    :title="'T_TOTAL_LEADS_GENERATION' | translate"
                    :content="primaryData.total.leadsGeneration"
                    :no-data="!getTotalVal(primaryData, 'leadsGeneration')"
                  />
                  <content-card
                    v-if="hasOrders(primaryData)"
                    no-gradient
                    :title="'T_TOTAL_ORDERS_GENERATION' | translate"
                    :content="primaryData.total.ordersGeneration"
                    :no-data="!getTotalVal(primaryData, 'ordersGeneration')"
                  />
                  <content-card
                    v-if="getBestNameplate(primaryData)"
                    no-gradient
                    :title="'T_BEST_PERFORMING_NAMEPLATE' | translate"
                    :content="getBestNameplate(primaryData)"
                  />
                  <content-card
                    v-else
                    no-gradient
                    no-data
                    :title="'T_BEST_PERFORMING_NAMEPLATE' | translate"
                  />
                </div>
                <h2>{{ 'T_ONLINE_TRAFFIC' | translate }}</h2>
                <!-- Compare mode -->
                <div v-if="primaryData && secondaryData" class="cards-row">
                  <content-card
                    v-if="primaryData.total.wechatUniqueVisit"
                    no-gradient
                    :title="'T_WECHAT_UNIQUE_VISITORS' | translate"
                    :content="primaryData.total.wechatUniqueVisit"
                  />
                  <content-card
                    v-else
                    no-gradient
                    no-data
                    :title="'T_WECHAT_UNIQUE_VISITORS' | translate"
                  />
                  <content-card
                    v-if="secondaryData.total.wechatUniqueVisit"
                    no-gradient
                    :title="'T_WECHAT_UNIQUE_VISITORS' | translate"
                    :content="secondaryData.total.wechatUniqueVisit"
                  />
                  <content-card
                    v-else
                    no-data
                    no-gradient
                    :title="'T_WECHAT_UNIQUE_VISITORS' | translate"
                  />
                  <content-card
                    v-if="primaryData.total.wechatVisit"
                    no-gradient
                    :title="'T_WECHAT_TOTAL_VISITORS' | translate"
                    :content="primaryData.total.wechatVisit"
                  />
                  <content-card
                    v-else
                    no-gradient
                    no-data
                    :title="'T_WECHAT_TOTAL_VISITORS' | translate"
                  />
                  <content-card
                    v-if="secondaryData.total.wechatVisit"
                    no-gradient
                    :title="'T_WECHAT_TOTAL_VISITORS' | translate"
                    :content="secondaryData.total.wechatVisit"
                  />
                  <content-card
                    v-else
                    no-gradient
                    no-data
                    :title="'T_WECHAT_TOTAL_VISITORS' | translate"
                  />
                  <content-card
                    v-if="primaryData.conversionFunnel && primaryData.conversionFunnel.downloaded"
                    no-gradient
                    :title="'T_TOTAL_DOWNLOAD' | translate"
                    :content="primaryData.conversionFunnel.downloaded"
                  />
                  <content-card
                    v-else
                    no-gradient
                    no-data
                    :title="'T_TOTAL_DOWNLOAD' | translate"
                  />
                  <content-card
                    v-if="
                      secondaryData.conversionFunnel && secondaryData.conversionFunnel.downloaded
                    "
                    no-gradient
                    :title="'T_TOTAL_DOWNLOAD' | translate"
                    :content="secondaryData.conversionFunnel.downloaded"
                  />
                  <content-card
                    v-else
                    no-gradient
                    no-data
                    :title="'T_TOTAL_DOWNLOAD' | translate"
                  />
                  <content-card
                    v-if="primaryData.conversionFunnel && primaryData.conversionFunnel.retrieved"
                    no-gradient
                    :title="'T_TOTAL_SOUVENIR_RETRIEVED' | translate"
                    :content="primaryData.conversionFunnel.retrieved"
                  />
                  <content-card
                    v-else
                    no-gradient
                    no-data
                    :title="'T_TOTAL_SOUVENIR_RETRIEVED' | translate"
                  />
                  <content-card
                    v-if="
                      secondaryData.conversionFunnel && secondaryData.conversionFunnel.retrieved
                    "
                    no-gradient
                    :title="'T_TOTAL_SOUVENIR_RETRIEVED' | translate"
                    :content="secondaryData.conversionFunnel.retrieved"
                  />
                  <content-card
                    v-else
                    no-gradient
                    no-data
                    :title="'T_TOTAL_SOUVENIR_RETRIEVED' | translate"
                  />
                </div>
                <!-- View show mode -->
                <div v-else class="cards-row">
                  <content-card
                    v-if="primaryData.total.wechatUniqueVisit"
                    no-gradient
                    :title="'T_WECHAT_UNIQUE_VISITORS' | translate"
                    :content="primaryData.total.wechatUniqueVisit"
                  />
                  <content-card
                    v-else
                    no-gradient
                    no-data
                    :title="'T_WECHAT_UNIQUE_VISITORS' | translate"
                  />
                  <content-card
                    v-if="primaryData.total.wechatVisit"
                    no-gradient
                    :title="'T_WECHAT_TOTAL_VISITORS' | translate"
                    :content="primaryData.total.wechatVisit"
                  />
                  <content-card
                    v-else
                    no-gradient
                    no-data
                    :title="'T_WECHAT_TOTAL_VISITORS' | translate"
                  />
                  <content-card
                    v-if="primaryData.conversionFunnel && primaryData.conversionFunnel.downloaded"
                    no-gradient
                    :title="'T_TOTAL_DOWNLOAD' | translate"
                    :content="primaryData.conversionFunnel.downloaded"
                  />
                  <content-card
                    v-else
                    no-gradient
                    no-data
                    :title="'T_TOTAL_DOWNLOAD' | translate"
                  />
                  <content-card
                    v-if="primaryData.conversionFunnel && primaryData.conversionFunnel.retrieved"
                    no-gradient
                    :title="'T_TOTAL_SOUVENIR_RETRIEVED' | translate"
                    :content="primaryData.conversionFunnel.retrieved"
                  />
                  <content-card
                    v-else
                    no-gradient
                    no-data
                    :title="'T_TOTAL_SOUVENIR_RETRIEVED' | translate"
                  />
                </div>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>
  </div>
</template>

<script>
import BaseSection from '@/mixins/BaseSection'
import OverviewSection from '@/mixins/OverviewSection'

export default {
  name: 'OverviewSection',
  mixins: [BaseSection, OverviewSection]
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';

#overview-section {
  .custom-legend-wrapper {
    width: 100%;
    text-align: center;
  }
  .custom-legend {
    display: inline-block;
    background-color: $dark-background;
    border-radius: 8px;
    padding: $half-margin;
    font-style: italic;
    font-weight: 400;
    font-size: $scale-vw * 10;
    line-height: $scale-vw * 13;
    .data-label {
      display: inline-flex;
      align-items: center;
      justify-items: flex-start;
      &:last-child {
        margin-left: $standard-margin;
      }
    }
  }
  h2 {
    text-align: left;
  }
}
.no-card-top-mb {
  .card-top {
    margin-bottom: 0;
  }
}
</style>
