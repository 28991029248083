<template>
  <div class="compare-show-titles">
    <div class="show-name">{{ getShowName(primaryData) | translate }}</div>
    <div class="vs">{{ 'T_VS' | translate }}</div>
    <div class="show-name">{{ getShowName(secondaryData) | translate }}</div>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  props: {
    primaryData: {
      type: Object,
      default: () => {}
    },
    secondaryData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.localCurrentView = _.clone(this.currentView)
  },
  methods: {
    getShowName(data) {
      return _.get(data, 'pavilion.event.name', '')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../vars.scss';
.compare-show-titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  padding-top: $standard-margin;
  padding-bottom: $half-margin;
  .show-name {
    max-width: 42%;
    &:first-child {
      color: $yellow;
    }
    &:last-child {
      color: $green;
    }
  }
  .vs {
    color: $purple;
  }
}
</style>
