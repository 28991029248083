<template>
  <div v-if="primaryData" id="event-section" :class="getDesktopWrapperClass()">
    <section-loader v-if="loading" />
    <template v-else>
      <template v-if="!secondaryData">
        <div class="cards-row">
          <v-card class="first-card">
            <h2>{{ primaryData.pavilion.event.name | translate }}</h2>
            <pavilion-gallery is-for-desktop :pavilion="primaryData.pavilion" />
            <pavilion-info is-for-desktop :pavilion="primaryData.pavilion" />
          </v-card>
          <!-- Voices of media -->
          <content-card
            :no-data="!hasField(primaryData.pavilion, 'voiceOfMedia')"
            :title="'T_VOICES_OF_MEDIA' | translate"
            :content="primaryData.pavilion.voiceOfMedia | translate"
            italic
          />
        </div>
        <!-- Press conference + Media attendancees -->
        <div class="cards-row">
          <content-card
            :no-data="
              !hasField(primaryData.pavilion, 'pressConference') ||
              translateArray(primaryData.pavilion, 'pressConference').length === 0
            "
            :title="'T_PRESS_CONFERENCE' | translate"
            :content-list="translateArray(primaryData.pavilion, 'pressConference')"
            italic
          />
          <content-card
            :no-data="
              !hasField(primaryData.pavilion, 'mediaAttendees') ||
              translateArray(primaryData.pavilion, 'mediaAttendees').length === 0
            "
            :title="'T_MEDIA_ATTENDANCEES' | translate"
            :content-list="translateArray(primaryData.pavilion, 'mediaAttendees')"
            has-list-bullet
          />
        </div>
      </template>
      <template v-else>
        <div class="cards-row">
          <compare-show-titles
            v-if="secondaryData"
            :primary-data="primaryData"
            :secondary-data="secondaryData"
          />
        </div>

        <div class="cards-row">
          <v-card>
            <pavilion-gallery is-for-desktop :pavilion="primaryData.pavilion" compare />
            <pavilion-info is-for-desktop :pavilion="primaryData.pavilion" compare />
          </v-card>
          <v-card>
            <pavilion-gallery is-for-desktop :pavilion="secondaryData.pavilion" compare />
            <pavilion-info is-for-desktop :pavilion="secondaryData.pavilion" compare />
          </v-card>
          <!-- Voices of media -->
          <content-card
            :no-data="!hasField(primaryData.pavilion, 'voiceOfMedia')"
            :title="'T_VOICES_OF_MEDIA' | translate"
            :content="hasField(primaryData.pavilion, 'voiceOfMedia')"
            italic
            truncate-text="60"
          />
          <content-card
            :title="'T_VOICES_OF_MEDIA' | translate"
            :content="hasField(secondaryData.pavilion, 'voiceOfMedia')"
            italic
            truncate-text="60"
            :no-data="!hasField(secondaryData.pavilion, 'voiceOfMedia')"
          />
          <!-- Press conference + Media attendancees -->
          <content-card
            :title="'T_PRESS_CONFERENCE' | translate"
            :content-list="translateArray(primaryData.pavilion, 'pressConference')"
            show-list-total
            italic
            truncate-text="60"
            :no-data="
              !hasField(primaryData.pavilion, 'pressConference') ||
              translateArray(primaryData.pavilion, 'pressConference').length === 0
            "
          />
          <content-card
            :title="'T_PRESS_CONFERENCE' | translate"
            :content-list="translateArray(secondaryData.pavilion, 'pressConference')"
            show-list-total
            italic
            truncate-text="60"
            :no-data="
              !hasField(secondaryData.pavilion, 'pressConference') ||
              translateArray(secondaryData.pavilion, 'pressConference').length === 0
            "
          />
          <content-card
            :no-data="
              !hasField(primaryData.pavilion, 'mediaAttendees') ||
              translateArray(primaryData.pavilion, 'mediaAttendees').length === 0
            "
            :title="'T_MEDIA_ATTENDANCEES' | translate"
            :content-list="translateArray(primaryData.pavilion, 'mediaAttendees')"
            show-list-total
            has-list-bullet
            truncate-text="5"
          />
          <content-card
            :no-data="
              !hasField(secondaryData.pavilion, 'mediaAttendees') ||
              translateArray(secondaryData.pavilion, 'mediaAttendees').length === 0
            "
            :title="'T_MEDIA_ATTENDANCEES' | translate"
            :content-list="translateArray(secondaryData.pavilion, 'mediaAttendees')"
            show-list-total
            has-list-bullet
            truncate-text="5"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
// import _ from 'lodash'
import CompareDataService from '@/services/CompareDataService'
import BaseSection from '@/mixins/BaseSection'
import PavilionGallery from '@/views/pavilion/PavilionGallery.vue'
import PavilionInfo from '@/views/pavilion/PavilionInfo.vue'
import ContentCard from '@/components/ContentCard.vue'
import CompareShowTitles from '@/components/CompareShowTitles.vue'

export default {
  name: 'EventSection',
  components: {
    ContentCard,
    CompareShowTitles,
    PavilionGallery,
    PavilionInfo
  },
  mixins: [BaseSection],
  data() {
    return {
      CompareDataService
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../varsDesktop.scss';

#event-section {
  .first-card {
    padding: $standard-margin;
  }
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: $scale-vw * 19;
    line-height: $scale-vw * 24;
    text-align: center;
    margin-bottom: $standard-margin;
  }
  &.compare {
    width: 95%;
    margin: 0 auto;
  }
}
</style>
