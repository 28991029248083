<template>
  <div class="not-found-page">
    <div class="content-wrapper">
      <v-img src="@/assets/icons/404.svg" lazy-src="@/assets/icons/404.svg" />
      <p>{{ 'T_404_DESCRIPTION' | translate }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage'
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';

.not-found-page {
  color: white;
  .content-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .v-image {
      margin-bottom: $standard-margin;
    }
    p {
      font-style: italic;
      font-weight: 400;
      font-size: $scale-vw * 17;
      line-height: $scale-vw * 22;
      text-align: center;
      letter-spacing: -0$scale-vw * 408;
    }
  }
}
</style>
