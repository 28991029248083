<template>
  <div id="pdf-export">
    <div class="pdf-content">
      <div class="page">
        <pdf-top-bar :logo="logo()" :event-name="eventName()" />
        <div class="page-content">
          <event />
          <overview />
        </div>
        <pdf-page-number :page-number="1" :total-pages="totalPages" />
      </div>
      <div class="page">
        <pdf-top-bar :logo="logo()" :event-name="eventName()" />
        <div class="page-content">
          <overview-page-2 />
        </div>
        <pdf-page-number :page-number="2" :total-pages="totalPages" />
      </div>
      <div class="page">
        <pdf-top-bar :logo="logo()" :event-name="eventName()" />
        <div class="page-content">
          <traffic />
        </div>
        <pdf-page-number :page-number="3" :total-pages="totalPages" />
      </div>
      <div class="page">
        <pdf-top-bar :logo="logo()" :event-name="eventName()" />
        <div class="page-content">
          <traffic-page-2 />
        </div>
        <pdf-page-number :page-number="4" :total-pages="totalPages" />
      </div>
      <div class="page">
        <pdf-top-bar :logo="logo()" :event-name="eventName()" />
        <div class="page-content">
          <traffic-page-3 />
        </div>
        <pdf-page-number :page-number="5" :total-pages="totalPages" />
      </div>
      <div v-if="hasStayAverage()" class="page">
        <pdf-top-bar :logo="logo()" :event-name="eventName()" />
        <div class="page-content">
          <traffic-page-4 />
        </div>
        <pdf-page-number :page-number="6" :total-pages="totalPages" />
      </div>

      <template v-if="primaryData">
        <div v-for="(area, areaIndex) in primaryData.abdAreas" :key="areaIndex" class="page">
          <pdf-top-bar :logo="logo()" :event-name="eventName()" />
          <div class="page-content">
            <traffic-area-page :pdf-area="area._id" />
          </div>
          <pdf-page-number :page-number="6 + (areaIndex + 1)" :total-pages="totalPages" />
        </div>
      </template>
      <div v-if="hasTouchpoints()" class="page">
        <pdf-top-bar :logo="logo()" :event-name="eventName()" />
        <div class="page-content">
          <experience />
        </div>
        <pdf-page-number :page-number="pageNumberPlusAreas(7)" :total-pages="totalPages" />
      </div>

      <div v-if="hasTouchpoints()" class="page">
        <pdf-top-bar :logo="logo()" :event-name="eventName()" />
        <div class="page-content">
          <experience-page-2 />
        </div>
        <pdf-page-number :page-number="pageNumberPlusAreas(8)" :total-pages="totalPages" />
      </div>
      <template v-if="primaryData">
        <template v-for="(experience, experienceIndex) in getFilteredExperiences()">
          <div :key="'experience-' + experienceIndex" class="page touchpoint">
            <pdf-top-bar :logo="logo()" :event-name="eventName()" />
            <div class="page-content">
              <touchpoint :pdf-experience="experience._id" />
            </div>
            <pdf-page-number
              :page-number="pageNumberPlusAreas(8) + (experienceIndex * 2 + 1)"
              :total-pages="totalPages"
            />
          </div>
          <div :key="'experience-page-2-' + experienceIndex" class="page touchpoint">
            <pdf-top-bar :logo="logo()" :event-name="eventName()" />
            <div class="page-content">
              <touchpoint-page-2 :pdf-experience="experience._id" />
            </div>
            <pdf-page-number
              :page-number="pageNumberPlusAreas(8) + (experienceIndex * 2 + 2)"
              :total-pages="totalPages"
            />
          </div>
        </template>
      </template>
      <div v-if="hasSales()" class="page">
        <pdf-top-bar :logo="logo()" :event-name="eventName()" />
        <div class="page-content">
          <sales />
        </div>
        <pdf-page-number
          :page-number="pageNumberPlusAreas(9) + getNbExperiences() * 2"
          :total-pages="totalPages"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import TranslateService from '@/services/TranslateService'
import PavilionSelectorService from '@/services/PavilionSelectorService'

import PdfTopBar from '@/components/PdfTopBar.vue'
import PdfPageNumber from '@/components/PdfPageNumber.vue'
import Event from '@/views/pdf/Event.vue'
import Overview from '@/views/pdf/Overview.vue'
import OverviewPage2 from '@/views/pdf/OverviewPage2.vue'
import Traffic from '@/views/pdf/Traffic.vue'
import TrafficPage2 from '@/views/pdf/TrafficPage2.vue'
import TrafficPage3 from '@/views/pdf/TrafficPage3.vue'
import TrafficPage4 from '@/views/pdf/TrafficPage4.vue'
import TrafficAreaPage from '@/views/pdf/TrafficAreaPage.vue'
import Experience from '@/views/pdf/Experience.vue'
import ExperiencePage2 from '@/views/pdf/ExperiencePage2.vue'
import Touchpoint from '@/views/pdf/Touchpoint.vue'
import TouchpointPage2 from '@/views/pdf/TouchpointPage2.vue'
import Sales from '@/views/pdf/Sales.vue'

export default {
  name: 'PdfExport',
  components: {
    PdfTopBar,
    PdfPageNumber,
    Event,
    Overview,
    OverviewPage2,
    Traffic,
    TrafficPage2,
    TrafficPage3,
    TrafficPage4,
    TrafficAreaPage,
    Experience,
    ExperiencePage2,
    Touchpoint,
    TouchpointPage2,
    Sales
  },
  data() {
    return {
      renderTimeout: null,
      TranslateService,
      isLoading: true,
      selectedBrand: null,
      menuSelectedBrand: null,
      totalPages: 0,
      selectedPrimaryPavilion: null,
      selectedSecondaryPavilion: null,
      primaryData: null,
      shouldDisplayMenuIcon: true,
      secondaryData: null,
      selectedDates: null,
      selectedDays: null
    }
  },
  watch: {
    currentFullscreenSlide() {
      this.slideChanged()
    }
  },
  async mounted() {
    window.renderingCompleted = false
    const locale = _.get(this.$route, 'query.locale', 'enUS')
    TranslateService.setLocale(locale)
    this.renderTimeout = setTimeout(() => this.renderReady('timeout'), 10000)
    PavilionSelectorService.events.on('update-pavilion-data', this.setData)
    // console.warn(`PDF locale: ${locale}`, this.$route)
  },
  beforeDestroy() {
    PavilionSelectorService.events.off('update-pavilion-data', this.setData)
  },
  methods: {
    hasStayAverage() {
      return _.get(this.primaryData, 'pavilion.hasStayAverage', false)
    },
    hasTouchpoints() {
      return this.getNbExperiences() !== 0
    },
    getNbExperiences() {
      return _.get(this.getFilteredExperiences(), 'length', 0)
    },
    hasSales() {
      return (
        _.get(this.primaryData, 'pavilion.hasLeads', false) ||
        _.get(this.primaryData, 'pavilion.hasOrders', false)
      )
    },
    logo() {
      return this.getField('selectedBrand.logo.light')
    },
    eventName() {
      return this.getField('selectedPrimaryPavilion.event.name', {})
    },
    getFilteredExperiences() {
      return _.take(_.get(this.primaryData, 'experiences', []), 9)
    },
    pageNumberPlusAreas(pageNumber) {
      return (
        pageNumber -
        (!this.hasStayAverage() ? 1 : 0) +
        _.get(this.primaryData, 'abdAreas.length', 0)
      )
    },
    getField(field, defaultVal = '') {
      return _.get(this, field, defaultVal)
    },
    renderReady(status) {
      window.renderingCompleted = true
      console.log(`renderingCompleted (status: ${status}):`, window.renderingCompleted)
    },
    setData(data) {
      _.each(data, (val, key) => {
        _.set(this, key, val)
      })
      this.isLoading = false
      this.totalPages =
        5 + (this.hasStayAverage() ? 1 : 0) + _.get(data, 'primaryData.abdAreas.length', 0)
      if (this.hasTouchpoints()) {
        this.totalPages += 2 + this.getNbExperiences() * 2 + (this.hasSales() ? 1 : 0)
      }
      this.$forceUpdate()
      clearTimeout(this.renderTimeout)
      this.renderTimeout = setTimeout(() => {
        console.warn(`PAGE READY`)
        this.renderReady('done')
      }, 2000)
    }
  }
}
</script>

<style lang="scss">
@import './reset.scss';
@import './fonts.scss';
@import './vars.scss';
@page {
  margin: 5mm;
}
@media print {
  .page {
    page-break-after: page;
  }
}
#pdf-export {
  overflow-x: hidden;
  .page {
    position: relative;
    background-color: white;
    color: black;
    height: $pdf-page-height;
    width: $pdf-page-width;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: white;
      z-index: 1;
      width: 100%;
      display: block;
      break-after: page;
    }
    &.touchpoint {
      .pdf-sub-section {
        .section-sidebar {
          .section-title {
            margin-top: 220px;
            width: 220px;
          }
        }
      }
    }
  }
  .page-content {
    height: 100%;
    overflow: hidden;
  }
  .pavilion-info {
    color: black;
    .info-box .v-image {
      filter: invert(1);
    }
  }
  .section {
    position: relative;
    background: white;
    box-shadow: none;
    border-radius: 0px;
    height: auto;
    min-height: auto;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .pdf-divider {
    margin: 16px 0;
    width: 100%;
  }
  .section-title {
    font-style: italic;
    font-family: 'SourceSansPro-Bold' !important;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 8px;
    &.big {
      font-size: 16px;
      font-style: initial;
    }
  }
  .content-card {
    border-radius: 4px;
    padding: 8px;
    background-color: #e6e6e6;
    color: black;
    box-shadow: none;
    font-style: italic;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    margin-bottom: 8px;
    .card-header {
      color: black;
      font-style: italic;
      font-weight: 400;
      font-size: 8px;
      line-height: 10px;
      margin-bottom: 0;
      width: 75%;
      margin-right: 5%;
      .v-image {
        display: none;
      }
    }
    .card-content {
      text-align: center;
    }

    .card-title,
    .truncate-title {
      font-style: italic;
      font-weight: 400;
      font-size: 8px;
      line-height: 10px;
      max-width: 100%;
      white-space: inherit;
      overflow: inherit;
    }
  }
  .pdf-sub-section {
    display: flex;
    align-items: stretch;
    align-content: stretch;
    justify-content: flex-start;
    flex-direction: row;
    .section-sidebar {
      background-color: transparent;
      width: 16px;
      padding: 16px;
      color: white;
      z-index: 100;
      .section-title {
        transform-origin: 0 0;
        font-style: italic;
        font-family: 'SourceSansPro-Bold' !important;
        font-weight: 700;
        font-size: 10px;
        line-height: 1px;
        text-align: right;
        text-transform: uppercase;
        transform: rotate(-90deg);
        margin-top: 100px;
        width: 100px;
      }
    }
    .section-content {
      width: calc(100% - 32px);
      padding: 16px;
      padding-bottom: 0;
      background-color: white;
      &.no-padding-top {
        padding-top: 0;
      }
    }
  }
  .left-sidebar {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    left: 0;
    top: 32px;
    height: calc(#{$pdf-page-height} - 32px);
    width: 32px;
    background-color: $purple;
    z-index: 50;
    .logo-imagination {
      width: 90%;
      margin: 0 auto;
      margin-bottom: 4px;
    }
  }

  .pie-chart,
  .bar-chart,
  .line-chart {
    height: 216px !important;
  }
  .bar-chart,
  .line-chart {
    max-width: 510px !important;
  }
  .traffic-by-date,
  .traffic-by-hour {
    .bar-chart {
      height: 145px !important;
    }
  }
  .traffic-by-nameplate {
    .pie-chart {
      height: 128px !important;
    }
  }
  #experience-section {
    .line-chart {
      height: 125px !important;
    }
  }
  .section-total {
    color: black;
    font-style: italic;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    margin-bottom: 8px;
    span {
      font-weight: bold;
    }
  }
  .title-graph {
    width: 100%;
    margin-bottom: 8px;
    &:not(.vertical) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    .graph-name,
    .graph-total {
      font-style: italic;
      font-family: 'SourceSansPro' !important;
      font-weight: 700;
      font-size: 8px;
      line-height: 10px;
    }
    .graph-total {
      font-weight: 400;
      span {
        font-family: 'SourceSansPro-Bold' !important;
        font-weight: 700;
      }
    }
    .graph-name {
      font-family: 'SourceSansPro-Bold' !important;
    }
    &.vertical {
      display: block;
    }
  }
  .data-table,
  .areas-table {
    width: 47.5%;
    table {
      width: 100%;
      text-align: center;
      border-collapse: separate;
      border-spacing: 0;
      border: 0.5px solid #b8babc;
      border-radius: 4px;
      overflow: hidden;
      color: black;
    }
    td,
    th {
      border: none;
      border-left: solid #b8babc 1px;
      font-style: italic;
      font-weight: 400;
      font-size: 6px;
      line-height: 8px;
      &:first-child {
        border-left: none;
      }
    }
    thead {
      background: #8094dd;
      color: white;
    }
    th {
      font-family: 'SourceSansPro-Bold' !important;
      font-weight: 700;
      line-height: 12px;
    }
    tr {
      height: 13px;
      th:first-child {
        font-style: italic;
      }
    }
    tr:nth-child(even) {
      background-color: #e6e6e6;
    }
    td {
      border-top: solid #b8babc 1px;
      line-height: 12px;
      &.time {
        font-family: 'SourceSansPro-Bold' !important;
        font-weight: 700;
      }
    }
    &.full-width {
      width: 100%;
    }
  }
  #experience-section.pdf-sub-section {
    .experience-line-graph {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 45%;
      .line-chart {
        width: 100%;
      }
    }
    .bar-chart {
      height: 125px !important;
      canvas {
        display: inline-block !important;
      }
    }

    .interaction-hours {
      .cards-row {
        .bar-chart {
          width: 47.5%;
        }
      }
      &.center {
        justify-content: center;
      }
    }
  }
  .per-area,
  .per-area-table {
    width: 47.5%;
  }
  .max-data-reached {
    font-size: 8px;
    line-height: 12px;
    color: red;
  }
}
</style>
