<template>
  <div id="experience-section" class="page-2 pdf-sub-section">
    <div class="section-sidebar">
      <div class="section-title">
        {{ 'T_EXPERIENCE' | translate }}
      </div>
    </div>
    <div v-if="primaryData" class="section-content">
      <!-- Souvenir -->
      <div class="sub-section souvenir-graphs">
        <div class="section-title">
          {{ 'T_SOUVENIR_INFORMATION' | translate }}
        </div>
        <div class="info-box">
          <div class="cards-row">
            <div v-for="(lineChart, i) in lineCharts" :key="i" class="souvenir-graph">
              <div class="title-graph">
                <div class="graph-name">
                  {{ lineChart.data.datasets[0].label }}
                </div>
                <div class="graph-total">
                  {{ 'T_TOTAL' | translate }}:
                  <span>{{ getTotalForChart(lineChart) | numeral }}</span>
                </div>
              </div>
              <line-chart
                :force-color="lineChartsColors[i]"
                is-for-pdf
                :data="lineChart.data"
                :options="lineChart.options"
              />
            </div>
          </div>
        </div>
      </div>
      <img class="pdf-divider" src="@/assets/divider.svg" />
      <!-- Conversion -->
      <div class="sub-section">
        <div class="section-title">
          {{ 'T_CONVERSION_FUNNEL' | translate }}
        </div>
        <funnel-chart v-if="primaryData" is-for-pdf :data="funnel" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import BaseSection from '@/mixins/BaseSection'
import LineChart from '@/components/Charts/LineChart.vue'
import FunnelChart from '@/components/FunnelChart.vue'
import ChartsDataService from '@/services/ChartsDataService'
import TranslateService from '@/services/TranslateService'

export default {
  name: 'Experience',
  components: {
    LineChart,
    FunnelChart
  },
  mixins: [BaseSection],
  data() {
    return {
      heatmap: false,
      heatmap2: false,
      bar: {},
      line: false,
      lineCharts: [],
      lineChartsColors: ['#8DE971', '#E1E000', '#DD2700', '#71DBD4'],
      lineSouvenirs: false,
      funnel: false,
      highlightedInteractionDayValue: 0,
      selectedDate: 0,
      highlightedInteractionHourValue: 0,
      selectedHour: 0,
      highlightedSouvenirDayValue: 0,
      selectedSouvenirDate: 0,
      selectedSouvenirType: 'generated',
      souvenirSubTypes: [
        {
          label: 'T_GENERATED',
          key: 'generated'
        },
        {
          label: 'T_RETRIEVED',
          key: 'retrieved'
        },
        {
          label: 'T_PLAYED',
          key: 'played'
        },
        {
          label: 'T_SHARED',
          key: 'shared'
        },
        {
          label: 'T_DOWNLOADED',
          key: 'downloaded'
        }
      ],
      souvenirList: false,
      ChartsDataService
    }
  },
  watch: {},
  mounted() {
    TranslateService.events.on('changeLanguage', this.generateLabelsForLists)
  },
  methods: {
    generateLabelsForLists() {
      this.souvenirList = false
      this.$nextTick(() => {
        this.souvenirList = this.getList(this.lineSouvenir, this.highlightedSouvenirDayValue)
        this.$forceUpdate()
      })
    },
    filterIfNoDataPerHour(cardTopData) {
      const nbDataPerHour = _.sum(
        _.map(_.get(this.primaryData, 'experiencesByDate', []), (date) => {
          return _.get(date, 'visitsByHour.length', 0)
        })
      )
      if (nbDataPerHour === 0) {
        cardTopData.type = 'hidden'
        console.info(`Interactions per hour hidden since no data`)
      }
      return cardTopData
    },
    getTotalForDataset(chart, datasetIndex) {
      return _.sum(_.get(chart, `data.datasets[${datasetIndex}].data`, []))
    },
    getFunnel(data) {
      if (!data) {
        return false
      }
      return {
        labels: this.getConversionFunnelLabels(),
        data: this.getFunnelData(data)
      }
    },
    getConversionFunnelLabels() {
      let labels = [
        'T_CAPACITY',
        'T_ACTIVATION_COMPLETED',
        'T_SOUVENIR_RETRIEVED',
        'T_SOUVENIR_PLAYED',
        'T_SOUVENIR_SHARED',
        'T_SOUVENIR_DOWNLOADED'
      ]
      if (!this.shouldDisplayPlayedSouvenirs(this.primaryData)) {
        labels = _.filter(labels, (label) => label !== 'T_SOUVENIR_PLAYED')
      }
      return _.map(labels, (label) => TranslateService.get(label))
    },
    getFunnelData(data) {
      const { capacity, generated, retrieved, played, shared, downloaded } = _.get(
        data,
        'conversionFunnel',
        {}
      )
      if (!this.shouldDisplayPlayedSouvenirs(this.primaryData)) {
        return [capacity, generated, retrieved, shared, downloaded]
      }
      return [capacity, generated, retrieved, played, shared, downloaded]
    },
    getTotalForAllDays(data, obj, type) {
      return `${_.sum(
        _.map(_.get(data, obj, []), (d) => {
          return _.toNumber(_.get(d, `countByAction.${type}`, 0))
        })
      )}`
    },
    getTotalInteractionsByHour() {
      if (this.selectedHour === 0) {
        return {
          label: TranslateService.get('T_AVERAGE_PER_HOUR'),
          value: this.getAverageForDatasets(this.bar.data.datasets)
        }
      }
      let value = _.get(this.getOriginalTrafficData(this.primaryData), 'visits', 0)
      if (this.secondaryData) {
        value += _.get(this.getOriginalTrafficData(this.secondaryData), 'visits', 0)
      }
      return {
        label: this.selectedDate,
        value
      }
    },
    getList(chartObj, highlightValue) {
      return _.map(_.get(chartObj, 'data.datasets', []), (dataset, i) => {
        return {
          borderColor: ChartsDataService.lineColors[i],
          label: TranslateService.get(dataset.translationKey),
          value:
            highlightValue === 0
              ? _.sum(dataset.data)
              : _.get(dataset, `data[${highlightValue - 1}]`, 0)
        }
      })
    },
    getExperiencesInteractions(perHour = false, data = false) {
      let formattedData = []
      formattedData = this.getExperiencesDatasets(data ? data : this.primaryData, perHour)
      // console.warn(`getExperiencesInteractions `, perHour, data, formattedData)
      if (perHour) {
        return formattedData
      }
      formattedData = _.map(formattedData, (experience) => {
        return {
          borderColor: experience.borderColor,
          label: experience.label,
          value:
            this.highlightedInteractionDayValue === 0
              ? _.sum(experience.data)
              : _.get(experience, `data[${this.highlightedInteractionDayValue - 1}]`, 0)
        }
      })
      return formattedData
    },
    resetChartsHighlights(keepValues = false) {
      if (!keepValues) {
        this.highlightedInteractionDayValue = 0
        this.highlightedInteractionHourValue = 0
        this.highlightedSouvenirDayValue = 0
      }
      this.$forceUpdate()
    },
    gotData() {
      this.bar.data = this.updateHourlyInteractionData()
      this.funnel = this.getFunnel(this.primaryData)
      const datasets = this.getSouvenirDatasets(this.primaryData)
      this.lineCharts = []
      _.each(datasets, (dataset) => {
        this.lineCharts.push(this.getLineSouvenirData(this.primaryData, dataset))
      })
      this.getSouvenirSubTypes(this.primaryData, this.secondaryData)
      this.loading = false
      this.generateLabelsForLists()
    },
    getLineSouvenirData(data, dataset) {
      return {
        data: {
          labels: this.getLabels(this.primaryData, this.secondaryData, 'souvenirsByDate', false),
          chartSelectorLabels: this.getLabels(
            this.primaryData,
            this.secondaryData,
            'souvenirsByDate',
            true
          ),
          datasets: [dataset]
        },
        options: {
          plugins: {
            datalabels: {
              display: true,
              align: 'center',
              font: {
                size: 8
              },
              opacity: 1,
              labels: {
                value: {
                  formatter: function (value, context) {
                    const val = _.get(context, `dataset.data[${context.dataIndex}]`, '')
                    return val === 0 ? '' : val
                  },
                  anchor: 'end',
                  align: 'top',
                  offset: '0',
                  color: 'black'
                }
              }
            }
          }
        }
      }
    },
    getLineData(data, dataset) {
      return {
        data: {
          labels: this.getLabels(this.primaryData, false, 'experiencesByDate', false),
          chartSelectorLabels: this.getLabels(
            this.primaryData,
            this.secondaryData,
            'experiencesByDate',
            true
          ),
          datasets: [dataset]
        }
      }
    },
    hasNoHourlyData(data) {
      return _.isEmpty(this.getSelectedDateTraffic(data))
    },
    updateHourlyInteractionData() {
      const labels = this.getTrafficHoursLabels(this.primaryData)
      const data = {
        labels: labels,
        chartSelectorLabels: labels,
        datasets: []
      }
      _.each(_.compact([this.primaryData, this.secondaryData]), (d, i) => {
        data.datasets.push({
          label: _.get(d, 'pavilion.event.key', false),
          backgroundColor: ChartsDataService.lineColors[i],
          data: _.map(this.getSelectedDateTraffic(d), (d) => numeral(d).format('0,0')),
          barPercentage: 0.3
        })
      })
      // console.warn(`HOUR GRAPH DATA - `, data)
      return data
    },
    getTrafficDates(data, secondaryData = false, obj) {
      if (secondaryData) {
        return _.map(
          this.getTrafficDates(secondaryData, false, obj),
          (date, i) => TranslateService.get('T_DAY') + ` ${i + 1}`
        )
      }
      const mappedData = _.map(_.get(data, obj), 'date')
      return _.isEmpty(mappedData)
        ? false
        : _.uniq(_.map(mappedData, (date) => moment(date).format('MM/DD/YYYY')))
    },
    getTrafficLabels(data, secondaryData = false, fullDate = false, obj) {
      if (secondaryData) {
        return _.map(
          this.getTrafficDates(data, false, obj),
          (date, i) => TranslateService.get('T_DAY') + ` ${i + 1}`
        )
      }
      if (fullDate) {
        return _.map(this.getTrafficDates(data, false, obj), (date) => {
          return moment(date, 'MM/DD/YYYY').format('DD/MM/YYYY')
        })
      }
      return _.map(this.getTrafficDates(data, false, obj), (date) => {
        return moment(date, 'MM/DD/YYYY').format('DD')
      })
    },
    getInteractionsValueForExperience(experiencesByDate, experienceId) {
      if (this.highlightedInteractionDayValue === 0 && this.highlightedInteractionHourValue === 0) {
        // console.warn(`NOTE: all days total for interaction ${experienceId}`)
        return _.sum(
          _.map(experiencesByDate, (date) => {
            return this.getAsNumber(
              _.find(_.get(date, 'visitsByExperience', []), (e) => e.experience === experienceId),
              'count'
            )
          })
        )
      }
      if (this.highlightedInteractionDayValue === 0 && this.highlightedInteractionHourValue !== 0) {
        // console.warn('NOTE: total of the hour for all days')
        return _.sum(
          _.map(experiencesByDate, (date) => {
            return this.getAsNumber(
              _.find(
                _.get(
                  date,
                  `visitsByHour[${this.highlightedInteractionHourValue - 1}].visitsByExperience`,
                  []
                ),
                (e) => e.experience === experienceId
              ),
              'count'
            )
          })
        )
      }
      if (this.highlightedInteractionDayValue !== 0 && this.highlightedInteractionHourValue === 0) {
        // console.warn('NOTE: total of the day')
        const subItem = _.get(
          experiencesByDate,
          `[${this.highlightedInteractionDayValue - 1}].visitsByExperience`,
          []
        )
        return this.getAsNumber(
          _.find(subItem, (e) => e.experience === experienceId),
          'count'
        )
      }
      const subItem = _.get(
        experiencesByDate,
        `[${this.highlightedInteractionDayValue - 1}].visitsByHour[${
          this.highlightedInteractionHourValue - 1
        }]`,
        {}
      )
      // console.warn('NOTE: total for the hour of a specific day')
      return this.getAsNumber(
        _.find(_.get(subItem, 'visitsByExperience', []), (e) => e.experience === experienceId),
        'count'
      )
    },
    getSouvenirDatasets(data) {
      const souvenirsByDate = _.get(data, 'souvenirsByDate', [])
      let souvenirSubTypes = ['generated', 'retrieved', 'shared', 'downloaded']
      const datasets = _.map(souvenirSubTypes, (type, i) => {
        const translationKey = `T_${_.toUpper(type)}`
        return {
          label: TranslateService.get(translationKey),
          translationKey,
          data: _.map(souvenirsByDate, (date) => {
            return _.toNumber(_.get(date, `countByAction.${type}`, 0))
          }),
          showLine: true,
          borderColor: this.lineChartsColors[i],
          backgroundColor: this.lineChartsColors[i],
          fill: false
        }
      })
      return datasets
    },
    getSouvenirSubTypes(data, secondaryData = false) {
      let displayAll = false
      _.each([data, secondaryData], (d) => {
        if (!d) {
          return false
        }
        if (this.shouldDisplayPlayedSouvenirs(d)) {
          displayAll = true
          return false
        }
      })
      if (displayAll) {
        return
      }
      this.souvenirSubTypes = _.filter(this.souvenirSubTypes, (type) => type.key !== 'played')
    },
    shouldDisplayPlayedSouvenirs(data) {
      let displayPlayed = false
      _.each(_.get(data, 'experiences', []), (experience) => {
        if (_.includes(experience.souvenirTypes, 'video')) {
          displayPlayed = true
          return false
        }
      })
      return displayPlayed
    },
    getExperiencesDatasets(data, perHour = false) {
      const datasets = []
      const experiencesByDate = _.get(data, 'experiencesByDate', [])
      const mappedData = {}
      let experiences = _.get(data, 'pavilion.experiences', [])
      if (perHour) {
        return _.map(experiences, (experience, i) => {
          return {
            label: experience.name,
            borderColor: ChartsDataService.lineColors[i],
            value: this.getInteractionsValueForExperience(experiencesByDate, experience._id)
          }
        })
      }
      _.each(experiencesByDate, (experiencesDate) => {
        _.each(experiences, (experience) => {
          mappedData[experience._id] = _.get(mappedData, experience._id, [])
          const subItem = _.find(
            _.get(experiencesDate, 'visitsByExperience', []),
            (e) => e.experience === experience._id
          )
          mappedData[experience._id].push(this.getAsNumber(subItem, 'count'))
        })
      })
      _.each(experiences, (experience, i) => {
        datasets.push({
          experience: experience._id,
          label: experience.name,
          data: _.get(mappedData, experience._id, []),
          showLine: true,
          borderColor: ChartsDataService.lineColors[i],
          fill: false
        })
      })
      return datasets
    },
    getOriginalTrafficData(data) {
      const experiencesByDate = _.get(data, 'experiencesByDate', [])
      if (this.selectedDate === 0) {
        // console.warn(`getOriginalTrafficData - 2`, experiencesByDate)
        return experiencesByDate
      }
      return _.get(experiencesByDate, `[${this.highlightedInteractionDayValue - 1}]`, {})
    },
    getSelectedDateTraffic(data) {
      if (this.selectedDate === 0) {
        const allDaysData = this.getOriginalTrafficData(data)
        const formattedData = {}
        _.each(allDaysData, (e) => {
          _.each(_.get(e, 'visitsByHour', []), (hour) => {
            _.set(formattedData, hour.time, _.get(formattedData, hour.time, 0) + hour.count)
          })
        })
        // console.warn(`getSelectedDateTraffic - all days -----`, formattedData)
        return _.values(formattedData)
      }
      return _.map(_.get(this.getOriginalTrafficData(data), 'visitsByHour', []), 'count')
    },
    getTrafficHoursLabels(data) {
      let experienceByDate = this.getOriginalTrafficData(data)
      if (_.isArray(experienceByDate)) {
        // console.warn(`getTrafficHoursLabels - is array`)
        experienceByDate = _.get(experienceByDate, `[0]`, {})
      }
      const perHourLabels = _.map(_.get(experienceByDate, 'visitsByHour', []), 'time')
      // console.warn(`PER HOUR LABELS = `, perHourLabels)
      return perHourLabels
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';
#experience-section {
  .funnel-charts {
    display: inline-block;
    width: 100%;
  }
  &.compare {
    .section {
      padding-top: $double-margin;
    }
    .pavilion-experiences {
      margin-bottom: 0;
    }
    .compare-data {
      margin-top: $half-margin;
    }
    .funnel-charts {
      .compare-show-titles {
        margin-bottom: $standard-margin;
      }
    }
  }
}
.vertical-activations {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .pavilion-experiences {
    width: 100%;
    max-width: $scale-vw * 175;
    margin-bottom: $half-margin;
  }
}
</style>

<style lang="scss">
#experience-section.pdf-sub-section.page-2 {
  // .bar-chart {
  //   height: 173px !important;
  // }
  .souvenir-graphs {
    .souvenir-graph {
      width: 47.5%;
    }
    .line-chart {
      width: 100%;
      margin-bottom: 8px;
    }
  }
  .funnel-chart {
    width: 47.5%;
  }
  .data-table {
    tr {
      line-height: 10px;
      td:first-child {
        span {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          display: inline-block;
          &.yellow {
            background-color: #e1e000;
          }
          &.green {
            background-color: #8de971;
          }
        }
      }
    }
  }
  .flex-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    span {
      margin-right: 4px;
    }
  }
  .data-table {
    tr {
      th:first-child {
        width: 10%;
        min-width: 50px;
      }
    }
  }
}
</style>
