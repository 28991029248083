<template>
  <div v-if="primaryData" id="pdf-overview-section" class="pdf-sub-section">
    <div class="section-sidebar">
      <div class="section-title">
        {{ 'T_OVERVIEW' | translate }}
      </div>
    </div>
    <div class="section-content no-padding-top">
      <div class="performance-overview">
        <div class="section-title">{{ 'T_PERFORMANCE_OVERVIEW' | translate }}</div>
        <radar-chart :labels="labels" :data-list="dataList" is-for-pdf />
      </div>
      <div class="cards-row">
        <div class="performance">
          <div class="section-title">{{ 'T_PERFORMANCE' | translate }}</div>
          <div class="cards-row">
            <content-card
              v-for="(contentCard, i) in dataList[0].contentCards"
              :key="i"
              is-for-pdf
              no-gradient
              :title="contentCard.item.label | translate"
              :content="contentCard.value"
              italic
              :no-data="!contentCard.value"
            />
          </div>
        </div>
        <div class="traffic">
          <div class="section-title">{{ 'T_TRAFFIC' | translate }}</div>

          <div class="cards-row">
            <content-card
              v-if="primaryData.total.eventVisitors"
              is-for-pdf
              no-gradient
              :title="'T_TOTAL_EVENT_VISITOR' | translate"
              :content="primaryData.total.eventVisitors | numeral"
              :no-data="!primaryData.total.eventVisitors"
            />

            <content-card
              v-if="primaryData.total.standTraffic"
              is-for-pdf
              no-gradient
              :title="'T_TOTAL_STAND_VISITOR' | translate"
              :content="primaryData.total.standTraffic"
              :no-data="!primaryData.total.standTraffic"
            />
            <content-card
              v-if="primaryData.total.standTraffic && primaryData.total.eventVisitors"
              is-for-pdf
              no-gradient
              :title="'T_TOTAL_VISITORS_CONVERSION' | translate"
              :content="
                reduceConversion(primaryData.total.standTraffic, primaryData.total.eventVisitors)
              "
              conversion
              :no-data="!primaryData.total.standTraffic || !primaryData.total.eventVisitors"
            />
            <content-card
              v-if="hasLeads(primaryData)"
              is-for-pdf
              no-gradient
              :title="'T_TOTAL_LEADS_GENERATION' | translate"
              :content="primaryData.total.leadsGeneration"
              :no-data="!getTotalVal(primaryData, 'leadsGeneration')"
            />
            <content-card
              v-if="hasOrders(primaryData)"
              is-for-pdf
              no-gradient
              :title="'T_TOTAL_ORDERS_GENERATION' | translate"
              :content="primaryData.total.ordersGeneration"
              :no-data="!getTotalVal(primaryData, 'ordersGeneration')"
            />
            <content-card
              v-if="getBestNameplate(primaryData)"
              is-for-pdf
              no-gradient
              :title="'T_BEST_PERFORMING_NAMEPLATE' | translate"
              :content="getBestNameplate(primaryData)"
            />
            <content-card
              v-else
              is-for-pdf
              no-gradient
              no-data
              :title="'T_BEST_PERFORMING_NAMEPLATE' | translate"
            />
          </div>
        </div>
        <div class="online-traffic">
          <div class="section-title">{{ 'T_ONLINE_TRAFFIC' | translate }}</div>
          <div class="cards-row">
            <content-card
              v-if="primaryData.total.wechatUniqueVisit"
              is-for-pdf
              no-gradient
              :title="'T_WECHAT_UNIQUE_VISITORS' | translate"
              :content="primaryData.total.wechatUniqueVisit"
            />
            <content-card
              v-else
              is-for-pdf
              no-gradient
              no-data
              :title="'T_WECHAT_UNIQUE_VISITORS' | translate"
            />
            <content-card
              v-if="primaryData.total.wechatVisit"
              is-for-pdf
              no-gradient
              :title="'T_WECHAT_TOTAL_VISITORS' | translate"
              :content="primaryData.total.wechatVisit"
            />
            <content-card
              v-else
              is-for-pdf
              no-gradient
              no-data
              :title="'T_WECHAT_TOTAL_VISITORS' | translate"
            />
            <content-card
              v-if="primaryData.conversionFunnel && primaryData.conversionFunnel.downloaded"
              is-for-pdf
              no-gradient
              :title="'T_TOTAL_DOWNLOAD' | translate"
              :content="primaryData.conversionFunnel.downloaded"
            />
            <content-card
              v-else
              is-for-pdf
              no-gradient
              no-data
              :title="'T_TOTAL_DOWNLOAD' | translate"
            />
            <content-card
              v-if="primaryData.conversionFunnel && primaryData.conversionFunnel.retrieved"
              is-for-pdf
              no-gradient
              :title="'T_TOTAL_SOUVENIR_RETRIEVED' | translate"
              :content="primaryData.conversionFunnel.retrieved"
            />
            <content-card
              v-else
              is-for-pdf
              no-gradient
              no-data
              :title="'T_TOTAL_SOUVENIR_RETRIEVED' | translate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
// import numeral from 'numeral'
import TranslateService from '@/services/TranslateService'
import ChartsDataService from '@/services/ChartsDataService'
import RadarChart from '@/components/Charts/RadarChart'
import ContentCard from '@/components/ContentCard'
import BaseSection from '@/mixins/BaseSection'

export default {
  name: 'Overview',
  components: { RadarChart, ContentCard },
  mixins: [BaseSection],
  data() {
    return {
      labels: false,
      circleColors: [ChartsDataService.barColors[0], ChartsDataService.barColors[1]],
      labelItems: [
        // {
        //   key: 'stand-traffic',
        //   label: 'T_STAND_TRAFFIC',
        //   field: 'total.standTraffic'
        // },
        {
          key: 'touchpoints-sessions',
          label: 'T_TOUCHPOINTS_SESSIONS',
          field: 'getTotalForExperiences'
        },
        {
          key: 'orders',
          label: 'T_ORDERS',
          field: 'total.ordersGeneration'
        },
        {
          key: 'souvenir-retrieved',
          label: 'T_SOUVENIR_RETRIEVED',
          field: 'conversionFunnel.retrieved'
        },
        {
          key: 'voucher-redemption',
          label: 'T_VOUCHER_REDEMPTION',
          field: 'total.voucherRedeemed'
        },
        {
          key: 'wechat-unique-visitors',
          label: 'T_WECHAT_UNIQUE_VISITORS',
          field: 'total.wechatUniqueVisit'
        },
        {
          key: 'leads-generation',
          label: 'T_LEADS_GENERATION',
          field: 'total.leadsGeneration'
        }
      ],
      dataList: [],
      subSections: [
        {
          title: 'T_PERFORMANCE',
          id: 'performance',
          cardTop: {
            type: 'hidden',
            states: [
              {
                title: 'T_PERFORMANCE'
              }
            ]
          }
        },
        {
          title: 'T_HIGHLIGHTS',
          id: 'highlights',
          cardTop: {
            type: 'hidden',
            states: [
              {
                title: 'T_HIGHLIGHTS'
              }
            ]
          }
        }
      ]
    }
  },
  async mounted() {
    TranslateService.events.on('changeLanguage', () => {
      this.gotData()
    })
  },
  methods: {
    getTotalVal(data, key) {
      return _.get(data, `total.${key}`, false)
    },
    getMostPopularTouchpoint(data) {
      const experiences = _.map(
        _.groupBy(_.get(data, 'experiencePerformances', []), 'experience'),
        (records, experience) => {
          return {
            experience,
            total: _.sum(_.map(records, (record) => record.generated.count))
          }
        }
      )
      const foundExperience = _.find(_.get(data, 'experiences', []), {
        _id: _.get(_.maxBy(experiences, 'total'), 'experience', false)
      })
      return _.get(foundExperience, `${TranslateService.currentLocale}.name`, '')
    },
    formatLabels(hideOrders, hideLeads) {
      const labels = _.compact(
        _.map(this.labelItems, (item) => {
          if (_.includes(['orders', 'leads-generation'], item.key)) {
            const shouldHide = item.key === 'orders' ? hideOrders : hideLeads
            if (shouldHide) {
              return false
            }
          }
          return TranslateService.get(item.label)
        })
      )
      const newlabels = []
      // const invisibleSpace = TranslateService.currentLocale === 'zhCN' ? '\u3800' : '\u2000'
      const invisibleSpace = '\u2000'
      // console.warn(`invisible space =\u2000  ${invisibleSpace}`)
      const maxLen = this.getLabelMaxLength(labels)
      const half = _.get(labels, 'length', 0) % 2 === 0 ? labels.length / 2 : false
      _.each(labels, (label, i) => {
        if (i === 0 || (half !== false && i === half)) {
          newlabels.push(label)
        } else {
          newlabels.push(_.padEnd(labels[i], maxLen, invisibleSpace))
        }
      })
      this.labels = newlabels
    },
    getLabelMaxLength(labels) {
      return _.max(_.map(labels, (l) => _.get(l, 'length', 1) - 1))
    },
    getBestNameplate(data) {
      const nameplate = _.get(data, 'bestPerformingNameplate.traffic.vehicle.nameplate', false)
      const color = _.get(data, 'bestPerformingNameplate.traffic.vehicle.color', false)
      const nameParts = []
      if (_.get(nameplate, TranslateService.currentLocale, false)) {
        nameParts.push(_.get(nameplate, TranslateService.currentLocale, false))
      }
      if (_.get(nameplate, TranslateService.currentLocale, false)) {
        nameParts.push(_.get(color, TranslateService.currentLocale, false))
      }
      return _.join(nameParts, ' - ')
    },
    getConversion(conversionString) {
      return _.toNumber(_.get(_.split(conversionString, ':'), `[1]`, '1'))
    },
    rank(num, array) {
      return (
        _.clone(array)
          .sort((a, b) => b - a)
          .indexOf(num) + 1
      )
    },
    gotData() {
      this.dataList = false
      this.labels = false
      const radarData = this.secondaryData
        ? [this.primaryData, this.secondaryData]
        : [this.primaryData]
      let hideOrders = !this.hasOrders(_.first(radarData))
      let hideLeads = !this.hasLeads(_.first(radarData))
      this.dataList = _.map(radarData, (data) => {
        const eventYear = _.get(data, 'pavilion.event.year', false)
        if (!eventYear) {
          return false
        }
        let datasetData = []
        _.each(this.labelItems, (item) => {
          if (_.get(item, 'field', false) === 'getTotalForExperiences') {
            datasetData.push(this.getTotalForExperiences(data))
          } else if (
            (item.key === 'orders' && hideOrders) ||
            (item.key === 'leads-generation' && hideLeads)
          ) {
            console.info(`${item.key} ignored since pavilion option = false`)
          } else {
            datasetData.push(Number(_.get(data, item.field, 0)))
          }
        })
        // const maxVal = _.max(datasetData)
        const divider =
          Math.pow(10, `${_.min(datasetData)}`.split('.').length) *
          _.first(`${_.get(datasetData, 'length', 1)}`)
        const roundedData = _.map(
          datasetData,
          (val) => (val > 0 ? Math.round(val / divider) * divider : 0) + 1
        )
        datasetData = _.map(roundedData, (val) => {
          const countIf = _.filter(roundedData, (v) => v === val).length
          const rank = this.rank(val, roundedData)
          const currentRank = rank + countIf - 1
          return datasetData.length + 1 - currentRank
        })
        return {
          label: _.get(data, 'pavilion.event.key', eventYear),
          data: datasetData,
          contentCards: _.compact(
            _.map(this.labelItems, (item) => {
              if (_.get(item, 'field', false) === 'getTotalForExperiences') {
                return { item, value: this.getTotalForExperiences(data) }
              } else if (
                (item.key === 'orders' && hideOrders) ||
                (item.key === 'leads-generation' && hideLeads)
              ) {
                return false
              }
              return { item, value: _.get(data, item.field, 0) }
            })
          )
        }
      })
      this.formatLabels(hideOrders, hideLeads)
    }
  }
}
</script>

<style lang="scss">
@import '../../vars.scss';

#pdf-overview-section {
  .performance-overview {
    .pie-chart {
      width: 277px;
      height: 264px !important;
    }
  }
  .section-sidebar {
    .section-title {
      margin-top: 50px;
    }
  }
  .custom-legend-wrapper {
    width: 100%;
    text-align: center;
  }
  .custom-legend {
    display: inline-block;
    background-color: $dark-background;
    border-radius: 8px;
    padding: $half-margin;
    font-style: italic;
    font-weight: 400;
    font-size: $scale-vw * 10;
    line-height: $scale-vw * 13;
    .data-label {
      display: inline-flex;
      align-items: center;
      justify-items: flex-start;
      &:last-child {
        margin-left: $standard-margin;
      }
    }
  }
  .performance-overview {
    width: 100%;
    text-align: center;
    .pie-chart {
      margin: 0 auto;
    }
  }
  .content-card {
    width: calc(50% - 4px);
    .card-content {
      font-style: normal;
      font-weight: 700;
      font-size: 8px;
      line-height: 10px;
    }
  }
  .section-content {
    > .cards-row {
      > div {
        width: 47.5%;
      }
    }
  }
}
.no-card-top-mb {
  .card-top {
    margin-bottom: 0;
  }
}
</style>
