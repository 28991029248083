<template>
  <div v-if="primaryData" id="event-section" :class="secondaryData ? 'compare' : 'section'">
    <section-loader v-if="loading" />
    <template v-else-if="primaryData">
      <template v-if="!secondaryData">
        <v-card class="first-card">
          <h2>{{ primaryData.pavilion.event.name | translate }}</h2>
          <pavilion-gallery :pavilion="primaryData.pavilion" />
          <pavilion-info :pavilion="primaryData.pavilion" />
        </v-card>
        <!-- Voices of media -->
        <content-card
          v-if="hasField(primaryData.pavilion, 'voiceOfMedia')"
          :title="'T_VOICES_OF_MEDIA' | translate"
          :content="primaryData.pavilion.voiceOfMedia | translate"
          can-expand
          italic
          truncate-text="60"
        />
        <content-card v-else :title="'T_VOICES_OF_MEDIA' | translate" no-data italic />
        <!-- Press conference + Media attendancees -->
        <div class="cards-row">
          <content-card
            v-if="
              hasField(primaryData.pavilion, 'pressConference') &&
              translateArray(primaryData.pavilion, 'pressConference').length > 0
            "
            :title="'T_PRESS_CONFERENCE' | translate"
            :content-list="translateArray(primaryData.pavilion, 'pressConference')"
            italic
            can-expand
            truncate-text="60"
          />
          <content-card v-else :title="'T_PRESS_CONFERENCE' | translate" no-data italic />
          <content-card
            v-if="
              hasField(primaryData.pavilion, 'mediaAttendees') &&
              translateArray(primaryData.pavilion, 'mediaAttendees').length > 0
            "
            :title="'T_MEDIA_ATTENDANCEES' | translate"
            :content-list="translateArray(primaryData.pavilion, 'mediaAttendees')"
            can-expand
            has-list-bullet
            truncate-text="5"
          />
          <content-card v-else :title="'T_MEDIA_ATTENDANCEES' | translate" no-data can-expand />
        </div>
      </template>
      <template v-else>
        <compare-show-titles
          v-if="secondaryData"
          :primary-data="primaryData"
          :secondary-data="secondaryData"
        />
        <div class="cards-row">
          <v-card>
            <pavilion-gallery :pavilion="primaryData.pavilion" compare />
            <pavilion-info :pavilion="primaryData.pavilion" compare />
          </v-card>
          <v-card>
            <pavilion-gallery :pavilion="secondaryData.pavilion" compare />
            <pavilion-info :pavilion="secondaryData.pavilion" compare />
          </v-card>
          <!-- Voices of media -->
          <content-card
            v-if="hasField(primaryData.pavilion, 'voiceOfMedia')"
            :title="'T_VOICES_OF_MEDIA' | translate"
            :content="hasField(primaryData.pavilion, 'voiceOfMedia')"
            italic
            can-expand
            truncate-text="60"
          />
          <content-card v-else :title="'T_VOICES_OF_MEDIA' | translate" italic no-data />
          <content-card
            v-if="hasField(secondaryData.pavilion, 'voiceOfMedia')"
            :title="'T_VOICES_OF_MEDIA' | translate"
            :content="hasField(secondaryData.pavilion, 'voiceOfMedia')"
            italic
            can-expand
            truncate-text="60"
          />
          <content-card v-else :title="'T_VOICES_OF_MEDIA' | translate" italic no-data />
          <!-- Press conference + Media attendancees -->
          <content-card
            v-if="
              hasField(primaryData.pavilion, 'pressConference') &&
              translateArray(primaryData.pavilion, 'pressConference').length > 0
            "
            :title="'T_PRESS_CONFERENCE' | translate"
            :content-list="translateArray(primaryData.pavilion, 'pressConference')"
            show-list-total
            italic
            can-expand
            truncate-text="60"
          />
          <content-card v-else :title="'T_PRESS_CONFERENCE' | translate" italic no-data />
          <content-card
            v-if="
              hasField(secondaryData.pavilion, 'pressConference') &&
              translateArray(secondaryData.pavilion, 'pressConference').length > 0
            "
            :title="'T_PRESS_CONFERENCE' | translate"
            :content-list="translateArray(secondaryData.pavilion, 'pressConference')"
            show-list-total
            italic
            can-expand
            truncate-text="60"
          />
          <content-card v-else :title="'T_PRESS_CONFERENCE' | translate" italic no-data />
          <content-card
            v-if="
              hasField(primaryData.pavilion, 'mediaAttendees') &&
              translateArray(primaryData.pavilion, 'mediaAttendees').length > 0
            "
            :title="'T_MEDIA_ATTENDANCEES' | translate"
            :content-list="translateArray(primaryData.pavilion, 'mediaAttendees')"
            show-list-total
            can-expand
            has-list-bullet
            truncate-text="5"
          />
          <content-card v-else :title="'T_MEDIA_ATTENDANCEES' | translate" no-data can-expand />
          <content-card
            v-if="
              hasField(secondaryData.pavilion, 'mediaAttendees') &&
              translateArray(secondaryData.pavilion, 'mediaAttendees').length > 0
            "
            :title="'T_MEDIA_ATTENDANCEES' | translate"
            :content-list="translateArray(secondaryData.pavilion, 'mediaAttendees')"
            show-list-total
            can-expand
            has-list-bullet
            truncate-text="5"
          />
          <content-card v-else :title="'T_MEDIA_ATTENDANCEES' | translate" no-data can-expand />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
// import _ from 'lodash'
import CompareDataService from '@/services/CompareDataService'
import BaseSection from '@/mixins/BaseSection'
import PavilionGallery from '@/views/pavilion/PavilionGallery.vue'
import PavilionInfo from '@/views/pavilion/PavilionInfo.vue'
import ContentCard from '@/components/ContentCard.vue'
import CompareShowTitles from '@/components/CompareShowTitles.vue'

export default {
  name: 'EventSection',
  components: {
    ContentCard,
    CompareShowTitles,
    PavilionGallery,
    PavilionInfo
  },
  mixins: [BaseSection],
  data() {
    return {
      CompareDataService
    }
  },
  async mounted() {},
  methods: {
    gotData() {
      // console.warn(`GOT DATA - `, _.get(this.primaryData, 'pavilion.voiceOfMedia.enUS', ''))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';

#event-section {
  .first-card {
    padding: $standard-margin;
  }
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: $scale-vw * 19;
    line-height: $scale-vw * 24;
    text-align: center;
    margin-bottom: $standard-margin;
  }
  &.compare {
    width: 95%;
    margin: 0 auto;
  }
}
</style>
