<template>
  <div id="touchpoint" class="pdf-sub-section traffic-experience-page">
    <div class="section-sidebar">
      <div class="section-title">{{ 'T_EXPERIENCE' | translate }} - {{ experienceName }}</div>
    </div>
    <div v-if="primaryData" class="section-content">
      <div class="experience-info">
        <div class="touchpoint-title">{{ 'T_EXPERIENCE' | translate }} - {{ experienceName }}</div>
        <div class="images">
          <img
            v-if="getImage('gallery[0]')"
            :src="getImage('gallery[0]')"
            class="img"
            height="68"
          />
          <img
            v-if="getImage('souvenirExamples[0]')"
            :src="getImage('souvenirExamples[0]')"
            class="img"
            height="68"
          />
          <img
            v-if="getImage('souvenirExamples[1]')"
            :src="getImage('souvenirExamples[1]')"
            class="img"
            height="68"
          />
        </div>
        <div v-if="getSouvenirTypes(experience)" class="souvenir-types">
          <img src="@/assets/icons/souvenir.svg" width="8" height="8" />
          <span>{{ getSouvenirTypes(experience) }}</span>
        </div>
        <div v-else class="souvenir-types">
          <span>{{ 'T_SOUVENIR_TYPE' | translate }}: {{ 'T_NO_SOUVENIR' | translate }}</span>
        </div>
        <div class="description">{{ getDescription() }}</div>
      </div>

      <!-- Interactions -->
      <img class="pdf-divider" src="@/assets/divider.svg" />

      <div class="sub-section">
        <div class="interaction-days">
          <div class="section-title">
            {{ 'T_GUEST_INTERACTIONS_BY_DATE' | translate }}
          </div>
          <div class="section-total">
            {{ 'T_TOTAL_STAND_GUEST_INTERACTIONS' | translate }}:
            <span>{{ totalGuestInteractions | numeral }}</span>
          </div>
          <line-chart
            is-for-pdf
            :data="lineDaily.data"
            :options="lineDaily.options"
            :highlight-value="0"
          />
        </div>
        <img class="pdf-divider" src="@/assets/divider.svg" />
        <div class="interaction-hours">
          <div class="section-title">
            {{ 'T_GUEST_INTERACTIONS_BY_HOUR' | translate }}
          </div>
          <bar-chart is-for-pdf :data="bar.data" :options="bar.options" :highlight-value="0" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import BaseSection from '@/mixins/BaseSection'
import LineChart from '@/components/Charts/LineChart.vue'
import BarChart from '@/components/Charts/BarChart.vue'
import ChartsDataService from '@/services/ChartsDataService'
import TranslateService from '@/services/TranslateService'

import TrafficSection from '@/mixins/TrafficSection'

export default {
  name: 'Traffic',
  components: {
    LineChart,
    BarChart
  },
  mixins: [BaseSection, TrafficSection],
  data() {
    return {
      pdfPage: 'touchpoint',
      bar: {
        options: {
          plugins: {
            datalabels: {
              display: true,
              align: 'center',
              font: {
                family: 'SourceSansPro-Bold',
                size: 8
              },
              opacity: 1,
              labels: {
                value: {
                  formatter: function (value, context) {
                    const val = _.get(context, `dataset.data[${context.dataIndex}]`, 0)
                    return val === 0 ? '' : val
                  },
                  font: {
                    family: 'SourceSansPro-Bold',
                    size: '8px',
                    weight: '700'
                  },
                  anchor: 'end',
                  align: 'top',
                  offset: 0,
                  color: 'black'
                }
              }
            }
          }
        }
      },
      lineDaily: {
        options: {
          plugins: {
            datalabels: {
              display: true,
              align: 'center',
              font: {
                family: 'SourceSansPro-Bold',
                size: 8
              },
              opacity: 1,
              labels: {
                value: {
                  formatter: function (value, context) {
                    const val = _.get(context, `dataset.data[${context.dataIndex}]`, 0)
                    return val === 0 ? '' : val
                  },
                  font: {
                    family: 'SourceSansPro-Bold',
                    size: '8px',
                    weight: '700'
                  },
                  anchor: 'end',
                  align: 'top',
                  offset: 0,
                  color: 'black'
                }
              }
            }
          }
        }
      },
      line: false,
      lineChartsColors: ['#8DE971', '#E1E000', '#DD2700', '#71DBD4'],
      totalGuestInteractions: 0,
      ChartsDataService
    }
  },
  computed: {
    experienceName() {
      return _.get(this.experience, `${TranslateService.currentLocale}.name`, '')
    },
    experience() {
      const experience = _.find(_.get(this.primaryData, 'experiences', []), {
        _id: this.pdfExperience
      })
      if (_.isUndefined(experience)) {
        return false
      }
      const attachments = _.get(experience, '_attachments', [])
      experience.souvenirExamples = this.formatAttachments(attachments, 'souvenirExamples')
      experience.gallery = this.formatAttachments(attachments, 'gallery')
      return experience
    }
  },
  async mounted() {},
  methods: {
    getImage(field) {
      const img = _.get(this.experience, field, false)
      if (_.includes(_.get(img, '_contentType', false), 'video')) {
        return false
      }
      return _.get(img, 'url', false)
    },
    getDescription() {
      return _.get(this.experience, `${TranslateService.currentLocale}.description`, '')
    },
    formatAttachments(attachments, name) {
      return _.compact(
        _.map(attachments, (attachment) => {
          if (attachment._name === name) {
            attachment.url = `/api/experiences/${this.pdfExperience}/attachments/${attachment._id}`
            return attachment
          }
        })
      )
    },
    async loadImg(imgUrl) {
      return new Promise((resolve) => {
        const img = new Image()
        let timeout = false
        img.onload = function () {
          clearTimeout(timeout)
          resolve({ width: this.width, height: this.height })
        }
        img.src = imgUrl
        timeout = setTimeout(() => {
          console.info(`Timed out when trying to load image: ${imgUrl}`)
          resolve({ width: 1, height: 1 })
        }, 5000)
      })
    },
    getSouvenirTypes() {
      const souvenirTypes = []
      _.each(_.get(this.experience, 'souvenirTypes', []), (type) => {
        souvenirTypes.push(TranslateService.get('T_' + _.toUpper(type)))
      })
      return souvenirTypes.length === 0 ? false : _.join(souvenirTypes, ', ')
    },
    filterIfNoDataPerHour(cardTopData) {
      const nbDataPerHour = _.sum(
        _.map(_.get(this.primaryData, 'experiencesByDate', []), (date) => {
          return _.get(date, 'visitsByHour.length', 0)
        })
      )
      if (nbDataPerHour === 0) {
        cardTopData.type = 'hidden'
        console.info(`Interactions per hour hidden since no data`)
      }
      return cardTopData
    },
    getTotalForDataset(chart, datasetIndex) {
      return _.sum(_.get(chart, `data.datasets[${datasetIndex}].data`, []))
    },
    getTotalForAllDays(data, obj, type) {
      return `${_.sum(
        _.map(_.get(data, obj, []), (d) => {
          return _.toNumber(_.get(d, `countByAction.${type}`, 0))
        })
      )}`
    },
    getList(chartObj, highlightValue) {
      return _.map(_.get(chartObj, 'data.datasets', []), (dataset, i) => {
        return {
          borderColor: ChartsDataService.lineColors[i],
          label: TranslateService.get(dataset.translationKey),
          value:
            highlightValue === 0
              ? _.sum(dataset.data)
              : _.get(dataset, `data[${highlightValue - 1}]`, 0)
        }
      })
    },
    getExperiencesInteractions(perHour = false, data = false) {
      let formattedData = []
      formattedData = this.getExperiencesDatasets(data ? data : this.primaryData, perHour)
      // console.warn(`getExperiencesInteractions `, perHour, data, formattedData)
      if (perHour) {
        return formattedData
      }
      formattedData = _.map(formattedData, (experience) => {
        return {
          borderColor: experience.borderColor,
          label: experience.label,
          value: _.sum(experience.data)
        }
      })
      return formattedData
    },
    getIcon(icon) {
      return require(`../../assets/icons/${icon}.svg`)
    },
    getTotalForExperience(data) {
      return _.sum(
        _.map(data.experiencesByDate, (date) => {
          return _.sum(
            _.map(date.visitsByExperience, (visitByExperience) => {
              return visitByExperience.experience === this.pdfExperience
                ? visitByExperience.count
                : 0
            })
          )
        })
      )
    },
    gotData() {
      this.bar.data = this.updateHourlyInteractionData()
      this.lineDaily.data = this.updateDailyInteractionData()
      this.totalGuestInteractions = this.getTotalForExperience(this.primaryData)
      this.loading = false
    },
    getLineData(data, dataset) {
      return {
        data: {
          labels: this.getLabels(this.primaryData, false, 'experiencesByDate', false),
          chartSelectorLabels: this.getLabels(
            this.primaryData,
            this.secondaryData,
            'experiencesByDate',
            true
          ),
          datasets: [dataset]
        },
        options: {
          plugins: {
            datalabels: {
              display: true,
              align: 'center',
              opacity: 1,
              labels: {
                value: {
                  font: {
                    family: 'SourceSansPro-Bold',
                    size: '8px',
                    weight: '700'
                  },
                  formatter: function (value, context) {
                    const val = _.get(context, `dataset.data[${context.dataIndex}]`, 0)
                    return val === 0 ? '' : val
                  },
                  anchor: 'end',
                  align: 'top',
                  offset: '0',
                  color: 'black'
                }
              }
            }
          }
        }
      }
    },
    hasNoHourlyData(data) {
      return _.isEmpty(this.getSelectedDateTraffic(data))
    },
    updateDailyInteractionData() {
      const labels = this.getTrafficDaysLabels(this.primaryData)
      const data = {
        labels: labels,
        chartSelectorLabels: labels,
        datasets: []
      }
      _.each(_.compact([this.primaryData, this.secondaryData]), (d, i) => {
        data.datasets.push({
          label: _.get(d, 'pavilion.event.key', false),
          backgroundColor: ChartsDataService.lineColors[i],
          data: this.getAllDaysTraffic(d),
          barPercentage: 0.3
        })
      })
      // console.warn(`DAY GRAPH DATA - `, data)
      return data
    },
    updateHourlyInteractionData() {
      const labels = this.getTrafficHoursLabels(this.primaryData)
      const data = {
        labels: labels,
        chartSelectorLabels: labels,
        datasets: []
      }
      _.each(_.compact([this.primaryData, this.secondaryData]), (d, i) => {
        data.datasets.push({
          label: _.get(d, 'pavilion.event.key', false),
          backgroundColor: ChartsDataService.lineColors[i],
          data: this.getSelectedDateTraffic(d),
          barPercentage: 0.3
        })
      })
      // console.warn(`HOUR GRAPH DATA - `, _.get(data, 'datasets[0].data', []))
      return data
    },
    getTrafficDates(data, secondaryData = false, obj) {
      if (secondaryData) {
        return _.map(
          this.getTrafficDates(secondaryData, false, obj),
          (date, i) => TranslateService.get('T_DAY') + ` ${i + 1}`
        )
      }
      const mappedData = _.map(_.get(data, obj), 'date')
      return _.isEmpty(mappedData)
        ? false
        : _.uniq(_.map(mappedData, (date) => moment(date).format('MM/DD/YYYY')))
    },
    getTrafficLabels(data, secondaryData = false, fullDate = false, obj) {
      if (secondaryData) {
        return _.map(
          this.getTrafficDates(data, false, obj),
          (date, i) => TranslateService.get('T_DAY') + ` ${i + 1}`
        )
      }
      if (fullDate) {
        return _.map(this.getTrafficDates(data, false, obj), (date) => {
          return moment(date, 'MM/DD/YYYY').format('DD/MM/YYYY')
        })
      }
      return _.map(this.getTrafficDates(data, false, obj), (date) => {
        return moment(date, 'MM/DD/YYYY').format('MM/DD')
      })
    },
    getInteractionsValueForExperience(experiencesByDate, experienceId) {
      // console.warn(`NOTE: all days total for interaction ${experienceId}`)
      return _.sum(
        _.map(experiencesByDate, (date) => {
          return this.getAsNumber(
            _.find(_.get(date, 'visitsByExperience', []), (e) => e.experience === experienceId),
            'count'
          )
        })
      )
    },
    getExperiencesDatasets(data, perHour = false, forceColors = false) {
      const datasets = []
      const experiencesByDate = _.get(data, 'experiencesByDate', [])
      const mappedData = {}
      let experiences = _.get(data, 'pavilion.experiences', [])
      if (perHour) {
        return _.map(experiences, (experience, i) => {
          return {
            label: experience.name,
            borderColor: forceColors ? forceColors[i] : ChartsDataService.lineColors[i],
            value: this.getInteractionsValueForExperience(experiencesByDate, experience._id)
          }
        })
      }
      _.each(experiencesByDate, (experiencesDate) => {
        _.each(experiences, (experience) => {
          mappedData[experience._id] = _.get(mappedData, experience._id, [])
          const subItem = _.find(
            _.get(experiencesDate, 'visitsByExperience', []),
            (e) => e.experience === experience._id
          )
          mappedData[experience._id].push(this.getAsNumber(subItem, 'count'))
        })
      })
      _.each(experiences, (experience, i) => {
        datasets.push({
          experience: experience._id,
          label: experience.name,
          data: _.map(_.get(mappedData, experience._id, []), (d) => numeral(d).format('0,0')),
          showLine: true,
          borderColor: forceColors ? forceColors[i] : ChartsDataService.lineColors[i],
          fill: false
        })
      })
      return datasets
    },
    getOriginalTrafficData(data) {
      return _.get(data, 'experiencesByDate', [])
    },
    getAllDaysTraffic(data) {
      return _.compact(
        _.map(this.getOriginalTrafficData(data), (date) => {
          return _.get(
            _.find(_.get(date, 'visitsByExperience', []), { experience: this.pdfExperience }),
            'count',
            false
          )
        })
      )
    },
    getSelectedDateTraffic(data) {
      const allDaysData = this.getOriginalTrafficData(data)
      const formattedData = {}
      _.each(allDaysData, (e) => {
        _.each(_.get(e, 'visitsByHour', []), (hour) => {
          const val = _.get(
            _.find(_.get(hour, 'visitsByExperience', []), { experience: this.pdfExperience }),
            'count',
            0
          )
          _.set(formattedData, hour.time, _.get(formattedData, hour.time, 0) + val)
        })
      })
      return _.values(formattedData)
    },
    getTrafficDaysLabels(data) {
      return _.map(_.get(data, 'experiencesByDate', []), 'date')
    },
    getTrafficHoursLabels(data) {
      let experienceByDate = this.getOriginalTrafficData(data)
      if (_.isArray(experienceByDate)) {
        // console.warn(`getTrafficHoursLabels - is array`)
        experienceByDate = _.get(experienceByDate, `[0]`, {})
      }
      const perHourLabels = _.map(_.get(experienceByDate, 'visitsByHour', []), 'time')
      // console.warn(`PER HOUR LABELS = `, perHourLabels)
      return perHourLabels
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';
#touchpoint {
  .experience-info {
    .touchpoint-title {
      margin-bottom: 8px;
    }
    .souvenir-types,
    .description {
      margin-top: 8px;
    }
    .description {
      font-size: 8px;
      line-height: 10px;
    }
    .souvenir-types {
      font-weight: 700;
      font-size: $scale-vw * 8;
      line-height: $scale-vw * 12;
      img {
        margin-right: $scale-vw * 3;
      }
    }
    .images {
      display: flex;
      justify-content: flex-start;
      img + img {
        margin-left: 8px;
      }
    }
  }
  .sub-section {
    display: flex;
    flex-direction: column;
    .cards-row {
      flex-direction: row;
    }
  }
}
</style>
