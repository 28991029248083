<template>
  <div id="traffic-section" :class="getDesktopWrapperClass()">
    <traffic-section-view-single />
    <traffic-section-compare />
  </div>
</template>

<script>
import BaseSection from '@/mixins/BaseSection'
import TrafficSection from '@/mixins/TrafficSection'
import TrafficSectionViewSingle from '@/views/desktop/TrafficSectionViewSingle'
import TrafficSectionCompare from '@/views/desktop/TrafficSectionCompare'

export default {
  name: 'TrafficSection',
  components: { TrafficSectionViewSingle, TrafficSectionCompare },
  mixins: [BaseSection, TrafficSection],
  data() {
    return {
      desktopMode: 'traffic-section-root'
    }
  }
}
</script>

<style lang="scss">
@import '../../varsDesktop.scss';
#traffic-section {
  .v-tabs-items {
    min-height: $card-sub-section;
  }

  &.compare {
    .pie-chart {
      margin-top: $standard-margin;
      height: 120px !important;
    }
    .compare-show-highlighted {
      margin-bottom: 0;
    }
  }
  .sub-section {
    &.full-width {
      width: 100%;
    }
  }
}

.map {
  margin: $standard-margin 0;
  &.rounded {
    border-radius: $half-margin;
  }
}
.areas-table {
  table {
    width: 90%;
    margin: $standard-margin auto;
    font-style: italic;
    font-weight: 400;
    font-size: $scale-vw * 14;
    line-height: $scale-vw * 26;
    text-align: center;
    color: white;
  }

  th,
  td {
    border: $table-border;
  }
  tr {
    background: $table-odd-row-background;
    &:nth-child(even) {
      background: $table-even-row-background;
    }
  }
}
.show-name {
  margin: $standard-margin 0;
  text-align: left;
}
h2 {
  text-align: left;
  margin: $half-margin 0;
}
.compare-show-highlighted {
  margin-top: 16px;
}
.play-pause-btn-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
</style>
