<template>
  <div class="section-loader">
    <v-progress-circular color="primary" indeterminate size="100" />
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
.section-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
