<template>
  <div class="pie-chart">
    <canvas ref="canvas" class="radar-canvas" />
  </div>
</template>
<script>
import _ from 'lodash'
// import numeral from 'numeral'
import Chart from 'chart.js/auto'
import ChartsDataService from '@/services/ChartsDataService'

function drawHexagon(ctx, nbPoints, r, x, y, color) {
  const a = (2 * Math.PI) / nbPoints
  ctx.beginPath()
  for (var i = 0; i < nbPoints; i++) {
    ctx.lineTo(x + r * Math.cos(a * i), y + r * Math.sin(a * i))
  }
  ctx.closePath()
  ctx.fillStyle = color
  ctx.strokeStyle = color
  ctx.fill()
  ctx.stroke()
}

function beforeDraw(chart) {
  if (!_.get(chart, 'config.options.chartArea.backgroundColor', false)) {
    return
  }
  chart.ctx.save()
  const nbPoints =
    _.get(chart, '$datalabels._labels.length', 0) / _.get(chart, 'data.datasets.length', 1)
  // console.warn(`nb points = `, nbPoints)
  const halfWidth = _.get(chart, 'scales.r.xCenter', 0)
  const halfHeight = _.get(chart, 'scales.r.yCenter', 0)
  chart.ctx.translate(halfWidth, halfHeight)
  let rotationAngle = 90
  if (nbPoints === 5) {
    rotationAngle = 126
    // } else if (nbPoints === 4) {
    //   rotationAngle = 90
  }
  chart.ctx.rotate((rotationAngle * Math.PI) / 180)
  const r = _.get(chart, 'scales.r.drawingArea', 0)
  drawHexagon(
    chart.ctx,
    nbPoints,
    r,
    0,
    0,
    _.get(chart, 'config.options.chartArea.backgroundColor', false)
  )
  chart.ctx.restore()
}

Chart.register({
  id: 'custom-radar',
  beforeDraw: beforeDraw
})

export default {
  props: {
    labels: {
      type: Array,
      default: () => {}
    },
    dataList: {
      type: Array,
      default: () => {}
    },
    isForPdf: {
      type: Boolean,
      default: false
    },
    isForDesktop: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    labels() {
      // console.warn(`labels changed`, this.labels, this.dataList)
      this.updateData()
    }
  },
  mounted() {
    const ctx = this.$refs.canvas.getContext('2d', { willReadFrequently: true })
    let options = {
      responsive: true,
      maintainAspectRatio: false,
      chartArea: {
        backgroundColor: this.isForPdf ? 'white' : '#292F47'
      },
      font: {
        family: 'SourceSansPro',
        size: this.isForDesktop ? 18 : 12
      },
      onResize: () => {
        beforeDraw(this.chart)
      },
      plugins: {
        datalabels: {
          display: false
        },
        legend: {
          display: false
        },
        tooltip: {
          enabled: false
        }
      },
      scales: {
        r: {
          grid: {
            color: this.isForPdf ? '#E6E6E6' : '#3E4B78'
          },
          gridLines: {
            color: this.isForPdf ? '#E6E6E6' : '#3E4B78'
          },
          angleLines: {
            color: this.isForPdf ? '#E6E6E6' : '#3E4B78'
          },
          min: 0,
          max: _.get(this.labels, 'length', 0),
          beginAtZero: true,
          ticks: {
            // display: false,
            stepSize: Math.ceil(
              _.max(_.get(this.dataList, '[0].data', 0)) / _.get(this.labels, 'length', 0)
            ),
            z: 1,
            backdropColor: 'transparent',
            color: this.isForPdf ? 'black' : 'white',
            font: {
              family: 'SourceSansPro',
              size: this.isForDesktop ? 16 : 8
            },
            callback: () => ''
          },
          pointLabels: {
            color: this.isForPdf ? 'black' : 'white'
          }
        }
      },
      elements: {
        line: {
          borderWidth: 1,
          borderColor: 'rgba(255, 92, 57, 1)'
        }
      },
      backgroundColor: this.isForPdf
        ? 'rgba(255, 92, 57, 1)'
        : [ChartsDataService.radarColors[2], ChartsDataService.radarColors[5]]
    }
    if (_.get(this.dataList, 'length', 0) === 1) {
      _.set(options, 'backgroundColor', [ChartsDataService.radarColorsForSingleShow[1]])
    }
    if (this.isForPdf) {
      _.set(options, 'animation.duration', 0)
    }
    const colorsObj = _.get(
      ChartsDataService,
      _.get(this.dataList, 'length', 0) === 1 ? 'radarColorsForSingleShow' : 'radarColors',
      []
    )
    if (this.isForDesktop) {
      _.set(options, 'scales.r.pointLabels.font.size', 16)
    }
    this.chart = new Chart(ctx, {
      type: 'radar',
      data: {
        // labels: this.isForPdf ? this.formatLabels() : this.labels,
        labels: this.formatLabels(),
        datasets: this.formatDataList(colorsObj)
      },
      options
    })
  },
  methods: {
    formatDataList(colorsObj) {
      return _.map(this.dataList, (item, i) => {
        const fullColor = _.get(colorsObj, `[${i * 3}]`, 'red')
        return {
          label: item.label,
          data: item.data,
          pointRadius: 3,
          pointHoverRadius: 5,
          pointHitRadius: 50,
          borderWidth: 1,
          borderColor: fullColor,
          pointBackgroundColor: fullColor,
          fillColor: _.get(colorsObj, `[${i * 2 + 1}]`),
          strokeColor: fullColor,
          pointColor: fullColor,
          pointStrokeColor: '#fff',
          pointHighlightFill: '#fff',
          pointHighlightStroke: fullColor,
          clip: 5
        }
      })
    },
    getMaxVal() {
      let maxVal = 0
      _.each(this.dataList, (data) => {
        const tmp = _.max(_.get(data, 'data', [0]))
        if (tmp > maxVal) {
          maxVal = tmp
        }
      })
      return maxVal
    },
    updateData() {
      if (!this.chart) {
        return
      }
      this.chart.data.labels = []
      _.set(this.chart, 'data.datasets', [])
      this.chart.data.labels = this.formatLabels()
      _.set(this.chart, 'data.datasets', this.formatDataList())
      // if (_.isArray(_.get(this.dataList, '[0]', false))) {
      //   _.each(this.dataList, d => {
      //     this.chart.data.datasets.push(d)
      //   })
      // } else {
      //   console.warn(`ELSE `)
      //   _.set(this.chart, 'data.datasets[0]', this.dataList)
      // }
      this.chart.update('none')
    },
    formatLabels() {
      let formattedLabels = []
      _.each(this.labels, (label) => {
        formattedLabels.push(_.map(_.split(label, ' '), (line) => _.trim(line)))
      })
      formattedLabels = _.map(formattedLabels, (label, i) => {
        if (i === 0 || i === 3) {
          return label
        }
        if (_.get(label, 'length', 0) === 1) {
          return [_.trim(label[0])]
        }
        let maxLen = _.get(label, '[0].length', 0) - _.get(label, `[1].length`, 0)
        if (maxLen > 0) {
          while (maxLen != 0) {
            if (i === 1 || i === 2) {
              _.set(label, `[1]`, ` ${_.get(label, '[1]', '')}`)
            } else if (i === 4 || i === 5) {
              _.set(label, `[1]`, `${_.get(label, '[1]', '')} `)
            }
            maxLen--
          }
        } else if (maxLen < 0) {
          while (maxLen !== 0) {
            if (i === 1 || i === 2) {
              _.set(label, `[0]`, ` ${_.get(label, '[0]', '')}`)
            } else if (i === 4 || i === 5) {
              _.set(label, `[0]`, `${_.get(label, '[0]', '')} `)
            }
            maxLen++
          }
        }
        return label
      })
      return formattedLabels
    }
  }
}
</script>

<style scoped>
.pie-chart {
  width: 100%;
  height: 300px;
}
</style>
