<template>
  <div class="pavilion-info" :class="{ compare: compare, 'is-for-desktop': isForDesktop }">
    <div class="info-box">
      <p :class="{ 'no-margin-bottom': filteredDates && filteredDates.length > 0 }">
        <img v-if="isForPdf" src="@/assets/icons/calendar.svg" width="10px" height="16px" />
        <v-img
          v-else
          src="@/assets/icons/calendar.svg"
          :max-width="compare ? 10 : 16"
          :max-height="compare ? 10 : 16"
        />
        <span class="dates-wrapper">
          {{ pavilion.event.startDate }} - {{ pavilion.event.endDate }}
          <span v-if="isForPdf && filteredDates && filteredDates.length > 0" class="filtered"
            >{{ 'T_FILTERED_FROM' | translate }}
            <span class="filtered-date">{{ filteredDates[0] }}</span>
            {{ 'T_FILTERED_TO' | translate }}
            <span class="filtered-date">{{ filteredDates[1] }}</span>
          </span>
        </span>
      </p>
      <p>
        <img v-if="isForPdf" src="@/assets/icons/location.svg" width="10px" height="16px" />
        <v-img
          v-else
          src="@/assets/icons/location.svg"
          :max-width="compare ? 10 : 16"
          :max-height="compare ? 10 : 16"
        />
        {{ pavilion.event.address | translate }}, {{ pavilion.hall | translate }}
      </p>
      <p>
        <img v-if="isForPdf" src="@/assets/icons/area.svg" width="10px" height="16px" />
        <v-img
          v-else
          src="@/assets/icons/area.svg"
          :max-width="compare ? 10 : 16"
          :max-height="compare ? 10 : 16"
        />
        {{ 'T_IMMERSIVE_PAVILION' | translate }}: {{ pavilion.size | numeral }}
        {{ 'T_SQM' | translate }}
      </p>
      <p>
        <img v-if="isForPdf" src="@/assets/icons/neighbors.svg" width="10px" height="16px" />
        <v-img
          v-else
          src="@/assets/icons/neighbors.svg"
          :max-width="compare ? 10 : 16"
          :max-height="compare ? 10 : 16"
        />
        {{ 'T_NEIGHBOURS' | translate }}: {{ pavilion.neighbours | translate | join }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pavilion: {
      type: Object,
      default: () => {}
    },
    compare: {
      type: Boolean,
      default: false
    },
    isForPdf: {
      type: Boolean,
      default: false
    },
    filteredDates: {
      type: [Array, Boolean],
      default: false
    },
    isForDesktop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';

#pdf-event-section .pavilion-info .info-box p {
  margin-bottom: -3px;
}
.pavilion-info {
  color: white;
}
p {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 8px;
}

img {
  filter: invert(1);
  margin-right: 5px;
}
h3 {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

h4 {
  font-family: 'SourceSansPro-Light';
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 4px;
  margin-bottom: 12px;
}

.info-box {
  margin-bottom: 0;
}

.pavilion-info {
  .info-box {
    width: auto;
    font-family: 'SourceSansPro-Light';
    .v-image {
      margin-right: $standard-margin;
    }
  }
  &.compare {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: $scale-vw * 12;
      line-height: $scale-vw * 15;
    }
  }
  &.is-for-desktop {
    .v-image {
      margin-right: 16px;
    }
    .info-box {
      padding: 0 16px;
      p {
        font-size: $desktop-scale-vw * 16;
        line-height: $desktop-scale-vw * 19;
      }
    }
    &.compare {
      p {
        font-size: $desktop-scale-vw * 16;
        line-height: $desktop-scale-vw * 19;
      }
    }
  }
  .dates-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .filtered-date {
    color: #8094dd;
  }
}
#pdf-event-section .pavilion-info .info-box p {
  &.no-margin-bottom {
    margin-bottom: 0;
  }
}
</style>
