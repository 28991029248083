<template>
  <div id="traffic-section" :class="{ compare: secondaryData }">
    <section-loader v-if="loading" />
    <template v-else-if="primaryData">
      <div v-if="secondaryData" class="big-section-title">{{ 'T_STAND_TRAFFIC' | translate }}</div>
      <v-chip-group
        v-model="currentSubSection"
        background-color="transparent"
        mandatory
        dark
        class="sub-sections"
      >
        <v-chip
          v-for="(subSection, i) in filteredSubSections"
          :key="i"
          :color="i == currentSubSection ? 'cyan' : 'primary'"
          link
          @click="goToTab(i)"
        >
          {{ subSection.title | translate }}
        </v-chip>
      </v-chip-group>
      <div class="sub-sections-wrapper">
        <v-tabs-items v-if="primaryData" v-model="currentSubSection">
          <!-- Stand traffic -->
          <v-tab-item class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter :current="1" :total="filteredSubSections.length" />
              <v-card class="sub-section">
                <card-top
                  :current-view.sync="selectedSubView"
                  :options="filterIfNoDataPerHour(getSectionCardTop('stand-traffic'))"
                />
                <div v-if="selectedSubView === 0" class="traffic-by-date">
                  <chart-selector
                    :data="
                      getDataForChart(bar, {
                        label: 'T_TOTAL_TRAFFIC',
                        value: getTotalValue(primaryData, secondaryData, 'total.standTraffic')
                      })
                    "
                    :center-label="secondaryData ? true : false"
                    :current-value.sync="highlightedTrafficValue"
                  />
                  <div v-if="graphHasData(bar)" class="info-box">
                    <bar-chart
                      :data="bar.data"
                      :options="bar.options"
                      :highlight-value="highlightedTrafficValue"
                    />
                    <div v-if="secondaryData && bar.data.datasets" class="compare-show-highlighted">
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="primaryData.pavilion.event.key"
                        :content="primaryData.total.eventVisitors"
                        type="compare"
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[0],
                          primary: getHighlightedValue(bar, 'primary', highlightedTrafficValue),
                          secondary: getHighlightedValue(bar, 'secondary', highlightedTrafficValue)
                        }"
                        italic
                      />
                      <v-divider vertical />
                      <content-card
                        no-gradient
                        dark-background
                        half-width
                        :title="secondaryData.pavilion.event.key"
                        type="compare"
                        :content="getHighlightedValue(bar, 'secondary', highlightedTrafficValue)"
                        :compare-data="{
                          circleColor: ChartsDataService.barColors[1]
                        }"
                        italic
                      />
                    </div>
                  </div>
                  <no-graph-data v-else />
                </div>
                <template v-else>
                  <div class="traffic-by-hour">
                    <template v-if="graphHasData(barHour)">
                      <template v-if="secondaryData">
                        <chart-selector
                          :data="
                            getDataForChart(bar, {
                              label: 'T_TOTAL_TRAFFIC',
                              value: getTotalValue(primaryData, secondaryData, 'total.standTraffic')
                            })
                          "
                          center-label
                          :current-value.sync="highlightedTrafficValue"
                        />
                        <div v-if="barHour.data.datasets" class="compare-show-highlighted">
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="primaryData.pavilion.event.key"
                            :content="primaryData.total.experienceGenerated"
                            type="compare"
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[0],
                              primary: getHighlightedValue(
                                barHour,
                                'primary',
                                highlightedTrafficValue,
                                highlightedTrafficHourValue
                              ),
                              secondary: getHighlightedValue(
                                barHour,
                                'secondary',
                                highlightedTrafficValue,
                                highlightedTrafficHourValue
                              )
                            }"
                            italic
                          />
                          <v-divider vertical />
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="secondaryData.pavilion.event.key"
                            type="compare"
                            :content="
                              getHighlightedValue(
                                barHour,
                                'secondary',
                                highlightedTrafficValue,
                                highlightedTrafficHourValue
                              )
                            "
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[1]
                            }"
                            italic
                          />
                        </div>
                        <bar-chart
                          :data="barHour.data"
                          :options="barHour.options"
                          :highlight-value="highlightedTrafficHourValue"
                        />
                        <chart-selector
                          center-label
                          :data="getDataForChart(barHour, getTotalTrafficByHour(), true)"
                          :current-value.sync="highlightedTrafficHourValue"
                        />
                      </template>
                      <template v-else>
                        <chart-selector
                          :data="
                            getDataForChart(bar, {
                              label: 'T_TOTAL_TRAFFIC',
                              value: getTotalValue(primaryData, secondaryData, 'total.standTraffic')
                            })
                          "
                          :current-value.sync="highlightedTrafficValue"
                        />
                        <bar-chart
                          :data="barHour.data"
                          :options="barHour.options"
                          :highlight-value="highlightedTrafficHourValue"
                        />
                        <chart-selector
                          :data="getDataForChart(barHour, getTotalTrafficByHour(), true)"
                          :current-value.sync="highlightedTrafficHourValue"
                        />
                      </template>
                    </template>
                    <no-graph-data v-else />
                  </div>
                </template>
              </v-card>
              <div v-if="secondaryData" class="cards-row">
                <compare-show-titles :primary-data="primaryData" :secondary-data="secondaryData" />
                <div class="compare-data">
                  <div class="cards-row vertical">
                    <content-card
                      no-gradient
                      :title="'T_TOTAL_VISITORS' | translate"
                      :content="primaryData.total.eventVisitors"
                      type="compare"
                      :compare-data="{
                        primary: primaryData.total.eventVisitors,
                        secondary: secondaryData.total.eventVisitors
                      }"
                      italic
                    />
                    <content-card
                      v-if="
                        (primaryData.total.standTraffic / primaryData.total.eventVisitors) * 100 &&
                          (secondaryData.total.standTraffic / secondaryData.total.eventVisitors) *
                            100
                      "
                      no-gradient
                      :title="'T_TOTAL_VISITORS_CONVERSION' | translate"
                      type="compare"
                      :compare-data="{
                        primaryPercentage: reduceConversion(
                          primaryData.total.standTraffic,
                          primaryData.total.eventVisitors
                        ),
                        primary:
                          (primaryData.total.standTraffic / primaryData.total.eventVisitors) * 100,
                        secondary:
                          (secondaryData.total.standTraffic / secondaryData.total.eventVisitors) *
                          100
                      }"
                      conversion
                      italic
                    />
                    <content-card
                      v-else
                      no-gradient
                      :title="'T_TOTAL_VISITORS_CONVERSION' | translate"
                      italic
                      no-data
                    />

                    <content-card
                      no-gradient
                      :title="'T_TOTAL_STAND_TRAFFIC' | translate"
                      :content="primaryData.total.eventVisitors"
                      type="compare"
                      :compare-data="{
                        primary: primaryData.total.standTraffic,
                        secondary: secondaryData.total.standTraffic
                      }"
                      italic
                    />
                  </div>
                  <v-divider vertical inset />
                  <div class="cards-row vertical">
                    <content-card
                      no-gradient
                      :title="'T_TOTAL_VISITORS' | translate"
                      type="compare"
                      :content="secondaryData.total.eventVisitors"
                      italic
                    />
                    <content-card
                      v-if="secondaryData.total.standTraffic && secondaryData.total.eventVisitors"
                      no-gradient
                      :title="'T_TOTAL_VISITORS_CONVERSION' | translate"
                      type="compare"
                      :content="
                        reduceConversion(
                          secondaryData.total.standTraffic,
                          secondaryData.total.eventVisitors
                        )
                      "
                      conversion
                      italic
                    />
                    <content-card
                      v-else
                      no-gradient
                      :title="'T_TOTAL_VISITORS_CONVERSION' | translate"
                      italic
                      no-data
                    />

                    <content-card
                      no-gradient
                      :title="'T_TOTAL_STAND_TRAFFIC' | translate"
                      type="compare"
                      :content="secondaryData.total.standTraffic"
                      italic
                    />
                  </div>
                </div>
              </div>
            </div>
          </v-tab-item>
          <!-- Stand statistics -->
          <v-tab-item v-if="hasStayAverage(primaryData)" class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter
                :current="getIndexForSubSection('stand-statistics')"
                :total="filteredSubSections.length"
              />
              <v-card class="sub-section">
                <card-top
                  :current-view.sync="selectedSubView"
                  :options="filterIfNoDataPerHour(getSectionCardTop('stand-statistics'))"
                />
                <div v-if="selectedSubView === 0" class="stay-average-by-date">
                  <div v-if="graphHasData(barStayAverage)" class="info-box">
                    <template v-if="hasStayAverage(primaryData)">
                      <chart-selector
                        :center-label="secondaryData ? true : false"
                        :data="
                          getDataForChart(barStayAverage, {
                            label: 'T_STAY_AVERAGE',
                            value: getStayAverage()
                          })
                        "
                        :formatter="formatStayAverage"
                        :current-value.sync="highlightedStayAverage"
                      />
                      <bar-chart
                        :data="barStayAverage.data"
                        :options="barStayAverage.options"
                        :highlight-value="highlightedStayAverage"
                      />
                      <template v-if="secondaryData">
                        <div v-if="barStayAverage.data.datasets" class="compare-show-highlighted">
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="primaryData.pavilion.event.key"
                            :content="
                              getHighlightedValue(
                                barStayAverage,
                                'primary',
                                highlightedStayAverage,
                                undefined,
                                true
                              )
                            "
                            :formatter="formatStayAverage"
                            type="compare"
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[0],
                              primary: getHighlightedValue(
                                barStayAverage,
                                'primary',
                                highlightedStayAverage,
                                undefined,
                                true
                              ),
                              secondary: getHighlightedValue(
                                barStayAverage,
                                'secondary',
                                highlightedStayAverage,
                                undefined,
                                true
                              )
                            }"
                            italic
                          />
                          <v-divider vertical />
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="secondaryData.pavilion.event.key"
                            :formatter="formatStayAverage"
                            type="compare"
                            :content="
                              getHighlightedValue(
                                barStayAverage,
                                'secondary',
                                highlightedStayAverage,
                                undefined,
                                true
                              )
                            "
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[1]
                            }"
                            italic
                          />
                        </div>
                        <demography-pie-charts
                          :hide="[
                            primaryData.pavilion.hideGenders,
                            secondaryData.pavilion.hideGenders
                          ]"
                          :titles="['PER_GENDER']"
                          :pies="[pieGenders, pieGenders2]"
                          :highlights="[highlightedStayAverage]"
                          compare
                        />
                        <demography-pie-charts
                          :hide="[
                            primaryData.pavilion.hideAgeRanges,
                            secondaryData.pavilion.hideAgeRanges
                          ]"
                          :titles="['PER_AGE_GROUP']"
                          :pies="[pieAgeRanges, pieAgeRanges2]"
                          :highlights="[highlightedStayAverage]"
                          compare
                        />
                      </template>
                      <demography-pie-charts
                        v-else
                        :hide="[
                          primaryData.pavilion.hideGenders,
                          primaryData.pavilion.hideAgeRanges
                        ]"
                        :titles="['PER_GENDER', 'PER_AGE_GROUP']"
                        :pies="[pieGenders, pieAgeRanges]"
                        :highlights="[highlightedStayAverage]"
                      />
                    </template>
                    <no-graph-data v-else />
                  </div>
                  <no-graph-data v-else />
                </div>
                <div v-else class="stay-average-by-hour">
                  <template v-if="graphHasData(barStayAverageHour) && hasStayAverage(primaryData)">
                    <chart-selector
                      :center-label="secondaryData ? true : false"
                      :data="
                        getDataForChart(barStayAverage, {
                          label: 'T_STAY_AVERAGE',
                          value: getStayAverage()
                        })
                      "
                      :formatter="formatStayAverage"
                      :current-value.sync="highlightedStayAverage"
                    />
                    <bar-chart
                      :data="barStayAverageHour.data"
                      :options="barStayAverageHour.options"
                      :highlight-value="highlightedStayAverageHour"
                    />
                    <chart-selector
                      :center-label="secondaryData ? true : false"
                      :data="
                        getDataForChart(barStayAverageHour, {
                          label: 'T_STAY_AVERAGE',
                          value: getStayAverage(true)
                        })
                      "
                      :formatter="formatStayAverage"
                      :current-value.sync="highlightedStayAverageHour"
                    />
                    <template v-if="secondaryData">
                      <div v-if="barStayAverageHour.data.datasets" class="compare-show-highlighted">
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="primaryData.pavilion.event.key"
                          :content="
                            getHighlightedValue(
                              barStayAverageHour,
                              'primary',
                              highlightedStayAverage,
                              highlightedStayAverageHour,
                              true
                            )
                          "
                          type="compare"
                          :formatter="formatStayAverage"
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[0],
                            primary: getHighlightedValue(
                              barStayAverageHour,
                              'primary',
                              highlightedStayAverage,
                              highlightedStayAverageHour,
                              true
                            ),
                            secondary: getHighlightedValue(
                              barStayAverageHour,
                              'secondary',
                              highlightedStayAverage,
                              highlightedStayAverageHour,
                              true
                            )
                          }"
                          italic
                        />
                        <v-divider vertical />
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="secondaryData.pavilion.event.key"
                          type="compare"
                          :content="
                            getHighlightedValue(
                              barStayAverageHour,
                              'secondary',
                              highlightedStayAverage,
                              highlightedStayAverageHour,
                              true
                            )
                          "
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[1]
                          }"
                          italic
                        />
                      </div>
                      <h2>{{ 'T_STAY_AVERAGE' | translate }}</h2>
                      <div v-if="barArea.data.datasets" class="compare-show-highlighted">
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :formatter="formatStayAverage"
                          :title="primaryData.pavilion.event.key"
                          :content="getStayAverage(false, selectedArea)"
                          type="compare"
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[0],
                            primary: getStayAverage(false, selectedArea),
                            secondary: getStayAverage(false, selectedArea, secondaryData)
                          }"
                          italic
                        />
                        <v-divider vertical />
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="secondaryData.pavilion.event.key"
                          :formatter="formatStayAverage"
                          type="compare"
                          :content="getStayAverage(false, selectedArea, secondaryData)"
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[1]
                          }"
                          italic
                        />
                      </div>
                      <demography-pie-charts
                        :hide="[
                          primaryData.pavilion.hideGenders,
                          secondaryData.pavilion.hideGenders
                        ]"
                        :titles="['PER_GENDER']"
                        :pies="[pieGendersByHour, pieGendersByHour2]"
                        :highlights="[highlightedStayAverage + highlightedStayAverageHour]"
                        compare
                      />
                      <demography-pie-charts
                        :hide="[
                          primaryData.pavilion.hideAgeRanges,
                          secondaryData.pavilion.hideAgeRanges
                        ]"
                        :titles="['PER_AGE_GROUP']"
                        :pies="[pieAgeRangesByHour, pieAgeRangesByHour2]"
                        :highlights="[highlightedStayAverage + highlightedStayAverageHour]"
                        compare
                      />
                    </template>
                    <demography-pie-charts
                      v-else
                      :hide="[primaryData.pavilion.hideGenders, primaryData.pavilion.hideAgeRanges]"
                      :titles="['PER_GENDER', 'PER_AGE_GROUP']"
                      :pies="[pieGendersByHour, pieAgeRangesByHour]"
                      :highlights="[highlightedStayAverage + highlightedStayAverageHour]"
                      compare
                    />
                  </template>
                  <no-graph-data v-else />
                </div>
              </v-card>
            </div>
          </v-tab-item>
          <!-- Area traffic -->
          <v-tab-item class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter
                :current="getIndexForSubSection('area-traffic')"
                :total="filteredSubSections.length"
              />
              <v-card class="sub-section">
                <card-top
                  :current-view.sync="selectedSubView"
                  :options="filterIfNoDataPerHour(getSectionCardTop('area-traffic'))"
                />
                <div v-if="selectedSubView === 0" class="demography-by-date">
                  <div v-if="graphHasData(barArea)" class="info-box">
                    <chart-selector
                      :center-label="secondaryData ? true : false"
                      :data="getDataForAreas(primaryData, false)"
                      :current-value.sync="highlightedArea"
                    />
                    <bar-chart
                      :data="barArea.data"
                      :options="barArea.options"
                      :highlight-value="highlightedAreaDate"
                    />
                    <chart-selector
                      :center-label="secondaryData ? true : !highlightedAreaDate"
                      :data="
                        getDataForChart(barArea, {
                          label: 'T_ALL_DAYS',
                          value: getTotalForSelectedArea(primaryData, false, getSelectedArea())
                        })
                      "
                      :current-value.sync="highlightedAreaDate"
                    />
                    <chart-selector-disabled
                      v-if="hasStayAverage(primaryData)"
                      label="T_STAY_AVERAGE"
                      :value="formatStayAverage(getStayAverage(false, selectedArea))"
                    />
                    <template v-if="secondaryData">
                      <div v-if="barArea.data.datasets" class="compare-show-highlighted">
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="primaryData.pavilion.event.key"
                          :content="
                            getHighlightedValue(
                              barArea,
                              'primary',
                              highlightedAreaDate,
                              undefined,
                              true
                            )
                          "
                          type="compare"
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[0],
                            primary: getHighlightedValue(
                              barArea,
                              'primary',
                              highlightedAreaDate,
                              undefined,
                              true
                            ),
                            secondary: getHighlightedValue(
                              barArea,
                              'secondary',
                              highlightedAreaDate,
                              undefined,
                              true
                            )
                          }"
                          italic
                        />
                        <v-divider vertical />
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="secondaryData.pavilion.event.key"
                          type="compare"
                          :content="
                            getHighlightedValue(
                              barArea,
                              'secondary',
                              highlightedAreaDate,
                              undefined,
                              true
                            )
                          "
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[1]
                          }"
                          italic
                        />
                      </div>
                      <demography-pie-charts
                        :hide="[
                          primaryData.pavilion.hideGenders,
                          secondaryData.pavilion.hideGenders
                        ]"
                        :titles="['PER_GENDER']"
                        :pies="[pieGendersPerArea, pieGendersPerArea2]"
                        :highlights="[highlightedArea + highlightedAreaDate]"
                        compare
                      />
                      <demography-pie-charts
                        :hide="[
                          primaryData.pavilion.hideAgeRanges,
                          secondaryData.pavilion.hideAgeRanges
                        ]"
                        :titles="['PER_AGE_GROUP']"
                        :pies="[pieAgeRangesPerArea, pieAgeRangesPerArea2]"
                        :highlights="[highlightedArea + highlightedAreaDate]"
                        compare
                      />
                    </template>
                    <demography-pie-charts
                      v-else
                      :hide="[primaryData.pavilion.hideGenders, primaryData.pavilion.hideAgeRanges]"
                      :titles="['PER_GENDER', 'PER_AGE_GROUP']"
                      :pies="[pieGendersByHourPerArea, pieAgeRangesByHourPerArea]"
                      :highlights="[highlightedArea + highlightedAreaDate + highlightedAreaHour]"
                    />
                  </div>
                  <no-graph-data v-else />
                </div>
                <div v-else class="demography-by-hour">
                  <chart-selector
                    :center-label="secondaryData ? true : false"
                    :data="getDataForAreas(primaryData, false)"
                    :current-value.sync="highlightedArea"
                  />
                  <div class="info-box">
                    <chart-selector
                      :center-label="secondaryData ? true : false"
                      :data="
                        getDataForChart(barArea, {
                          label: 'T_ALL_DAYS',
                          value: getTotalForSelectedArea(primaryData, false, getSelectedArea())
                        })
                      "
                      :current-value.sync="highlightedAreaDate"
                    />
                    <div class="graph-wrapper">
                      <bar-chart
                        :data="barAreaHour.data"
                        :options="barAreaHour.options"
                        :highlight-value="highlightedAreaHour"
                      />
                    </div>
                    <chart-selector
                      :data="getDataForChart(barAreaHour, getAverageForArea(primaryData), true)"
                      :current-value.sync="highlightedAreaHour"
                    />
                    <chart-selector-disabled
                      v-if="!secondaryData && hasStayAverage(primaryData)"
                      label="T_STAY_AVERAGE"
                      :value="formatStayAverage(getStayAverage(false, selectedArea))"
                    />
                    <template v-if="secondaryData">
                      <div v-if="barAreaHour.data.datasets" class="compare-show-highlighted">
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="primaryData.pavilion.event.key"
                          :content="
                            getHighlightedValue(
                              barAreaHour,
                              'primary',
                              highlightedAreaHour,
                              undefined,
                              true
                            )
                          "
                          type="compare"
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[0],
                            primary: getHighlightedValue(
                              barAreaHour,
                              'primary',
                              highlightedAreaHour,
                              undefined,
                              true
                            ),
                            secondary: getHighlightedValue(
                              barAreaHour,
                              'secondary',
                              highlightedAreaHour,
                              undefined,
                              true
                            )
                          }"
                          italic
                        />
                        <v-divider vertical />
                        <content-card
                          no-gradient
                          dark-background
                          half-width
                          :title="secondaryData.pavilion.event.key"
                          type="compare"
                          :content="
                            getHighlightedValue(
                              barAreaHour,
                              'secondary',
                              highlightedAreaHour,
                              undefined,
                              true
                            )
                          "
                          :compare-data="{
                            circleColor: ChartsDataService.barColors[1]
                          }"
                          italic
                        />
                      </div>
                      <template v-if="hasStayAverage(primaryData)">
                        <h2>{{ 'T_STAY_AVERAGE' | translate }}</h2>
                        <div v-if="barArea.data.datasets" class="compare-show-highlighted">
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :formatter="formatStayAverage"
                            :title="primaryData.pavilion.event.key"
                            :content="getStayAverage(false, selectedArea)"
                            type="compare"
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[0],
                              primary: getStayAverage(false, selectedArea),
                              secondary: getStayAverage(false, selectedArea, secondaryData)
                            }"
                            italic
                          />
                          <v-divider vertical />
                          <content-card
                            no-gradient
                            dark-background
                            half-width
                            :title="secondaryData.pavilion.event.key"
                            :formatter="formatStayAverage"
                            type="compare"
                            :content="getStayAverage(false, selectedArea, secondaryData)"
                            :compare-data="{
                              circleColor: ChartsDataService.barColors[1]
                            }"
                            italic
                          />
                        </div>
                        <demography-pie-charts
                          :hide="[
                            primaryData.pavilion.hideGenders,
                            secondaryData.pavilion.hideGenders
                          ]"
                          :titles="['PER_GENDER']"
                          :pies="[pieGendersByHourPerArea, pieGendersByHourPerArea2]"
                          :highlights="[highlightedArea + highlightedAreaDate]"
                          compare
                        />
                        <demography-pie-charts
                          :hide="[
                            primaryData.pavilion.hideAgeRanges,
                            secondaryData.pavilion.hideAgeRanges
                          ]"
                          :titles="['PER_AGE_GROUP']"
                          :pies="[pieAgeRangesByHourPerArea, pieAgeRangesByHourPerArea2]"
                          :highlights="[highlightedArea + highlightedAreaDate]"
                          compare
                        />
                      </template>
                    </template>
                    <demography-pie-charts
                      v-else
                      :hide="[primaryData.pavilion.hideGenders, primaryData.pavilion.hideAgeRanges]"
                      :titles="['PER_GENDER', 'PER_AGE_GROUP']"
                      :pies="[pieGendersByHourPerArea, pieAgeRangesByHourPerArea]"
                      :highlights="[highlightedArea + highlightedAreaDate + highlightedAreaHour]"
                    />
                  </div>
                </div>
              </v-card>
            </div>
          </v-tab-item>
          <!-- Vehicle list -->
          <v-tab-item class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter
                :current="getIndexForSubSection('area-list')"
                :total="filteredSubSections.length"
              />
              <v-card class="sub-section">
                <card-top
                  :current-view.sync="selectedSubView"
                  :options="getSectionCardTop('area-list')"
                />
                <div v-if="selectedSubView === 0" class="floorplans">
                  <div
                    v-if="secondaryData"
                    class="show-name"
                    :style="`color:${ChartsDataService.barColors[0]}`"
                  >
                    {{ primaryData.pavilion.event.name | translate }}
                  </div>
                  <axo-view :data="primaryData" />
                  <areas-table v-if="!reloadAreasTable" :data="primaryData" />
                  <div
                    v-if="secondaryData"
                    class="show-name"
                    :style="`color:${ChartsDataService.barColors[1]}`"
                  >
                    {{ secondaryData.pavilion.event.name | translate }}
                  </div>
                  <axo-view :data="secondaryData" />
                  <areas-table v-if="secondaryData && reloadAreasTable" :data="secondaryData" />
                </div>
                <div v-else class="traffic-by-nameplate">
                  <template v-if="secondaryData">
                    <div class="compare-show-highlighted">
                      <div class="show">
                        <pie-chart
                          v-if="
                            pie &&
                              primaryData &&
                              primaryData.bestPerformingNameplate &&
                              primaryData.bestPerformingNameplate.traffic
                          "
                          :data="pie.data"
                          :options="pie.options"
                        />
                        <areas-table
                          v-if="!reloadAreasTable"
                          :data="primaryData"
                          show-unique-visitors
                        />
                      </div>
                      <v-divider vertical />
                      <div class="show">
                        <pie-chart
                          v-if="
                            pie2 &&
                              secondaryData &&
                              secondaryData.bestPerformingNameplate &&
                              secondaryData.bestPerformingNameplate.traffic
                          "
                          :data="pie2.data"
                          :options="pie2.options"
                        />
                        <areas-table
                          v-if="!reloadAreasTable"
                          :data="secondaryData"
                          show-unique-visitors
                        />
                      </div>
                    </div>
                  </template>
                  <template v-else-if="primaryData">
                    <pie-chart
                      v-if="
                        pie &&
                          primaryData.bestPerformingNameplate &&
                          primaryData.bestPerformingNameplate.traffic
                      "
                      :data="pie.data"
                      :options="pie.options"
                    />
                    <areas-table
                      v-if="!reloadAreasTable"
                      :data="primaryData"
                      show-unique-visitors
                    />
                  </template>
                </div>
              </v-card>
            </div>
          </v-tab-item>
          <!-- Attractiveness -->
          <v-tab-item v-if="primaryData" class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter
                :current="getIndexForSubSection('attractiveness')"
                :total="filteredSubSections.length"
              />
              <template v-if="!canCompareSubSection('attractiveness')">
                <v-card class="sub-section no-data">
                  <cannot-compare-data />
                </v-card>
              </template>
              <template v-else>
                <v-card class="sub-section">
                  <div v-if="primaryData" class="info-box">
                    <card-top
                      :current-view.sync="selectedSubView"
                      :options="getSectionCardTop('attractiveness')"
                    />
                    <div v-if="selectedSubView === 0" class="info-box">
                      <div
                        v-if="secondaryData"
                        class="show-name"
                        :style="`color:${ChartsDataService.barColors[0]}`"
                      >
                        {{ primaryData.pavilion.event.name | translate }}
                      </div>
                      <chart-selector
                        :play-btn-callback="playPauseBubble"
                        :center-label="true"
                        :data="heatmapChartSelector"
                        :loading="heatmapChartSelector.length === 0"
                        :formatter="formatPercentage"
                        :current-value.sync="highlightedBubbleDay"
                      />
                      <bubble-chart
                        :data="primaryData"
                        :reloading-bubble="reloadingBubble"
                        :chart-data="bubbleChart.data"
                        :data-per-day="bubbleForSelectedDay"
                        :background-image="getBubbleChartBackground(primaryData)"
                      />
                      <template v-if="secondaryData">
                        <div class="show-name" :style="`color:${ChartsDataService.barColors[1]}`">
                          {{ secondaryData.pavilion.event.name | translate }}
                        </div>
                        <chart-selector
                          :play-btn-callback="playPauseBubble2"
                          :center-label="true"
                          :loading="heatmapChartSelector2.length === 0"
                          :data="heatmapChartSelector2"
                          :formatter="formatPercentage"
                          :current-value.sync="highlightedBubbleDay2"
                        />
                        <bubble-chart
                          :data="secondaryData"
                          :chart-data="bubbleChart2.data"
                          :reloading-bubble="reloadingBubble"
                          :data-per-day="bubbleForSelectedDay2"
                          :background-image="getBubbleChartBackground(secondaryData)"
                        />
                      </template>
                    </div>
                    <div v-else-if="graphHasData(barAttractiveness)" class="info-box">
                      <chart-selector
                        :center-label="selectedAttractiveness === 0 || secondaryData ? true : false"
                        :data="
                          getDataForAttractiveness(primaryData, {
                            label: 'T_ALL_AREAS',
                            value: formatPercentage(getTotalForSelectedAttractiveness(primaryData))
                          })
                        "
                        :formatter="formatPercentage"
                        :current-value.sync="highlightedAttractiveness"
                      />
                      <bar-chart
                        :data="barAttractiveness.data"
                        :options="barAttractiveness.options"
                        :highlight-value="highlightedAttractivenessDate"
                      />
                      <chart-selector
                        :center-label="
                          selectedAttractivenessDate === 0 || secondaryData ? true : false
                        "
                        :data="
                          getDataForChart(barAttractiveness, {
                            label: 'T_ALL_DAYS',
                            value: formatPercentage(getTotalForSelectedAttractiveness(primaryData))
                          })
                        "
                        :formatter="formatPercentage"
                        :current-value.sync="highlightedAttractivenessDate"
                      />
                    </div>
                    <no-graph-data v-else />
                  </div>
                </v-card>
              </template>
            </div>
          </v-tab-item>

          <!-- Heatmap -->
          <v-tab-item
            v-if="primaryData && primaryData.pavilion && primaryData.pavilion.hasHeatmap"
            class="section"
          >
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter
                :current="getIndexForSubSection('heatmap')"
                :total="filteredSubSections.length"
              />
              <template v-if="!canCompareSubSection('heatmap')">
                <v-card class="sub-section no-data">
                  <cannot-compare-data />
                </v-card>
              </template>
              <template v-else>
                <v-card class="sub-section">
                  <div v-if="primaryData" class="info-box">
                    <card-top
                      :current-view.sync="selectedSubView"
                      :options="getSectionCardTop('heatmap')"
                    />
                    <div
                      v-if="secondaryData"
                      class="show-name"
                      :style="`color:${ChartsDataService.barColors[0]}`"
                    >
                      {{ primaryData.pavilion.event.name | translate }}
                    </div>
                    <chart-selector
                      :loading="heatmapChartSelector.length === 0"
                      :play-btn-callback="playPauseHeatmap"
                      :center-label="selectedHeatmapDay === 0 || secondaryData ? true : false"
                      :data="heatmapChartSelector"
                      :formatter="formatPercentage"
                      :current-value.sync="highlightedHeatmapDay"
                    />
                    <heatmap
                      v-if="!reloadingHeatmap"
                      :data="primaryData"
                      :data-per-day="heatmapForSelectedDay"
                      :show-tooltips="selectedSubView === 1"
                    />
                  </div>
                  <div v-if="secondaryData" class="info-box">
                    <div class="show-name" :style="`color:${ChartsDataService.barColors[1]}`">
                      {{ secondaryData.pavilion.event.name | translate }}
                    </div>
                    <chart-selector
                      :play-btn-callback="playPauseHeatmap2"
                      :center-label="selectedHeatmapDay2 === 0"
                      :loading="heatmapChartSelector2.length === 0"
                      :data="heatmapChartSelector2"
                      :formatter="formatPercentage"
                      :current-value.sync="highlightedHeatmapDay2"
                    />
                    <heatmap
                      v-if="!reloadingHeatmap2"
                      :data="secondaryData"
                      :show-tooltips="selectedSubView === 1"
                    />
                  </div>
                </v-card>
              </template>
            </div>
          </v-tab-item>

          <!-- Cameras -->
          <v-tab-item
            v-if="
              !secondaryData &&
                primaryData &&
                primaryData.pavilion &&
                primaryData.pavilion.hasCamera
            "
            class="section"
          >
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter
                :current="getIndexForSubSection('cameras')"
                :total="filteredSubSections.length"
              />
              <v-card v-if="!initCameras" class="sub-section">
                <card-top
                  :current-view.sync="selectedSubView"
                  :options="getSectionCardTop('cameras')"
                />
                <div v-if="cameras" class="info-box">
                  <chart-selector
                    v-if="getDataForCameras('devices')"
                    :data="getDataForCameras('devices')"
                    :current-value.sync="selectedCameraDevice"
                    center-label
                  />
                  <chart-selector
                    v-if="getDataForCameras('days')"
                    :data="getDataForCameras('days')"
                    :current-value.sync="selectedCameraDay"
                    center-label
                  />
                  <image-loader
                    v-if="getCameraImage()"
                    contain
                    gradient-loading
                    min-height="193"
                    :src="getCameraImage()"
                    :lazy-src="getLazySrc(getCameraImage(), '400')"
                    classes="camera-image"
                    @click.native="displayFullscreenImage(getCameraImage())"
                  />
                  <chart-selector
                    v-if="getDataForCameras('hours').length > 0"
                    :data="getDataForCameras('hours')"
                    :current-value.sync="selectedCameraHour"
                    center-label
                  />
                </div>
              </v-card>
            </div>
          </v-tab-item>
          <!-- Neighbors -->
          <v-tab-item class="section">
            <div v-touch="swipeDirective" class="sub-section">
              <sub-sections-counter
                :current="getIndexForSubSection('neighbors')"
                :total="filteredSubSections.length"
              />
              <v-card class="sub-section">
                <card-top
                  :current-view.sync="selectedSubView"
                  :options="getSectionCardTop('neighbors')"
                />
                <div class="info-box">
                  <div
                    v-if="secondaryData"
                    class="show-name"
                    :style="`color:${ChartsDataService.barColors[0]}`"
                  >
                    {{ primaryData.pavilion.event.name | translate }}
                  </div>
                  <!-- :src="secondaryData.pavilion.hallView" -->

                  <image-loader
                    max-height="600"
                    :src="primaryData.pavilion.hallView"
                    :lazy-src="getLazySrc(primaryData.pavilion.hallView, '500')"
                    classes="map rounded"
                    gradient-loading
                    @click.native="displayFullscreenNeighbors(primaryData)"
                  />

                  <template v-if="secondaryData">
                    <div class="show-name" :style="`color:${ChartsDataService.barColors[1]}`">
                      {{ secondaryData.pavilion.event.name | translate }}
                    </div>
                    <image-loader
                      max-height="600"
                      min-height="200"
                      :src="secondaryData.pavilion.hallView"
                      :lazy-src="getLazySrc(secondaryData.pavilion.hallView, '500')"
                      gradient-loading
                      classes="map rounded"
                      @click.native="displayFullscreenNeighbors(secondaryData)"
                    />
                  </template>
                </div>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>
  </div>
</template>

<script>
import BaseSection from '@/mixins/BaseSection'
import TrafficSection from '@/mixins/TrafficSection'
import AxoView from '@/components/AxoView'
import AreasTable from '@/components/AreasTable'

export default {
  name: 'TrafficSection',
  components: { AxoView, AreasTable },
  mixins: [BaseSection, TrafficSection],
  data() {
    return {
      mobileMode: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';
#traffic-section {
  .v-tabs-items {
    min-height: $card-sub-section;
  }
  &.compare {
    .pie-chart {
      margin-top: $standard-margin;
      height: 120px !important;
    }
    .compare-show-highlighted {
      margin-bottom: 0;
    }
  }
}

.map {
  margin: $standard-margin 0;
  &.rounded {
    border-radius: $half-margin;
  }
}
.areas-table {
  table {
    width: 90%;
    margin: $standard-margin auto;
    font-style: italic;
    font-weight: 400;
    font-size: $scale-vw * 12;
    line-height: $scale-vw * 24;
    text-align: center;
    color: white;
  }
  td {
    border: 2px solid black;
    border: $table-border;
  }
  tr {
    background: $table-odd-row-background;
    &:nth-child(even) {
      background: $table-even-row-background;
    }
  }
}
.show-name {
  margin: $standard-margin 0;
  text-align: left;
}
h2 {
  text-align: left;
  margin: $half-margin 0;
}
.demography-pie {
  width: 47.5%;
  .pie-chart {
    height: 160px !important;
  }
}
.play-pause-btn-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
</style>
