<template>
  <div class="traffic-section-sub-view">
    <section-loader v-if="loading" />
    <template v-else-if="primaryData && !secondaryData">
      <div class="sub-sections-wrapper">
        <div v-if="desktopLayoutReady" class="cards-row">
          <template v-for="(titleLine, i) in desktopTitles">
            <template v-for="(title, y) in titleLine">
              <card-top
                v-if="!title.hidden"
                :key="`desktop-mode-card-top-${i}-${y}`"
                :full-width="title.fullWidth === true"
                :options="getDesktopTitle(title.title)"
                divider
              />
            </template>
            <template v-for="(subSection, y) in titleLine">
              <v-card
                :key="`desktop-mode-card-${i}-${y}`"
                class="sub-section"
                :class="{ 'full-width': subSection.id !== 'stand-traffic' && subSection.fullWidth }"
              >
                <!-- Stand traffic -->
                <template v-if="subSection.id === 'stand-traffic'">
                  <card-top
                    :current-view.sync="subSection.cardTop.syncVal"
                    :options="filterIfNoDataPerHour(getSectionCardTop('stand-traffic'))"
                  />
                  <div v-if="subSection.cardTop.syncVal === 0" class="traffic-by-date">
                    <div class="info-box">
                      <chart-selector
                        :data="
                          getDataForChart(bar, {
                            label: 'T_TOTAL_TRAFFIC',
                            value: getTotalValue(primaryData, false, 'total.standTraffic')
                          })
                        "
                        :center-label="false"
                        :current-value.sync="highlightedTrafficValue"
                      />
                      <template v-if="graphHasData(bar)">
                        <bar-chart
                          :data="bar.data"
                          :options="bar.options"
                          :highlight-value="highlightedTrafficValue"
                        />
                      </template>
                      <no-graph-data v-else />
                    </div>
                  </div>
                  <div v-else class="traffic-by-hour">
                    <chart-selector
                      :data="
                        getDataForChart(bar, {
                          label: 'T_TOTAL_TRAFFIC',
                          value: getTotalValue(primaryData, false, 'total.standTraffic')
                        })
                      "
                      :current-value.sync="highlightedTrafficValue"
                    />
                    <template v-if="graphHasData(barHour)">
                      <bar-chart
                        :data="barHour.data"
                        :options="barHour.options"
                        :highlight-value="highlightedTrafficHourValue"
                      />
                      <chart-selector
                        :data="getDataForChart(barHour, getTotalTrafficByHour(), true)"
                        :current-value.sync="highlightedTrafficHourValue"
                      />
                    </template>
                    <no-graph-data v-else />
                  </div>
                </template>
                <!-- Stay average -->
                <template v-else-if="subSection.id === 'stand-statistics'">
                  <card-top
                    :current-view.sync="subSection.cardTop.syncVal"
                    :options="filterIfNoDataPerHour(getSectionCardTop('stand-statistics'))"
                  />
                  <div v-if="subSection.cardTop.syncVal === 0" class="stay-average-by-date">
                    <div class="info-box">
                      <template v-if="hasStayAverage(primaryData)">
                        <chart-selector
                          :center-label="false"
                          :data="
                            getDataForChart(barStayAverage, {
                              label: 'T_STAY_AVERAGE',
                              value: getStayAverage()
                            })
                          "
                          :formatter="formatStayAverage"
                          :current-value.sync="highlightedStayAverage"
                        />
                        <template v-if="graphHasData(barStayAverage)">
                          <bar-chart
                            :data="barStayAverage.data"
                            :options="barStayAverage.options"
                            :highlight-value="highlightedStayAverage"
                          />
                          <demography-pie-charts
                            :hide="[
                              primaryData.pavilion.hideGenders,
                              primaryData.pavilion.hideAgeRanges
                            ]"
                            :titles="['PER_GENDER', 'PER_AGE_GROUP']"
                            :pies="[pieGenders, pieAgeRanges]"
                            :highlights="[highlightedStayAverage]"
                          />
                        </template>
                        <no-graph-data v-else />
                      </template>
                      <no-graph-data v-else />
                    </div>
                  </div>
                  <div v-else class="stay-average-by-hour">
                    <template v-if="hasStayAverage(primaryData)">
                      <chart-selector
                        :data="
                          getDataForChart(barStayAverage, {
                            label: 'T_STAY_AVERAGE',
                            value: getStayAverage()
                          })
                        "
                        :formatter="formatStayAverage"
                        center-label
                        :current-value.sync="highlightedStayAverage"
                      />
                      <template v-if="graphHasData(barStayAverageHour)">
                        <bar-chart
                          :data="barStayAverageHour.data"
                          :options="barStayAverageHour.options"
                          :highlight-value="highlightedStayAverageHour"
                        />
                        <chart-selector
                          center-label
                          :data="
                            getDataForChart(barStayAverageHour, {
                              label: 'T_STAY_AVERAGE',
                              value: getStayAverage(true)
                            })
                          "
                          :formatter="formatStayAverage"
                          :current-value.sync="highlightedStayAverageHour"
                        />
                        <demography-pie-charts
                          :hide="[
                            primaryData.pavilion.hideGenders,
                            primaryData.pavilion.hideAgeRanges
                          ]"
                          :titles="['PER_GENDER', 'PER_AGE_GROUP']"
                          :pies="[pieGendersByHour, pieAgeRangesByHour]"
                          :highlights="[highlightedStayAverage + highlightedStayAverageHour]"
                        />
                      </template>
                      <no-graph-data v-else />
                    </template>
                    <no-graph-data v-else />
                  </div>
                </template>
                <!-- Demography -->
                <template v-else-if="subSection.id === 'area-traffic'">
                  <card-top
                    :current-view.sync="subSection.cardTop.syncVal"
                    :options="filterIfNoDataPerHour(getSectionCardTop('area-traffic'))"
                  />
                  <div v-if="subSection.cardTop.syncVal === 0" class="demography-by-date">
                    <chart-selector
                      :center-label="false"
                      :data="getDataForAreas(primaryData, false)"
                      :current-value.sync="highlightedArea"
                    />
                    <div v-if="graphHasData(barArea)" class="info-box">
                      <bar-chart
                        :data="barArea.data"
                        :options="barArea.options"
                        :highlight-value="highlightedAreaDate"
                      />
                      <chart-selector
                        :center-label="!highlightedAreaDate"
                        :data="
                          getDataForChart(barArea, {
                            label: 'T_ALL_DAYS',
                            value: getTotalForSelectedArea(primaryData, false, getSelectedArea())
                          })
                        "
                        :current-value.sync="highlightedAreaDate"
                      />
                      <chart-selector-disabled
                        v-if="hasStayAverage(primaryData) && graphHasData(barAreaHour)"
                        label="T_STAY_AVERAGE"
                        :value="formatStayAverage(getStayAverage(false, selectedArea))"
                      />
                      <demography-pie-charts
                        :hide="[
                          primaryData.pavilion.hideGenders,
                          primaryData.pavilion.hideAgeRanges
                        ]"
                        :titles="['PER_GENDER', 'PER_AGE_GROUP']"
                        :pies="[pieGendersByHourPerArea, pieAgeRangesByHourPerArea]"
                        :highlights="[highlightedArea + highlightedAreaDate + highlightedAreaHour]"
                      />
                    </div>
                    <no-graph-data v-else />
                  </div>
                  <div v-else class="demography-by-hour">
                    <chart-selector
                      :center-label="false"
                      :data="getDataForAreas(primaryData, false)"
                      :current-value.sync="highlightedArea"
                    />
                    <chart-selector
                      :center-label="false"
                      :data="
                        getDataForChart(barArea, {
                          label: 'T_ALL_DAYS',
                          value: getTotalForSelectedArea(primaryData, false, getSelectedArea())
                        })
                      "
                      :current-value.sync="highlightedAreaDate"
                    />
                    <div class="info-box">
                      <div class="graph-wrapper">
                        <bar-chart
                          :data="barAreaHour.data"
                          :options="barAreaHour.options"
                          :highlight-value="highlightedAreaHour"
                        />
                      </div>
                      <chart-selector
                        :data="getDataForChart(barAreaHour, getAverageForArea(primaryData), true)"
                        :current-value.sync="highlightedAreaHour"
                      />
                      <chart-selector-disabled
                        v-if="hasStayAverage(primaryData)"
                        label="T_STAY_AVERAGE"
                        :value="formatStayAverage(getStayAverage(false, selectedArea))"
                      />
                      <demography-pie-charts
                        :hide="[
                          primaryData.pavilion.hideGenders,
                          primaryData.pavilion.hideAgeRanges
                        ]"
                        :titles="['PER_GENDER', 'PER_AGE_GROUP']"
                        :pies="[pieGendersByHourPerArea, pieAgeRangesByHourPerArea]"
                        :highlights="[highlightedArea + highlightedAreaDate + highlightedAreaHour]"
                      />
                    </div>
                  </div>
                </template>
                <!-- Area list -->
                <template v-else-if="subSection.id === 'area-list'">
                  <card-top
                    :current-view.sync="subSection.cardTop.syncVal"
                    :options="getSectionCardTop('area-list')"
                  />
                  <div v-if="subSection.cardTop.syncVal === 0" class="floorplans">
                    <axo-view :data="primaryData" />
                    <areas-table v-if="!reloadAreasTable" :data="primaryData" />
                  </div>
                  <div v-else class="traffic-by-nameplate">
                    <pie-chart
                      v-if="
                        pie &&
                          primaryData &&
                          primaryData.bestPerformingNameplate &&
                          primaryData.bestPerformingNameplate.traffic
                      "
                      :data="pie.data"
                      :options="pie.options"
                    />
                    <areas-table
                      v-if="primaryData && !reloadAreasTable"
                      :data="primaryData"
                      show-unique-visitors
                    />
                  </div>
                </template>
                <!-- Heatmap -->
                <template v-else-if="subSection.id === 'heatmap'">
                  <card-top
                    :current-view.sync="selectedSubView5"
                    :options="getSectionCardTop('heatmap')"
                  />

                  <chart-selector
                    :play-btn-callback="playPauseHeatmap"
                    :center-label="selectedHeatmapDay === 0"
                    :loading="heatmapChartSelector.length === 0"
                    :data="heatmapChartSelector"
                    :formatter="formatPercentage"
                    :current-value.sync="highlightedHeatmapDay"
                  />
                  <heatmap
                    :reloading-heatmap="reloadingHeatmap"
                    :data="primaryData"
                    :data-per-day="heatmapForSelectedDay"
                    :show-tooltips="selectedSubView5 === 1"
                  />
                </template>
                <!-- Attractiveness -->
                <template v-else-if="subSection.id === 'attractiveness'">
                  <card-top
                    :current-view.sync="subSection.cardTop.syncVal"
                    :options="getSectionCardTop('attractiveness')"
                  />
                  <div v-if="subSection.cardTop.syncVal === 0" class="info-box">
                    <chart-selector
                      :play-btn-callback="playPauseBubble"
                      :center-label="true"
                      :loading="heatmapChartSelector.length === 0"
                      :data="heatmapChartSelector"
                      :formatter="formatPercentage"
                      :current-value.sync="highlightedBubbleDay"
                    />
                    <bubble-chart
                      :data="primaryData"
                      :reloading-bubble="reloadingBubble"
                      :chart-data="bubbleChart.data"
                      :data-per-day="bubbleForSelectedDay"
                      :background-image="getBubbleChartBackground(primaryData)"
                    />
                  </div>
                  <div v-else class="info-box">
                    <chart-selector
                      :center-label="selectedAttractiveness === 0"
                      :data="
                        getDataForAttractiveness(primaryData, {
                          label: 'T_ALL_AREAS',
                          value: formatPercentage(getTotalForSelectedAttractiveness(primaryData))
                        })
                      "
                      :formatter="formatPercentage"
                      :current-value.sync="highlightedAttractiveness"
                    />
                    <bar-chart
                      :data="barAttractiveness.data"
                      :options="barAttractiveness.options"
                      :highlight-value="highlightedAttractivenessDate"
                    />
                    <chart-selector
                      :center-label="selectedAttractivenessDate === 0"
                      :data="
                        getDataForChart(barAttractiveness, {
                          label: 'T_ALL_DAYS',
                          value: formatPercentage(getTotalForSelectedAttractiveness(primaryData))
                        })
                      "
                      :formatter="formatPercentage"
                      :current-value.sync="highlightedAttractivenessDate"
                    />
                  </div>
                </template>
                <!-- Cameras -->
                <template v-else-if="subSection.id === 'cameras'">
                  <card-top
                    :current-view.sync="subSection.cardTop.syncVal"
                    :options="getSectionCardTop('cameras')"
                  />
                  <div v-if="cameras" class="info-box">
                    <chart-selector
                      v-if="getDataForCameras('devices')"
                      :data="getDataForCameras('devices')"
                      :current-value.sync="selectedCameraDevice"
                      center-label
                    />
                    <chart-selector
                      v-if="getDataForCameras('days')"
                      :data="getDataForCameras('days')"
                      :current-value.sync="selectedCameraDay"
                      center-label
                    />
                    <image-loader
                      v-if="getCameraImage()"
                      contain
                      gradient-loading
                      min-height="193"
                      :src="getCameraImage()"
                      :lazy-src="getLazySrc(getCameraImage(), '400')"
                      classes="camera-image"
                      @click.native="displayFullscreenImage(getCameraImage())"
                    />
                    <chart-selector
                      v-if="getDataForCameras('hours').length > 0"
                      :data="getDataForCameras('hours')"
                      :current-value.sync="selectedCameraHour"
                      center-label
                    />
                  </div>
                </template>
                <!-- Neighbors -->
                <template v-else-if="subSection.id === 'neighbors'">
                  <div class="card-wrapper full-width">
                    <card-top
                      :current-view.sync="subSection.cardTop.syncVal"
                      :options="getSectionCardTop('neighbors')"
                    />
                    <div class="info-box">
                      <image-loader
                        :src="primaryData.pavilion.hallView"
                        :lazy-src="getLazySrc(primaryData.pavilion.hallView, '500')"
                        min-height="400"
                        classes="map rounded"
                        gradient-loading
                        @click.native="displayFullscreenNeighbors(primaryData)"
                      />
                    </div>
                  </div>
                </template>
              </v-card>
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseSection from '@/mixins/BaseSection'
import TrafficSection from '@/mixins/TrafficSection'
import AxoView from '@/components/AxoView'
import AreasTable from '@/components/AreasTable'

export default {
  name: 'TrafficSectionViewSingle',
  components: { AxoView, AreasTable },
  mixins: [BaseSection, TrafficSection],
  data() {
    return {
      desktopMode: 'view-single'
    }
  }
}
</script>
