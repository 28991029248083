import Vue from 'vue'
import Paginate from 'vuejs-paginate'
import _ from 'lodash'
import DeviceDetector from 'device-detector-js'

import App from './App.vue'
import AppDesktop from './AppDesktop.vue'
import router from './router'
import desktopRouter from './router/desktop'
import vuetify from './plugins/vuetify'
import Numeral from '@/filters/Numeral'
import Join from '@/filters/Join'
import Translate from '@/filters/Translate'
import Truncate from '@/filters/Truncate'
import ToUppercase from '@/filters/ToUppercase'
import VueScrollTo from 'vue-scrollto'
import imagePreloader from 'vue-image-preloader'

const deviceDetector = new DeviceDetector()

Vue.use(imagePreloader)
Vue.use(VueScrollTo)
Vue.filter('numeral', Numeral)
Vue.filter('Join', Join)
Vue.filter('translate', Translate)
Vue.filter('truncate', Truncate)
Vue.filter('uppercase', ToUppercase)
Vue.component('Paginate', Paginate)

const isDesktop =
  _.get(deviceDetector.parse(navigator.userAgent), 'device.type', 'mobile') === 'desktop'

if (isDesktop) {
  console.warn(`Desktop mode detected`)
}
Vue.config.productionTip = false
new Vue({
  router: isDesktop ? desktopRouter : router,
  vuetify,
  render: (h) => h(isDesktop ? AppDesktop : App)
}).$mount('#app')
