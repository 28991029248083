<template>
  <div class="sub-sections-counter">
    <v-img
      lazy-src="@/assets/icons/card-counter.svg"
      src="@/assets/icons/card-counter.svg"
      max-height="38"
      max-width="38"
    />
    <div class="progress">{{ current }}/{{ total }}</div>
  </div>
</template>

<script>
// import _ from 'lodash'
export default {
  props: {
    current: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';

.sub-sections-counter {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 38px;
  height: 38px;
  .v-image {
  }
  .progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-65%, -65%);
    z-index: 2;
    font-style: normal;
    font-weight: 700;
    font-size: $scale-vw * 10;
    line-height: $scale-vw * 13;
    color: $cyan;
  }
}
</style>
