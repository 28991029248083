import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '@/views/MainPage.vue'
import NotFoundPage from '@/views/NotFoundPage.vue'
import EventSection from '@/views/desktop/EventSection.vue'
import OverviewSection from '@/views/desktop/OverviewSection.vue'
import TrafficSection from '@/views/desktop/TrafficSection.vue'
import ExperienceSection from '@/views/desktop/ExperienceSection.vue'
import SalesSection from '@/views/desktop/SalesSection.vue'
import PdfExport from '@/PdfExport.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: MainPage
  },
  {
    path: '/event',
    name: 'EventSection',
    component: EventSection
  },
  {
    path: '/overview/:section?',
    name: 'OverviewSection',
    component: OverviewSection
  },
  {
    path: '/traffic/:section?',
    name: 'TrafficSection',
    component: TrafficSection
  },
  {
    path: '/experience/:section?',
    name: 'ExperienceSection',
    component: ExperienceSection
  },
  {
    path: '/sales/:section?',
    name: 'SalesSection',
    component: SalesSection
  },
  {
    path: '/pdf',
    name: 'PdfExport',
    component: PdfExport
  },
  {
    path: '/*',
    name: 'NotFoundPage',
    component: NotFoundPage
  }
]

const desktopRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default desktopRouter
