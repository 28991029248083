import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#8094DD',
        secondary: '#FF5C39',
        'light-grey': '#B8BABC',
        'dark-grey': '#818386',
        'cyan-highlight': '#71DBD4',
        purple: '#8094DD',
        orange: '#FF5C39',
        yellow: '#E1E000',
        green: '#8DE971',
        red: '#DD2700'
      }
    }
  }
})
