<template>
  <div
    ref="funnel-chart"
    class="funnel-chart"
    :class="{ compare: compare, 'is-for-pdf': isForPdf, 'is-for-desktop': isForDesktop }"
  >
    <template v-if="isForPdf">
      <div class="values">
        <div v-for="(category, i) in data.data" :key="i" class="value">
          <div class="label">
            {{ data.labels[i] }}: <span>{{ category || 0 | numeral }}</span>
          </div>
        </div>
      </div>
      <div class="categories">
        <div v-for="(category, i) in data.data" :key="i" class="category">
          <div class="value" :style="getStyle(category, i)">{{ category || 0 | numeral }}</div>
        </div>
      </div>
    </template>
    <div v-for="(category, i) in data.data" v-else :key="i" class="category">
      <div class="label">{{ data.labels[i] }}</div>
      <div class="value" :style="getStyle(category, i)">{{ category || 0 | numeral }}</div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import ChartsDataService from '@/services/ChartsDataService'
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    compare: {
      type: Boolean,
      default: false
    },
    isForPdf: {
      type: Boolean,
      default: false
    },
    isForDesktop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  watch: {},
  methods: {
    getStyle(val, i) {
      return `background-color:${ChartsDataService.funnelChartColors[i]};width:${
        i === 0 ? '100' : this.getPercentage(val)
      }%;`
    },
    getPercentage(val) {
      if (val === 0 || _.isUndefined(val)) {
        return 10
      }
      return (val / this.data.data[0]) * 100
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';
.funnel-chart {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .category {
    width: 100%;
    text-align: center;
    .label {
      font-style: normal;
      font-weight: 400;
      font-size: $scale-vw * 12;
      line-height: $scale-vw * 15;
      color: $purple;
      margin: $half-margin / 2 0;
    }
    .value {
      min-width: 10%;
      max-width: 100%;
      border-radius: $half-margin / 2;
      margin: 0 auto;
      font-style: italic;
      font-weight: 400;
      font-size: $scale-vw * 12;
      line-height: $scale-vw * 15;
      color: $slideshow-progress;
      padding: $half-margin 0;
    }
  }
  &.compare {
    .value {
      min-width: 20%;
    }
  }
  &.is-for-pdf {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    .values {
      text-align: right;
      .label {
        font-style: italic;
        font-weight: 400;
        font-size: 8px;
        line-height: 14px;
        margin-bottom: 4px;
        span {
          font-weight: 700;
          font-family: 'SourceSansPro-Bold' !important;
        }
      }
    }
    .category {
      width: 130px;
      .value {
        border-radius: 4px;
        padding: 0;
        line-height: 14px;
        color: transparent;
        margin-bottom: 4px;
      }
    }
  }
}

@media (min-width: 500px) {
  .funnel-chart {
    .category {
      .label,
      .value {
        font-size: $desktop-scale-vw * 18;
        line-height: $desktop-scale-vw * 20;
      }
      .label {
        margin: $desktop-half-margin;
      }
      .value {
        border-radius: $desktop-half-margin / 2;
        color: $slideshow-progress;
        padding: $desktop-half-margin 0;
      }
    }
  }
}
</style>
