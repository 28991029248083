<template>
  <div ref="heatmapWrapper" class="heatmap-wrapper" :class="{ 'is-pdf': isForPdf }">
    <div class="heatmap-background" :style="isForPdf ? pdfStyle : style">
      <img
        v-if="isForPdf"
        :height="pdfStyle.height"
        :width="pdfStyle.width"
        :src="options.background"
        classes="loading-background"
      />
      <image-loader
        v-else
        :height="`${calculateMapHeight()}`"
        :width="`${calculateMapWidth()}`"
        :src="options.background"
        :lazy-src="getLazySrc(options.background, '500')"
        classes="loading-background"
        gradient-loading
      />
      <div v-if="!reloadingHeatmap" class="heatmap-static" :style="heatmapStatic" />
      <div v-if="areas" class="areas" :style="mapSize">
        <div
          v-for="(area, i) in areas"
          :key="getKey(area, i)"
          class="area"
          :style="getStyleForArea(area)"
          @click="highlightTooltip(area, i)"
        />
        <div
          v-for="(area, i) in areas"
          :key="'tooltip-' + getKey(area, i)"
          :ref="'tooltip-' + getKey(area, i)"
          class="tooltip"
          :class="{
            displayed: showTooltips || isForPdf,
            'on-top': selectedTooltip === getKey(area, i)
          }"
          :style="getStyleForArea(area, true)"
          @click="highlightTooltip(area, i)"
        >
          <span v-if="area.vehicle"
            >{{ area.vehicle.visualIdentifier }} - {{ area.vehicle.nameplate | translate }}</span
          >
          <span v-else-if="area.poi"
            >{{ area.poi.visualIdentifier }} - {{ area.poi.name | translate }}</span
          >
          <span v-else>{{ getAreaName(area) }}</span>
        </div>
      </div>
    </div>
    <div
      ref="heatmap"
      class="heatmap hidden"
      :style="`width:${options.width}px;height:${options.height}px;`"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import h337 from 'heatmap.js'
import ImageLoader from '@/components/ImageLoader.vue'

export default {
  components: { ImageLoader },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    dataPerDay: {
      type: [Object, Boolean],
      default: false
    },
    showTooltips: {
      type: Boolean,
      default: false
    },
    isForPdf: {
      type: Boolean,
      default: false
    },
    reloadingHeatmap: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      heatmapInstance: false,
      heatmapData: false,
      areas: false,
      vehicles: false,
      options: false,
      pdfStyle: {
        width: 'auto',
        height: '240px'
      },
      style: {
        width: '100px',
        height: '100px'
      },
      mapSize: {
        width: '100px',
        height: '100px'
      },
      selectedTooltip: false,
      localEventKey: '',
      heatmapStatic: false
    }
  },
  watch: {
    reloadingHeatmap() {
      this.createHeatmap()
    },
    data() {
      const dataEventKey = this.getEventKey()
      if (dataEventKey !== this.localEventKey) {
        this.localEventKey = dataEventKey
        this.createHeatmap()
      }
    }
  },
  created() {
    window.addEventListener('resize', this.resizeCanvas)
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeCanvas)
  },
  mounted() {
    this.createHeatmap()
  },
  methods: {
    getLazySrc(url, resizeOptions) {
      return `${url}?resize=${resizeOptions}`
    },
    getAreaName(area) {
      return _.get(area, 'abdName', '')
    },
    getEventKey() {
      return `${_.get(this.data, 'pavilion.event.key', '')}-${_.join(
        _.get(this.data, 'dates', []),
        '-'
      )}`
    },
    highlightTooltip(area, i) {
      this.selectedTooltip = this.getKey(area, i)
    },
    getHeatmapData() {
      if (!this.data || _.get(this.data, 'heatmapPointsList.length', 0) === 0) {
        return false
      }
      let heatmapHeight = 200
      let heatmapWidth = 200
      if (_.get(this.data, 'pavilion.trafficDataSource', 'manual') === 'manual') {
        heatmapHeight = _.get(this.data, 'pavilion.gaHeight', heatmapHeight)
        heatmapWidth = _.get(this.data, 'pavilion.gaWidth', heatmapWidth)
      } else {
        heatmapHeight = _.get(this.data, 'abdShow.height', heatmapHeight)
        heatmapWidth = _.get(this.data, 'abdShow.width', heatmapWidth)
      }
      // console.warn(`HEATMAP SIZE`, heatmapWidth, heatmapWidth)
      this.options = {
        maxOpacity: 0.75,
        minOpacity: 0.45,
        valueField: 'count',
        width: heatmapWidth,
        height: heatmapHeight,
        background: _.get(this.data, 'pavilion.gaImage') || _.get(this.data, 'abdShow.map')
      }
      const dataObj = _.get(this, 'dataPerDay', false) ? this.dataPerDay : this.data
      this.heatmapData = {
        max: dataObj.heatmapPointsListMax,
        min: 0,
        data: dataObj.heatmapPointsList || [],
        eventKey: this.getEventKey()
      }
      this.totalTraffic = _.get(this.data, 'total.standTraffic', 0)
      this.vehicles = _.get(this.data, 'vehicles', [])
      this.pois = _.get(this.data, 'pois', [])
      this.areas = _.clone(_.get(this.data, 'abdAreas', []))
      _.forEach(this.vehicles, (vehicle) => {
        const area = _.find(
          this.areas,
          (area) => _.get(area, 'id', '?') === _.get(vehicle, 'abd.areaId', '??')
        )
        if (area) {
          area.vehicle = vehicle
        }
      })
      _.forEach(this.pois, (poi) => {
        const area = _.find(
          this.areas,
          (area) => _.get(area, 'id', '?') === _.get(poi, 'abd.areaId', '??')
        )
        if (area) {
          area.poi = poi
        }
      })
      this.$forceUpdate()
    },
    createHeatmap() {
      this.getHeatmapData()
      if (this.heatmapData === false) {
        return console.error(`No data for heatmap`)
      }
      if (this.heatmapInstance) {
        this.heatmapInstance.setData(this.heatmapData)
        this.heatmapStatic = {}
        this.$nextTick(() => {
          this.heatmapStatic = {
            'background-image': `url(${this.heatmapInstance.getDataURL()})`,
            width: this.style.width,
            height: this.style.height
          }
          this.resizeCanvas()
        })
        return
      }
      this.$nextTick(() => {
        this.style.width = this.getMapWidth()
        this.style.height = this.getMapHeight()
        this.pdfStyle.width = this.style.width
        this.pdfStyle.height = this.style.height
        this.mapSize.width = this.style.width
        this.mapSize.height = this.style.height
        _.each(this.areas, (area) => {
          const rect = _.get(area, 'rect', false)
          area.zIndex = rect.x * rect.y
        })
        _.orderBy(this.areas, ['zIndex'], ['desc'])
        _.each(this.areas, (area, i) => {
          area.zIndex = i + 10
        })
        const ratio =
          (this.getMapWidth() > this.getMapHeight() ? this.getMapHeight() : this.getMapWidth()) /
          (this.getMapWidth() <= this.getMapHeight() ? this.getMapWidth() : this.getMapHeight())
        const startTime = new Date()
        this.heatmapInstance = h337.create({
          container: this.$refs.heatmap,
          radius: ratio * 40,
          blur: ratio,
          gradient: {
            0.05: 'rgb(0,255,255)',
            0.1: 'rgb(0,255,0)',
            0.5: 'rgb(255,255,0)',
            0.9: 'rgb(255,130,0)',
            0.98: 'rgb(255,100,0)',
            1.0: 'rgb(255,0,0)'
          },
          ...this.options
        })
        _.set(this.heatmapInstance, 'data', _.get(this.heatmapInstance, 'data', []))
        this.heatmapInstance.setData(this.heatmapData)
        this.heatmapStatic = {}
        this.$nextTick(() => {
          this.heatmapStatic = {
            'background-image': `url(${this.heatmapInstance.getDataURL()})`,
            width: this.style.width,
            height: this.style.height
          }
          console.warn(`Finished to generate heatmap in ${Math.round(new Date() - startTime)}ms`)
          this.resizeCanvas()
        })
      })
    },
    getKey(area, i) {
      return _.get(area, 'key', i) + i
    },
    getResponsiveMapWidth() {
      if (this.getWindowWidth() < 1024) {
        return this.compare ? 43.6 : 93.6
      }
      return 43.8
    },
    getWindowWidth() {
      return this.getPixelValue('innerWidth', 'clientWidth')
    },
    getWindowHeight() {
      return this.getPixelValue('innerHeight', 'clientHeight')
    },
    getPixelValue(windowVal, clientVal) {
      return (
        _.get(window, windowVal, false) ||
        _.get(document, `documentElement.${clientVal}`, false) ||
        _.get(document, `body.${clientVal}`, false)
      )
    },
    roundNumber(number) {
      return Math.round(number * 100) / 100
    },
    calculatePercentage(count) {
      return `${this.roundNumber((count / this.totalTraffic) * 100)}%`
    },
    calculateMapWidth() {
      if (_.get(this.$refs, 'heatmapWrapper.clientWidth', 0)) {
        return this.$refs.heatmapWrapper.clientWidth
      }
      return (this.getWindowWidth() / 100) * this.getResponsiveMapWidth()
    },
    calculateMapHeight() {
      return this.roundNumber(
        this.calculateMapWidth() *
          (_.get(this.options, 'height', 0) / _.get(this.options, 'width', 1))
      )
    },
    getMapWidth() {
      let width = this.roundNumber(this.calculateMapWidth())
      const clientWidth = _.get(this.$refs, 'heatmapWrapper.clientWidth', false)
      if (clientWidth && width > clientWidth) {
        width = clientWidth
      }
      return `${width}px`
    },
    getMapHeight() {
      const height = this.roundNumber(this.calculateMapHeight())
      return `${height > this.options.height ? this.options.height : height}px`
    },
    resizeCanvas() {
      this.$nextTick(() => {
        if (!this.heatmapInstance) {
          return
        }
        this.style = {}
        _.set(this.style, 'width', this.getMapWidth())
        _.set(this.style, 'height', this.getMapHeight())
        this.mapSize = {}
        this.$nextTick(() => {
          this.heatmapInstance = this.heatmapInstance.repaint()
          // console.warn('HEATMAP - resizeCanvas - chart', this.style.width, this.style.height)
          this.$forceUpdate()
        })
      })
    },
    getStyleForArea(area, forTooltip = false) {
      const rect = _.get(area, 'rect', false)
      if (!rect) {
        return console.error('No rect area data in ', area)
      }
      const widthRatio = this.calculateMapWidth() / this.options.width
      const heightRatio = this.calculateMapHeight() / this.options.height
      const style = {
        left: this.roundNumber(rect.x * widthRatio) + 'px',
        top: this.roundNumber(rect.y * heightRatio) + 'px',
        'z-index': area.zIndex
      }
      if (forTooltip) {
        style['z-index'] += 10
        const mapWidth = this.roundNumber(this.calculateMapWidth())
        if (this.roundNumber(rect.x * widthRatio) >= mapWidth / 2) {
          const right = mapWidth - this.roundNumber(rect.x * widthRatio) - rect.width * widthRatio
          style.left = 'auto'
          style.right = `${right}px`
        }
        return style
      }
      style.width = this.roundNumber(rect.width * widthRatio) + 'px'
      style.height = this.roundNumber(rect.height * heightRatio) + 'px'
      return style
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';

.heatmap-wrapper {
  position: relative;
  width: 100%;
}
.heatmap,
.heatmap-static,
.heatmap-background {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.heatmap-static,
.areas,
.area,
.outside-areas {
  position: absolute;
}
.area {
  touch-action: none;
  pointer-events: none;
}
.heatmap-static,
.heatmap-background {
  width: 100%;
  height: 100%;
}
.heatmap-static {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.is-pdf {
  display: inline-block;
  // width: auto;
  // height: 480px;
  .heatmap-background {
    display: inline-block;
    // width: auto;
    // height: 480px;
    // img {
    //   width: auto;
    //   height: 480px;
    // }
    .heatmap-static {
      display: block;
      background-size: 100%;
      background-position: 0% 0%;
    }
    .areas .tooltip {
      padding: 3px;
      border-radius: 2px;
      span {
        font-size: 6px;
        line-height: 6px;
      }
    }
  }
}
.hidden {
  pointer-events: none;
  touch-action: none;
  opacity: 0;
  position: absolute !important;
}
.areas {
  top: 0px;
  left: 0px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  // .area,
  // .outside-areas {
  // border: 2px solid black;
  // &.displayed {
  //   border-color: red;
  // }
  // }
  .tooltip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    background: white;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 2px 4px;
    opacity: 0;
    transition: opacity 0.3s;
    span {
      font-style: italic;
      font-weight: 400;
      font-size: $scale-vw * 10;
      line-height: $scale-vw * 13;
      text-align: center;
      color: black;
      &.percentage {
        font-weight: 700;
        color: black;
      }
      // &:first-child {
      //   margin-right: $half-margin;
      // }
    }
    &.displayed {
      opacity: 1;
    }
    &.on-top {
      z-index: 1000 !important;
    }
  }
  .outside-areas {
    width: 100%;
    height: 100%;
  }
}
.loading-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 500px) {
  .areas {
    .tooltip {
      span {
        font-size: $desktop-scale-vw * 10;
        line-height: $desktop-scale-vw * 13;
        // &:first-child {
        //   margin-right: $desktop-half-margin;
        // }
      }
    }
  }
}
</style>
