<template>
  <div id="overview-section" :class="getDesktopWrapperClass()">
    <section-loader v-if="loading" />
    <div v-else-if="primaryData" class="sub-sections-wrapper">
      <div class="cards-row">
        <!-- Performance -->
        <card-top
          :current-view.sync="selectedSubView"
          :options="getSectionCardTop('performance')"
          divider
        />
        <card-top
          :current-view.sync="selectedSubView"
          :options="getSectionCardTop('highlights')"
          divider
        />
        <v-card class="sub-section no-card-top-mb">
          <radar-chart v-if="dataList" is-for-desktop :labels="labels" :data-list="dataList" />
          <div v-if="secondaryData" class="custom-legend-wrapper">
            <div class="custom-legend">
              <div class="data-label">
                <span class="circle-color" :style="`background-color:${circleColors[0]}`"></span>
                <span class="label">{{ primaryData.pavilion.event.key }}</span>
              </div>
              <div class="data-label">
                <span class="circle-color" :style="`background-color:${circleColors[1]}`"></span>
                <span class="label">{{ secondaryData.pavilion.event.key }}</span>
              </div>
            </div>
            <compare-show-titles
              v-if="secondaryData"
              :primary-data="primaryData"
              :secondary-data="secondaryData"
            />
            <div v-if="dataList" class="compare-data">
              <div class="cards-row vertical">
                <content-card
                  v-for="(contentCard, i) in dataList[0].contentCards"
                  :key="i"
                  no-gradient
                  :title="contentCard.item.label | translate"
                  dark-background
                  :content="contentCard.value"
                  type="compare"
                  :compare-data="{
                    primary: contentCard.value,
                    secondary: dataList[1].contentCards[i].value
                  }"
                  italic
                  :no-data="!contentCard.value"
                />
              </div>
              <v-divider vertical inset />
              <div class="cards-row vertical">
                <content-card
                  v-for="(contentCard, i) in dataList[1].contentCards"
                  :key="i"
                  no-gradient
                  :title="contentCard.item.label | translate"
                  dark-background
                  :content="contentCard.value"
                  type="compare"
                  italic
                  :no-data="!contentCard.value"
                />
              </div>
            </div>
          </div>
          <div v-else-if="dataList" class="cards-row">
            <content-card
              v-for="(contentCard, i) in dataList[0].contentCards"
              :key="i"
              no-gradient
              :link="contentCard.item.link"
              :title="contentCard.item.label | translate"
              :content="contentCard.value"
              italic
              dark-background
              :no-data="!contentCard.value"
            />
          </div>
        </v-card>
        <!-- Highlights -->
        <v-card class="sub-section">
          <compare-show-titles
            v-if="secondaryData"
            :primary-data="primaryData"
            :secondary-data="secondaryData"
          />
          <h2>{{ 'T_TRAFFIC' | translate }}</h2>
          <!-- Compare mode -->
          <div v-if="secondaryData" class="cards-row">
            <card-wrapper
              :content="primaryData.total.eventVisitors"
              title="T_TOTAL_EVENT_VISITOR"
              type="compare"
              :compare-data="{
                primary: primaryData.total.eventVisitors,
                secondary: secondaryData.total.eventVisitors
              }"
            />
            <card-wrapper
              :content="secondaryData.total.eventVisitors"
              title="T_TOTAL_EVENT_VISITOR"
            />
            <card-wrapper
              :content="primaryData.total.standTraffic"
              title="T_TOTAL_STAND_VISITOR"
              type="compare"
              :compare-data="{
                primary: primaryData.total.standTraffic,
                secondary: secondaryData.total.standTraffic
              }"
            />
            <card-wrapper
              :content="secondaryData.total.standTraffic"
              title="T_TOTAL_STAND_VISITOR"
            />
            <card-wrapper
              title="T_TOTAL_VISITORS_CONVERSION"
              type="compare"
              :compare-data="{
                primaryPercentage: reduceConversion(
                  primaryData.total.standTraffic,
                  primaryData.total.eventVisitors
                ),
                primary: (primaryData.total.standTraffic / primaryData.total.eventVisitors) * 100,
                secondary:
                  (secondaryData.total.standTraffic / secondaryData.total.eventVisitors) * 100
              }"
            />
            <card-wrapper
              :content="
                reduceConversion(
                  secondaryData.total.standTraffic,
                  secondaryData.total.eventVisitors
                )
              "
              title="T_TOTAL_VISITORS_CONVERSION"
              conversion
              italic
            />
            <template v-if="hasLeads(primaryData)">
              <card-wrapper
                :content="primaryData.total.leadsGeneration"
                title="T_TOTAL_LEADS_GENERATION"
                type="compare"
                :compare-data="{
                  primary: primaryData.total.leadsGeneration,
                  secondary: secondaryData.total.leadsGeneration
                }"
                :no-data="!getTotalVal(primaryData, 'leadsGeneration')"
              />
              <card-wrapper
                :content="secondaryData.total.leadsGeneration"
                title="T_TOTAL_LEADS_GENERATION"
                :no-data="!getTotalVal(secondaryData, 'leadsGeneration')"
              />
            </template>
            <template v-if="hasOrders(primaryData)">
              <card-wrapper
                :content="primaryData.total.ordersGeneration"
                type="compare"
                :compare-data="{
                  primary: primaryData.total.ordersGeneration,
                  secondary: secondaryData.total.ordersGeneration
                }"
                title="T_TOTAL_ORDERS_GENERATION"
                :no-data="!getTotalVal(primaryData, 'ordersGeneration')"
              />
              <card-wrapper
                :content="secondaryData.total.ordersGeneration"
                title="T_TOTAL_ORDERS_GENERATION"
                :no-data="!getTotalVal(secondaryData, 'ordersGeneration')"
              />
            </template>
            <card-wrapper
              :content="getBestNameplate(primaryData)"
              title="T_BEST_PERFORMING_NAMEPLATE"
            />
            <card-wrapper
              :content="getBestNameplate(secondaryData)"
              title="T_BEST_PERFORMING_NAMEPLATE"
            />
          </div>
          <!-- View show mode -->
          <div v-else class="cards-row">
            <card-wrapper
              title="T_TOTAL_EVENT_VISITOR"
              :content="primaryData.total.eventVisitors | numeral"
            />
            <card-wrapper title="T_TOTAL_STAND_VISITOR" :content="primaryData.total.standTraffic" />
            <card-wrapper
              title="T_TOTAL_VISITORS_CONVERSION"
              :content="
                reduceConversion(primaryData.total.standTraffic, primaryData.total.eventVisitors)
              "
              conversion
            />
            <card-wrapper
              v-if="hasLeads(primaryData)"
              :content="primaryData.total.leadsGeneration"
              title="T_TOTAL_LEADS_GENERATION"
              :no-data="!getTotalVal(primaryData, 'leadsGeneration')"
            />
            <card-wrapper
              v-if="hasOrders(primaryData)"
              :content="primaryData.total.ordersGeneration"
              title="T_TOTAL_ORDERS_GENERATION"
              :no-data="!getTotalVal(primaryData, 'ordersGeneration')"
            />
            <card-wrapper
              :content="getBestNameplate(primaryData)"
              title="T_BEST_PERFORMING_NAMEPLATE"
            />
          </div>
          <h2>{{ 'T_ONLINE_TRAFFIC' | translate }}</h2>
          <!-- Compare mode -->
          <div v-if="primaryData && secondaryData" class="cards-row">
            <card-wrapper
              :content="primaryData.total.wechatUniqueVisit"
              title="T_WECHAT_UNIQUE_VISITORS"
            />
            <card-wrapper
              :content="secondaryData.total.wechatUniqueVisit"
              title="T_WECHAT_UNIQUE_VISITORS"
            />
            <card-wrapper
              :content="primaryData.total.wechatVisit"
              title="T_WECHAT_TOTAL_VISITORS"
            />
            <card-wrapper
              :content="secondaryData.total.wechatVisit"
              title="T_WECHAT_TOTAL_VISITORS"
            />
            <card-wrapper
              :content="primaryData.conversionFunnel && primaryData.conversionFunnel.downloaded"
              title="T_TOTAL_DOWNLOAD"
            />
            <card-wrapper
              :content="secondaryData.conversionFunnel && secondaryData.conversionFunnel.downloaded"
              title="T_TOTAL_DOWNLOAD"
            />
            <card-wrapper
              :content="primaryData.conversionFunnel && primaryData.conversionFunnel.retrieved"
              title="T_TOTAL_SOUVENIR_RETRIEVED"
            />
            <card-wrapper
              :content="secondaryData.conversionFunnel && secondaryData.conversionFunnel.retrieved"
              title="T_TOTAL_SOUVENIR_RETRIEVED"
            />
            <card-wrapper
              :content="getMostPopularTouchpoint(primaryData)"
              title="T_MOST_POPULAR_TOUCHPOINT"
            />
            <card-wrapper
              :content="getMostPopularTouchpoint(secondaryData)"
              title="T_MOST_POPULAR_TOUCHPOINT"
            />
          </div>
          <!-- View show mode -->
          <div v-else class="cards-row">
            <card-wrapper
              :content="primaryData.total.wechatUniqueVisit"
              title="T_WECHAT_UNIQUE_VISITORS"
            />
            <card-wrapper
              :content="primaryData.total.wechatVisit"
              title="T_WECHAT_TOTAL_VISITORS"
            />
            <card-wrapper
              :content="primaryData.conversionFunnel && primaryData.conversionFunnel.downloaded"
              title="T_TOTAL_DOWNLOAD"
            />
            <card-wrapper
              :content="primaryData.conversionFunnel && primaryData.conversionFunnel.retrieved"
              title="T_TOTAL_SOUVENIR_RETRIEVED"
            />
            <card-wrapper
              :content="getMostPopularTouchpoint(primaryData)"
              title="T_MOST_POPULAR_TOUCHPOINT"
            />
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSection from '@/mixins/BaseSection'
import OverviewSection from '@/mixins/OverviewSection'

export default {
  name: 'OverviewSection',
  mixins: [BaseSection, OverviewSection]
}
</script>

<style lang="scss" scoped>
@import '../../varsDesktop.scss';

#overview-section {
  .custom-legend-wrapper {
    width: 100%;
    text-align: center;
  }
  .custom-legend {
    display: inline-block;
    background-color: $dark-background;
    border-radius: 8px;
    padding: $half-margin;
    font-style: italic;
    font-weight: 400;
    font-size: $scale-vw * 10;
    line-height: $scale-vw * 13;
    margin-top: 16px;
    .data-label {
      display: inline-flex;
      align-items: center;
      justify-items: flex-start;
      &:last-child {
        margin-left: $standard-margin;
      }
    }
  }
  h2 {
    text-align: left;
  }
}
.no-card-top-mb {
  .card-top {
    margin-bottom: 0;
  }
}
</style>
