<template>
  <div id="traffic-section" class="pdf-sub-section">
    <div class="section-sidebar">
      <div class="section-title">
        {{ 'T_STAND_TRAFFIC' | translate }}
      </div>
    </div>
    <div v-if="primaryData" class="section-content">
      <div class="traffic-by-date">
        <div class="info-box">
          <div class="section-title big">{{ 'T_TRAFFIC_BY_DATE' | translate }}</div>
          <p class="total-traffic">
            {{ 'T_TOTAL_UNIQUE_VISITORS_BY_DATE' | translate }}:
            <span>{{ primaryData.total.standTraffic }}</span>
          </p>
          <bar-chart
            is-for-pdf
            :data="bar.data"
            :options="bar.options"
            :highlight-value="highlightedTrafficValue"
          />
        </div>
      </div>
      <img class="pdf-divider" src="@/assets/divider.svg" />
      <div class="traffic-by-hour">
        <div class="info-box">
          <div class="section-title big">{{ 'T_TOTAL_UNIQUE_VISITORS_PER_HOUR' | translate }}</div>
          <bar-chart
            is-for-pdf
            :data="barHour.data"
            :options="barHour.options"
            :highlight-value="highlightedTrafficHourValue"
          />
        </div>
      </div>
      <template v-if="showDemographySection()">
        <img class="pdf-divider" src="@/assets/divider.svg" />
        <div class="section-title big">
          {{ 'T_DEMOGRAPHY' | translate }}
        </div>
        <div
          class="cards-row"
          :class="{ center: !graphHasData(pieAgeRanges) || !graphHasData(pieGenders) }"
        >
          <div v-if="graphHasData(pieGenders)" class="per-gender">
            <div class="section-title">{{ 'T_DEMOGRAPHY_PER_GENDER' | translate }}</div>
            <pdf-pie-chart
              v-if="pieGenders"
              :data="pieGenders.data"
              :options="pieGenders.options"
            />
            <pie-chart-legend is-for-pdf :data="pieGenders" only-percentages />
          </div>
          <div v-if="graphHasData(pieAgeRanges)" class="per-age">
            <div class="section-title">{{ 'T_DEMOGRAPHY_PER_AGE_GROUP' | translate }}</div>
            <pdf-pie-chart
              v-if="pieAgeRanges"
              :data="pieAgeRanges.data"
              :options="pieAgeRanges.options"
            />
            <pie-chart-legend is-for-pdf :data="pieAgeRanges" only-percentages />
          </div>
        </div>
        <percentages-disclaimer is-for-pdf />
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import TranslateService from '@/services/TranslateService'
import ChartsDataService from '@/services/ChartsDataService'
import BaseSection from '@/mixins/BaseSection'
import TrafficSection from '@/mixins/TrafficSection'
import PdfPieChart from '@/components/Charts/PdfPieChart.vue'

export default {
  name: 'Traffic',
  components: {
    PdfPieChart
  },
  mixins: [BaseSection, TrafficSection],
  data() {
    return {
      bar: {},
      barHour: {},
      pdfPage: 'traffic',
      weatherMap: {
        cloudy: '\uE804',
        sunny: '\u2600',
        rainy: '\uE800',
        snowy: '\uE801',
        windy: '\uE805'
      },
      weatherColorMap: {
        cloudy: 'grey',
        sunny: '#E1E000',
        rainy: '#71DBD4',
        snowy: 'grey',
        windy: 'grey'
      },
      highlightedTrafficValue: 0,
      highlightedTrafficHourValue: 0,
      selectedHour: 0,
      highlightedNameplate: -1,
      highlightedNameplate2: -1,
      ChartsDataService,
      pieGenders: false,
      pieAgeRanges: false
    }
  },
  watch: {
    highlightedTrafficValue() {
      this.gotData(true)
    },
    highlightedTrafficHourValue() {
      this.gotData(true)
    }
  },
  methods: {
    filterIfNoDataPerHour(cardTopData) {
      if (_.sum(_.map(_.get(this.primaryData, 'abdTrafficHours', []), 'count')) === 0) {
        cardTopData.type = 'hidden'
        console.info(`Traffic per hour hidden since no data`)
      }
      return cardTopData
    },
    getTotalTrafficByHour() {
      if (this.selectedHour === 0) {
        return {
          label: TranslateService.get('T_AVERAGE_PER_HOUR'),
          value: _.sum(_.map(this.primaryData.abdTrafficHours, 'count'))
        }
      }
      return {
        label: this.selectedDate,
        value: _.get(
          this.primaryData,
          `abdTrafficHours[${this.highlightedTrafficHourValue - 1}].count`,
          0
        )
      }
    },
    showDemographySection() {
      return (
        _.get(this.primaryData, 'pavilion.hideGenders', undefined) !== false ||
        _.get(this.primaryData, 'pavilion.hideAgeRanges', undefined) !== false
      )
    },
    gotData(refreshOnlyBarChart = false) {
      this.bar = this.getBarData(this.primaryData, this.secondaryData)
      this.barHour = this.getBarData(this.primaryData, this.secondaryData, true)
      if (refreshOnlyBarChart) {
        this.loading = false
        return
      }
      // console.warn(`primaryData - `, this.primaryData)
      this.loading = true
      process.nextTick(() => {
        if (!_.get(this.primaryData, 'pavilion.hideGenders', false)) {
          this.pieGenders = this.getPieData(this.primaryData, false, 'gender')
          this.getPiePercentages(this.pieGenders, true)
        }
        if (!_.get(this.primaryData, 'pavilion.hideAgeRanges', false)) {
          this.pieAgeRanges = this.getPieData(this.primaryData, false, 'age')
          this.getPiePercentages(this.pieAgeRanges, true, true)
        }
        this.loading = false
        this.$forceUpdate()
      })
    },
    getWeatherColors(data) {
      return _.map(_.get(data, 'weather', []), d =>
        _.get(this.weatherColorMap, `[${d.status}]`, false)
      )
    },
    getTrafficByHour(data) {
      if (this.highlightedTrafficValue === 0) {
        return _.map(_.groupBy(data.abdTrafficHours, 'time'), hour => _.sum(_.map(hour, 'count')))
      }
      return _.map(
        _.get(data, `abdByDate[${this.highlightedTrafficValue - 1}].visitsByHour`, []),
        'count'
      )
    },
    formatBarData(data, perHour) {
      if (!perHour) {
        return _.map(_.get(data, 'abdTraffic'), 'count')
      }
      return this.getTrafficByHour(data)
    },
    getBarData(data, secondaryData = false, perHour = false) {
      const barData = {
        data: {
          datasets: [
            {
              datasetIndex: 0,
              label: TranslateService.get('T_ON_STAND_VISITORS'),
              data: this.formatBarData(data, perHour),
              backgroundColor: '#8DE971',
              barPercentage: 0.2
            }
          ],
          labels: this.getTrafficLabels(data, secondaryData, false, perHour),
          chartSelectorLabels: this.getTrafficLabels(
            data,
            secondaryData,
            perHour ? false : true,
            perHour
          )
        }
      }
      if (!perHour) {
        _.set(
          barData,
          'data.datasets[0].weatherStatuses',
          _.map(_.get(data, 'weather', []), 'status')
        )
      }
      _.set(barData, 'options', {
        scales: {
          y: {
            grace: perHour ? '25%' : '50%'
          }
        },
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            align: 'center',
            opacity: 1,
            labels: {
              title: {
                font: {
                  family: 'weatherFont',
                  size: 10
                },
                formatter: this.weatherFormatter,
                anchor: 'end',
                align: 'top',
                offset: '12',
                color: context => {
                  return this.getWeatherColors(
                    context.dataset.datasetIndex === 0 ? data : secondaryData
                  )
                }
              },
              value: {
                formatter: function(value, context) {
                  const val = _.get(context, `dataset.data[${context.dataIndex}]`, 0)
                  return val === 0 ? '' : numeral(val).format('0,0')
                },
                font: {
                  family: 'SourceSansPro-Bold',
                  size: '8px',
                  weight: '700'
                },
                anchor: 'end',
                align: 'top',
                offset: '0',
                color: 'black'
              }
            }
          }
        }
      })
      return barData
    },
    getTrafficLabels(data, secondaryData = false, fullDate = false, perHour = false) {
      if (secondaryData) {
        return _.map(
          this.getTrafficDates(data, perHour),
          (date, i) => TranslateService.get('T_DAY') + ` ${i + 1}`
        )
      }
      if (perHour) {
        return this.getTrafficDates(data, perHour)
      }
      if (fullDate) {
        return _.map(this.getTrafficDates(data, perHour), date => moment(date).format('DD/MM/YYYY'))
      }
      return _.map(this.getTrafficDates(data, perHour), date => moment(date).format('DD/MM'))
    },
    getTrafficDates(data, perHour = false) {
      if (perHour) {
        const times = []
        _.each(_.get(data, 'abdByDate', []), date => {
          _.merge(times, _.map(_.get(date, 'visitsByHour', []), 'time'))
        })
        return times
      }
      return _.compact(
        _.map(_.get(data, 'abdTraffic'), abdTrafficDay => _.get(abdTrafficDay, 'date', false))
      )
    },
    weatherFormatter(value, context) {
      if (_.get(context, 'dataset.weatherStatuses.length', 0) === 0 || value === 0) {
        return ''
      }
      const weather = _.get(context, `dataset.weatherStatuses[${context.dataIndex}]`, 'not-defined')
      return this.weatherMap[weather] || ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';
#traffic-section {
  .v-tabs-items {
    min-height: $card-sub-section;
  }
  .demography-pie {
    width: 47.5%;
    .pie-chart {
      width: 60%;
      height: 136px !important;
      margin: 0 auto;
    }
  }
  .cards-row {
    &.center {
      .pie-chart {
        height: 145px !important;
      }
    }
  }
}

.map {
  margin-bottom: 16px;
  max-width: 100%;
  &.rounded {
    border-radius: $half-margin;
  }
}
.areas-table {
  table {
    width: 90%;
    margin: $standard-margin auto;
    font-style: italic;
    font-weight: 400;
    font-size: $scale-vw * 12;
    line-height: $scale-vw * 24;
    text-align: center;
    color: white;
  }
  td {
    border: 2px solid black;
    border: $table-border;
  }
  tr {
    height: 17px;
    background: $table-odd-row-background;
    &:nth-child(even) {
      background: $table-even-row-background;
    }
  }
}
.show-name {
  margin: $standard-margin 0;
  text-align: left;
}
.sub-section {
  display: flex;
}
.traffic-by-nameplate {
  width: 40%;
}
.floorplans {
  width: 50%;
}
.areas-table {
  color: black;
  border-radius: 4px;
  border: 1px solid black;

  table {
    margin: 0;
    width: 100%;
  }
  tr {
    border-bottom: 0.5px solid #000;
    td {
      color: black;
      font-style: italic;
      font-weight: 400;
      font-size: 8px;
      line-height: 12px;
      padding: 4px;
      text-align: left;
      border: none;
      border-right: 0.5px solid #000;
      &:last-child {
        border-right: none;
      }
    }
    &:nth-child(even) {
      background-color: #e6e6e6;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
.map {
  margin-top: 0;
  border-radius: 4px;
  width: 100%;
}
.total-traffic {
  font-style: italic;
  font-weight: normal;
  font-size: 8px;
  line-height: 10px;
  margin-bottom: 8px;
  span {
    font-weight: bold;
  }
}
</style>
