<template>
  <div v-if="showBlock(0) || showBlock(1)" class="demography-pies">
    <div
      v-if="!compare"
      class="cards-row"
      :class="{
        center: !showBlock(0) || !showBlock(1)
      }"
    >
      <template v-for="(pie, i) in pies">
        <div v-if="showBlock(i)" :key="i" class="demography-pie">
          <h2>{{ `T_DEMOGRAPHY_${titles[i]}` | translate }}</h2>
          <pie-chart :data="pie.data" :options="pie.options" :highlight-value="getHighlight(i)" />
          <pie-chart-legend :data="pie" only-percentages />
        </div>
        <!-- <cannot-compare-data v-else :key="i" no-data /> -->
      </template>
    </div>
    <div v-else>
      <h2>{{ `T_DEMOGRAPHY_${titles[0]}` | translate }}</h2>
      <div class="compare-show-highlighted">
        <template v-for="(pie, i) in pies">
          <div v-if="showBlock(i)" :key="i" class="show">
            <pie-chart
              :data="pies[i].data"
              :options="pies[i].options"
              :highlight-value="getHighlight(i)"
            />
            <pie-chart-legend compare :data="pies[i]" only-percentages />
          </div>
          <cannot-compare-data v-else :key="i" />
          <v-divider v-if="i === 0" :key="'divider-' + i" vertical />
        </template>
      </div>
      <percentages-disclaimer />
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import CannotCompareData from '@/components/CannotCompareData.vue'
import PieChart from '@/components/Charts/PieChart.vue'
import PieChartLegend from '@/components/PieChartLegend.vue'
import PercentagesDisclaimer from '@/components/PercentagesDisclaimer.vue'

export default {
  components: {
    CannotCompareData,
    PieChart,
    PieChartLegend,
    PercentagesDisclaimer
  },
  props: {
    titles: {
      type: Array,
      default: () => []
    },
    hide: {
      type: Array,
      default: () => []
    },
    pies: {
      type: Array,
      default: () => []
    },
    highlights: {
      type: Array,
      default: () => []
    },
    compare: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getHighlight(i) {
      return _.get(this.highlights, `[${i}]`, _.get(this.highlights, `[0]`, 0))
    },
    showBlock(i) {
      return (
        !this.hide[i] &&
        _.sum(
          _.map(_.get(this.pies[i], 'data.datasets', []), (dataset) =>
            _.sum(_.get(dataset, 'data', []))
          )
        ) !== 0
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';
.demography-pie,
.cannot-compare-data {
  width: 47.5%;
}
</style>

<style lang="scss">
.v-application {
  #traffic-section {
    .demography-pies {
      .pie-chart,
      .cannot-compare-data {
        height: 120px !important;
      }
      .cannot-compare-data {
        min-height: auto;
      }
    }
    &.compare {
      .pie-chart,
      .cannot-compare-data {
        height: 120px !important;
      }
      .cannot-compare-data {
        min-height: auto;
      }
    }
  }
}
</style>
<style lang="scss">
@import '../varsDesktop.scss';
.v-application {
  &.desktop .sub-section .demography-pies h2 {
    margin: $half-margin 0;
  }
  #traffic-section {
    &.compare {
      .pie-chart,
      .cannot-compare-data {
        margin-top: $standard-margin;
      }
    }
  }
}
</style>

<style lang="scss">
@import '../vars.scss';
.v-application {
  &.mobile .sub-section .demography-pies h2 {
    margin: $half-margin 0;
  }
  #traffic-section {
    &.compare {
      .cannot-compare-data {
        margin-top: $standard-margin;
      }
    }
  }
}
</style>
