<template>
  <div
    v-if="data"
    class="events-menu"
    :class="{ compare: compare, loading: loading, 'is-for-desktop': isForDesktop }"
  >
    <div v-for="obj in data" :key="obj.year" class="year">
      <div class="year-title">
        {{ obj.year }}
      </div>
      <div class="year-divider" />

      <v-btn
        v-for="pavilion in obj.pavilions"
        :key="pavilion._id"
        rounded
        pill
        elevation="0"
        class="pavilion"
        active-class="active-btn"
        :disabled="
          (compare && pavilion._id === compare._id) ||
          (loading && selectedPavilion && pavilion._id !== selectedPavilion._id)
        "
        :ripple="!selectedPavilion || (selectedPavilion && pavilion._id !== selectedPavilion._id)"
        :text="pavilion !== selectedPavilion"
        :class="{
          'active-btn': selectedPavilion && pavilion._id === selectedPavilion._id,
          'only-admin': pavilion && pavilion.disabled
        }"
        @click="selectedPavilionCallback(pavilion)"
      >
        {{ formatEventName(pavilion.event.name) }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import TranslateService from '@/services/TranslateService'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    selectedPavilion: {
      type: [Object, Boolean],
      default: () => {}
    },
    selectedPavilionCallback: {
      type: Function,
      default: () => {}
    },
    compare: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    singleEvent: {
      type: Boolean,
      default: false
    },
    isForDesktop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    formatEventName(eventName, length = 44) {
      return _.truncate(TranslateService.get(eventName), {
        length: this.isForDesktop ? 35 : length,
        omission: '...'
      })
    },
    handleClick(pavilion) {
      if (
        this.singleEvent ||
        _.get(pavilion, '_id', false) !== _.get(this.selectedPavilion, '_id', false)
      ) {
        this.selectedPavilionCallback(pavilion)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../vars.scss';
.events-menu {
  margin: 0 auto;
  width: 90%;
  .year {
    margin-top: $double-margin;
    .year-title {
      font-style: italic;
      font-weight: 400;
      font-size: $scale-vw * 25;
      line-height: $scale-vw * 31;
      display: flex;
      align-items: center;
    }
    .year-divider {
      width: 100%;
      border-bottom: 1px dashed white;
      margin: $standard-margin 0;
    }
  }
  .pavilion {
    margin: $half-margin 0;
    display: block;
  }
  .event-name {
    font-style: normal;
    font-weight: 700;
    font-size: $scale-vw * 19;
    line-height: $scale-vw * 24;
  }
  &.loading {
    .v-btn {
      touch-action: none;
      pointer-events: none;
    }
  }
  &.compare {
    margin: 0;
    .v-btn {
      color: $primary;
    }
    .v-btn.v-btn--disabled {
      color: $grey !important;
    }
  }
  &.is-for-desktop {
    width: 100%;
    .year {
      margin-top: 0;
    }
    .year-title {
      font-style: italic;
      font-weight: 400;
      font-size: $desktop-scale-vw * 25;
      line-height: $desktop-scale-vw * 31;
    }
    .year-divider,
    .pavilion {
      margin: 16px 0;
    }
  }
}
</style>

<style lang="scss">
@import '../vars.scss';
.v-application
  .v-application--wrap
  .v-navigation-drawer
  .events-menu
  .year
  .v-btn.only-admin
  .v-btn__content {
  color: $red;
}
.events-menu.is-for-desktop {
  .pavilion {
    .v-btn__content {
      text-transform: none !important;
      font-style: normal;
      font-weight: 700;
      font-size: $desktop-scale-vw * 19 !important;
      line-height: $desktop-scale-vw * 24 !important;
    }
  }
}
</style>
