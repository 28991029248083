<template>
  <div id="nav-bar" :class="{ 'logged-in': user }">
    <v-menu offset-y dark tile min-width="100%" content-class="elevation-0">
      <template #activator="{ attrs, on }">
        <v-app-bar flat app dense :height="user ? '40px' : '72px'">
          <div class="menu-wrapper" :class="{ 'no-user': !user }">
            <v-img
              class="logo-imagination"
              src="@/assets/imagination-logo.svg"
              contain
              :max-width="user ? '60' : '90'"
              :max-height="user ? '16' : '24'"
              v-bind="attrs"
              v-on="on"
            />
            <div class="button" @click="onClickLanguage()">
              <v-img
                v-if="TranslateService.currentLocale === 'zhCN'"
                src="@/assets/icons/lang-cn.svg"
                max-height="24"
                max-width="24"
              />
              <v-img v-else src="@/assets/icons/lang-en.svg" max-height="24" max-width="24" />
              <span v-if="user" class="current-language-key">{{ getCurrentLanguageKey() }}</span>
            </div>
          </div>
        </v-app-bar>
      </template>
    </v-menu>
  </div>
</template>

<script>
import _ from 'lodash'
import TranslateService from '@/services/TranslateService'

export default {
  components: {},
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      TranslateService
    }
  },
  methods: {
    getCurrentLanguageKey() {
      return TranslateService.get(`T_${_.toUpper(TranslateService.currentLocale)}`)
    },
    async onClickLanguage() {
      TranslateService.setLocale(TranslateService.currentLocale === 'enUS' ? 'zhCN' : 'enUS')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../varsDesktop.scss';
@import '../../navBar.scss';
#nav-bar {
  height: 40px;
  .button {
    display: flex;
    align-items: center;
  }
  .current-language-key {
    font-style: normal;
    font-weight: 700;
    font-size: $scale-vw * 16;
    line-height: $scale-vw * 20;
    color: white;
    margin-left: 8px;
  }
  &:not(.logged-in) {
    padding: 32px;
  }
  &.logged-in {
    padding-left: 0px;
    .v-sheet {
      background-color: black;
      transition: background-color 0.3s;
      .menu-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        &.no-user {
          background-color: transparent;
        }
      }
    }
  }
}
</style>
