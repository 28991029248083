<template>
  <div class="no-graph-data">
    <v-img
      src="@/assets/icons/no-info.svg"
      lazy-src="@/assets/icons/no-info.svg"
      width="80"
      height="80"
      max-height="80"
    />
    <div class="description">{{ 'T_NO_DATA_FOUND_FOR_THIS_PERIOD' | translate }}</div>
  </div>
</template>
<script>
export default {}
</script>

<style lang="scss" scoped>
@import '../varsDesktop.scss';
.no-graph-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
  .description {
    color: $purple;
    font-style: italic;
    font-weight: 400;
    font-size: inherit;
    line-height: inherit;
    margin-top: $standard-margin;
  }
}
@media (min-width: 500px) {
  .no-graph-data {
    .description {
      font-size: 16px;
      line-height: 16px;
    }
  }
}
</style>
