<template>
  <v-card class="custom-list dark-background">
    <div v-if="compareShow.length > 0 && circleColor.length > 0" class="show-title">
      <span class="circle-color" :style="`background-color:${circleColor}`" />
      <span>{{ compareShow }}</span>
    </div>
    <div v-for="(item, i) in items" :key="i" class="item">
      <span class="name">
        <span
          v-if="showColors && item.borderColor"
          class="color"
          :style="`background-color:${item.borderColor}`"
        />
        <template v-if="item.label && item.label.enUS">{{ item.label | translate }}</template>
        <template v-else>{{ item.label }}</template>
      </span>
      <span class="traffic-value">{{ item.value | numeral('0,0') }}</span>
    </div>
  </v-card>
</template>

<script>
// import _ from 'lodash'
export default {
  props: {
    showColors: {
      type: Boolean,
      default: false
    },
    compareShow: {
      type: String,
      default: ''
    },
    circleColor: {
      type: String,
      default: ''
    },
    colors: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';
.custom-list {
  margin-top: $standard-margin;
  margin-bottom: $standard-margin * 1.5;
  padding: $half-margin $standard-margin;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: italic;
    .name {
      max-width: 60%;
      text-align: left;
      font-weight: 400;
      font-size: $scale-vw * 12;
      line-height: $scale-vw * 15;
    }
    .color {
      display: inline-block;
      height: $half-margin;
      width: $half-margin;
      border-radius: 50%;
      margin-right: $half-margin;
    }
    .traffic-value {
      text-align: right;
      font-weight: 700;
      font-size: $scale-vw * 14;
      line-height: $scale-vw * 18;
    }
  }
  .item + .item {
    margin-top: $half-margin / 2;
  }
  .show-title {
    color: $primary;
    font-style: italic;
    font-weight: 400;
    font-size: $scale-vw * 12;
    line-height: $scale-vw * 15;
    margin-bottom: $standard-margin;
  }
}

@media (min-width: 500px) {
  .custom-list {
    margin: 0 auto;
    margin-top: $desktop-standard-margin;
    margin-bottom: $desktop-standard-margin * 1.5;
    padding: $desktop-half-margin $desktop-standard-margin;
    .item {
      .name {
        font-size: $desktop-scale-vw * 14;
        line-height: $desktop-scale-vw * 17;
      }
      .color {
        display: inline-block;
        height: $desktop-half-margin;
        width: $desktop-half-margin;
        margin-right: $desktop-half-margin;
      }
      .traffic-value {
        font-size: $desktop-scale-vw * 16;
        line-height: $desktop-scale-vw * 20;
      }
    }
    .item + .item {
      margin-top: $desktop-half-margin / 2;
    }
    .show-title {
      font-size: $desktop-scale-vw * 14;
      line-height: $desktop-scale-vw * 17;
      margin-bottom: $desktop-standard-margin;
    }
  }
}
</style>
