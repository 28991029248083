<template>
  <div id="traffic-section-page-3" class="pdf-sub-section">
    <div class="section-sidebar">
      <div class="section-title">
        {{ 'T_STAND_TRAFFIC' | translate }}
      </div>
    </div>
    <div v-if="primaryData" class="section-content">
      <div class="info-box">
        <div class="section-title big">{{ 'T_AREA_ATTRACTIVENESS' | translate }}</div>
        <bubble-chart
          v-if="primaryData"
          is-for-pdf
          :data="primaryData"
          :reloading-bubble="reloadingBubble"
          :chart-data="bubbleChart.data"
          :background-image="getBubbleChartBackground(primaryData)"
        />
      </div>
      <img class="pdf-divider" src="@/assets/divider.svg" />
      <div class="section-title big">
        {{ 'T_AREA_POPULARITY' | translate }}
      </div>
      <bar-chart
        is-for-pdf
        :data="barAttractiveness.data"
        :options="barOptions"
        :highlight-value="highlightedAttractivenessDate"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
// import numeral from 'numeral'
import BaseSection from '@/mixins/BaseSection'
import TrafficSection from '@/mixins/TrafficSection'
import BubbleChart from '@/components/Charts/BubbleChart.vue'

export default {
  name: 'Traffic',
  components: { BubbleChart },
  mixins: [BaseSection, TrafficSection],
  data() {
    return {
      pdfPage: 'traffic-3',
      bubbleChart: false,
      barAttractiveness: false,
      maxAreasToDisplay: 10,
      showMaxDataReached: false,
      barOptions: {
        plugins: {
          datalabels: {
            display: true,
            align: 'center',
            opacity: 1,
            labels: {
              title: {
                font: {
                  family: 'SourceSansPro-Bold',
                  size: 8,
                  weight: 700
                },
                anchor: 'end',
                align: 'top',
                offset: '0',
                color: 'black',
                formatter: function (value, context) {
                  const val = _.get(context, `dataset.data[${context.dataIndex}]`, 0)
                  return val === 0 ? '' : `${Math.round(val * 100) / 100}%`
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    gotData() {
      this.bubbleChart = this.getBubbleChart(this.primaryData)
      this.getBarAttractiveness()
      if (_.get(this.barAttractiveness, 'data.labels.length', 0) > this.maxAreasToDisplay) {
        console.warn(
          `Bar chart has more than ${this.maxAreasToDisplay} areas, will truncate the data`
        )
        _.set(
          this.barAttractiveness,
          'data.labels',
          _.take(this.barAttractiveness.data.labels, this.maxAreasToDisplay)
        )
        _.set(
          this.barAttractiveness,
          'data.datasets[0].data',
          _.take(this.barAttractiveness.data.datasets[0].data, this.maxAreasToDisplay)
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';
#traffic-section-page-3 {
  .bubble-chart {
    display: block;
    width: 60%;
    height: auto;
    margin: 0 auto;
  }
}
</style>
