<template>
  <div class="sub-type-selector" :class="{ 'is-for-desktop': isForDesktop }">
    <div
      v-for="type in types"
      :key="type.key"
      class="type"
      :class="{ selected: type.key === currentLocalType }"
      @click="changeData(type.key)"
    >
      {{ type.label | translate }}
      <div class="underline" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    types: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentType: {
      type: String,
      default: ''
    },
    isForDesktop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentLocalType: 0
    }
  },
  watch: {
    currentType() {
      this.currentLocalType = _.clone(this.currentType)
    }
  },
  mounted() {
    this.currentLocalType = _.clone(this.currentType)
  },
  methods: {
    changeData(val) {
      this.currentLocalType = val
      this.$emit('update:currentType', this.currentLocalType)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';
.sub-type-selector {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-style: normal;
  font-weight: 700;
  font-size: $scale-vw * 14;
  line-height: $scale-vw * 18;
  text-align: center;
  color: $purple;
  margin: $standard-margin 0;
  .type {
    .underline {
      width: 100%;
      height: 2px;
      background-color: $cyan;
      transform: scaleX(0);
      transition: all 0.3s;
    }
    &.selected {
      color: $cyan;
      .underline {
        transform: scaleX(1);
      }
    }
  }
  &.is-for-desktop {
    font-size: $desktop-scale-vw * 16;
    line-height: $desktop-scale-vw * 20;
    color: $purple;
    margin: $desktop-standard-margin 0;
    .type {
      padding: 8px;
      cursor: pointer;
    }
  }
}
</style>
