// import _ from 'lodash'
import moment from 'moment'
import AnalyticDataService from '@/services/AnalyticDataService'
import { _ } from 'core-js'

class CompareDataService {
  constructor() {
    this.primaryData = null
    this.secondaryData = null

    this.primaryPavilion = null
    this.secondaryPavilion = null

    this.days = null
    this.onChangeCallbackList = []

    this.showPrimary = true
    this.showSecondary = true
  }

  async setPrimaryPavilion(pavilion, secondaryPavilion, forceUpdate = false) {
    this.showPrimary = true
    this.days = [0, pavilion.event.days - 1]
    this.primaryPavilion = pavilion
    const selectedDates = this.daysToPavilionDates(this.primaryPavilion, this.days)
    // console.warn(`CompareDataService - setPrimary`)
    this.primaryData = await AnalyticDataService.getData(
      this.primaryPavilion,
      selectedDates,
      forceUpdate
    )

    if (secondaryPavilion) {
      this.secondaryPavilion = secondaryPavilion
      const selectedDates = this.daysToPavilionDates(this.secondaryPavilion, this.days)
      this.secondaryData = await AnalyticDataService.getData(
        this.secondaryPavilion,
        selectedDates,
        forceUpdate
      )
    } else {
      this.secondaryPavilion = null
      this.secondaryData = null
    }
    // console.warn(`CompareDataService - setPrimaryPavilion`, this.primaryData)
    await this.handleDataChange()
  }

  async setSecondaryPavilion(pavilion) {
    this.showSecondary = true
    this.secondaryPavilion = pavilion
    if (this.secondaryPavilion) {
      const selectedDates = this.daysToPavilionDates(this.secondaryPavilion, this.days)
      console.warn(`CompareDataService - setSecondary`)
      this.secondaryData = await AnalyticDataService.getData(this.secondaryPavilion, selectedDates)
    } else {
      this.secondaryData = null
    }
    console.warn(`CompareDataService - setSecondaryPavilion`, this.secondaryPavilion)

    await this.handleDataChange()
  }

  async setDays(days) {
    this.days = days
    let primaryData = false
    if (this.primaryPavilion) {
      const selectedDates = this.daysToPavilionDates(this.primaryPavilion, this.days)
      // console.warn(`CompareDataService - setDays - 1`, selectedDates, days)
      primaryData = await AnalyticDataService.getData(this.primaryPavilion, selectedDates)
      if (primaryData) {
        this.primaryData = primaryData
      }
    }

    if (this.secondaryPavilion) {
      const selectedDates = this.daysToPavilionDates(this.secondaryPavilion, this.days)
      // console.warn(`CompareDataService - setDays - 2`)
      this.secondaryData = await AnalyticDataService.getData(this.secondaryPavilion, selectedDates)
    }
    if (primaryData) {
      await this.handleDataChange()
    } else {
      console.info(`CompareDataService - setDays - no data, will not send event`)
    }
  }

  daysToPavilionDates(pavilion, days) {
    const startDate = moment(pavilion.event.startDate)
      .startOf('day')
      .add(days[0], 'd')
      .format('YYYY-MM-DD')
    const endDate = moment(pavilion.event.startDate)
      .startOf('day')
      .add(days[1], 'd')
      .format('YYYY-MM-DD')
    return [startDate, endDate]
  }

  eventDatesToDays(pavilion, dates) {
    const startOfDay = moment(pavilion.event.startDate).startOf('day')
    const startDay = moment.duration(moment(dates[0]).startOf('day').diff(startOfDay)).asDays()
    const endDay = moment.duration(moment(dates[1]).startOf('day').diff(startOfDay)).asDays()
    return [startDay, endDay]
  }

  onChangeData(onChangeCallback) {
    this.onChangeCallbackList.push(onChangeCallback)
  }

  handleDataChange() {
    return Promise.all(
      _.map(this.onChangeCallbackList, (callback) => {
        callback(this.primaryData, this.secondaryData)
      })
    )
  }
}

export default new CompareDataService()
