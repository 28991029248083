<template>
  <div class="nameplates-list" :class="{ compare: compare, 'is-for-pdf': isForPdf }">
    <div v-if="compare" class="show-title">
      <span class="circle-color" :style="`background-color:${show.circleColor}`" /><span>{{
        show.label
      }}</span>
    </div>
    <table>
      <tr
        v-for="(nameplate, i) in nameplatesToDisplay"
        :key="i"
        :class="{ highlighted: highlightedNameplate === i }"
      >
        <td colspan="2" class="name-column">
          <div v-if="i === 0" class="best-nameplate-wrapper">
            <span class="name">{{ i + 1 }}. {{ nameplate.vehicle.nameplate | translate }}</span>
            <img
              v-if="isForPdf"
              class="star-img"
              src="@/assets/icons/star-black.svg"
              width="8px"
              height="8px"
              max-width="8px"
              max-height="8px"
            />
            <v-img
              v-else
              src="@/assets/icons/star.svg"
              width="8px"
              height="8px"
              max-width="8px"
              max-height="8px"
            />
          </div>
          <span v-else class="name"
            >{{ i + 1 }}. {{ nameplate.vehicle.nameplate | translate }}</span
          >
        </td>
        <td>{{ nameplate.count | numeral }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
import _ from 'lodash'
import TranslateService from '@/services/TranslateService'

export default {
  props: {
    nameplates: {
      type: Array,
      default: () => []
    },
    compare: {
      type: Boolean,
      default: false
    },
    isForPdf: {
      type: Boolean,
      default: false
    },
    highlightedNameplate: {
      type: Number,
      default: 0
    },
    show: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  computed: {
    nameplatesToDisplay() {
      if (!this.compare || !_.get(this.show, 'label', false)) {
        return this.nameplates
      }
      const test = _.cloneDeep(this.nameplates)
      _.each(test, (n) => {
        test.push(n)
      })
      if (test.length > 5) {
        const top5 = _.take(test, 5)
        const others = _.slice(test, 5, test.length)
        top5.push({
          vehicle: {
            nameplate: {
              enUS: TranslateService.get('T_OTHERS'),
              zhCN: TranslateService.get('T_OTHERS')
            }
          },
          count: _.sum(_.map(others, (n) => _.get(n, 'count', 0))),
          percentage: _.sum(_.map(others, (n) => _.get(n, 'percentage', 0)))
        })
        return top5
      }
      return this.nameplates
    }
  }
}
</script>

<style scoped lang="scss">
@import '../vars.scss';
.nameplates-list {
  background: $dark-background;
  border-radius: $half-margin;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: $scale-vw * 14;
  line-height: $scale-vw * 18;
  padding: $half-margin $standard-margin;
  margin-top: $standard-margin;
  table {
    width: 100%;
  }
  td {
    height: 18px;
    padding: 2px 0;
    vertical-align: middle;
    &:first-child {
      > * {
        display: inline-block;
      }
      .v-image {
        margin-left: $half-margin / 2;
      }
      > span {
        max-width: $scale-vw * 190;
      }
      .best-nameplate-wrapper {
        max-width: $scale-vw * 190;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    &:nth-child(2),
    &:last-child {
      font-style: italic;
      text-align: right;
      padding-left: 4px;
    }
    &:last-child {
      color: $purple;
    }
  }
  tr {
    &:first-child {
      color: $yellow;
      td:last-child {
        color: $purple;
      }
    }
    &.highlighted {
      background-color: rgba(128, 148, 221, 0.5);
    }
  }
  &.compare {
    padding: $half-margin;
    margin-top: 0;
    .show-title {
      font-style: italic;
      font-weight: 400;
      font-size: $scale-vw * 12;
      line-height: $scale-vw * 15;
      color: $purple;
      margin-bottom: $standard-margin;
    }
    .name-column {
      max-width: $scale-vw * 100;
    }
    .name {
      max-width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .percentage {
      display: none;
    }
  }
  &.is-for-pdf {
    background-color: #e6e6e6;
    padding: 8px;
    border-radius: 4px;
    margin-top: 24px;
    .star-img {
      margin-left: 4px;
    }
    tr:first-child {
      color: black;
      font-weight: bold;
      .percentage {
        color: black;
      }
    }
    tr {
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 10px;
      .percentage {
        color: black;
        font-weight: normal;
      }
    }
  }
}
@media (min-width: 500px) {
  .nameplates-list {
    border-radius: $desktop-half-margin;
    font-size: $desktop-scale-vw * 14;
    line-height: $desktop-scale-vw * 18;
    padding: $desktop-half-margin $desktop-standard-margin;
    margin-top: $desktop-standard-margin;
    td {
      &:first-child {
        .v-image {
          margin-left: $desktop-half-margin / 2;
        }
        > span {
          max-width: $desktop-scale-vw * 190;
        }
        .best-nameplate-wrapper {
          max-width: $desktop-scale-vw * 190;
        }
      }
    }
    &.compare {
      padding: $desktop-half-margin;
      .show-title {
        font-size: $desktop-scale-vw * 12;
        line-height: $desktop-scale-vw * 15;
        margin-bottom: $desktop-standard-margin;
      }
      .name-column {
        max-width: $desktop-scale-vw * 100;
      }
    }
  }
}
</style>
