<template>
  <div id="touchpoint-page-2" class="pdf-sub-section traffic-experience-page">
    <div class="section-sidebar">
      <div class="section-title">{{ 'T_EXPERIENCE' | translate }} - {{ experienceName }}</div>
    </div>
    <div v-if="primaryData" class="section-content">
      <div class="data-table">
        <table>
          <thead>
            <tr>
              <th></th>
              <th v-for="day in hourlyTableHeaders" :key="day">{{ day }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(timeData, i) in hourlyTable" :key="i">
              <td v-for="(hourData, y) in timeData" :key="`data-${y}`" :class="{ time: y === 0 }">
                {{ hourData }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <img
        v-if="funnel && funnel.data && funnel.data.length > 0"
        class="pdf-divider"
        src="@/assets/divider.svg"
      />
      <funnel-chart v-if="primaryData" :data="funnel" is-for-pdf />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import BaseSection from '@/mixins/BaseSection'
import TrafficSection from '@/mixins/TrafficSection'
import ChartsDataService from '@/services/ChartsDataService'
import TranslateService from '@/services/TranslateService'
import FunnelChart from '@/components/FunnelChart.vue'

export default {
  name: 'Traffic',
  components: { FunnelChart },
  mixins: [BaseSection, TrafficSection],
  data() {
    return {
      pdfPage: 'touchpoint-page-2',
      hourlyTableHeaders: [],
      hourlyTable: [],
      ChartsDataService
    }
  },
  computed: {
    experienceName() {
      return _.get(this.experience, `${TranslateService.currentLocale}.name`, '')
    },
    experience() {
      const experience = _.find(_.get(this.primaryData, 'experiences', []), {
        _id: this.pdfExperience
      })
      return _.isUndefined(experience) ? false : experience
    }
  },
  async mounted() {},
  methods: {
    getSouvenirTypes() {
      const souvenirTypes = []
      _.each(_.get(this.experience, 'souvenirTypes', []), (type) => {
        souvenirTypes.push(TranslateService.get('T_' + _.toUpper(type)))
      })
      return souvenirTypes.length === 0 ? false : _.join(souvenirTypes, ', ')
    },
    getFunnel(data) {
      if (!data) {
        return false
      }
      return {
        labels: this.getConversionFunnelLabels(data),
        data: this.getFunnelData(data)
      }
    },
    shouldDisplayPlayedSouvenirs(data) {
      let displayPlayed = false
      _.each(_.get(data, 'experiences', []), (experience) => {
        if (_.includes(experience.souvenirTypes, 'video')) {
          displayPlayed = true
          return false
        }
      })
      return displayPlayed
    },
    getConversionFunnelLabels(data) {
      let labels = [
        'T_CAPACITY',
        'T_ACTIVATION_COMPLETED',
        'T_SOUVENIR_RETRIEVED',
        'T_SOUVENIR_PLAYED',
        'T_SOUVENIR_SHARED',
        'T_SOUVENIR_DOWNLOADED'
      ]
      if (!this.getSouvenirTypes()) {
        labels = ['T_CAPACITY', 'T_ACTIVATION_COMPLETED']
      }
      if (!this.shouldDisplayPlayedSouvenirs(data)) {
        labels = _.filter(labels, (label) => label !== 'T_SOUVENIR_PLAYED')
      }
      return _.map(labels, (label) => TranslateService.get(label))
    },
    getFunnelData(data) {
      const capacity = _.get(
        _.find(_.get(data, 'experiences', []), { _id: this.experience._id }),
        'capacity',
        0
      )
      if (capacity === 0) {
        return []
      }
      const experienceData = _.filter(_.get(data, 'experiencePerformances', []), {
        experience: this.pdfExperience
      })
      const generated = this.getSumForFunnel(experienceData, 'generated')
      if (!this.getSouvenirTypes()) {
        return [capacity, generated]
      }
      const retrieved = this.getSumForFunnel(experienceData, 'retrieved')
      const played = this.getSumForFunnel(experienceData, 'played')
      const shared = this.getSumForFunnel(experienceData, 'shared')
      const downloaded = this.getSumForFunnel(experienceData, 'downloaded')
      if (!this.shouldDisplayPlayedSouvenirs(data)) {
        return [capacity, generated, retrieved, shared, downloaded]
      }
      return [capacity, generated, retrieved, played, shared, downloaded]
    },
    getSumForFunnel(experienceData, type) {
      return _.sum(_.map(experienceData, (day) => _.get(day, `${type}.count`, 0)))
    },
    gotData() {
      // console.warn(`primaryData: `, this.primaryData)
      const dates = _.map(_.get(this.primaryData, 'abdByDate', []), 'date')
      _.each(dates, (date) => {
        this.hourlyTableHeaders.push(moment(date).format('MM-DD'))
      })
      const experiencesByDate = _.get(this.primaryData, 'experiencesByDate', [])
      const times = []
      _.each(experiencesByDate, (date) => {
        _.each(_.get(date, 'visitsByHour', []), (hour) => {
          if (!_.includes(times, hour.time)) {
            times.push(hour.time)
          }
        })
      })
      _.each(times, (time) => {
        this.hourlyTable.push(
          _.concat(
            time,
            _.map(dates, (date) => {
              const foundVisitsByHour = _.find(
                _.get(_.find(experiencesByDate, { date: date }), 'visitsByHour', []),
                { time: time }
              )
              const foundVisitsForExperience = _.get(
                _.find(_.get(foundVisitsByHour, 'visitsByExperience', []), {
                  experience: this.pdfExperience
                }),
                'count',
                0
              )
              return numeral(foundVisitsForExperience).format('0,0')
            })
          )
        )
      })
      this.funnel = this.getFunnel(this.primaryData)
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../vars.scss';
#touchpoint-page-2 {
  .data-table {
    width: 100%;
  }
  .funnel-chart {
    width: 50%;
  }
}
</style>
