<template>
  <v-menu
    v-model="datePickerMenu"
    :close-on-content-click="false"
    transition="slide-y-transition"
    :offset-y="isForDesktop ? false : true"
    left
    :content-class="contentClass"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        dense
        text
        :color="isForDesktop ? 'white' : 'primary'"
        :ripple="false"
        v-bind="attrs"
        readonly
        class="caption date-picker"
        :placeholder="'T_PICK_A_DATE' | translate"
        append-icon="mdi-calendar"
        v-on="on"
      >
        <span class="date-range-text" :class="{ 'is-for-desktop': isForDesktop }">{{
          dateRangeText
        }}</span>
        <v-img
          v-if="isForDesktop"
          class="calendar-icon"
          src="@/assets/icons/calendar.svg"
          lazy-src="@/assets/icons/calendar.svg"
          width="24"
          height="24"
        />
        <v-img
          v-else
          class="calendar-icon"
          src="@/assets/icons/calendar-purple.svg"
          lazy-src="@/assets/icons/calendar-purple.svg"
          width="24"
          height="24"
        />
      </v-btn>
    </template>

    <v-date-picker
      v-if="!reset"
      v-model="dates"
      range
      full-width
      flat
      elevation="0"
      color="white"
      :min="min()"
      :max="max()"
      :class="{ 'incomplete-range': dates && dates.length < 2 }"
      :show-current="false"
      :allowed-dates="allowedDates"
      :first-day-of-week="1"
      :year-format="formatYear"
      :weekday-format="formatDays"
      :events="eventColors"
      @input="onInputDateRange"
    >
      <div class="after-date-picker">
        <div class="separator" />
        <div
          v-if="pavilion && pavilion.mediaStartDate && pavilion.mediaEndDate"
          class="date-ranges-wrapper"
        >
          <v-btn
            class="date-range-btn"
            color="#71dbd4"
            rounded
            :outlined="!isSelectedRange(pavilion.mediaStartDate, pavilion.mediaEndDate)"
            @click="onSelectDateRange('media')"
            >{{ 'T_MEDIA_DAYS' | translate }}</v-btn
          >
          <v-btn
            class="date-range-btn"
            color="#71dbd4"
            rounded
            :outlined="!isSelectedRange(getPublicStartDate(), event.endDate)"
            @click="onSelectDateRange('public')"
            >{{ 'T_PUBLIC_DAYS' | translate }}</v-btn
          >
          <v-btn
            class="full-show-btn"
            color="#71dbd4"
            rounded
            :outlined="!isSelectedRange(event.startDate, event.endDate)"
            @click="onSelectDateRange('full-show')"
            >{{ 'T_FULL_EVENT' | translate }}</v-btn
          >
        </div>
        <div class="separator" />
        <div class="btn-wrapper">
          <v-btn class="reset-btn" color="secondary" rounded @click="onResetDates()">{{
            'T_RESET' | translate
          }}</v-btn>
          <v-btn
            class="confirm-btn"
            color="primary"
            rounded
            :disabled="!dates || (dates && dates.length !== 2)"
            @click="onClickConfirm()"
            >{{ 'T_CONFIRM' | translate }}</v-btn
          >
        </div>
      </div>
    </v-date-picker>
    <div class="close-icon-wrapper" :class="{ displayed: datePickerMenu }" @click="closeMenu()">
      <v-img class="close-icon" src="@/assets/icons/close.svg" width="24" height="24" />
    </div>
  </v-menu>
</template>
<script>
import moment from 'moment'
import _ from 'lodash'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    event: {
      type: Object,
      default: () => {}
    },
    pavilion: {
      type: Object,
      default: () => {}
    },
    isForDesktop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      reset: false,
      dates: this.value,
      confirmedDates: this.value,
      datePickerMenu: false
    }
  },
  computed: {
    contentClass() {
      return `date-picker-menu${this.isForDesktop ? ' is-for-desktop' : ''}`
    },
    dateRangeText() {
      return this.formatDates(this.confirmedDates).join(' - ')
    }
  },
  watch: {
    event() {
      this.resetDates()
    }
  },
  mounted() {
    if (_.isEmpty(this.value)) {
      this.resetDates()
    }
  },
  methods: {
    eventColors(date) {
      if (
        this.pavilion &&
        !_.isEmpty(this.pavilion.mediaStartDate) &&
        !_.isEmpty(this.pavilion.mediaStartDate)
      ) {
        const mediaDays = this.getMediaDays()
        const mediaDayStart = moment(mediaDays[0], 'YYYY-MM-DD').startOf('day')
        const mediaDayEnd = moment(mediaDays[1], 'YYYY-MM-DD').endOf('day')
        let diff = mediaDayEnd.diff(mediaDayStart, 'days')
        let range = []
        for (let i = 0; i <= diff; i++) {
          range.push(mediaDayStart.add(i, 'days').format('YYYY-MM-DD'))
        }
        // console.warn(date, !_.isUndefined(_.find(range, d => d === date)))
        return !_.isUndefined(_.find(range, (d) => d === date))
      }
      return false
    },
    isSelectedRange(start, end) {
      return start === _.get(this.dates, `[0]`, '??') && end === _.get(this.dates, `[1]`, '??')
    },
    getMediaDays() {
      return this.formatDates([this.pavilion.mediaStartDate, this.pavilion.mediaEndDate])
    },
    formatDates(dates) {
      return _.map(dates, (item) => {
        return moment(item).format('YYYY/MM/DD')
      })
    },
    getPublicStartDate() {
      return moment(this.pavilion.mediaEndDate).add(1, 'd').format('YYYY-MM-DD')
    },
    onSelectDateRange(dateRange) {
      if (dateRange === 'full-show') {
        this.dates = [this.event.startDate, this.event.endDate]
      } else if (dateRange === 'media') {
        this.dates = [this.pavilion.mediaStartDate, this.pavilion.mediaEndDate]
      } else {
        this.dates = [this.getPublicStartDate(), this.event.endDate]
      }
    },
    min() {
      return this.event.startDate
    },
    max() {
      return this.event.endDate
    },
    closeMenu() {
      this.datePickerMenu = false
      this.dates = this.value
      this.confirmedDates = this.value
    },
    onResetDates() {
      this.resetDates(true)
    },
    formatDays(day) {
      return _.toUpper(moment(day).format('dd')[0])
    },
    convertMonth(monthNumber) {
      return moment()
        .month(monthNumber - 1)
        .format('MMMM')
    },
    formatYear() {
      const startDate = _.split(this.event.startDate, '-')
      const endDate = _.split(this.event.endDate, '-')
      if (startDate[0] === endDate[0]) {
        if (startDate[1] === endDate[1]) {
          return `${this.convertMonth(startDate[1])} ${startDate[0]}`
        }
        return `${this.convertMonth(startDate[1])} - ${this.convertMonth(endDate[1])} ${
          startDate[0]
        }`
      }
      return `${this.convertMonth(startDate[1])} ${startDate[0]} - ${this.convertMonth(
        endDate[1]
      )} ${endDate[0]} `
    },
    resetDates(ignoreConfirmed) {
      this.dates = [this.event.startDate, this.event.endDate]
      if (!ignoreConfirmed) {
        this.confirmedDates = [this.event.startDate, this.event.endDate]
        this.$emit('input', this.confirmedDates)
      }
      this.reset = true
      this.$nextTick(() => {
        this.reset = false
        this.$forceUpdate()
      })
    },
    onInputDateRange() {
      if (_.size(this.dates) === 2) {
        this.dates = _.sortBy(this.dates)
      }
    },
    onClickConfirm() {
      if (_.size(this.dates) === 2) {
        this.datePickerMenu = false
        this.confirmedDates = _.clone(this.dates)
        this.$emit('input', this.dates)
      }
    },
    allowedDates(value) {
      return value >= this.event.startDate && value <= this.event.endDate
    },
    startOn() {
      return this.event.startDate
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../vars.scss';

.calendar-icon {
  margin-left: $half-margin;
}
.date-range-text {
  font-style: italic;
  font-weight: 400;
  font-size: $scale-vw * 12;
  line-height: $scale-vw * 15;
  &.is-for-desktop {
    font-size: 12px;
    line-height: 15px;
  }
}
.v-btn {
  padding: 0 !important;
  &:before {
    display: none;
  }
}

.after-date-picker {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: stretch;
  width: 100%;
  .separator,
  .btn-wrapper {
    width: calc(100% - #{$standard-margin * 2});
    margin: 0 auto;
  }
  .separator {
    border-bottom: 1px dashed #b8babc !important;
  }
  .btn-wrapper {
    text-align: right;
  }
  .date-range-btn,
  .full-show-btn,
  .reset-btn,
  .confirm-btn {
    margin-top: $standard-margin;
    padding: 6px 24px !important;
    font-style: normal;
    font-weight: 700;
    font-size: $scale-vw * 12;
    line-height: $scale-vw * 15;
  }
  .reset-btn {
    margin-right: $scale-vw * 4;
  }
  .date-range-btn + .date-range-btn {
    margin-left: $scale-vw * 4;
  }
  .full-show-btn {
    margin-bottom: $scale-vw * 4;
  }
}
.close-icon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  z-index: 1000;
  transition: opacity 0.3s;
  padding: 6px;
  padding-right: $half-margin;
  &.displayed {
    opacity: 1;
  }
}
.close-icon {
  filter: invert(1);
}
.date-picker-menu {
  left: 0 !important;
  top: $menu-height !important;
  min-width: 100% !important;
  width: 100%;
  z-index: 100 !important;
  &.is-for-desktop {
    min-width: $desktop-scale-vw * 400 !important;
    width: $desktop-scale-vw * 400;
    top: 40px !important;

    .close-icon-wrapper {
      padding-right: $desktop-half-margin;
    }
    .after-date-picker {
      .separator,
      .btn-wrapper {
        width: calc(100% - #{$desktop-standard-margin * 2});
      }
      .date-range-btn,
      .full-show-btn,
      .reset-btn,
      .confirm-btn {
        margin-top: $desktop-standard-margin;
        font-size: $desktop-scale-vw * 14;
        line-height: $desktop-scale-vw * 1;
      }
      .reset-btn {
        margin-right: $desktop-scale-vw * 8;
      }
      .date-range-btn + .date-range-btn {
        margin-left: $scale-vw * 4;
      }
    }
  }
}
.date-picker {
  justify-content: space-between;
}
</style>
<style lang="scss">
.date-ranges-wrapper {
  width: 100%;
  .full-show-btn {
    display: block;
  }
  .v-btn {
    .v-btn__content {
      color: white !important;
    }
    &.v-btn--outlined {
      .v-btn__content {
        color: black !important;
      }
    }
  }
}
.v-date-picker-table {
  .v-date-picker-table__events {
    display: none;
  }
  // TODO: If we need to highlight media days differently
  // .v-btn.btn--active:has(.v-date-picker-table__events) {
  //   background-color: white;
  // }
  // .v-btn:has(.v-date-picker-table__events) {
  //   background-color: red;
  //   border-color: red;
  //   border: 2px solid red;
  // }
}
</style>
